// Calculate the subtotal from the service list
const calculateSubtotal = (serviceList) => {
  return serviceList.reduce(
    (sum, service) => sum + (service.show ? service.pr_total : 0),
    0
  );
};

// Calculate the total after applying the discount
const calculateTotal = (
  newSubtotal,
  discount,
  discountType,
  percentagemDescontoOriginal,
  majoracao
) => {
  // Step 1: Apply the majoração (additional INPS benefit) if it exists
  const majoracaoValue = Number(majoracao) || 0;
  const subtotalAposMajoracao = Math.round(newSubtotal - majoracaoValue);

  if (discountType === "%") {
    return Math.round(
      subtotalAposMajoracao -
        (subtotalAposMajoracao * percentagemDescontoOriginal) / 100
    );
  } else if (discountType === "$") {
    return Math.round(subtotalAposMajoracao - discount);
  }
  //Se não houver desconto, retorna-se o subtotal aplicado a majoracao se houver
  return subtotalAposMajoracao;
};

// Main function to update all calculations
const updateCalculations = (
  serviceList,
  originalDiscount,
  discountType,
  percentagemDescontoOriginal,
  majoracao
) => {
  if (!serviceList || !Array.isArray(serviceList) || serviceList.length === 0) {
    console.error("Invalid serviceList:", serviceList);
    return { newSubtotal: 0, newTotal: 0 };
  }

  const newSubtotal = calculateSubtotal(serviceList);
  const newTotal = calculateTotal(
    newSubtotal,
    originalDiscount,
    discountType,
    percentagemDescontoOriginal,
    majoracao
  );

  return {
    newSubtotal,
    newTotal,
  };
};

export { calculateSubtotal, calculateTotal, updateCalculations };
