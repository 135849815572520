import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";

import { useLoading } from "../../../../shared/context/LoadingContext";
import FiltrosRelatorio from "../ui/filtrosRelatorio/FiltrosRelatorio";
import RelatorioProcedimentosCategoria from "./components/RelatorioProcedimentosCategoria";

import toast from "react-hot-toast";
import RelatorioEficienciaMedicos from "./components/RelatorioEficienciaMedicos";
import RelatorioProcedimentosPendentes from "./components/RelatorioProcedimentosPendentes";

const tiposRelatorios = [
  { value: "", label: "Selecione um relatório" },
  {
    value: "procedimento-categoria",
    label: "Relatório de categorias de procedimentos",
  },
  { value: "eficiencia-medicos", label: "Eficiência dos Médicos" },
  { value: "procedimentos-pendentes", label: "Procedimentos Pendentes" },
];

// Definição de quais relatórios suportam quais ações
const relatorioCaps = {
  "procedimentos-categoria": { gerar: true, exportar: true },
  "eficiencia-medicos": { gerar: true, exportar: true },
  "procedimentos-pendentes": { gerar: true, exportar: false },
};

function RelatoriosPlanosTratamento({ clinica, auth }) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dadosClinica, setDadosClinica] = useState();
  const [dadosRelatorio, setDadosRelatorio] = useState({
    open: "",
    dataFiltro: {},
    acao: "",
    filtrosAdicionais: {
      ocultarVazios: true,
      incluirDesativados: false,
    },
    //Contador para forçar a atualização
    atualizacoes: 0,
  });

  useEffect(() => {
    const fetchDadosClinica = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosClinica(responseData.clinica);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchDadosClinica();
  }, [auth.perm, auth.token, sendRequest]);

  const gerarRelatorioHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      toast.error("Por favor, selecione um tipo de relatório.");
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "gerar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  const exportarFicheiroHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      toast.error("Por favor, selecione um tipo de relatório.");
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "exportar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  // Função auxiliar para renderizar o componente de relatório correto
  const renderizarRelatorio = () => {
    const { open, dataFiltro, acao, atualizacoes } = dadosRelatorio;

    if (!open) return null;

    const props = {
      dataFiltro: dataFiltro,
      dadosClinica: dadosClinica,
      auth: auth,
      acao: acao, // Passa a ação para o componente
      atualizacoes: atualizacoes,
    };

    switch (open) {
      case "procedimento-categoria":
        return <RelatorioProcedimentosCategoria {...props} />;
      case "eficiencia-medicos":
        return <RelatorioEficienciaMedicos {...props} />;
      case "procedimentos-pendentes":
        return <RelatorioProcedimentosPendentes {...props} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <FiltrosRelatorio
        tiposRelatorios={tiposRelatorios}
        gerarRelatorioHandler={gerarRelatorioHandler}
        exportarFicheiroHandler={exportarFicheiroHandler}
        relatorioCaps={relatorioCaps} // Passar as capacidades para potencialmente desabilitar botões
        // Manter o tipo de relatório selecionado
        relatorioSelecionado={dadosRelatorio.open}
      />

      {renderizarRelatorio()}
    </div>
  );
}

export default RelatoriosPlanosTratamento;
