import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "./ModalStockHistoricoEntradas.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
import ArticleIcon from "@mui/icons-material/Article";
import { AuthContext } from "../../../shared/context/auth-context";
import { useLoading } from "../../../shared/context/LoadingContext";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const columns = [
  {
    field: "data",
    headerName: "Data",
    flex: 1,
    valueGetter: (params) =>
      moment(params.value, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm"),
  },
  { field: "produto", headerName: "Produto", flex: 3 },
  { field: "lote", headerName: "Lote", flex: 1 },
  {
    field: "qtdAdicionada",
    headerName: "Qtd Adicionada",
    flex: 1,
    type: "number",
  },
  { field: "respMovimento", headerName: "Adicionada por", flex: 2 },
];

function ModalStockHistoricoEntradas({
  handleCloseModalStockHistoricoEntradas,
  sendRequest,
  open,
  clinicaId,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [rows, setRows] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(1, "months").startOf("day")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const auth = useContext(AuthContext);

  useEffect(() => {
    fetchStock();
  }, [sendRequest, clinicaId, auth.token]);

  const fetchStock = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("saida", false);
      formData.append("dataInicio", dataInicial);
      formData.append("dataFim", dataFinal);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/getMovimentosByClinica/${clinicaId}`,
        "PATCH",
        formData,
        { Authorization: "Bearer " + auth.token }
      );

      console.log("responseData", responseData);
      const tempRows = responseData.movimentos
        .filter((mv) => !mv.saida)
        .map((mv) => ({
          id: mv.id,
          data: moment(mv.data).format("DD-MM-YYYY HH:mm"),
          produto: mv.produto.nome,
          lote: mv.lote,
          qtdAdicionada: mv.quantidade,
          respMovimento: mv.responsavelMovimento
            ? mv.responsavelMovimento?.responsavel?.name ||
              mv.responsavelMovimento?.responsavel?.nome
            : "N/A",
        }))
        .sort(
          (a, b) =>
            moment(b.data, "DD-MM-YYYY HH:mm") -
            moment(a.data, "DD-MM-YYYY HH:mm")
        );

      setRows(tempRows);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleExportHistoricoEntradas = () => {
    const sheetData = rows.map((r) => ({
      Data: r.data,
      Produto: r.produto,
      Lote: r.lote,
      Quantidade_Adicionada: r.qtdAdicionada,
      Adicionado_Por: r.respMovimento,
    }));

    generateExcelFile(
      `Historico_Entradas_Stock_${moment().format("YYYY-MM-DD")}.xlsx`,
      "Entradas de Stock",
      sheetData
    );
  };

  return (
    <Modal open={open} onClose={handleCloseModalStockHistoricoEntradas}>
      <Box
        className="fluxo-atendimento__modal__box"
        id="historicoEntradasModal__modal"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Histórico de Entradas
        </h1>
        <div className="historico__dataFilter__container">
          <div className="historico__dataFilter__container__datas">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => setDataInicial(value)}
                value={dataInicial}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => setDataFinal(value)}
                value={dataFinal}
              />
            </LocalizationProvider>

            <span
              onClick={fetchStock}
              className="relatorio__filtros__container__btn relatorio__filtros__container__btn--blue"
            >
              Filtrar
            </span>
          </div>

          <div
            className="financeiro__filtros__filtrar"
            onClick={handleExportHistoricoEntradas}
          >
            <ArticleIcon />
            <span>Exportar Excel</span>
          </div>
        </div>
        <div className="historicoEntradasModal__container">
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50]}
              disableSelectionOnClick
            />
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalStockHistoricoEntradas}
            >
              Fechar
            </span>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalStockHistoricoEntradas;
