import React, { useEffect, useState } from "react";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";
import XLSX from "xlsx-js-style";
import toast from "react-hot-toast";

// Material UI imports
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import PeopleIcon from "@mui/icons-material/People";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventNoteIcon from "@mui/icons-material/EventNote";
import UpdateIcon from "@mui/icons-material/Update";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

// Configurar locale do moment para português
moment.locale("pt-br");

// Componentes estilizados
const SummaryCard = styled(Card)(({ theme, cardtype }) => ({
  height: "100%",
  backgroundColor:
    cardtype === "taxa"
      ? "rgba(255, 152, 0, 0.08)"
      : cardtype === "horarios"
      ? "rgba(33, 150, 243, 0.08)"
      : cardtype === "dias"
      ? "rgba(76, 175, 80, 0.08)"
      : cardtype === "genero"
      ? "rgba(156, 39, 176, 0.08)"
      : cardtype === "mes"
      ? "rgba(211, 47, 47, 0.08)"
      : "rgba(63, 81, 181, 0.08)",
  borderLeft: `4px solid ${
    cardtype === "taxa"
      ? theme.palette.warning.main
      : cardtype === "horarios"
      ? theme.palette.info.main
      : cardtype === "dias"
      ? theme.palette.success.main
      : cardtype === "genero"
      ? theme.palette.secondary.main
      : cardtype === "mes"
      ? theme.palette.error.main
      : theme.palette.primary.main
  }`,
}));

function RelatorioFaltas({
  dataFiltro,
  clinicaId,
  auth,
  acao,
  sendRequest,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada, execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes]);

  const gerarRelatorio = async () => {
    if (!clinicaId) return;

    setError(null);
    startLoading();
    try {
      const formData = new FormData();
      formData.append("clinicaId", clinicaId);
      formData.append(
        "dataInicio",
        dataFiltro.dataInicial.format("YYYY-MM-DD")
      );
      formData.append("dataFim", dataFiltro.dataFinal.format("YYYY-MM-DD"));

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/gerarRelatorioFaltas`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData);
    } catch (err) {
      console.error("Erro ao gerar relatório de faltas:", err);
      setError(
        err.message || "Ocorreu um erro ao gerar o relatório de faltas."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = () => {
    if (!dados) {
      toast.error("É necessário gerar o relatório primeiro!");
      return;
    }

    try {
      // Criar um novo workbook
      const wb = XLSX.utils.book_new();

      // Criar planilha de resumo
      const resumoData = [
        ["RELATÓRIO DE FALTAS"],
        [
          `Período: ${dataFiltro.dataInicial.format(
            "DD/MM/YYYY"
          )} a ${dataFiltro.dataFinal.format("DD/MM/YYYY")}`,
        ],
        [`Clínica: ${dados.clinica.nome || ""}`],
        [],
        ["ESTATÍSTICAS GERAIS"],
        ["Taxa de Faltas", dados.estatisticas.taxaFaltas],
        ["Total de Faltas", dados.estatisticas.totalFaltas],
        ["Total de Consultas", dados.estatisticas.totalConsultas],
        [],
        ["HORÁRIOS COM MAIS FALTAS"],
        ...dados.estatisticas.horariosComMaisFaltas.map((horario) => [horario]),
        [],
        ["DIAS COM MAIS FALTAS"],
        ...dados.estatisticas.diasComMaisFaltas.map((dia) => [dia]),
        [],
        ["FALTAS POR GÊNERO"],
        ...dados.estatisticas.faltasPorGenero.map((genero) => [genero]),
        [],
        ["FALTAS POR FAIXA ETÁRIA"],
        ...dados.estatisticas.faltasPorFaixaEtaria.map((faixa) => [faixa]),
        [],
        ["FALTAS POR MÊS"],
        ...(dados.estatisticas.faltasPorMes || ["Dados insuficientes"]).map(
          (mes) => [mes]
        ),
        [],
        ["FALTAS POR ANTECEDÊNCIA DE AGENDAMENTO"],
        ...(
          dados.estatisticas.faltasPorAntecedencia || ["Dados insuficientes"]
        ).map((antecedencia) => [antecedencia]),
      ];

      const wsResumo = XLSX.utils.aoa_to_sheet(resumoData);

      // Criar planilha com detalhes dos médicos
      const medicosHeader = [
        ["Médico", "Total Agendamentos", "Faltas", "Taxa"],
      ];

      const medicosData = dados.estatisticas.taxaFaltasPorMedico.map(
        (medico) => [medico.nome, medico.total, medico.faltas, medico.taxa]
      );

      const wsMedicos = XLSX.utils.aoa_to_sheet([
        ...medicosHeader,
        ...medicosData,
      ]);

      // Criar planilha com clientes
      const clientesHeader = [
        ["Nome", "Contato", "Total Faltas", "Gênero", "Idade"],
      ];

      const clientesData = dados.estatisticas.clientesComMaisFaltas.map(
        (cliente) => [
          cliente.nome,
          cliente.contato,
          cliente.totalFaltas,
          cliente.genero,
          cliente.idade,
        ]
      );

      const wsClientes = XLSX.utils.aoa_to_sheet([
        ...clientesHeader,
        ...clientesData,
      ]);

      // Criar planilha com recomendações
      const recomendacoesData = [
        ["RECOMENDAÇÕES DA IA PARA REDUÇÃO DE FALTAS"],
        [],
        ...dados.recomendacoes.split("\n").map((linha) => [linha.trim()]),
      ];

      const wsRecomendacoes = XLSX.utils.aoa_to_sheet(recomendacoesData);

      // Aplicar estilos
      // Título
      wsResumo["A1"].s = {
        font: { bold: true, sz: 16 },
        alignment: { horizontal: "center" },
      };

      // Cabeçalhos de seção
      wsResumo["A5"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A10"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A13"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A16"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A19"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A22"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A25"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };

      // Cabeçalhos de tabelas
      for (let i = 0; i < medicosHeader[0].length; i++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: i });
        wsMedicos[cell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } },
          alignment: { horizontal: "center" },
        };
      }

      for (let i = 0; i < clientesHeader[0].length; i++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: i });
        wsClientes[cell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } },
          alignment: { horizontal: "center" },
        };
      }

      // Título da recomendação
      wsRecomendacoes["A1"].s = {
        font: { bold: true, sz: 16 },
        alignment: { horizontal: "center" },
      };

      // Adicionar planilhas ao workbook
      XLSX.utils.book_append_sheet(wb, wsResumo, "Resumo");
      XLSX.utils.book_append_sheet(wb, wsMedicos, "Taxa de Faltas por Médico");
      XLSX.utils.book_append_sheet(wb, wsClientes, "Clientes com Mais Faltas");
      XLSX.utils.book_append_sheet(wb, wsRecomendacoes, "Recomendações");

      // Configurar tamanhos de coluna
      wsResumo["!cols"] = [{ wch: 40 }, { wch: 15 }];
      wsMedicos["!cols"] = [
        { wch: 40 }, // Médico
        { wch: 20 }, // Total
        { wch: 15 }, // Faltas
        { wch: 15 }, // Taxa
      ];
      wsClientes["!cols"] = [
        { wch: 40 }, // Nome
        { wch: 20 }, // Contato
        { wch: 15 }, // Faltas
        { wch: 15 }, // Gênero
        { wch: 10 }, // Idade
      ];
      wsRecomendacoes["!cols"] = [
        { wch: 100 }, // Recomendações
      ];

      // Configurar merges
      wsResumo["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, // Título
        { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // Período
        { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } }, // Clínica
      ];

      wsRecomendacoes["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } }, // Título
      ];

      // Salvar o arquivo
      XLSX.writeFile(
        wb,
        `relatorio_faltas_${moment().format("YYYY-MM-DD")}.xlsx`
      );

      toast.success("Arquivo exportado com sucesso!");
    } catch (err) {
      console.error("Erro ao exportar arquivo:", err);
      toast.error("Erro ao exportar arquivo");
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Faltas
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          {dados && (
            <Typography variant="subtitle1">
              Clínica: {dados.clinica?.nome || ""}
            </Typography>
          )}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            {/* Cards de resumo - primeira linha */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="taxa">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <WarningIcon color="warning" sx={{ mr: 1 }} />
                      <Typography variant="h6">Taxa de Faltas</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="warning.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {dados.estatisticas.taxaFaltas}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {dados.estatisticas.totalFaltas} faltas em{" "}
                      {dados.estatisticas.totalConsultas} agendamentos
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="horarios">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <AccessTimeIcon color="info" sx={{ mr: 1 }} />
                      <Typography variant="h6">Horários</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Horários com mais faltas:</strong>
                    </Typography>
                    <ul style={{ margin: "8px 0", paddingLeft: "20px" }}>
                      {dados.estatisticas.horariosComMaisFaltas
                        .slice(0, 3)
                        .map((horario, idx) => (
                          <li key={idx}>{horario}</li>
                        ))}
                    </ul>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="dias">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <CalendarTodayIcon color="success" sx={{ mr: 1 }} />
                      <Typography variant="h6">Dias da Semana</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Dias com mais faltas:</strong>
                    </Typography>
                    <ul style={{ margin: "8px 0", paddingLeft: "20px" }}>
                      {dados.estatisticas.diasComMaisFaltas
                        .slice(0, 3)
                        .map((dia, idx) => (
                          <li key={idx}>{dia}</li>
                        ))}
                    </ul>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="genero">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PeopleIcon color="secondary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Perfil</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Faltas por gênero:</strong>
                    </Typography>
                    <ul style={{ margin: "8px 0", paddingLeft: "20px" }}>
                      {dados.estatisticas.faltasPorGenero
                        .slice(0, 2)
                        .map((genero, idx) => (
                          <li key={idx}>{genero}</li>
                        ))}
                    </ul>
                  </CardContent>
                </SummaryCard>
              </Grid>
            </Grid>

            {/* Cards de resumo - nova linha para novos dados */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6} md={4}>
                <SummaryCard cardtype="mes">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <EventNoteIcon color="error" sx={{ mr: 1 }} />
                      <Typography variant="h6">Meses</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Faltas por mês (%):</strong>
                    </Typography>
                    <ul style={{ margin: "8px 0", paddingLeft: "20px" }}>
                      {(
                        dados.estatisticas.faltasPorMes || [
                          "Dados insuficientes",
                        ]
                      )
                        .slice(0, 4)
                        .map((mes, idx) => (
                          <li key={idx}>{mes}</li>
                        ))}
                    </ul>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <SummaryCard cardtype="antecedencia">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <UpdateIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Antecedência</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Faltas por antecedência (%):</strong>
                    </Typography>
                    <ul style={{ margin: "8px 0", paddingLeft: "20px" }}>
                      {(
                        dados.estatisticas.faltasPorAntecedencia || [
                          "Dados insuficientes",
                        ]
                      ).map((antecedencia, idx) => (
                        <li key={idx}>{antecedencia}</li>
                      ))}
                    </ul>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <SummaryCard cardtype="tendencia">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <TrendingUpIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Análise de Tendência</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Correlação faltas vs. antecedência:</strong>
                    </Typography>
                    <Box
                      sx={{
                        p: 1,
                        mt: 1,
                        bgcolor: "background.paper",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="body2">
                        {dados.estatisticas.faltasPorAntecedencia &&
                        dados.estatisticas.faltasPorAntecedencia.find((item) =>
                          item.includes("Mais de 1 mês")
                        )
                          ? "Os dados indicam que consultas agendadas com maior antecedência têm maior probabilidade de resultar em faltas."
                          : "Dados insuficientes para análise conclusiva de tendência."}
                      </Typography>
                    </Box>
                  </CardContent>
                </SummaryCard>
              </Grid>
            </Grid>

            {/* Taxa de Faltas por Médico */}
            <Paper sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Taxa de Faltas por Médico
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Médico</TableCell>
                      <TableCell align="right">Total Agendamentos</TableCell>
                      <TableCell align="right">Faltas</TableCell>
                      <TableCell align="right">Taxa</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dados.estatisticas.taxaFaltasPorMedico.map(
                      (medico, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{medico.nome}</TableCell>
                          <TableCell align="right">{medico.total}</TableCell>
                          <TableCell align="right">{medico.faltas}</TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              color:
                                parseFloat(medico.taxa) > 20
                                  ? "error.main"
                                  : parseFloat(medico.taxa) > 10
                                  ? "warning.main"
                                  : "success.main",
                            }}
                          >
                            {medico.taxa}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {/* Pacientes com Mais Faltas */}
            <Paper sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Pacientes com Mais Faltas
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Contato</TableCell>
                      <TableCell align="right">Total Faltas</TableCell>
                      <TableCell>Gênero</TableCell>
                      <TableCell align="right">Idade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dados.estatisticas.clientesComMaisFaltas.map(
                      (cliente, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{cliente.nome}</TableCell>
                          <TableCell>{cliente.contato}</TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: "bold",
                              color:
                                cliente.totalFaltas > 3
                                  ? "error.main"
                                  : cliente.totalFaltas > 1
                                  ? "warning.main"
                                  : "text.primary",
                            }}
                          >
                            {cliente.totalFaltas}
                          </TableCell>
                          <TableCell>{cliente.genero}</TableCell>
                          <TableCell align="right">{cliente.idade}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {/* Nova seção: Análise de Antecedência */}
            <Paper sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Análise de Antecedência vs. Faltas
              </Typography>
              <Box sx={{ p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
                <Typography variant="body1" paragraph>
                  <strong>Conclusão:</strong>{" "}
                  {dados.estatisticas.faltasPorAntecedencia &&
                  dados.estatisticas.faltasPorAntecedencia.find((item) =>
                    item.includes("Mais de 1 mês")
                  ) &&
                  dados.estatisticas.faltasPorAntecedencia.find((item) =>
                    item.includes("Mesma semana")
                  )
                    ? (() => {
                        const mesmaSemana =
                          dados.estatisticas.faltasPorAntecedencia.find(
                            (item) => item.includes("Mesma semana")
                          );
                        const maisDeMes =
                          dados.estatisticas.faltasPorAntecedencia.find(
                            (item) => item.includes("Mais de 1 mês")
                          );

                        const percentMesmaSemana = parseFloat(
                          mesmaSemana.match(/(\d+\.\d+)%/)?.[1] || 0
                        );
                        const percentMaisDeMes = parseFloat(
                          maisDeMes.match(/(\d+\.\d+)%/)?.[1] || 0
                        );

                        if (percentMaisDeMes > percentMesmaSemana) {
                          return "Os dados confirmam que agendamentos feitos com maior antecedência (mais de um mês) têm maior probabilidade de resultar em faltas. Recomenda-se implementar um sistema de lembretes específico para estes agendamentos.";
                        } else {
                          return "A análise não mostra correlação significativa entre a antecedência do agendamento e a probabilidade de faltas.";
                        }
                      })()
                    : "Dados insuficientes para uma análise conclusiva sobre a relação entre antecedência de agendamento e probabilidade de faltas."}
                </Typography>
                <Typography variant="body1">
                  <strong>Recomendação:</strong>{" "}
                  {dados.estatisticas.faltasPorAntecedencia &&
                  dados.estatisticas.faltasPorAntecedencia.find((item) =>
                    item.includes("Mais de 1 mês")
                  )
                    ? "Implementar confirmação obrigatória 48h antes da consulta para pacientes que agendaram com mais de um mês de antecedência. Enviar lembretes escalonados: 7 dias, 3 dias e 24 horas antes."
                    : "Manter o sistema atual de lembretes e monitore os dados para identificar padrões emergentes."}
                </Typography>
              </Box>
            </Paper>

            {/* Nova seção: Análise Sazonal */}
            <Paper sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Análise Sazonal de Faltas
              </Typography>
              <Box sx={{ p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
                <Typography variant="body1" paragraph>
                  <strong>Meses com maior percentagem de faltas:</strong>
                </Typography>
                <ul>
                  {(dados.estatisticas.faltasPorMes || ["Dados insuficientes"])
                    .slice(0, 3)
                    .map((mes, idx) => (
                      <li key={idx}>
                        <Typography variant="body1">{mes}</Typography>
                      </li>
                    ))}
                </ul>
                <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                  <strong>Conclusão:</strong>{" "}
                  {dados.estatisticas.faltasPorMes &&
                  dados.estatisticas.faltasPorMes.length > 0 &&
                  dados.estatisticas.faltasPorMes[0] !== "Dados insuficientes"
                    ? `A análise indica uma concentração de faltas em certos períodos do ano, o que pode estar relacionado a fatores sazonais específicos de Cabo Verde, como períodos de férias, condições climáticas ou fatores culturais.`
                    : "Dados insuficientes para uma análise conclusiva sobre padrões sazonais de faltas."}
                </Typography>
              </Box>
            </Paper>

            {/* Recomendações */}
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Recomendações da IA para Redução de Faltas
              </Typography>
              <Box sx={{ p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
                {dados.recomendacoes.split("\n").map((linha, index) =>
                  linha.trim() ? (
                    <Typography key={index} paragraph>
                      {linha}
                    </Typography>
                  ) : (
                    <Box key={index} sx={{ height: 16 }} />
                  )
                )}
              </Box>
            </Paper>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioFaltas;
