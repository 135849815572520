import React, { useState, useContext, useEffect } from "react";
import "./ModalStockRetirada.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import { useLoading } from "../../../shared/context/LoadingContext";
import moment from "moment";

function ModalStockRetirada({
  handleCloseModalStockRetirada,
  stock,
  sendRequest,
  open,
  atualizarStock,
  stockRetirada,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantidade, setQuantidade] = useState(1);
  const [responsaveisMovimento, setResponsaveisMovimento] = useState([]);
  const [responsavelMovimento, setResponsavelMovimento] = useState();
  const [selectedLote, setSelectedLote] = useState("");
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    fetchFuncionariosAndUser();
    if (
      stockRetirada &&
      stockRetirada.lotes &&
      stockRetirada.lotes.length > 0
    ) {
      setSelectedLote(stockRetirada.lotes[0].lote);
    }
  }, [sendRequest, clinica.clinica._id, auth.token, stockRetirada]);

  const fetchFuncionariosAndUser = async () => {
    startLoading();
    try {
      let grabbers = [];

      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      responseData.funcionarios.forEach((fun) => {
        let fullName = `${fun.nome}${fun.cargo ? ` - ${fun.cargo}` : ""}`;
        if (fullName.endsWith(" - undefined")) {
          fullName = fun.nome;
        }

        grabbers.push({
          id: fun.id,
          nome: `${fun.nome}${fun.cargo ? ` - ${fun.cargo}` : ""}`,
          type: "Funcionario",
        });
      });

      let responseData2;
      try {
        responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      responseData2.users.forEach((user) => {
        let fullName = `${user.name}${
          user.profissao ? ` - ${user.profissao}` : ""
        }`;
        if (fullName.endsWith(" - undefined")) {
          fullName = user.name;
        }

        grabbers.push({
          id: user.id,
          nome: fullName,
          type: "User",
        });
      });

      setResponsaveisMovimento(grabbers);
      setResponsavelMovimento(grabbers[0].id);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const changeResponsavelMovimento = (e) => {
    setResponsavelMovimento(e.target.value);
  };

  const changeQuantidade = (value) => {
    setQuantidade(value);
  };

  const changeLote = (e) => {
    setSelectedLote(e.target.value);
  };

  const getMaxQuantityForSelectedLote = () => {
    const lote = stockRetirada.lotes.find((l) => l.lote === selectedLote);
    return lote ? lote.quantidade : 0;
  };

  const submit = async () => {
    startLoading();
    try {
      const stockItem = stock.stock.find(
        (st) => st.produto._id === stockRetirada.produto._id
      );
      const loteIndex = stockItem.lotes.findIndex(
        (l) => l.lote === selectedLote
      );

      if (loteIndex === -1) {
        throw new Error("Lote não encontrado");
      }

      const updatedLotes = [...stockItem.lotes];
      updatedLotes[loteIndex] = {
        ...updatedLotes[loteIndex],
        quantidade: Math.max(
          updatedLotes[loteIndex].quantidade - Number(quantidade),
          0
        ),
      };

      // Remove lote if quantity becomes zero
      if (updatedLotes[loteIndex].quantidade === 0) {
        updatedLotes.splice(loteIndex, 1);
      }

      const updatedStockItem = {
        produto: stockItem.produto._id,
        lotes: updatedLotes,
        quantidadeTotal: stockItem.quantidadeTotal - Number(quantidade),
        quantidadeIdeal: stockItem.quantidadeIdeal,
      };

      const movimento = {
        clinica: clinica.clinica._id,
        produto: stockRetirada.produto._id,
        lote: selectedLote,
        dataValidade: stockItem.lotes[loteIndex].dataValidade,
        beneficiario: auth.userId,
        quantidade: Number(quantidade),
        data: new Date(),
        saida: true,
      };

      // Find the selected responsavel
      const selectedResponsavel = responsaveisMovimento.find(
        (r) => r.id === responsavelMovimento
      );

      if (selectedResponsavel.type === "User") {
        movimento.responsavelMovimento = {
          tipo: "User",
          responsavel: selectedResponsavel.id,
        };
      } else {
        movimento.responsavelMovimento = {
          tipo: "Funcionario",
          responsavel: selectedResponsavel.id,
        };
      }

      const formData = new FormData();
      formData.append("updatedData", JSON.stringify([updatedStockItem]));
      formData.append("historicoMovimentos", JSON.stringify([movimento]));

      const stockReturned = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/fazerretirada/${stock._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      atualizarStock(stockReturned.stock, "Retirada efetuada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
      handleCloseModalStockRetirada();
    }
  };

  const preSubmitHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submit();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalStockRetirada}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box retirarDoStock__modal"
        id="modal__cinquentaPorCento"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Retirar produto do stock
        </h1>

        <div className="retirarDoStock__container">
          <span className="retirarDoStock__container__nomeProduto">
            {stockRetirada.produto.nome}
          </span>
          <div className="retirarDoStock__container__row">
            <div className="customInputContainer">
              <select
                onChange={changeLote}
                className="customInputSelect"
                value={selectedLote}
              >
                {stockRetirada.lotes.map((lote, index) => (
                  <option key={index} value={lote.lote}>
                    {`${lote.lote} (Qtd: ${lote.quantidade}${
                      lote.dataValidade
                        ? ", Validade: " +
                          moment(lote.dataValidade).format("DD/MM/YYYY")
                        : ""
                    })`}
                  </option>
                ))}
              </select>
              <label className="customInputLabelSelect">Lote*</label>
            </div>

            <InputNumber
              initialValue={1}
              label="Qtd"
              handleChange={changeQuantidade}
              min={1}
              max={getMaxQuantityForSelectedLote()}
              notEmpty={true}
            />

            <div className="customInputContainer">
              <select
                onChange={changeResponsavelMovimento}
                className="customInputSelect"
                value={responsavelMovimento}
              >
                {responsaveisMovimento &&
                  responsavelMovimento &&
                  responsaveisMovimento.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
              </select>
              <label className="customInputLabelSelect">Profissional*</label>
            </div>
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalStockRetirada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={preSubmitHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalStockRetirada;
