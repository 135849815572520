import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DateRangeIcon from "@mui/icons-material/DateRange";

// Recharts para gráficos
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// Configurar locale do moment
moment.locale("pt-br");

// Cores para o gráfico
const COLORS = [
  "#f44336",
  "#ff9800",
  "#2196f3",
  "#4caf50",
  "#9c27b0",
  "#607d8b",
];

// Componentes estilizados
const ErrorChip = styled(Chip)(({ theme, errortype }) => ({
  backgroundColor:
    errortype === "critical"
      ? theme.palette.error.light
      : errortype === "warning"
      ? theme.palette.warning.light
      : theme.palette.info.light,
  color:
    errortype === "critical"
      ? theme.palette.error.contrastText
      : errortype === "warning"
      ? theme.palette.warning.contrastText
      : theme.palette.info.contrastText,
}));

function RelatorioDocumentosEletronicosComErro({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
  filtrosAdicionais,
  atualizarFiltrosAdicionais,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedDoc, setExpandedDoc] = useState(null);
  const [agrupamento, setAgrupamento] = useState("tipo"); // 'tipo', 'codigo', 'emissor'

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes, filtrosAdicionais]); // Reexecuta quando a ação ou filtros mudam

  const gerarRelatorio = async () => {
    console.log("here", dadosClinica);
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar o corpo da requisição com filtros adicionais
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      };

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/getRelatorioDocumentosErros/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      console.log("here 2");
      console.log("responseData", responseData);
      // Atualiza o estado com os dados recebidos
      setDados(responseData.data);
      // Limpar estados de UI
      setExpandedDoc(null);
      setSearchTerm("");
    } catch (err) {
      console.error(
        "Erro ao gerar relatório de documentos eletrônicos com erros:",
        err
      );
      setError(
        err.message ||
          "Ocorreu um erro ao gerar o relatório de documentos eletrônicos com erros."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar os parâmetros de query com filtros adicionais
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      };

      // Chamada para a API de exportação
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/getRelatorioDocumentosErros/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `documentos-eletronicos-erros-${dataInicialFormatada}-a-${dataFinalFormatada}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handler para mudança de aba (entrada vs parcela)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSearchTerm("");
    setExpandedDoc(null);
  };

  // Handler para pesquisa
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handler para expandir/recolher documentos
  const handleExpandDoc = (docId) => {
    setExpandedDoc(expandedDoc === docId ? null : docId);
  };

  // Handler para mudança de agrupamento
  const handleAgrupamentoChange = (event) => {
    setAgrupamento(event.target.value);
  };

  // Handler para atualizar os filtros adicionais
  const handleFiltroChange = (event) => {
    atualizarFiltrosAdicionais({
      [event.target.name]: event.target.checked,
    });
  };

  // Filtrar documentos de acordo com a pesquisa
  const filtrarDocumentos = (docs) => {
    if (!docs) return [];

    return docs.filter(
      (doc) =>
        doc.nrDocumento?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.tipoDocumento?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.emissor?.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.cliente?.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.messages?.some((msg) =>
          msg.description?.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
  };

  // Agrupar documentos por tipo selecionado
  const agruparDocumentos = (docs) => {
    if (!docs) return {};

    return docs.reduce((acc, doc) => {
      let key;

      switch (agrupamento) {
        case "codigo":
          // Agrupar por código de erro (primeiro erro se houver múltiplos)
          key =
            doc.messages && doc.messages.length > 0
              ? doc.messages[0].code
              : "Sem código";
          break;
        case "emissor":
          // Agrupar por emissor
          key = doc.emissor ? doc.emissor.nome : "Não especificado";
          break;
        case "tipo":
        default:
          // Agrupar por tipo de documento
          key = doc.tipoDocumento || "Não especificado";
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(doc);
      return acc;
    }, {});
  };

  // Preparar dados para o gráfico de erros
  const prepararDadosGrafico = () => {
    if (!dados) return [];

    // Unir documentos de entradas e parcelas
    const todosDocumentos = [
      ...(dados.documentosEntrada || []),
      ...(dados.documentosParcela || []),
    ];

    // Obter contagem de cada código de erro
    const contagemErros = todosDocumentos.reduce((acc, doc) => {
      if (doc.messages && doc.messages.length > 0) {
        doc.messages.forEach((msg) => {
          const codigoErro = msg.code || "Desconhecido";
          acc[codigoErro] = (acc[codigoErro] || 0) + 1;
        });
      }
      return acc;
    }, {});

    // Transformar em array para o gráfico
    return Object.entries(contagemErros)
      .map(([codigo, quantidade]) => ({
        codigo,
        quantidade,
      }))
      .sort((a, b) => b.quantidade - a.quantidade);
  };

  // Preparar dados para o gráfico de tipos de documento
  const prepararDadosGraficoTipos = () => {
    if (!dados) return [];

    // Unir documentos de entradas e parcelas
    const todosDocumentos = [
      ...(dados.documentosEntrada || []),
      ...(dados.documentosParcela || []),
    ];

    // Obter contagem de cada tipo de documento
    const contagemTipos = todosDocumentos.reduce((acc, doc) => {
      const tipo = doc.tipoDocumento || "Desconhecido";
      acc[tipo] = (acc[tipo] || 0) + 1;
      return acc;
    }, {});

    // Transformar em array para o gráfico
    return Object.entries(contagemTipos)
      .map(([tipo, quantidade]) => ({
        tipo,
        quantidade,
      }))
      .sort((a, b) => b.quantidade - a.quantidade);
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Documentos Eletrônicos com Erros
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Clínica: {dadosClinica?.nome || ""}
          </Typography>

          {/* Filtros adicionais */}
          {/* <Box sx={{ mt: 2, mb: 2 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtrosAdicionais.incluirDesativados}
                    onChange={handleFiltroChange}
                    name="incluirDesativados"
                  />
                }
                label="Incluir itens desativados"
              />
            </FormGroup>
          </Box> */}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && !dados.totalDocumentos && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Nenhum documento eletrônico com erro encontrado para o período
            selecionado.
          </Alert>
        )}

        {dados && dados.totalDocumentos > 0 && (
          <>
            {/* Cards de resumo */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: "error.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Total de Documentos com Erros
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorIcon
                        sx={{ color: "error.dark", mr: 1, fontSize: 30 }}
                      />
                      <Typography variant="h3" color="error.dark">
                        {dados.totalDocumentos}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: "warning.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Documentos de Entrada
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReceiptIcon
                        sx={{ color: "warning.dark", mr: 1, fontSize: 30 }}
                      />
                      <Typography variant="h3" color="warning.dark">
                        {dados.documentosEntrada?.length || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: "primary.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Documentos de Parcela
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccountBalanceIcon
                        sx={{ color: "primary.dark", mr: 1, fontSize: 30 }}
                      />
                      <Typography variant="h3" color="primary.dark">
                        {dados.documentosParcela?.length || 0}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Gráficos */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Distribuição por Código de Erro
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={prepararDadosGrafico()}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="quantidade"
                            nameKey="codigo"
                            label={({ codigo, percent }) =>
                              `${codigo}: ${(percent * 100).toFixed(0)}%`
                            }
                          >
                            {prepararDadosGrafico().map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            formatter={(value, name, props) => [
                              value,
                              "Quantidade",
                            ]}
                          />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Distribuição por Tipo de Documento
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={prepararDadosGraficoTipos()}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="tipo" />
                          <YAxis />
                          <Tooltip
                            formatter={(value) => [
                              `${value} documentos`,
                              "Quantidade",
                            ]}
                          />
                          <Legend />
                          <Bar
                            dataKey="quantidade"
                            name="Quantidade"
                            fill="#8884d8"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Tabs para Entrada vs Parcela */}
            <Box sx={{ mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                aria-label="documentos eletronicos tabs"
              >
                <Tab
                  label={`Documentos de Entrada (${
                    dados.documentosEntrada?.length || 0
                  })`}
                  icon={<ReceiptIcon />}
                  iconPosition="start"
                />
                <Tab
                  label={`Documentos de Parcela (${
                    dados.documentosParcela?.length || 0
                  })`}
                  icon={<AccountBalanceIcon />}
                  iconPosition="start"
                />
              </Tabs>
            </Box>

            {/* Filtros e Pesquisa */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Pesquisar por número, tipo, emissor ou mensagem de erro..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  value={agrupamento}
                  onChange={handleAgrupamentoChange}
                  label="Agrupar por"
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="tipo">Tipo de Documento</option>
                  <option value="codigo">Código de Erro</option>
                  <option value="emissor">Emissor do Documento</option>
                </TextField>
              </Grid>
            </Grid>

            {/* Conteúdo da Tab Selecionada */}
            {tabValue === 0 && (
              /* Tab de Documentos de Entrada */
              <Box>
                {dados.documentosEntrada &&
                dados.documentosEntrada.length > 0 ? (
                  Object.entries(
                    agruparDocumentos(
                      filtrarDocumentos(dados.documentosEntrada)
                    )
                  ).map(([grupo, docs], groupIndex) => (
                    <Card key={`grupo-entrada-${groupIndex}`} sx={{ mb: 3 }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {agrupamento === "tipo" && "Tipo: "}
                          {agrupamento === "codigo" && "Código: "}
                          {agrupamento === "emissor" && "Emissor: "}
                          {grupo} ({docs.length})
                        </Typography>

                        {docs.map((doc) => (
                          <Accordion
                            key={doc._id}
                            expanded={expandedDoc === doc._id}
                            onChange={() => handleExpandDoc(doc._id)}
                            sx={{ mb: 1 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${doc._id}-content`}
                              id={`panel-${doc._id}-header`}
                            >
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DescriptionIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "error.main" }}
                                    />
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {doc.tipoDocumento} {doc.nrDocumento}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    Emitido por: {doc.emissor?.nome || "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PersonIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "primary.main" }}
                                    />
                                    <Typography variant="body2">
                                      {doc.cliente?.nome ||
                                        "Cliente não especificado"}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {doc.descricao || "Sem descrição"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DateRangeIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "info.main" }}
                                    />
                                    <Typography variant="body2">
                                      {doc.dataEmissao
                                        ? moment(doc.dataEmissao).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "Data não especificada"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AttachMoneyIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "success.main" }}
                                    />
                                    <Typography variant="body2">
                                      {doc.valor?.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "CVE",
                                      }) || "Valor não especificado"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Divider sx={{ mb: 2 }} />

                              <Typography variant="subtitle1" gutterBottom>
                                Mensagens de Erro
                              </Typography>

                              {doc.messages && doc.messages.length > 0 ? (
                                <TableContainer
                                  component={Paper}
                                  variant="outlined"
                                >
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Código</TableCell>
                                        <TableCell>Descrição</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Data/Hora</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {doc.messages.map((msg, msgIndex) => (
                                        <TableRow key={`msg-${msgIndex}`}>
                                          <TableCell>
                                            <ErrorChip
                                              label={msg.code}
                                              size="small"
                                              errortype={
                                                msg.type === "error"
                                                  ? "critical"
                                                  : msg.type === "warning"
                                                  ? "warning"
                                                  : "info"
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {msg.description}
                                          </TableCell>
                                          <TableCell>{msg.type}</TableCell>
                                          <TableCell>
                                            {msg.dateTime
                                              ? moment(msg.dateTime).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )
                                              : "N/A"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <Alert severity="info">
                                  Nenhuma mensagem de erro específica
                                  registrada.
                                </Alert>
                              )}

                              {/* Informações Adicionais da Entrada */}
                              <Typography
                                variant="subtitle1"
                                sx={{ mt: 3, mb: 1 }}
                              >
                                Informações da Entrada
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Card variant="outlined">
                                    <CardContent>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Detalhes do Pagamento
                                      </Typography>
                                      <Typography variant="body2">
                                        Método: {doc.metodoPagamento || "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Data de Pagamento:{" "}
                                        {doc.dataPagamento
                                          ? moment(doc.dataPagamento).format(
                                              "DD/MM/YYYY"
                                            )
                                          : "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Data Limite:{" "}
                                        {doc.dataLimitePagamento
                                          ? moment(
                                              doc.dataLimitePagamento
                                            ).format("DD/MM/YYYY")
                                          : "N/A"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Card variant="outlined">
                                    <CardContent>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Status do Documento
                                      </Typography>
                                      <Typography variant="body2">
                                        Sucesso: {doc.succeeded ? "Sim" : "Não"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Série: {doc.serie || "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Anulado:{" "}
                                        {doc.anulacao?.anulado ? "Sim" : "Não"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Alert severity="info">
                    Nenhum documento de entrada com erro encontrado para o
                    período selecionado.
                  </Alert>
                )}
              </Box>
            )}

            {tabValue === 1 && (
              /* Tab de Documentos de Parcela */
              <Box>
                {dados.documentosParcela &&
                dados.documentosParcela.length > 0 ? (
                  Object.entries(
                    agruparDocumentos(
                      filtrarDocumentos(dados.documentosParcela)
                    )
                  ).map(([grupo, docs], groupIndex) => (
                    <Card key={`grupo-parcela-${groupIndex}`} sx={{ mb: 3 }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {agrupamento === "tipo" && "Tipo: "}
                          {agrupamento === "codigo" && "Código: "}
                          {agrupamento === "emissor" && "Emissor: "}
                          {grupo} ({docs.length})
                        </Typography>

                        {docs.map((doc) => (
                          <Accordion
                            key={doc._id}
                            expanded={expandedDoc === doc._id}
                            onChange={() => handleExpandDoc(doc._id)}
                            sx={{ mb: 1 }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${doc._id}-content`}
                              id={`panel-${doc._id}-header`}
                            >
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DescriptionIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "error.main" }}
                                    />
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {doc.tipoDocumento} {doc.nrDocumento}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    Emitido por: {doc.emissor?.nome || "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PersonIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "primary.main" }}
                                    />
                                    <Typography variant="body2">
                                      {doc.cliente?.nome ||
                                        "Cliente não especificado"}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    Orçamento: {doc.orcamentoCodigo || "N/A"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DateRangeIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "info.main" }}
                                    />
                                    <Typography variant="body2">
                                      {doc.dataEmissao
                                        ? moment(doc.dataEmissao).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "Data não especificada"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AttachMoneyIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "success.main" }}
                                    />
                                    <Typography variant="body2">
                                      {doc.valor?.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "CVE",
                                      }) || "Valor não especificado"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Divider sx={{ mb: 2 }} />

                              <Typography variant="subtitle1" gutterBottom>
                                Mensagens de Erro
                              </Typography>

                              {doc.messages && doc.messages.length > 0 ? (
                                <TableContainer
                                  component={Paper}
                                  variant="outlined"
                                >
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Código</TableCell>
                                        <TableCell>Descrição</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Data/Hora</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {doc.messages.map((msg, msgIndex) => (
                                        <TableRow key={`msg-${msgIndex}`}>
                                          <TableCell>
                                            <ErrorChip
                                              label={msg.code}
                                              size="small"
                                              errortype={
                                                msg.type === "error"
                                                  ? "critical"
                                                  : msg.type === "warning"
                                                  ? "warning"
                                                  : "info"
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            {msg.description}
                                          </TableCell>
                                          <TableCell>{msg.type}</TableCell>
                                          <TableCell>
                                            {msg.dateTime
                                              ? moment(msg.dateTime).format(
                                                  "DD/MM/YYYY HH:mm:ss"
                                                )
                                              : "N/A"}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <Alert severity="info">
                                  Nenhuma mensagem de erro específica
                                  registrada.
                                </Alert>
                              )}

                              {/* Informações Adicionais da Parcela */}
                              <Typography
                                variant="subtitle1"
                                sx={{ mt: 3, mb: 1 }}
                              >
                                Informações da Parcela
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Card variant="outlined">
                                    <CardContent>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Detalhes do Pagamento
                                      </Typography>
                                      <Typography variant="body2">
                                        Método: {doc.metodoPagamento || "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Data de Pagamento:{" "}
                                        {doc.dataPagamento
                                          ? moment(doc.dataPagamento).format(
                                              "DD/MM/YYYY"
                                            )
                                          : "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Data Limite:{" "}
                                        {doc.dataLimitePagamento
                                          ? moment(
                                              doc.dataLimitePagamento
                                            ).format("DD/MM/YYYY")
                                          : "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Parcela Paga:{" "}
                                        {doc.parcelaPaga ? "Sim" : "Não"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Card variant="outlined">
                                    <CardContent>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Status do Documento
                                      </Typography>
                                      <Typography variant="body2">
                                        Sucesso: {doc.succeeded ? "Sim" : "Não"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Série: {doc.serie || "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Número Parcela: {doc.nrParcela || "N/A"}
                                      </Typography>
                                      <Typography variant="body2">
                                        Anulado:{" "}
                                        {doc.anulacao?.anulado ? "Sim" : "Não"}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Alert severity="info">
                    Nenhum documento de parcela com erro encontrado para o
                    período selecionado.
                  </Alert>
                )}
              </Box>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioDocumentosEletronicosComErro;
