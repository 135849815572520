import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tabs,
  Tab,
  Chip,
  Button,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BalanceIcon from "@mui/icons-material/Balance";

// Configurar locale do moment para português
moment.locale("pt-br");

// Componentes estilizados
const SummaryCard = styled(Card)(({ theme, cardtype }) => ({
  height: "100%",
  backgroundColor:
    cardtype === "receber"
      ? "rgba(46, 125, 50, 0.08)"
      : cardtype === "pagar"
      ? "rgba(211, 47, 47, 0.08)"
      : cardtype === "saldo"
      ? "rgba(25, 118, 210, 0.08)"
      : theme.palette.background.paper,
  borderLeft: `4px solid ${
    cardtype === "receber"
      ? theme.palette.success.main
      : cardtype === "pagar"
      ? theme.palette.error.main
      : cardtype === "saldo"
      ? theme.palette.primary.main
      : theme.palette.divider
  }`,
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  backgroundColor:
    status === "Em dia"
      ? theme.palette.success.main
      : status === "Vence hoje"
      ? theme.palette.warning.main
      : status === "Atrasada"
      ? theme.palette.error.main
      : theme.palette.grey[400],
  color: "white",
  fontWeight: "bold",
  fontSize: "0.75rem",
}));

function RelatorioContasPagarReceber({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // Paginação para contas a receber
  const [receberPage, setReceberPage] = useState(0);
  const [receberRowsPerPage, setReceberRowsPerPage] = useState(10);

  // Paginação para contas a pagar
  const [pagarPage, setPagarPage] = useState(0);
  const [pagarRowsPerPage, setPagarRowsPerPage] = useState(10);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada, execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes]);

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarRelatorioContasPagarReceber/${dadosClinica._id}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData);
    } catch (err) {
      console.error(
        "Erro ao gerar relatório de contas a pagar e receber:",
        err
      );
      setError(err.message || "Ocorreu um erro ao gerar o relatório.");
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Chamada para a API de exportação
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarRelatorioContasPagarReceber/${dadosClinica._id}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `contas-pagar-receber-${moment().format(
          "DD-MM-YYYY"
        )}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handlers para paginação de contas a receber
  const handleReceberChangePage = (event, newPage) => {
    setReceberPage(newPage);
  };

  const handleReceberChangeRowsPerPage = (event) => {
    setReceberRowsPerPage(parseInt(event.target.value, 10));
    setReceberPage(0);
  };

  // Handlers para paginação de contas a pagar
  const handlePagarChangePage = (event, newPage) => {
    setPagarPage(newPage);
  };

  const handlePagarChangeRowsPerPage = (event) => {
    setPagarRowsPerPage(parseInt(event.target.value, 10));
    setPagarPage(0);
  };

  // Handler para mudança de aba
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Contas a Pagar e Receber
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1">
            Clínica: {dadosClinica?.nome || ""}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Dados atualizados em:{" "}
            {moment().format("DD [de] MMMM [de] YYYY [às] HH:mm")}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            {/* Cards de resumo */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={4}>
                <SummaryCard cardtype="receber">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <ArrowUpwardIcon color="success" sx={{ mr: 1 }} />
                      <Typography variant="h6">Total a Receber</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="success.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.contasReceber.total.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Em dia
                            </Typography>
                            <Typography
                              variant="body1"
                              color="success.main"
                              sx={{ fontWeight: "bold" }}
                            >
                              {dados.contasReceber.porStatus.emDia.quantidade}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Vence hoje
                            </Typography>
                            <Typography
                              variant="body1"
                              color="warning.main"
                              sx={{ fontWeight: "bold" }}
                            >
                              {
                                dados.contasReceber.porStatus.venceHoje
                                  .quantidade
                              }
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Atrasadas
                            </Typography>
                            <Typography
                              variant="body1"
                              color="error.main"
                              sx={{ fontWeight: "bold" }}
                            >
                              {
                                dados.contasReceber.porStatus.atrasadas
                                  .quantidade
                              }
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <SummaryCard cardtype="pagar">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <ArrowDownwardIcon color="error" sx={{ mr: 1 }} />
                      <Typography variant="h6">Total a Pagar</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="error.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.contasPagar.total.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Em dia
                            </Typography>
                            <Typography
                              variant="body1"
                              color="success.main"
                              sx={{ fontWeight: "bold" }}
                            >
                              {dados.contasPagar.porStatus.emDia.quantidade}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Vence hoje
                            </Typography>
                            <Typography
                              variant="body1"
                              color="warning.main"
                              sx={{ fontWeight: "bold" }}
                            >
                              {dados.contasPagar.porStatus.venceHoje.quantidade}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="body2" color="text.secondary">
                              Atrasadas
                            </Typography>
                            <Typography
                              variant="body1"
                              color="error.main"
                              sx={{ fontWeight: "bold" }}
                            >
                              {dados.contasPagar.porStatus.atrasadas.quantidade}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <SummaryCard cardtype="saldo">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <BalanceIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Saldo Líquido</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color={
                        dados.resumo.saldoLiquido >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.resumo.saldoLiquido.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Alert
                        severity={
                          dados.resumo.saldoLiquido >= 0 ? "success" : "warning"
                        }
                        icon={
                          dados.resumo.saldoLiquido >= 0 ? (
                            <CheckCircleIcon />
                          ) : (
                            <WarningIcon />
                          )
                        }
                      >
                        {dados.resumo.saldoLiquido >= 0
                          ? "Contas a receber superam as contas a pagar."
                          : "Contas a pagar superam as contas a receber."}
                      </Alert>
                    </Box>
                  </CardContent>
                </SummaryCard>
              </Grid>
            </Grid>

            {/* Abas para alternar entre contas a receber e contas a pagar */}
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Abas de contas a receber e pagar"
                variant="fullWidth"
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ mr: 1, color: "success.main" }}
                      />
                      <Typography>Contas a Receber</Typography>
                    </Box>
                  }
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ mr: 1, color: "error.main" }}
                      />
                      <Typography>Contas a Pagar</Typography>
                    </Box>
                  }
                />
              </Tabs>
            </Box>

            {/* Conteúdo da aba Contas a Receber */}
            {tabValue === 0 && (
              <Box>
                {/* Resumo por status */}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "success.light", color: "white" }}>
                      <CardContent>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Em dia
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {dados.contasReceber.porStatus.emDia.valor.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "CVE",
                            }
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {dados.contasReceber.porStatus.emDia.quantidade}{" "}
                          conta(s)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "warning.light", color: "white" }}>
                      <CardContent>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Vence hoje
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {dados.contasReceber.porStatus.venceHoje.valor.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "CVE",
                            }
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {dados.contasReceber.porStatus.venceHoje.quantidade}{" "}
                          conta(s)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "error.light", color: "white" }}>
                      <CardContent>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Atrasadas
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {dados.contasReceber.porStatus.atrasadas.valor.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "CVE",
                            }
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {dados.contasReceber.porStatus.atrasadas.quantidade}{" "}
                          conta(s)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Tabela de contas a receber */}
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Vencimento</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell>Dias em Atraso</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dados.contasReceber.detalhes
                        .slice(
                          receberPage * receberRowsPerPage,
                          receberPage * receberRowsPerPage + receberRowsPerPage
                        )
                        .map((conta, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{conta.cliente}</TableCell>
                            <TableCell>{conta.descricao}</TableCell>
                            <TableCell>{conta.dataVencimento}</TableCell>
                            <TableCell>
                              <StatusChip
                                label={conta.status}
                                status={conta.status}
                                size="small"
                                icon={
                                  conta.status === "Em dia" ? (
                                    <CheckCircleIcon />
                                  ) : conta.status === "Vence hoje" ? (
                                    <WarningIcon />
                                  ) : (
                                    <ErrorIcon />
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ fontWeight: "bold" }}
                            >
                              {conta.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "CVE",
                              })}
                            </TableCell>
                            <TableCell>
                              {conta.diasAtraso > 0 ? (
                                <Typography
                                  variant="body2"
                                  color="error.main"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {conta.diasAtraso}{" "}
                                  {conta.diasAtraso === 1 ? "dia" : "dias"}
                                </Typography>
                              ) : (
                                "Em dia"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={dados.contasReceber.detalhes.length}
                  rowsPerPage={receberRowsPerPage}
                  page={receberPage}
                  onPageChange={handleReceberChangePage}
                  onRowsPerPageChange={handleReceberChangeRowsPerPage}
                  labelRowsPerPage="Linhas por página:"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                />
              </Box>
            )}

            {/* Conteúdo da aba Contas a Pagar */}
            {tabValue === 1 && (
              <Box>
                {/* Resumo por status */}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "success.light", color: "white" }}>
                      <CardContent>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Em dia
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {dados.contasPagar.porStatus.emDia.valor.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "CVE",
                            }
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {dados.contasPagar.porStatus.emDia.quantidade}{" "}
                          conta(s)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "warning.light", color: "white" }}>
                      <CardContent>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Vence hoje
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {dados.contasPagar.porStatus.venceHoje.valor.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "CVE",
                            }
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {dados.contasPagar.porStatus.venceHoje.quantidade}{" "}
                          conta(s)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ bgcolor: "error.light", color: "white" }}>
                      <CardContent>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Atrasadas
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {dados.contasPagar.porStatus.atrasadas.valor.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "CVE",
                            }
                          )}
                        </Typography>
                        <Typography variant="body2">
                          {dados.contasPagar.porStatus.atrasadas.quantidade}{" "}
                          conta(s)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* Tabela de contas a pagar */}
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Fornecedor</TableCell>
                        <TableCell>Categoria</TableCell>
                        <TableCell>Vencimento</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell>Dias em Atraso</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dados.contasPagar.detalhes
                        .slice(
                          pagarPage * pagarRowsPerPage,
                          pagarPage * pagarRowsPerPage + pagarRowsPerPage
                        )
                        .map((conta, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{conta.fornecedor}</TableCell>
                            <TableCell>{conta.categoria}</TableCell>
                            <TableCell>{conta.dataVencimento}</TableCell>
                            <TableCell>
                              <StatusChip
                                label={conta.status}
                                status={conta.status}
                                size="small"
                                icon={
                                  conta.status === "Em dia" ? (
                                    <CheckCircleIcon />
                                  ) : conta.status === "Vence hoje" ? (
                                    <WarningIcon />
                                  ) : (
                                    <ErrorIcon />
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ fontWeight: "bold" }}
                            >
                              {conta.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "CVE",
                              })}
                            </TableCell>
                            <TableCell>
                              {conta.diasAtraso > 0 ? (
                                <Typography
                                  variant="body2"
                                  color="error.main"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {conta.diasAtraso}{" "}
                                  {conta.diasAtraso === 1 ? "dia" : "dias"}
                                </Typography>
                              ) : (
                                "Em dia"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={dados.contasPagar.detalhes.length}
                  rowsPerPage={pagarRowsPerPage}
                  page={pagarPage}
                  onPageChange={handlePagarChangePage}
                  onRowsPerPageChange={handlePagarChangeRowsPerPage}
                  labelRowsPerPage="Linhas por página:"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                />
              </Box>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioContasPagarReceber;
