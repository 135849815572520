import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  IconButton,
  Collapse,
  Tooltip,
  Badge,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WarningIcon from "@mui/icons-material/Warning";
import CategoryIcon from "@mui/icons-material/Category";
import PersonIcon from "@mui/icons-material/Person";

// Recharts para gráficos
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Configurar locale do moment
moment.locale("pt-br");

function RelatorioProcedimentosPendentes({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
  filtrosAdicionais,
  atualizarFiltrosAdicionais,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [categoriaAtiva, setCategoriaAtiva] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedRows, setExpandedRows] = useState({});

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes, filtrosAdicionais]); // Reexecuta quando a ação ou filtros mudam

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar o corpo da requisição
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
      };

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getResumoProcedimentosPendentes/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData.data);
      // Reseta a categoria ativa para a primeira
      setCategoriaAtiva(0);
      // Limpa as pesquisas e expansões de linhas
      setSearchTerm("");
      setExpandedRows({});
    } catch (err) {
      console.error("Erro ao gerar relatório de procedimentos pendentes:", err);
      setError(
        err.message ||
          "Ocorreu um erro ao gerar o relatório de procedimentos pendentes."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar os parâmetros de query
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
      };

      // Chamada para a API de exportação
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getResumoProcedimentosPendentes/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `procedimentos-pendentes-${dataInicialFormatada}-a-${dataFinalFormatada}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handler para mudar a categoria ativa
  const handleCategoriaChange = (event, newValue) => {
    setCategoriaAtiva(newValue);
    // Limpar pesquisa e linhas expandidas ao mudar de categoria
    setSearchTerm("");
    setExpandedRows({});
  };

  // Handler para pesquisa
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handler para expandir/recolher linhas de detalhes
  const toggleRowExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  // Preparar dados para o gráfico
  const prepararDadosGrafico = () => {
    if (!dados) return [];

    return dados.map((categoria) => ({
      categoria: categoria.categoria,
      "Não Iniciados": categoria.naoIniciados,
      "Em Andamento": categoria.emAndamento,
      "Total Pendentes": categoria.totalPendentes,
    }));
  };

  // Filtrar procedimentos na categoria atual de acordo com a pesquisa
  const filtrarProcedimentos = () => {
    if (!dados || dados.length === 0 || categoriaAtiva >= dados.length)
      return [];

    const categoria = dados[categoriaAtiva];
    if (!categoria.procedimentos) return [];

    return categoria.procedimentos.filter(
      (proc) =>
        proc.cliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
        proc.tratamento.toLowerCase().includes(searchTerm.toLowerCase()) ||
        proc.medicoResponsavel
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        proc.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (proc.dente &&
          proc.dente.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (proc.faces &&
          proc.faces.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  // Calcular totais
  const calcularTotais = () => {
    if (!dados)
      return {
        totalPendentes: 0,
        naoIniciados: 0,
        emAndamento: 0,
        clientesPendentes: 0,
      };

    return {
      totalPendentes: dados.reduce((sum, cat) => sum + cat.totalPendentes, 0),
      naoIniciados: dados.reduce((sum, cat) => sum + cat.naoIniciados, 0),
      emAndamento: dados.reduce((sum, cat) => sum + cat.emAndamento, 0),
      clientesPendentes: dados.reduce(
        (sum, cat) => sum + cat.clientesPendentes,
        0
      ),
    };
  };

  // Totais calculados
  const totais = calcularTotais();

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Procedimentos Pendentes
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1">
            Clínica: {dadosClinica?.nome || ""}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && dados.length === 0 && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Nenhum procedimento pendente encontrado para o período selecionado.
          </Alert>
        )}

        {dados && dados.length > 0 && (
          <>
            {/* Cards de resumo */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ bgcolor: "error.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Total Pendentes
                    </Typography>
                    <Typography variant="h3" color="error.dark">
                      {totais.totalPendentes}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ bgcolor: "warning.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Não Iniciados
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <WarningIcon
                        sx={{ color: "warning.dark", mr: 1, fontSize: 30 }}
                      />
                      <Typography variant="h3" color="warning.dark">
                        {totais.naoIniciados}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ bgcolor: "primary.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Em Andamento
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <HourglassEmptyIcon
                        sx={{ color: "primary.dark", mr: 1, fontSize: 30 }}
                      />
                      <Typography variant="h3" color="primary.dark">
                        {totais.emAndamento}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{ bgcolor: "info.light", height: "100%" }}>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Clientes Pendentes
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PersonIcon
                        sx={{ color: "info.dark", mr: 1, fontSize: 30 }}
                      />
                      <Typography variant="h3" color="info.dark">
                        {totais.clientesPendentes}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Gráfico de Pendentes por Categoria */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Procedimentos Pendentes por Categoria
                </Typography>
                <Box sx={{ height: 400 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={prepararDadosGrafico()}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 100,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="categoria"
                        angle={-45}
                        textAnchor="end"
                        height={80}
                        interval={0}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar
                        dataKey="Não Iniciados"
                        fill="#ff9800"
                        stackId="a"
                        name="Não Iniciados"
                      />
                      <Bar
                        dataKey="Em Andamento"
                        fill="#2196f3"
                        stackId="a"
                        name="Em Andamento"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>

            {/* Detalhes por Categoria */}
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Detalhes por Categoria
                </Typography>

                {/* Tabs para navegação entre categorias */}
                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                  <Tabs
                    value={categoriaAtiva}
                    onChange={handleCategoriaChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="categorias de procedimentos pendentes"
                  >
                    {dados.map((categoria, index) => (
                      <Tab
                        key={categoria.categoriaId}
                        label={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CategoryIcon fontSize="small" sx={{ mr: 1 }} />
                            <span>{categoria.categoria}</span>
                            <Badge
                              badgeContent={categoria.totalPendentes}
                              color="error"
                              sx={{ ml: 1 }}
                            />
                          </Box>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>

                {/* Campo de pesquisa */}
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Pesquisar por cliente, tratamento, médico, dente ou faces..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {/* Categoria atual */}
                {dados[categoriaAtiva] && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {dados[categoriaAtiva].categoria}:{" "}
                        {dados[categoriaAtiva].totalPendentes} Pendentes
                      </Typography>
                      <Box>
                        <Chip
                          icon={<WarningIcon />}
                          label={`${dados[categoriaAtiva].naoIniciados} Não Iniciados`}
                          color="warning"
                          sx={{ mr: 1 }}
                        />
                        <Chip
                          icon={<HourglassEmptyIcon />}
                          label={`${dados[categoriaAtiva].emAndamento} Em Andamento`}
                          color="primary"
                        />
                      </Box>
                    </Box>

                    {/* Tabela de procedimentos */}
                    <TableContainer
                      component={Paper}
                      variant="outlined"
                      sx={{ maxHeight: 500 }}
                    >
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Tratamento</TableCell>
                            <TableCell>Médico</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Detalhes</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filtrarProcedimentos().length > 0 ? (
                            filtrarProcedimentos().map((proc) => (
                              <React.Fragment
                                key={`${proc.planoId}-${proc.tratamentoId}`}
                              >
                                <TableRow
                                  hover
                                  sx={{
                                    "& > *": { borderBottom: "unset" },
                                    cursor: "pointer",
                                    backgroundColor: expandedRows[
                                      `${proc.planoId}-${proc.tratamentoId}`
                                    ]
                                      ? "rgba(0, 0, 0, 0.04)"
                                      : "inherit",
                                  }}
                                  onClick={() =>
                                    toggleRowExpand(
                                      `${proc.planoId}-${proc.tratamentoId}`
                                    )
                                  }
                                >
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <PersonIcon
                                        fontSize="small"
                                        sx={{ mr: 1, color: "info.main" }}
                                      />
                                      {proc.cliente}
                                    </Box>
                                  </TableCell>
                                  <TableCell>{proc.tratamento}</TableCell>
                                  <TableCell>
                                    {proc.medicoResponsavel}
                                  </TableCell>
                                  <TableCell>
                                    {proc.status === "Não iniciado" ? (
                                      <Chip
                                        size="small"
                                        label="Não iniciado"
                                        color="warning"
                                      />
                                    ) : proc.status === "Em andamento" ? (
                                      <Chip
                                        size="small"
                                        label="Em andamento"
                                        color="primary"
                                      />
                                    ) : (
                                      <Chip size="small" label={proc.status} />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleRowExpand(
                                          `${proc.planoId}-${proc.tratamentoId}`
                                        );
                                      }}
                                    >
                                      {expandedRows[
                                        `${proc.planoId}-${proc.tratamentoId}`
                                      ] ? (
                                        <ExpandLessIcon />
                                      ) : (
                                        <ExpandMoreIcon />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{ paddingBottom: 0, paddingTop: 0 }}
                                    colSpan={5}
                                  >
                                    <Collapse
                                      in={
                                        expandedRows[
                                          `${proc.planoId}-${proc.tratamentoId}`
                                        ]
                                      }
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box
                                        sx={{
                                          margin: 1,
                                          p: 2,
                                          backgroundColor: "#f5f5f5",
                                          borderRadius: 1,
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2">
                                              Informações do Plano:
                                            </Typography>
                                            <Typography variant="body2">
                                              Número:{" "}
                                              {proc.planoNumero || "N/A"}
                                            </Typography>
                                            <Typography variant="body2">
                                              ID: {proc.planoId}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2">
                                              Informações do Procedimento:
                                            </Typography>
                                            <Typography variant="body2">
                                              Dente: {proc.dente}
                                            </Typography>
                                            <Typography variant="body2">
                                              Faces: {proc.faces}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5} align="center">
                                <Typography variant="body2" sx={{ py: 2 }}>
                                  Nenhum procedimento encontrado com os
                                  critérios de pesquisa.
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioProcedimentosPendentes;
