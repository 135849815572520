import React, { useState, useContext, useEffect } from "react";
import "./ModalImportXlsStock.scss";
import moment from "moment";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { AuthContext } from "../../../shared/context/auth-context";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../shared/components/inputs/InputText/InputText";

import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

import { TextField, MenuItem } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import InputSelect from "../../../shared/components/inputs/InputSelect/InputSelect";

import ImportErrorList from "./ImportErrorList";

const UNIDADES_MEDIDA = [
  { value: "EA", label: "Unidade" },
  { value: "KGM", label: "Kilograma (kg)" },
  { value: "MTR", label: "Metro (m)" },
  { value: "LTR", label: "Litro (l)" },
  { value: "MTQ", label: "Metro Cúbico (m³)" },
  { value: "MTK", label: "Metro Quadrado (m²)" },
  { value: "BX", label: "Caixa" },
];

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  "Cheque Visado",
  "Transferência de crédito",
  "Internet banking",
  "Cartão do banco",
  "Débito direto da conta bancária",
  "Cartão de crédito",
  "Cartão de débito",
];

const normalizeUnidadeMedida = (unit) => {
  if (!unit) return "EA";

  // Convert to lowercase and remove spaces
  const normalized = unit.toString().toLowerCase().trim();

  // Handle variations
  switch (normalized) {
    // Each/Unit variations
    case "ea":
    case "un":
    case "unidade":
    case "cada":
    case "unit":
    case "piece":
    case "pce":
      return "EA";

    // Kilogram variations
    case "kgm":
    case "kg":
    case "kgs":
    case "kilos":
    case "kilogramas":
    case "kilogram":
    case "kilograma":
      return "KGM";

    // Meter variations
    case "mtr":
    case "m":
    case "metro":
    case "metros":
      return "MTR";

    // Liter variations
    case "ltr":
    case "l":
    case "lt":
    case "litro":
    case "litros":
      return "LTR";

    // Cubic meter variations
    case "mtq":
    case "m3":
    case "m³":
    case "metro cubico":
    case "metros cubicos":
    case "metro cúbico":
    case "metros cúbicos":
      return "MTQ";

    // Square meter variations
    case "mtk":
    case "m2":
    case "m²":
    case "metro quadrado":
    case "metros quadrados":
      return "MTK";

    // Box variations
    case "bx":
    case "cx":
    case "caixa":
    case "caixas":
    case "box":
    case "boxes":
      return "BX";

    default:
      return "EA"; // Default to 'EA' for unrecognized units
  }
};

const safeNumberConversion = (value) => {
  console.log(`Tentando converter valor: "${value}" (tipo: ${typeof value})`);

  // Handle dash-only values explicitly
  if (typeof value === "string") {
    // Check if the string contains only spaces, dashes, or both
    if (/^\s*-\s*$/.test(value)) {
      console.log(`Valor contém apenas traços ou espaços: "${value}"`);
      return 0; // Return 0 for these invalid cases
    }
  }

  // Verificar se é um número válido usando regex
  const numberRegex = /^[0-9]+(\.[0-9]+)?$/;

  if (
    value === null ||
    value === undefined ||
    value === "" ||
    !numberRegex.test(String(value).trim())
  ) {
    console.log(`Valor inválido, retornando 0: "${value}"`);
    return 0; // Valor padrão para casos inválidos
  }

  console.log(`Convertendo para número: "${value}" => ${Number(value)}`);
  return Number(value);
};

function ModalImportXlsStock({
  stock,
  handleCloseModalImportStock,
  sendRequest,
  produtos,
  atualizarStock,
}) {
  const { startLoading, stopLoading } = useLoading();
  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [categoriasStock, setCategoriasStock] = useState();
  const [adicionarComoDespesa, setAdicionarComoDespesa] = useState(false);
  const [custoTotal, setCustoTotal] = useState(0);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [despesaPaga, setDespesaPaga] = useState(true);

  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  const [currentStep, setCurrentStep] = useState(1);
  const [processedData, setProcessedData] = useState([]);
  const [categoriaPrincipal, setCategoriaPrincipal] = useState();

  const [errorColumnData, setErrorColumnData] = useState([]);
  const [errorRowData, setErrorRowData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          categoriasStockResponse,
          categoriasFinanceiroResponse,
          caixasResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        setCategoriasStock(categoriasStockResponse.categoriasStock);
        setCategoriaPrincipal(categoriasStockResponse.categoriasStock[0]._id);

        setCategorias(
          categoriasFinanceiroResponse.categoriasFinanceiro.map((cat) => {
            return { id: cat.id, value: cat.categoria };
          })
        );
        setCategoria(categoriasFinanceiroResponse.categoriasFinanceiro[0].id);
        setCaixas(
          caixasResponse.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
        setCaixa(caixasResponse.caixas[0].id);
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    };
    fetchData();
  }, [auth.token, clinica, sendRequest]);

  const handleSubmitImportStock = async () => {
    if (!processedData || processedData.length === 0) {
      toast.error("Nenhum dado disponível para importar.");
      return;
    }

    processedData.forEach((p) => {
      if (p.dataValidade) {
        const dataMoment = moment(p.dataValidade); // Converta a string para Moment
        if (!dataMoment.isValid()) {
          // Verifique a conversão
          toast.error(`Data inválida em ${p.nome}: ${p.dataValidade}`);
          return;
        }
      }
    });

    startLoading();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const novosProdutos = [];
      const produtosExistentes = [];
      const novosMovimentosParaStock = [];
      let updatedStock = [...stock.stock];

      for (const item of processedData) {
        const produtoExistente = updatedStock.find(
          (stockItem) =>
            stockItem.produto.nome.trim().toLowerCase() ===
            item.nome.trim().toLowerCase()
        );

        if (produtoExistente) {
          // Update existing product
          const loteExistente = produtoExistente.lotes.find(
            (l) =>
              l.lote.trim().toLowerCase() === item.lote.trim().toLowerCase()
          );

          if (loteExistente) {
            // Update existing lote
            loteExistente.quantidade += item.quantidade;
            loteExistente.dataValidade = item.dataValidade;
          } else {
            // Add new lote
            produtoExistente.lotes.push({
              lote: item.lote,
              quantidade: item.quantidade,
              dataValidade: item.dataValidade,
            });
          }

          produtoExistente.quantidadeTotal += item.quantidade;
          // Se o arquivo tiver quantidade ideal, atualiza o valor no produto
          if (item.quantidadeIdeal !== null) {
            produtoExistente.quantidadeIdeal = item.quantidadeIdeal;
          }

          produtosExistentes.push(produtoExistente);

          novosMovimentosParaStock.push({
            clinica: clinica.clinica._id,
            produto: produtoExistente.produto._id,
            lote: item.lote,
            dataValidade: item.dataValidade,
            responsavelMovimento: { tipo: "User", responsavel: auth.userId },
            quantidade: item.quantidade,
            data: new Date(),
            saida: false,
          });
        } else {
          // Add new product
          novosProdutos.push({
            clinica: clinica.clinica._id,
            nome: item.nome,
            categoriaStock: item.categoriaStock,
            unidadeMedida: item.unidadeMedida,
            lotes: [
              {
                lote: item.lote,
                quantidade: item.quantidade,
                dataValidade: item.dataValidade,
              },
            ],
            quantidadeTotal: item.quantidade,
            quantidadeIdeal:
              item.quantidadeIdeal !== null ? item.quantidadeIdeal : 2,
          });
        }
      }

      const novosProdutosBackend = novosProdutos.map((p) => ({
        clinica: p.clinica,
        nome: p.nome,
        categoriaStock: p.categoriaStock,
        criadoPor: auth.userId,
        unidadeMedida: p.unidadeMedida,
      }));

      // Add new products
      if (novosProdutosBackend.length > 0) {
        const formData = new FormData();
        formData.append("produtos", JSON.stringify(novosProdutosBackend));

        const produtosRetornados = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/addmany`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        produtosRetornados.produtos.forEach((p, index) => {
          const originalProduto = novosProdutos[index];
          novosMovimentosParaStock.push({
            clinica: clinica.clinica._id,
            produto: p._id,
            lote: originalProduto.lotes[0].lote,
            dataValidade: originalProduto.lotes[0].dataValidade,
            responsavelMovimento: {
              tipo: "User",
              responsavel: auth.userId,
            },
            quantidade: originalProduto.lotes[0].quantidade,
            data: new Date(),
            saida: false,
          });

          // Add new product to updatedStock
          updatedStock.push({
            produto: p,
            lotes: originalProduto.lotes,
            quantidadeTotal: originalProduto.quantidadeTotal,
            quantidadeIdeal: originalProduto.quantidadeIdeal,
          });
        });
      }

      const stockAtualizado = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/${stock._id}`,
        "PATCH",
        JSON.stringify({
          updatedData: updatedStock,
          historicoMovimentos: novosMovimentosParaStock,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      //Caso houver necessidade de criar a despesa
      if (adicionarComoDespesa) {
        try {
          const formData = new FormData();
          formData.append("clinica", clinica.clinica._id);
          formData.append("observacoes", "");
          formData.append("descricao", descricao);
          formData.append("valor", custoTotal);
          formData.append("dataLimitePagamento", new Date());
          formData.append("categoria", categoria);
          formData.append("caixa", caixa);
          formData.append("criadoPor", auth.userId);

          if (despesaPaga) {
            formData.append("dataPagamento", new Date());
            formData.append("metodoPagamento", metodoPagamento);
            formData.append("despesaPaga", true);
            formData.append("pagaPor", auth.userId);
          } else {
            formData.append("despesaPaga", false);
          }

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/despesas`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          toast.success("Despesa adicionada com sucesso");
        } catch (err) {
          console.error("err", err);
        }
      }

      atualizarStock(
        stockAtualizado.stock,
        `Importação concluída com sucesso! ${novosProdutos.length} novos produtos e ${produtosExistentes.length} produtos existentes atualizados.`
      );
      handleCloseModalImportStock();
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    // Check file size (10MB limit)
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes

    // Check file extension
    const allowedExtensions = /(\.xls|\.xlsx)$/i;
    if (!allowedExtensions.test(selectedFile.name)) {
      setErrorMessage(
        `O ficheiro "${selectedFile.name}" tem uma extensão inválida.`
      );
      return;
    } else if (selectedFile.size > maxSizeInBytes) {
      setErrorMessage(
        `O ficheiro "${selectedFile.name}" tem uma extensão inválida.`
      );
      return;
    } else {
      setErrorMessage("");
    }

    setErrorMessage("");
    convertFileToObject(selectedFile);
    setFile(selectedFile);
  };

  // Adicione esta função para normalizar os nomes das colunas
  const normalizeColumnName = (columnName) => {
    if (!columnName) return "";

    // Converte para minúsculo e remove espaços extras
    const normalized = columnName.toString().toLowerCase().trim();

    // Mapeamento das variações para os nomes padrão
    const columnMappings = {
      // Variações para "nome"
      nome: "nome",
      nomes: "nome",
      produto: "nome",
      produtos: "nome",
      item: "nome",
      itens: "nome",
      descrição: "nome",
      descricao: "nome",

      // Variações para "quantidade"
      quantidade: "quantidade",
      qtd: "quantidade",
      qtde: "quantidade",
      qty: "quantidade",
      quant: "quantidade",
      quantidades: "quantidade",

      // Variações para "quantidade ideal"
      "quantidade ideal": "quantidade ideal",
      quantidadeideal: "quantidade ideal",
      "qtd ideal": "quantidade ideal",
      qtdideal: "quantidade ideal",
      "qtde ideal": "quantidade ideal",
      "estoque mínimo": "quantidade ideal",
      "estoque minimo": "quantidade ideal",
      mínimo: "quantidade ideal",
      minimo: "quantidade ideal",

      // Variações para "unidadeMedida"
      unidademedida: "unidadeMedida",
      "unidade medida": "unidadeMedida",
      "unidade de medida": "unidadeMedida",
      um: "unidadeMedida",
      medida: "unidadeMedida",
      unidade: "unidadeMedida",

      // Variações para "lote"
      lote: "lote",
      lotes: "lote",
      batch: "lote",
      "número de lote": "lote",
      "numero de lote": "lote",
      "num lote": "lote",

      // Variações para "dataValidade"
      datavalidade: "dataValidade",
      "data validade": "dataValidade",
      "data de validade": "dataValidade",
      validade: "dataValidade",
      vencimento: "dataValidade",
      "data vencimento": "dataValidade",
      "data de vencimento": "dataValidade",
    };

    return columnMappings[normalized] || normalized;
  };

  const convertFileToObject = (ficheiro) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryData = event.target.result;

      // Log the workbook before applying any options
      console.log("Parsing Excel file with enhanced options...");

      // Use more advanced options for XLSX.read to prevent auto-conversion
      const readOptions = {
        type: "binary",
        cellText: true, // Force cells to be read as text
        cellDates: false, // Don't auto-convert dates
        cellNF: false, // Don't parse number formats
        cellStyles: false, // Don't parse styles
        sheetStubs: true, // Include empty cells
      };

      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Log the worksheet to see raw cell values
      console.log("Worksheet raw data:", worksheet);

      // Use advanced options for sheet_to_json
      const jsonOptions = {
        raw: true, // Don't convert values
        rawNumbers: false, // Don't convert numbers
        defval: "", // Empty cells become empty strings
        header: 1, // Use array of arrays format to see exact cell values
      };

      // First get the data as arrays to see exact cell values
      const rawArrays = XLSX.utils.sheet_to_json(worksheet, jsonOptions);
      console.log("Raw arrays from Excel:", rawArrays);

      // Now get the data with headers
      const rawData = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        rawNumbers: false,
        defval: "",
      });

      console.log("Raw data with headers:", rawData);

      // Examine each row to find problematic values
      rawData.forEach((row, i) => {
        console.log(`Analyzing row ${i + 1}:`, row);
        if (row.quantidade === 0 || row.quantidade === "0") {
          console.log(
            `ATTENTION: Row ${i + 1} has quantidade=0, checking raw cell...`
          );
          // Try to access the raw cell value from the worksheet
          // Assuming the quantidade column is the second column (adjust if needed)
          const cellRef = XLSX.utils.encode_cell({ r: i + 1, c: 1 }); // Column B (index 1)
          const rawCell = worksheet[cellRef];
          console.log(`Raw cell value at ${cellRef}:`, rawCell);
        }
      });

      // Normalizar os nomes das colunas nos dados
      const jsonData = rawData.map((row) => {
        const normalizedRow = {};

        console.log("Dados brutos da linha:", row);

        // Para cada campo no objeto row
        Object.keys(row).forEach((key) => {
          const normalizedKey = normalizeColumnName(key);
          // Primeiro, salve o valor original
          const originalValue = row[key];
          console.log(
            `Campo original ${key} => ${normalizedKey}: "${originalValue}" (${typeof originalValue})`
          );

          // Tratamento especial para campos numéricos
          if (
            normalizedKey === "quantidade" ||
            normalizedKey === "quantidade ideal"
          ) {
            // Converter para string para facilitar manipulação
            const strValue = String(originalValue);
            console.log(`Valor como string: "${strValue}"`);

            // Verificar se é apenas traços e espaços
            if (/^\s*-\s*$/.test(strValue)) {
              console.log(`Detectado valor com traço: "${strValue}"`);
              // Preservar o valor exatamente como está (com o traço)
              normalizedRow[normalizedKey] = "-";
              console.log(
                `Valor preservado como: "${normalizedRow[normalizedKey]}"`
              );
            }
            // Para outros valores, normalize normalmente
            else {
              // Substituir vírgulas por pontos e remover espaços extras
              normalizedRow[normalizedKey] = strValue.trim().replace(",", ".");
              console.log(
                `Valor normalizado: "${normalizedRow[normalizedKey]}"`
              );
            }
          }
          // Para campos não numéricos, use o valor original
          else {
            normalizedRow[normalizedKey] = originalValue;
          }
        });

        console.log("Linha normalizada:", normalizedRow);
        return normalizedRow;
      });

      // Validate the data
      const { columnErrors, rowErrors } = validateExcelData(jsonData);

      // Se houver erros de coluna, não processar os dados
      if (columnErrors.length > 0) {
        setProcessedData(null);
        setErrorColumnData(columnErrors);
        setErrorRowData([]);
        setErrorMessage(
          "Foram encontrados erros. Verifique os detalhes abaixo."
        );
      }
      // Se houver erros nas linhas, ainda pode processar os dados válidos mas mostrar os erros
      else if (rowErrors.length > 0) {
        // Processar apenas dados válidos
        const validData = jsonData.filter(
          (_, index) => !rowErrors.some((err) => err.linha === index + 1)
        );

        const processedData = validData.map((row) => {
          let dataValidade = null;
          if (row.dataValidade) {
            dataValidade = new Date(
              (row.dataValidade - (25567 + 2)) * 86400 * 1000
            );
            dataValidade = new Date(
              dataValidade.getTime() + dataValidade.getTimezoneOffset() * 60000
            );
          }

          return {
            nome: row.nome,
            quantidade: Number(row.quantidade),
            quantidadeIdeal: Number(
              row["quantidade ideal"] || row.quantidadeIdeal
            ),
            lote: row.lote || "N/A", //N/A como lote padrão
            dataValidade: dataValidade,
            categoriaStock: categoriaPrincipal,
            unidadeMedida: normalizeUnidadeMedida(row.unidadeMedida),
          };
        });

        setProcessedData(processedData.length > 0 ? processedData : null);
        setErrorColumnData([]);
        setErrorRowData(rowErrors);
        setErrorMessage(
          `Foram encontrados erros. Verifique os detalhes abaixo.`
        );
      }
      // Se não houver erros, processar normalmente
      else {
        const processedData = jsonData.map((row) => {
          let dataValidade = null;
          if (row.dataValidade) {
            dataValidade = new Date(
              (row.dataValidade - (25567 + 2)) * 86400 * 1000
            );
            dataValidade = new Date(
              dataValidade.getTime() + dataValidade.getTimezoneOffset() * 60000
            );
          }

          // Verificar se existe quantidadeIdeal no arquivo
          const qtdIdealExists =
            "quantidade ideal" in row && row["quantidade ideal"] !== "";

          return {
            nome: row.nome,
            quantidade: safeNumberConversion(row.quantidade),
            quantidadeIdeal: qtdIdealExists
              ? safeNumberConversion(row["quantidade ideal"])
              : null, // Pode ser null ou um número
            lote: row.lote || "N/A", // Usando "N/A" como lote padrão em vez de "Default"
            dataValidade: dataValidade,
            categoriaStock: categoriaPrincipal,
            unidadeMedida: normalizeUnidadeMedida(row.unidadeMedida),
          };
        });

        setProcessedData(processedData);
        setErrorColumnData([]);
        setErrorRowData([]);
        setErrorMessage("");
      }
    };
    reader.readAsArrayBuffer(ficheiro);
  };

  const custoTotalChangeHandler = (value) => {
    setCustoTotal(value);
  };

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const validateExcelData = (data) => {
    const requiredColumns = ["nome", "quantidade"];
    const errors = [];
    const rowErrors = [];

    if (data.length === 0) {
      errors.push("O arquivo está vazio.");
    } else {
      // Verificar se as colunas obrigatórias estão presentes após normalização
      const firstRow = data[0];
      const presentColumns = Object.keys(firstRow).map((key) =>
        normalizeColumnName(key)
      );

      requiredColumns.forEach((column) => {
        if (!presentColumns.includes(column)) {
          errors.push(`A coluna "${column}" está faltando no arquivo.`);
        }
      });

      // Se não houver problemas com as colunas, validar os dados em cada linha
      if (errors.length === 0) {
        data.forEach((row, index) => {
          const lineErrors = [];
          const lineNum = index + 1;

          // Validar nome
          if (!row.nome || row.nome.trim() === "") {
            lineErrors.push(`Nome do produto não pode ser vazio`);
          }

          // Validar quantidade
          const qtyValue = row.quantidade;

          // Special check for dash-only values
          if (typeof qtyValue === "string" && /^\s*-\s*$/.test(qtyValue)) {
            lineErrors.push(
              `Quantidade inválida ou não é um número: "${qtyValue}"`
            );
          }
          // Check with regex for valid positive numbers
          else {
            const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
            if (
              qtyValue === undefined ||
              qtyValue === null ||
              qtyValue === "" ||
              !numberRegex.test(String(qtyValue).trim())
            ) {
              lineErrors.push(
                `Quantidade inválida ou não é um número: "${qtyValue}"`
              );
            } else if (Number(qtyValue) < 0) {
              lineErrors.push(`Quantidade não pode ser negativa: ${qtyValue}`);
            }
          }

          // Validar quantidade ideal (APENAS SE EXISTIR)
          const qtdIdealExists = "quantidade ideal" in row;
          if (qtdIdealExists) {
            const qtdIdealValue = row["quantidade ideal"];
            // Consider empty string as valid (it will be treated as null later)
            if (qtdIdealValue === "") {
              // Do nothing, empty is valid
            }
            // Special check for dash-only values
            else if (
              typeof qtdIdealValue === "string" &&
              /^\s*-\s*$/.test(qtdIdealValue)
            ) {
              lineErrors.push(
                `Quantidade ideal inválida ou não é um número: "${qtdIdealValue}"`
              );
            } else {
              const numberRegex = /^[0-9]+(\.[0-9]+)?$/;
              if (
                qtdIdealValue === undefined ||
                qtdIdealValue === null ||
                !numberRegex.test(String(qtdIdealValue).trim())
              ) {
                lineErrors.push(
                  `Quantidade ideal inválida ou não é um número: "${qtdIdealValue}"`
                );
              } else if (Number(qtdIdealValue) < 0) {
                lineErrors.push(
                  `Quantidade ideal não pode ser negativa: ${qtdIdealValue}`
                );
              }
            }
          }

          // Validar data de validade, se presente
          if (row.dataValidade && isNaN(row.dataValidade)) {
            lineErrors.push(`Data de validade inválida: ${row.dataValidade}`);
          }

          // Se houver erros nesta linha, adicionar ao array de erros por linha
          if (lineErrors.length > 0) {
            rowErrors.push({
              linha: lineNum,
              produto: row.nome || `Linha ${lineNum}`,
              erros: lineErrors,
            });
          }
        });
      }
    }

    return { columnErrors: errors, rowErrors: rowErrors };
  };

  const handleCategoriaPrincipalChange = (event) => {
    const newCategoria = event.target.value;
    setCategoriaPrincipal(newCategoria);
    updateProcessedDataWithNewCategoria(newCategoria);
  };

  const handleProceedToNextStep = () => {
    if (processedData) {
      setCurrentStep(2);
    } else {
      toast.error(
        "Por favor, selecione um arquivo válido antes de prosseguir."
      );
    }
  };

  const StepTwo = ({ processedData, setProcessedData, categoriasStock }) => {
    const handleTextChange = (index, field, value) => {
      const updatedData = [...processedData];
      updatedData[index][field] = value;
      setProcessedData(updatedData);
    };

    const handleCategoriaChange = (index, value) => {
      const updatedData = [...processedData];
      updatedData[index].categoriaStock = value;
      setProcessedData(updatedData);
    };

    const handleDateChange = (index, date) => {
      const updatedData = [...processedData];
      updatedData[index].dataValidade = date ? date.toDate() : null;
      setProcessedData(updatedData);
    };
    const handleNumberChange = (index, field, value) => {
      const updatedData = [...processedData];
      updatedData[index][field] = Number(value);
      setProcessedData(updatedData);
    };

    const renderCategoryOptions = (categories, level = 0) => {
      return categories.flatMap((category) => [
        <MenuItem
          key={category.id}
          value={category.id}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {category.nome}
        </MenuItem>,
        ...(category.subcategorias
          ? renderCategoryOptions(category.subcategorias, level + 1)
          : []),
      ]);
    };

    return (
      <div className="stockImport2__container">
        {processedData.map((item, index) => (
          <div key={crypto.randomUUID() + index} className="stockImport2__item">
            <TextField
              label="Nome do Produto"
              value={item.nome}
              onChange={(e) => handleTextChange(index, "nome", e.target.value)}
              fullWidth
            />

            <TextField
              select
              label="Medida"
              value={item.unidadeMedida || "EA"} // Changed default to EA
              onChange={(e) =>
                handleTextChange(index, "unidadeMedida", e.target.value)
              }
              fullWidth
            >
              {UNIDADES_MEDIDA.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Categoria"
              value={item.categoriaStock}
              onChange={(e) => handleCategoriaChange(index, e.target.value)}
              fullWidth
              variant="outlined"
              className=""
            >
              {renderCategoryOptions(categoriasStock)}
            </TextField>

            <TextField
              label="Lote"
              value={item.lote}
              onChange={(e) => handleTextChange(index, "lote", e.target.value)}
              fullWidth
            />

            <TextField
              label="Qtd"
              type="number"
              value={item.quantidade}
              onChange={(e) =>
                handleNumberChange(index, "quantidade", e.target.value)
              }
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
            />

            <TextField
              label="Qtd Ideal"
              type="number"
              value={item.quantidadeIdeal}
              onChange={(e) =>
                handleNumberChange(index, "quantidadeIdeal", e.target.value)
              }
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
            />

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-entrada__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field"
                label="Validade"
                inputFormat="DD-MM-YYYY"
                value={item.dataValidade ? moment(item.dataValidade) : null}
                onChange={(date) => handleDateChange(index, date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        ))}
      </div>
    );
  };

  const updateProcessedDataWithNewCategoria = (newCategoria) => {
    if (processedData.length > 0) {
      const updatedData = processedData.map((item) => ({
        ...item,
        categoriaStock: newCategoria,
      }));
      setProcessedData(updatedData);
    }
  };

  return (
    <div className="stockImport__container">
      {currentStep === 1 ? (
        <>
          <div className="modalAdicionarFicheiros__container">
            <div className="modalAdicionarFicheiros__container-adicionar">
              {categoriasStock && (
                <div className="customInputContainer">
                  <select
                    onChange={handleCategoriaPrincipalChange}
                    className="customInputSelect"
                    value={categoriaPrincipal}
                  >
                    {categoriasStock.map((c) => (
                      <option value={c._id}>{c.nome}</option>
                    ))}
                  </select>
                  <label className="customInputLabelSelect">
                    Categoria Principal
                  </label>
                </div>
              )}
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="fileInput"
                  className="modalAdicionarFicheiros__container-adicionar--btn-adicionar"
                >
                  Escolher Ficheiro
                </label>
              </div>
              {!file?.name && (
                <>
                  <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                    O Tamanho máximo do upload é 10MB
                  </span>
                  <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                    Formatos aceites: .xls e .xlsx
                  </span>
                </>
              )}
              {file?.name && (
                <span className="modalImportarXlsStock__nomeFicheiro">
                  Ficheiro escolhido - {file.name}
                </span>
              )}
              <span
                className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro"
                style={{
                  marginTop: "20px",
                  lineHeight: "25px",
                  maxWidth: "800px",
                  textAlign: "justify",
                }}
              >
                O ficheiro deve ter colunas para: nome do produto e quantidade
                (obrigatórias), quantidade ideal, unidade de medida, lote e data
                de validade (opcionais). As colunas podem ser nomeadas de forma
                flexível - por exemplo, "nome", "produto", "item" serão
                reconhecidos como nome do produto; "qtd", "quantidade" serão
                reconhecidos como quantidade, etc. Os nomes dos produtos cuja
                quantidade pretende alterar devem corresponder aos nomes já
                existentes no stock, caso contrário serão criados novos
                produtos. Clique no link abaixo para baixar um xls exemplar.
              </span>

              <span className="modalImportarXlsStock__spanModeloXlsx">
                <a
                  href="/modeloImportStock.xlsx"
                  download="modeloImportStock.xlsx"
                >
                  Baixar Modelo xlsx
                </a>
              </span>
            </div>

            <div>
              <div className="adicionar-despesa__modal__container__checkbox-pago__container">
                <input
                  type="checkbox"
                  name=""
                  id="adicionarComoDespesa-checkbox"
                  onChange={(e) => setAdicionarComoDespesa(e.target.checked)}
                  checked={adicionarComoDespesa}
                />
                <label htmlFor="adicionarComoDespesa-checkbox">
                  Adicionar os custos como despesa
                </label>
              </div>

              {adicionarComoDespesa && (
                <div className="modalImportarXlsStock__adicionarComoDespesa__container">
                  <InputText
                    className="modalImportarXlsStock__adicionarComoDespesa__container__span3"
                    label="Descrição *"
                    initialValue={descricao}
                    handleChange={descricaoChangeHandler}
                  />

                  <InputNumber
                    className="modalImportarXlsStock__adicionarComoDespesa__container__span1"
                    label="Custo Total *"
                    initialValue={custoTotal}
                    handleChange={custoTotalChangeHandler}
                  />

                  {categorias && categoria && (
                    <div className="customInputContainer modalImportarXlsStock__adicionarComoDespesa__container__span2">
                      <select
                        onChange={(e) => setCategoria(e.target.value)}
                        className="customInputSelect"
                        value={categoria}
                      >
                        {categorias &&
                          categoria &&
                          categorias.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                      <label className="customInputLabelSelect">
                        Categoria
                      </label>
                    </div>
                  )}

                  {caixas && caixa && (
                    <div className="customInputContainer modalImportarXlsStock__adicionarComoDespesa__container__span1">
                      <select
                        onChange={(e) => setCaixa(e.target.value)}
                        className="customInputSelect"
                        value={caixa}
                      >
                        {caixas &&
                          caixa &&
                          caixas.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                      <label className="customInputLabelSelect">Caixa</label>
                    </div>
                  )}

                  <InputSelect
                    label="Forma de pagamento"
                    initialValue={metodoPagamento}
                    handleChange={metodoPagamentoChangeHandler}
                    items={formasPagamento}
                    className="span1"
                  />

                  <div className="modalImportarXlsStock__adicionarComoDespesa__container__despesaPagaContainer modalImportarXlsStock__adicionarComoDespesa__container__span3">
                    <input
                      type="checkbox"
                      name=""
                      id="despesaPaga-checkbox"
                      onChange={(e) => setDespesaPaga(e.target.checked)}
                      checked={despesaPaga}
                    />
                    <label htmlFor="despesaPaga-checkbox">Despesa Paga</label>
                  </div>
                </div>
              )}
            </div>
            {errorMessage && (
              <div className="modalAdicionarFicheiros__errorMessage">
                {errorMessage}
              </div>
            )}

            {(errorColumnData.length > 0 || errorRowData.length > 0) && (
              <ImportErrorList
                columnErrors={errorColumnData}
                rowErrors={errorRowData}
              />
            )}
          </div>
        </>
      ) : (
        <StepTwo
          processedData={processedData}
          setProcessedData={setProcessedData}
          categoriasStock={categoriasStock}
          handleSubmitImportStock={handleSubmitImportStock}
          produtos={produtos}
          stock={stock}
          auth={auth}
          clinica={clinica}
          sendRequest={sendRequest}
          atualizarStock={atualizarStock}
          handleCloseModalImportStock={handleCloseModalImportStock}
        />
      )}

      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalImportStock}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        {currentStep === 2 && (
          <span
            disabled={isSubmitting || errorMessage || !file}
            className={
              isSubmitting || errorMessage || !file
                ? "cancel-btn"
                : `blue-button`
            }
            onClick={handleSubmitImportStock}
          >
            {isSubmitting ? "Processando..." : "Processar Arquivo"}
          </span>
        )}

        {currentStep === 1 && (
          <span
            disabled={isSubmitting || errorMessage || !file || !processedData}
            className={
              isSubmitting || errorMessage || !file || !processedData
                ? "cancel-btn"
                : `blue-button`
            }
            onClick={handleProceedToNextStep}
          >
            {isSubmitting ? "Processando..." : "Prosseguir"}
          </span>
        )}
      </div>
    </div>
  );
}

export default ModalImportXlsStock;
