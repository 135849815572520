import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined"; //Executar
import moment from "moment";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function ListPlanoTratamento({
  data,
  handleOpenModal,
  handleDesativar,
  handleOpenModalVerPlano,
}) {
  const [planosTratamento, setPlanosTratamento] = useState(data);
  const auth = useContext(AuthContext);
  useEffect(() => {
    setPlanosTratamento(data);
  }, [data]);

  const hasSessions = (planoTratamento) => {
    // Check if any procedimento has sessions
    const hasSessions = planoTratamento.procedimentos.some(
      (procedimento) => procedimento.sessoes && procedimento.sessoes.length > 0
    );

    // Return true only if there are sessions
    return hasSessions;
  };

  const renderDeleteOption = (row) => {
    const isMedicoAutorizado =
      (auth.role === "Médico/Administrador" || auth.role === "Dentista") &&
      row.medico_responsavel._id === auth.userId;

    const isSecretariaOuAssistenteAutorizado =
      (auth.role === "Secretária(o)" || auth.role === "Assistente") &&
      auth.usuariosAssociados.some(
        (medico) => medico.id === row.medico_responsavel.id
      );

    const podeExcluir =
      !row.plano_completo &&
      !row.data_inicio &&
      row.canDelete &&
      hasSessions(row) === false &&
      row.status !== "INTERROMPIDO" &&
      auth.perm.includes("d-plt") &&
      (isMedicoAutorizado || isSecretariaOuAssistenteAutorizado);

    if (podeExcluir) {
      return (
        <Popconfirm
          title="Desativação de plano de tratamento"
          description="Tem a certeza que pretende desativar o plano de tratamento?"
          icon={<DeleteIcon style={{ color: "red" }} />}
          okText="Sim"
          cancelText="Não"
          onConfirm={() => handleDesativar(row.id)}
        >
          <div className="popOverMenu--option">
            <span>Desativar</span>
            <DeleteIcon className="popOverMenu--option__icon" />
          </div>
        </Popconfirm>
      );
    }
    return null;
  };

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Histórico
              </TableCell> */}

              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Código
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Médico Responsável
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Criação
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Inicio
              </TableCell>
              <TableCell className="tableCell tableCell--heading">
                Data Fim
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Estado
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--end">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {planosTratamento && (
            <TableBody>
              {planosTratamento.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">
                    {"PL" +
                      row.numero +
                      "_OR" +
                      row.orcamento.codigo +
                      "_" +
                      +moment(row.created_at).year()}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.medico_responsavel.name}
                  </TableCell>

                  <TableCell className="tableCell">
                    {moment(row.created_at).format("DD-MM-YYYY")}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.data_inicio ? row.data_inicio : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.data_fim ? row.data_fim : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    <span
                      className={
                        row.status
                          ? "status " + row.status
                          : row.plano_completo
                          ? "status concluido"
                          : row.canDelete && hasSessions(row) === false
                          ? "status pendente"
                          : "status transformado"
                      }
                    >
                      {row.status === "INTERROMPIDO"
                        ? "Interrompido"
                        : row.plano_completo
                        ? "Concluido"
                        : row.canDelete && hasSessions(row) === false
                        ? "Criado"
                        : "Em Andamento"}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Dentista") &&
                        !row.plano_completo &&
                        row.status !== "INTERROMPIDO" && (
                          <Tooltip title="Executar">
                            <PlaylistAddCheckOutlinedIcon
                              className="icon__list"
                              onClick={handleOpenModal.bind(null, row.id)}
                            />
                          </Tooltip>
                        )}

                      {auth.perm.includes("r-plt") &&
                        (row.status === "INTERROMPIDO" ? (
                          <Tooltip title="Ver Plano de Tratamento">
                            <RemoveRedEyeIcon
                              className="icon__list"
                              onClick={handleOpenModalVerPlano.bind(
                                null,
                                row.id,
                                "interrompido"
                              )}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Ver Plano de Tratamento">
                            <RemoveRedEyeIcon
                              className="icon__list"
                              onClick={handleOpenModalVerPlano.bind(
                                null,
                                row.id,
                                "normal"
                              )}
                            />
                          </Tooltip>
                        ))}

                      {renderDeleteOption(row) && (
                        <Popover
                          content={renderDeleteOption(row)}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ListPlanoTratamento;
