import { useState, useEffect, useContext } from "react";
import Sidebar from "../../shared/components/sidebar/Sidebar";
import Navbar from "../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./Stock.scss";
import moment from "moment";

import { AuthContext } from "../../shared/context/auth-context";
import { ClinicaContext } from "../../shared/context/clinica-context";

//MUI
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ModalAdicionarNoStock from "./components/ModalAdicionarNoStock";
import ModalStockRetirada from "./components/ModalStockRetirada";
import ModalStockHistoricoSaidas from "./components/ModalStockHistoricoSaidas";
import ModalStockHistoricoEntradas from "./components/ModalStockHistoricoEntradas";
import ModalEditarProduto from "./components/ModalEditarProduto";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { DataGrid } from "@mui/x-data-grid";
import { useLoading } from "../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";

function Stock() {
  const { startLoading, stopLoading } = useLoading();

  const [rows, setRows] = useState([]);
  const [stock, setStock] = useState();
  const [categoriasStock, setCategoriasStock] = useState();
  const [permaStock, setPermaStock] = useState();
  const [openModal, setOpenModal] = useState("");
  const { sendRequest } = useHttpClient();
  const [stockRetirada, setStockRetirada] = useState();
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("todas");
  const [dadosModalEditar, setDadosModalEditar] = useState({});

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    fetchData();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  const fetchData = async () => {
    startLoading();
    try {
      const [stockResponse, categoriasStockResponse] = await Promise.all([
        sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        ),
        sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        ),
      ]);

      // Process stock data
      if (
        stockResponse &&
        stockResponse.stock &&
        stockResponse.stock.length > 0
      ) {
        const tempRows = processStockData(stockResponse.stock[0].stock);
        setRows(tempRows);
        setPermaStock(stockResponse.stock[0]);
        setStock(stockResponse.stock[0]);
      } else {
        setRows([]);
        setPermaStock(null);
        setStock(null);
      }

      // Process categories data
      if (categoriasStockResponse && categoriasStockResponse.categoriasStock) {
        const tree = buildCategoryTree(categoriasStockResponse.categoriasStock);
        setCategoriesTree(tree);
        setCategoriasStock(categoriasStockResponse.categoriasStock);
      } else {
        setCategoriesTree([]);
        setCategoriasStock([]);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };
  // Helper function

  const buildCategoryTree = (categories, parentId = null) => {
    return categories
      .filter((category) => category.categoriaPai === parentId)
      .map((category) => ({
        ...category,
        subcategorias: buildCategoryTree(categories, category.id),
      }));
  };

  const processStockData = (stockData) => {
    let totalByProduct = {};
    let rows = [];

    stockData.forEach((st) => {
      st.lotes.forEach((lote, index) => {
        rows.push({
          id: `${st._id}-${lote._id}`,
          produto: st.produto.nome,
          unidadeMedida: st.produto.unidadeMedida,
          produtoRetirado: st.produto.comRetirada,
          stock: lote.quantidade,
          qtdIdeal: st.quantidadeIdeal,
          produtoID: st.produto._id,
          categoriaProduto: st.produto.categoriaStock,
          lote: lote.lote,
          dataValidade: lote.dataValidade,
          isFirstLot: index === 0,
        });

        // Calculate total for each product
        totalByProduct[st.produto._id] =
          (totalByProduct[st.produto._id] || 0) + lote.quantidade;
      });
    });

    // Add total to each row
    rows = rows.map((row) => ({
      ...row,
      total: totalByProduct[row.produtoID],
    }));

    return rows;
  };

  const renderCategoryOptions = (categories, level = 0) => {
    return categories.flatMap((category) => [
      <MenuItem
        key={category.id}
        value={category.id}
        style={{ paddingLeft: `${level * 20}px` }}
      >
        {category.nome}
      </MenuItem>,
      ...renderCategoryOptions(category.subcategorias || [], level + 1),
    ]);
  };

  useEffect(() => {
    if (stock) {
      setRows(processStockData(stock.stock));
    }
  }, [stock]);

  const handleCloseModal = () => {
    setOpenModal("");
    setDadosModalEditar({});
  };

  const atualizarStock = (novoStock, mensagem) => {
    setStock(novoStock);
    setPermaStock(novoStock);

    toast.success(mensagem);
  };

  const handleFazerRetirada = (stId) => {
    const id = stId.split("-")[0];
    setStockRetirada(stock.stock.filter((st) => st.id === id)[0]);
    setOpenModal("stockRetirada");
  };

  const procuraStock = (e) => {
    const result = permaStock.stock.filter((perma) => {
      return perma.produto.nome
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setStock((current) => {
      return {
        ...current,
        stock: result,
      };
    });
  };

  const changeCategoriaStockHandler = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);

    if (categoryId === "todas") {
      setStock(permaStock);
    } else {
      const filteredStock = permaStock.stock.filter((item) =>
        isCategoryOrSubcategory(item.produto.categoriaStock, categoryId)
      );
      setStock({ ...permaStock, stock: filteredStock });
    }
  };

  const isCategoryOrSubcategory = (itemCategoryId, selectedCategoryId) => {
    if (itemCategoryId === selectedCategoryId) return true;
    const category = categoriesTree.find(
      (cat) => cat.id === selectedCategoryId
    );
    if (!category) return false;
    return category.subcategorias.some((subcat) =>
      isCategoryOrSubcategory(itemCategoryId, subcat.id)
    );
  };

  const getRowClassName = (params) => {
    if (params.row.total < params.row.qtdIdeal) {
      return "stock-row__danger";
    }
    const validade = params.row.dataValidade;
    if (validade) {
      const daysUntilExpiry = moment(validade).diff(moment(), "days");
      if (daysUntilExpiry <= 0) return "stock-row__expired";
      if (daysUntilExpiry <= 30) return "stock-row__expiring-soon";
    }
    return "";
  };

  const apagarProdutoDoStock = async (idProduto) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("produtoId", idProduto);
      formData.append("utilizador", auth.userId);

      const resultado = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/removeprodutohistorico/${stock.id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      atualizarStock(resultado.stock, "Produto removido do stock!");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleOpenModalEditar = (dados) => {
    setDadosModalEditar({
      open: true,
      id: dados.produtoID,
      qtdIdeal: dados.qtdIdeal,
    });
  };

  const columns = [
    {
      field: "produto",
      headerName: "Produto",
      flex: 3,
      renderCell: (params) => {
        return params.row.isFirstLot ? params.value : "";
      },
    },
    {
      field: "stock",
      headerName: "Quantidade",
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "qtdIdeal",
      headerName: "QtdIdeal",
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "unidadeMedida",
      headerName: "Medida",
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return (
          <span style={{ marginRight: "auto" }}>
            {params.row.unidadeMedida}
          </span>
        );
      },
    },
    { field: "lote", headerName: "Lote", flex: 2 },
    {
      field: "dataValidade",
      headerName: "Validade",
      flex: 2,
      valueGetter: (params) =>
        params.value ? moment(params.value).format("DD/MM/YYYY") : "N/A",
      renderCell: (params) => {
        if (!params.value) return "N/A";
        const date = moment(params.value, "DD/MM/YYYY");
        const now = moment();
        const daysUntilExpiry = date.diff(now, "days");
        let color = "inherit";
        if (daysUntilExpiry <= 30) color = "orange";
        if (daysUntilExpiry <= 0) color = "red";
        return <span style={{ color }}>{params.value}</span>;
      },
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      type: "number",
      renderCell: (params) => {
        return params.row.isFirstLot ? params.value : "";
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1.5,
      renderCell: (params) => {
        const content = (
          <div className="dots__menu__popup">
            {auth.perm.includes("u-stR") && (
              <div
                className="popOverMenu--option"
                onClick={handleOpenModalEditar.bind(null, params.row)}
              >
                <span>Editar</span>
                <EditIcon className="popOverMenu--option__icon" />
              </div>
            )}

            {!params.row.produtoRetirado && auth.perm.includes("d-st") && (
              <Popconfirm
                title="Excluir Produto"
                description={`Excluir ${params.row.produto}  do stock?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={apagarProdutoDoStock.bind(
                  null,
                  params.row.produtoID
                )}
                // onCancel={}
              >
                <div className="popOverMenu--option">
                  <span>Apagar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );

        return (
          <>
            {auth.perm.includes("u-stR") && params.row.stock > 0 && (
              <span
                onClick={handleFazerRetirada.bind(null, params.row.id)}
                className="btn__retiradas"
              >
                Dar Retirada
              </span>
            )}

            {auth.perm.includes("c-st") && params.row.stock === 0 && (
              <span className="btn__esgotado">Esgotado</span>
            )}
            {(auth.perm.includes("u-stR") || auth.perm.includes("d-st")) && (
              <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                  <div className="dots__menu">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </Popover>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar title="Stock" icon={Inventory2Icon} />
          <div className="stock__container caixa">
            <div className="stock__header">
              <div className="stock__header__container1">
                {auth.perm.includes("c-st") && (
                  <Button
                    variant="contained"
                    color="success"
                    className="stock__entrada-btn"
                    onClick={(e) => setOpenModal("adicionar")}
                  >
                    Fazer entrada no stock
                  </Button>
                )}
                {auth.perm.includes("r-histSt") && (
                  <>
                    <span
                      className="stock__header__historico-btn"
                      onClick={(e) => setOpenModal("historicoRetiradas")}
                    >
                      Histórico de Retiradas
                    </span>

                    <span
                      className="stock__header__historico-btn"
                      onClick={(e) => setOpenModal("historicoEntradas")}
                    >
                      Histórico de Entradas
                    </span>
                  </>
                )}
              </div>

              <div className="stock__header__container2">
                <div className="stock__header__container2__container1">
                  {categoriesTree.length > 0 && (
                    <TextField
                      select
                      label="Exibir por Categoria"
                      value={selectedCategory}
                      onChange={changeCategoriaStockHandler}
                      variant="outlined"
                      className="select__filter-categoria"
                    >
                      <MenuItem value="todas">Todas Categorias</MenuItem>
                      {renderCategoryOptions(categoriesTree)}
                    </TextField>
                  )}
                </div>
                <div className="stock__header__container2__container2 textBoxSearch__container">
                  <input
                    type="text"
                    className="textBoxSearch__input"
                    placeholder="O que está procurando?"
                    onChange={procuraStock}
                  />
                  <SearchIcon className="financeiro__filtros__search-icon" />
                </div>
              </div>
            </div>
            {rows && (
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  autoWidth
                  className="datagrid"
                  rows={rows}
                  columns={columns}
                  getRowClassName={getRowClassName}
                  autoPageSize
                  getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 5,
                    bottom: params.isLastVisible ? 0 : 5,
                  })}
                />
              </div>
            )}
            {openModal === "adicionar" && (
              <ModalAdicionarNoStock
                sendRequest={sendRequest}
                clinicaId={clinica.clinica._id}
                auth={auth}
                handleCloseModalAdicionarStock={handleCloseModal}
                open={openModal === "adicionar"}
                atualizarStock={atualizarStock}
                stock={permaStock}
                categoriasStock={categoriasStock}
              />
            )}

            {openModal === "stockRetirada" && (
              <ModalStockRetirada
                sendRequest={sendRequest}
                handleCloseModalStockRetirada={handleCloseModal}
                open={openModal === "stockRetirada"}
                atualizarStock={atualizarStock}
                stock={permaStock}
                stockRetirada={stockRetirada}
              />
            )}

            {openModal === "historicoRetiradas" && (
              <ModalStockHistoricoSaidas
                sendRequest={sendRequest}
                handleCloseModalStockHistoricoSaidas={handleCloseModal}
                open={openModal === "historicoRetiradas"}
                clinicaId={clinica.clinica._id}
              />
            )}

            {openModal === "historicoEntradas" && (
              <ModalStockHistoricoEntradas
                sendRequest={sendRequest}
                handleCloseModalStockHistoricoEntradas={handleCloseModal}
                open={openModal === "historicoEntradas"}
                clinicaId={clinica.clinica._id}
              />
            )}

            {dadosModalEditar.open && (
              <ModalEditarProduto
                sendRequest={sendRequest}
                handleCloseModalEditar={handleCloseModal}
                open={dadosModalEditar.open}
                id={dadosModalEditar.id}
                qtdIdeal={dadosModalEditar.qtdIdeal}
                clinicaId={clinica.clinica._id}
                auth={auth}
                categoriasStock={categoriasStock}
                fetchData={fetchData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Stock;
