import React, { useState, useEffect } from "react";

import { useLoading } from "../../../../../shared/context/LoadingContext";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { Link } from "react-router-dom";

import {
  Container,
  Typography,
  Box,
  Paper,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Badge,
} from "@mui/material";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonOffIcon from "@mui/icons-material/PersonOff";

function RelatorioInatividade({ dadosClinica, auth, acao, atualizacoes }) {
  const { sendRequest } = useHttpClient();
  const { startLoading, stopLoading } = useLoading();
  const [clientes, setClientes] = useState([]);
  const [error, setError] = useState(null);
  const [periodo, setPeriodo] = useState(30);
  const [unidade, setUnidade] = useState("dias");
  const [stats, setStats] = useState({ total: 0, message: "" });

  const unidadesOptions = [
    { value: "dias", label: "Dias" },
    { value: "meses", label: "Meses" },
  ];

  const periodosOptions = {
    dias: [
      { value: 15, label: "15 dias" },
      { value: 30, label: "30 dias" },
      { value: 60, label: "60 dias" },
      { value: 90, label: "90 dias" },
      { value: 180, label: "180 dias" },
      { value: 365, label: "365 dias" },
    ],
    meses: [
      { value: 1, label: "1 mês" },
      { value: 2, label: "2 meses" },
      { value: 3, label: "3 meses" },
      { value: 6, label: "6 meses" },
      { value: 12, label: "1 ano" },
      { value: 24, label: "2 anos" },
    ],
  };

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        fetchClientesInativos();
      }
    }
  }, [acao, atualizacoes]); // Importante: só reexecuta quando a ação muda

  // Função para buscar os dados da API
  const fetchClientesInativos = async () => {
    startLoading();
    setError(null);
    try {
      const formData = new FormData();
      formData.append("periodo", periodo);
      formData.append("unidade", unidade);
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getClientesInativosPorPeriodo/${dadosClinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Transformar os dados para o formato do DataGrid (adicionar id)
      const clientesComId = response.clientes.map((cliente, index) => ({
        ...cliente,
        id: cliente.clienteId || index,
      }));

      setClientes(clientesComId);
      setStats({
        total: clientesComId.length,
        message: response.message,
      });
    } catch (err) {
      console.error("Erro ao buscar clientes inativos:", err);
      setError(err.response?.message || "Erro ao buscar dados.");
    } finally {
      stopLoading();
    }
  };

  // Formatador para telefones
  const formatarTelefone = (telefone) => {
    if (!telefone) return "";
    // Remover caracteres não numéricos e adicionar formatação se necessário
    return telefone;
  };

  // Colunas do DataGrid
  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 2,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <span className="nomeDataTable">
            <Link to={`/clientes/${params.row.clienteId}-planotratamento`}>
              {params.row.nome}
            </Link>
          </span>
        );
      },
    },
    {
      field: "contacto",
      headerName: "Contacto",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        // Determinar qual contacto mostrar (principal ou secundário)
        const contactoPrincipal = params.row.contacto;
        const contactoSecundario = params.row.contacto2;
        const temContacto = contactoPrincipal || contactoSecundario;
        const prefereSMS = params.row.preferencias?.sms;
        const prefereWhatsApp = params.row.preferencias?.whatsapp;

        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {temContacto
              ? formatarTelefone(contactoPrincipal || contactoSecundario)
              : "Não informado"}

            {temContacto && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Ligar">
                  <IconButton
                    size="small"
                    href={`tel:${contactoPrincipal || contactoSecundario}`}
                  >
                    <CallIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                {prefereWhatsApp && (
                  <Tooltip title="WhatsApp (preferencial)">
                    <IconButton
                      size="small"
                      href={`https://wa.me/${(
                        contactoPrincipal || contactoSecundario
                      ).replace(/[^0-9]/g, "")}`}
                      target="_blank"
                      color="success"
                    >
                      <Badge color="primary" variant="dot">
                        <WhatsAppIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}

                {!prefereWhatsApp && contactoPrincipal && (
                  <Tooltip title="WhatsApp">
                    <IconButton
                      size="small"
                      href={`https://wa.me/${contactoPrincipal.replace(
                        /[^0-9]/g,
                        ""
                      )}`}
                      target="_blank"
                      color="success"
                    >
                      <WhatsAppIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}

                {prefereSMS && (
                  <Tooltip title="SMS (preferencial)">
                    <IconButton
                      size="small"
                      href={`sms:${contactoPrincipal || contactoSecundario}`}
                      color="primary"
                    >
                      <Badge color="primary" variant="dot">
                        <SmsIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      minWidth: 200,
      renderCell: (params) => {
        const email = params.value;
        const prefereEmail = params.row.preferencias?.email;

        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {email || "Não informado"}
            {email && (
              <Tooltip
                title={prefereEmail ? "Email (preferencial)" : "Enviar e-mail"}
              >
                <IconButton size="small" href={`mailto:${email}`}>
                  {prefereEmail ? (
                    <Badge color="primary" variant="dot">
                      <EmailIcon fontSize="small" />
                    </Badge>
                  ) : (
                    <EmailIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "ultimaVisita",
      headerName: "Última Visita",
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => {
        if (!params.value) return "Não registrada";
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "diasInativo",
      headerName: "Inativo há",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        // Formatação dinâmica baseada no período
        const diasInativo = params.row.diasInativo || 0;
        const mesesInativo = params.row.mesesInativo || 0;

        let texto = "";
        let color = "default";

        if (diasInativo > 365) {
          texto = `${Math.floor(diasInativo / 365)} ${
            Math.floor(diasInativo / 365) === 1 ? "ano" : "anos"
          }`;
          color = "error";
        } else if (diasInativo > 30) {
          texto = `${Math.round(mesesInativo)} ${
            Math.round(mesesInativo) === 1 ? "mês" : "meses"
          }`;
          color = diasInativo > 180 ? "error" : "warning";
        } else {
          texto = `${diasInativo} ${diasInativo === 1 ? "dia" : "dias"}`;
          color = "info";
        }

        return (
          <Chip
            icon={<PersonOffIcon />}
            label={texto}
            color={color}
            size="small"
            variant="filled"
          />
        );
      },
    },
    {
      field: "qtdPlanos",
      headerName: "Qtd Planos",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "actions",
    //   headerName: "Ações",
    //   width: 120,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Box sx={{ display: "flex", gap: 1 }}>
    //       <Tooltip title="Agendar consulta">
    //         <IconButton
    //           size="small"
    //           color="primary"
    //           onClick={() => handleAgendar(params.row)}
    //         >
    //           <CalendarTodayIcon fontSize="small" />
    //         </IconButton>
    //       </Tooltip>
    //     </Box>
    //   ),
    // },
  ];

  // Handler para agendamento (pode ser integrado com um sistema de agendamento existente)
  // const handleAgendar = (cliente) => {
  //   // Implementar lógica para abrir modal de agendamento ou redirecionar
  //   console.log("Agendar consulta para:", cliente);
  //   // Por exemplo: navigate(`/agendamentos/novo?clienteId=${cliente.clienteId}`);
  // };

  // Handler para aplicar filtros
  const handleFiltrar = () => {
    fetchClientesInativos();
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Clientes Inativos
        </Typography>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems={{ sm: "center" }}
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              Filtrar clientes sem retorno há mais de:
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                select
                label="Período"
                value={periodo}
                onChange={(e) => setPeriodo(e.target.value)}
                sx={{ minWidth: 120 }}
              >
                {periodosOptions[unidade].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label="Unidade"
                value={unidade}
                onChange={(e) => {
                  setUnidade(e.target.value);
                  // Resetar para um valor válido quando mudar a unidade
                  setPeriodo(periodosOptions[e.target.value][0].value);
                }}
                sx={{ minWidth: 120 }}
              >
                {unidadesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                onClick={handleFiltrar}
                startIcon={<RefreshIcon />}
              >
                Filtrar
              </Button>
            </Stack>
          </Stack>
        </Paper>

        {error && (
          <Paper
            sx={{
              p: 2,
              mb: 3,
              bgcolor: "error.light",
              color: "error.contrastText",
            }}
          >
            <Typography>{error}</Typography>
          </Paper>
        )}

        <Paper sx={{ height: 600, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="h6">{stats.message}</Typography>
            <Chip
              label={`Total: ${stats.total}`}
              color="primary"
              variant="outlined"
            />
          </Box>

          <DataGrid
            rows={clientes}
            columns={columns}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: CircularProgress,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              sorting: {
                sortModel: [{ field: "diasInativo", sort: "desc" }],
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            density="standard"
          />
        </Paper>
      </Box>
    </Container>
  );
}

export default RelatorioInatividade;
