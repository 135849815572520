import React from "react";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import Datatable from "../components/datatable/Datatable";
import PeopleIcon from "@mui/icons-material/People";

function Funcionarios() {
  const people = PeopleIcon;
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar title="Funcionários" icon={people} />
        <Datatable />
      </div>
    </div>
  );
}

export default Funcionarios;
