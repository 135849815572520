import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PercentIcon from "@mui/icons-material/Percent";
import ShowChartIcon from "@mui/icons-material/ShowChart";

// Configurar locale do moment
moment.locale("pt-br");

// Componentes estilizados
const MetricCard = styled(Card)(({ theme, cardColor }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  borderLeft: `4px solid ${
    cardColor === "primary"
      ? theme.palette.primary.main
      : cardColor === "success"
      ? theme.palette.success.main
      : cardColor === "error"
      ? theme.palette.error.main
      : cardColor === "warning"
      ? theme.palette.warning.main
      : cardColor === "info"
      ? theme.palette.info.main
      : theme.palette.divider
  }`,
}));

const ValueTypography = styled(Typography)(({ theme, valuetype }) => ({
  color:
    valuetype === "positivo"
      ? theme.palette.success.main
      : valuetype === "negativo"
      ? theme.palette.error.main
      : theme.palette.text.primary,
  fontWeight: "bold",
}));

const ComparativoIndicator = styled(Box)(({ theme, trend }) => ({
  display: "flex",
  alignItems: "center",
  color:
    trend === "positiva"
      ? theme.palette.success.main
      : trend === "negativa"
      ? theme.palette.error.main
      : theme.palette.text.secondary,
  fontSize: "0.875rem",
}));

function RelatorioDashboardFinanceiro({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar), execute-a
    if (acao === "gerar") {
      gerarDashboard();
    }
  }, [acao, atualizacoes]);

  const gerarDashboard = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarDashboardFinanceiro/${dadosClinica._id}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData);
    } catch (err) {
      console.error("Erro ao gerar dashboard financeiro:", err);
      setError(
        err.message || "Ocorreu um erro ao gerar o dashboard financeiro."
      );
    } finally {
      stopLoading();
    }
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Dashboard Financeiro
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1">
            Clínica: {dadosClinica?.nome || ""}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Dados atualizados em: {moment().format("DD/MM/YYYY [às] HH:mm")}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            {/* Seção de Faturamento e Despesas */}
            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Visão Geral Financeira
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3} sx={{ mb: 4 }}>
              {/* Card de Faturamento */}
              <Grid item xs={12} md={6}>
                <MetricCard cardColor="success">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <TrendingUpIcon color="success" sx={{ mr: 1 }} />
                      <Typography variant="h6">Faturamento</Typography>
                    </Box>

                    <Typography
                      variant="h4"
                      component="div"
                      color="success.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.faturamento.mesAtual.valor.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "CVE",
                        }
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 1 }}
                    >
                      {moment(
                        dados.faturamento.mesAtual.periodo,
                        "MMMM YYYY"
                      ).format("MMMM [de] YYYY")}
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <ComparativoIndicator trend={dados.faturamento.tendencia}>
                        {dados.faturamento.tendencia === "positiva" ? (
                          <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                        ) : dados.faturamento.tendencia === "negativa" ? (
                          <TrendingDownIcon fontSize="small" sx={{ mr: 0.5 }} />
                        ) : null}
                        {dados.faturamento.variacao > 0 ? "+" : ""}
                        {dados.faturamento.variacao}% comparado a{" "}
                        {dados.faturamento.mesAnterior.periodo.toLocaleString(
                          "pt-BR"
                        )}
                      </ComparativoIndicator>
                    </Box>

                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" sx={{ mb: 0.5 }}>
                        Período anterior:{" "}
                        {dados.faturamento.mesAnterior.valor.toLocaleString(
                          "pt-BR",
                          { style: "currency", currency: "CVE" }
                        )}
                      </Typography>
                    </Box>
                  </CardContent>
                </MetricCard>
              </Grid>

              {/* Card de Despesas */}
              <Grid item xs={12} md={6}>
                <MetricCard cardColor="error">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <TrendingDownIcon color="error" sx={{ mr: 1 }} />
                      <Typography variant="h6">Despesas</Typography>
                    </Box>

                    <Typography
                      variant="h4"
                      component="div"
                      color="error.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.despesas.mesAtual.valor.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 1 }}
                    >
                      {dados.despesas.mesAtual.periodo}
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <ComparativoIndicator trend={dados.despesas.tendencia}>
                        {dados.despesas.tendencia === "positiva" ? (
                          <TrendingDownIcon fontSize="small" sx={{ mr: 0.5 }} />
                        ) : dados.despesas.tendencia === "negativa" ? (
                          <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                        ) : null}
                        {dados.despesas.variacao > 0 ? "+" : ""}
                        {dados.despesas.variacao}% comparado a{" "}
                        {dados.despesas.mesAnterior.periodo}
                      </ComparativoIndicator>
                    </Box>

                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" sx={{ mb: 0.5 }}>
                        Período anterior:{" "}
                        {dados.despesas.mesAnterior.valor.toLocaleString(
                          "pt-BR",
                          { style: "currency", currency: "CVE" }
                        )}
                      </Typography>
                    </Box>
                  </CardContent>
                </MetricCard>
              </Grid>
            </Grid>

            {/* Indicadores Principais */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
              Indicadores Principais
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Grid container spacing={3} sx={{ mb: 4 }}>
              {/* Saldo Atual */}
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard cardColor="primary">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <AccountBalanceIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="subtitle1">Saldo Atual</Typography>
                    </Box>
                    <ValueTypography
                      variant="h5"
                      component="div"
                      valuetype={
                        dados.saldoAtual >= 0 ? "positivo" : "negativo"
                      }
                    >
                      {dados.saldoAtual.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </ValueTypography>
                  </CardContent>
                </MetricCard>
              </Grid>

              {/* Lucro do Mês */}
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard
                  cardColor={
                    dados.lucratividade.lucroMesAtual >= 0 ? "success" : "error"
                  }
                >
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <ShowChartIcon
                        sx={{
                          mr: 1,
                          color:
                            dados.lucratividade.lucroMesAtual >= 0
                              ? "success.main"
                              : "error.main",
                        }}
                      />
                      <Typography variant="subtitle1">Lucro do Mês</Typography>
                    </Box>
                    <ValueTypography
                      variant="h5"
                      component="div"
                      valuetype={
                        dados.lucratividade.lucroMesAtual >= 0
                          ? "positivo"
                          : "negativo"
                      }
                    >
                      {dados.lucratividade.lucroMesAtual.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "CVE",
                        }
                      )}
                    </ValueTypography>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <PercentIcon
                        sx={{
                          fontSize: "1rem",
                          mr: 0.5,
                          color: "text.secondary",
                        }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        Margem: {dados.lucratividade.margemLucro}%
                      </Typography>
                    </Box>
                  </CardContent>
                </MetricCard>
              </Grid>

              {/* Contas a Receber */}
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard cardColor="info">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <ReceiptIcon color="info" sx={{ mr: 1 }} />
                      <Typography variant="subtitle1">A Receber</Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      component="div"
                      color="info.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.contasReceber.total.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>

                    {/* Indicador de inadimplência */}
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mt: 1 }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 0.5 }}
                      >
                        Inadimplência:{" "}
                        {dados.contasReceber.percentualInadimplencia}%
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={dados.contasReceber.percentualInadimplencia}
                        color={
                          dados.contasReceber.percentualInadimplencia > 30
                            ? "error"
                            : dados.contasReceber.percentualInadimplencia > 15
                            ? "warning"
                            : "success"
                        }
                        sx={{ height: 6, borderRadius: 1 }}
                      />
                    </Box>
                  </CardContent>
                </MetricCard>
              </Grid>

              {/* Contas a Pagar */}
              <Grid item xs={12} sm={6} md={3}>
                <MetricCard cardColor="warning">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PaymentsIcon color="warning" sx={{ mr: 1 }} />
                      <Typography variant="subtitle1">A Pagar</Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      component="div"
                      color="warning.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.contasPagar.total.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        {dados.contasPagar.quantidade} despesas pendentes
                      </Typography>
                    </Box>
                  </CardContent>
                </MetricCard>
              </Grid>
            </Grid>

            {/* Resumo Financeiro */}
            <Box
              sx={{ mt: 4, p: 2, bgcolor: "background.paper", borderRadius: 1 }}
            >
              <Typography variant="h6" gutterBottom>
                Análise Rápida
              </Typography>

              <Typography variant="body1" paragraph>
                {dados.lucratividade.lucroMesAtual >= 0
                  ? `A clínica está operando com lucro de ${dados.lucratividade.lucroMesAtual.toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "CVE" }
                    )} no mês atual, representando uma margem de ${
                      dados.lucratividade.margemLucro
                    }% sobre o faturamento.`
                  : `A clínica está operando com prejuízo de ${Math.abs(
                      dados.lucratividade.lucroMesAtual
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "CVE",
                    })} no mês atual.`}
              </Typography>

              <Typography variant="body1" paragraph>
                {dados.faturamento.tendencia === "positiva"
                  ? `O faturamento apresenta tendência de crescimento de ${dados.faturamento.variacao}% em relação ao mês anterior.`
                  : dados.faturamento.tendencia === "negativa"
                  ? `O faturamento apresenta tendência de queda de ${Math.abs(
                      dados.faturamento.variacao
                    )}% em relação ao mês anterior.`
                  : `O faturamento está estável em relação ao mês anterior.`}
              </Typography>

              <Typography variant="body1">
                {dados.contasReceber.total > dados.contasPagar.total
                  ? `As contas a receber (${dados.contasReceber.total.toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "CVE" }
                    )}) são maiores que as contas a pagar (${dados.contasPagar.total.toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "CVE" }
                    )}), indicando potencial liquidez futura.`
                  : `As contas a pagar (${dados.contasPagar.total.toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "CVE" }
                    )}) são maiores que as contas a receber (${dados.contasReceber.total.toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "CVE" }
                    )}), o que pode indicar necessidade de atenção ao fluxo de caixa.`}
              </Typography>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioDashboardFinanceiro;
