//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { fixSpaces } from "../../../../../shared/util/helperFunctions";

import { useLoading } from "../../../../../shared/context/LoadingContext";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";

import PageviewIcon from "@mui/icons-material/Pageview";

import "./ModalMotivoCancelamentoEntradas.scss";
const motivosCancelamento = [
  { cod: 2, description: "Retificação - Art.º 65, n.º 2 CIVA" },
  { cod: 3, description: "Retificação - Art.º 65, n.º 3 CIVA" },
  { cod: 6, description: "Retificação - Art.º 65, n.º 6 CIVA" },
  { cod: 7, description: "Retificação - Art.º 65, n.º 7 CIVA" },
  { cod: 8, description: "Retificação - Art.º 65, n.º 8 CIVA" },
  { cod: 9, description: "Retificação - Art.º 65, n.º 9 CIVA" },
  // { cod: 0, description: "Outro" },
];

function ModalMotivoCancelamentoEntradas({
  open,
  handleCloseModal,
  modalData,
  handleCancelarRecebimentoEntradaClienteFornecedor,
  handleCancelarRecebimentoEntradaReembolso,
  emitirNCE,
  clinica,
  auth,
}) {
  const requiresNifNome =
    modalData.tipoDocumentoAnular === "TVE" &&
    (!modalData.entrada?.cliente?.nif ||
      modalData.entrada?.cliente?.nif === "000000000" ||
      modalData.entrada?.cliente?.nif === "999999999");

  const [motivoCancelamento, setMotivoCancelamento] = useState(
    motivosCancelamento[0]
  );
  const [erro, setErro] = useState("");
  const [outro, setOutro] = useState("");
  const [motivoEvent, setMotivoEvent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();

  const [nome, setNome] = useState(modalData.entrada?.cliente?.nome || "");
  const [nif, setNif] = useState(
    modalData.entrada?.cliente?.nif || "000000000"
  );

  const motivosCancelamentoChangeHandler = (e) => {
    const selectedMotivo = motivosCancelamento.find(
      (m) => m.cod === parseInt(e.target.value)
    );
    setMotivoCancelamento(selectedMotivo);
  };

  const beforeSubmitHandler = () => {
    if (
      (motivoCancelamento.cod === 0 &&
        fixSpaces(outro).length < 10 &&
        modalData.tipoDocumento !== "Event") ||
      (fixSpaces(motivoEvent).length < 10 &&
        modalData.tipoDocumento === "Event")
    ) {
      setErro(
        `Deve indicar pelo menos 10 carateres no campo (Descrição de Motivo de Emissão de ${modalData.tipoDocumento})`
      );
    } else if (requiresNifNome) {
      if (
        !nif ||
        nif?.toString()?.trim() === "000000000" ||
        nif?.toString()?.trim() === "999999999"
      ) {
        setErro(
          "É necessário um NIF válido para a emissão do documento eletrónico!"
        );
      } else if (!nome || nome?.trim() === "") {
        setErro(
          "É necessário um Nome válido para a emissão do documento eletrónico!"
        );
      } else {
        setIsSubmitting(true);
        submitHandler();
      }
    } else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = () => {
    setIsSubmitting(true);

    if (modalData.apenasEmitir) {
      emitirNCE(modalData.entrada._id, motivoCancelamento);
    } else {
      if (modalData.tipoEntrada === "reembolso") {
        handleCancelarRecebimentoEntradaReembolso(
          modalData.entrada,
          true,
          motivoCancelamento.cod === 0
            ? {
                cod: motivoCancelamento.cod,
                description: fixSpaces(outro),
              }
            : motivoCancelamento,
          modalData.tipoDocumento,
          requiresNifNome
            ? { nome: nome?.trim(), nif: nif?.toString()?.trim() }
            : null
        );
      } else {
        handleCancelarRecebimentoEntradaClienteFornecedor(
          modalData.entrada,
          true,
          fixSpaces(motivoEvent)
        );
      }
    }
    /////////////////////////////////
    setIsSubmitting(false);
    handleCloseModal();
  };

  const findClientInfo = async (nif, nomeToCheck) => {
    const formData = new FormData();
    nomeToCheck && formData.append("nome", nomeToCheck);
    nif && formData.append("nif", nif);
    formData.append(
      "clienteSecret",
      clinica.clinica.dadosEmissor.cliente_oauth_secret
    );
    formData.append("clienteId", clinica.clinica.dadosEmissor.cliente_id);
    try {
      startLoading();
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      stopLoading();

      if (response.payload.length === 0) {
        setErro("Nenhum cliente encontrado");
      } else if (response.payload.length === 1) {
        setNome(response.payload[0].Name);
        setNome(response.payload[0].Name);
        if (!nome || nome.trim() === "") {
          setNome(response.payload[0].Name);
        }
        setNif(response.payload[0].TaxId);
      } else {
        // Caso encontrar mais do que um cliente
      }
    } catch (error) {
      stopLoading();
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoCancelamento__modal"
          id="modal__motivoCancelamento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Anular {modalData.tipoDocumentoAnular} - Motivo emissão de{" "}
            {modalData.tipoDocumento}
          </h1>

          <div className="motivoCancelamento__modal__container">
            {modalData.tipoDocumento === "Event" ? (
              <textarea
                className="motivoCancelamento__modal__textarea"
                cols={4}
                rows={4}
                onChange={(e) => setMotivoEvent(e.target.value)}
                value={motivoEvent}
                placeholder="Descrição do motivo de emissão do Evento"
              />
            ) : (
              <>
                <select
                  onChange={motivosCancelamentoChangeHandler}
                  className="motivoCancelamento__modal__select"
                >
                  {motivosCancelamento.map((m) => (
                    <option key={m.cod} value={m.cod}>
                      {m.description}
                    </option>
                  ))}
                </select>

                {motivoCancelamento.cod === 0 && (
                  <>
                    <textarea
                      className="motivoCancelamento__modal__textarea"
                      cols={4}
                      rows={4}
                      onChange={(e) => setOutro(e.target.value)}
                      value={outro}
                      placeholder={`Descrição do motivo de emissão de ${modalData.tipoDocumento}`}
                    />
                    <span className="motivoCancelamento__modal__motivoStringLenght">
                      {outro.length}{" "}
                      {outro.length === 1 ? "caracter" : "caracteres"}
                    </span>
                  </>
                )}

                {requiresNifNome && (
                  <>
                    <div
                      className="takes2-5 form-input"
                      style={{ marginTop: "25px", marginBottom: "15px" }}
                    >
                      <input
                        id="nome"
                        name="nome"
                        type="text"
                        placeholder="Nome Fiscal"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        className="input"
                      />
                      <label className="label" htmlFor="nome">
                        Nome Fiscal
                      </label>
                      <PageviewIcon
                        className="icon-search-field"
                        onClick={findClientInfo.bind(null, null, nome)}
                      />
                    </div>

                    <div className="form-input takes3-5">
                      <input
                        id="nif"
                        name="nif"
                        type="text"
                        placeholder="1333333"
                        value={nif}
                        onChange={(e) => setNif(e.target.value)}
                        className="input"
                      />
                      <label className="label" htmlFor="nif">
                        Nif
                      </label>
                      <PageviewIcon
                        className="icon-search-field"
                        onClick={findClientInfo.bind(null, nif, null)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div className="motivoCancelamento__modal__erro">{erro && erro}</div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoCancelamentoEntradas;
