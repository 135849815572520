import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  LinearProgress,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PieChartIcon from "@mui/icons-material/PieChart";
import CategoryIcon from "@mui/icons-material/Category";

// Recharts para gráficos
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Configurar locale do moment
moment.locale("pt-br");

// Função para gerar iniciais do nome
const getInitials = (name) => {
  if (!name) return "?";
  return name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .substring(0, 2)
    .toUpperCase();
};

// Função para gerar uma cor baseada no nome
const stringToColor = (string) => {
  if (!string) return "#bdbdbd";
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

// Componentes estilizados
const EfficiencyChip = styled(Chip)(({ theme, value }) => ({
  backgroundColor:
    value >= 75
      ? theme.palette.success.light
      : value >= 50
      ? theme.palette.primary.light
      : value >= 25
      ? theme.palette.warning.light
      : theme.palette.error.light,
  color:
    value >= 75
      ? theme.palette.success.contrastText
      : value >= 50
      ? theme.palette.primary.contrastText
      : value >= 25
      ? theme.palette.warning.contrastText
      : theme.palette.error.contrastText,
}));

function RelatorioEficienciaMedicos({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
  filtrosAdicionais,
  atualizarFiltrosAdicionais,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [expandedMedico, setExpandedMedico] = useState(null);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes, filtrosAdicionais]); // Reexecuta quando a ação ou filtros mudam

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar o corpo da requisição com filtros adicionais
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      };

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getRelatorioEficienciaMedicos/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData.data);
    } catch (err) {
      console.error("Erro ao gerar relatório de eficiência de médicos:", err);
      setError(
        err.message || "Ocorreu um erro ao gerar o relatório de eficiência."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar os parâmetros de query com filtros adicionais
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      };

      // Chamada para a API de exportação
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getRelatorioEficienciaMedicos/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `eficiencia-medicos-${dataInicialFormatada}-a-${dataFinalFormatada}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handler para expandir/recolher médicos
  const handleExpandMedico = (medicoId) => {
    setExpandedMedico(expandedMedico === medicoId ? null : medicoId);
  };

  // Handler para atualizar os filtros adicionais
  const handleFiltroChange = (event) => {
    atualizarFiltrosAdicionais({
      [event.target.name]: event.target.checked,
    });
  };

  // Preparar dados para o gráfico de barras
  const prepararDadosGrafico = () => {
    if (!dados || !dados.medicos) return [];

    return dados.medicos
      .filter((medico) => medico.totalSessoes > 0)
      .slice(0, 10) // Limitar aos 10 primeiros para melhor visualização
      .map((medico) => ({
        nome: medico.medico.nome,
        Completados: medico.totalProcedimentosCompletos,
        Eficiência: parseFloat(medico.taxaEficiencia.toFixed(1)),
        Sessões: medico.totalSessoes,
      }));
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Eficiência dos Médicos
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Clínica: {dadosClinica?.nome || ""}
          </Typography>

          {/* Filtros adicionais */}
          {/* <Box sx={{ mt: 2, mb: 2 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtrosAdicionais.incluirDesativados}
                    onChange={handleFiltroChange}
                    name="incluirDesativados"
                  />
                }
                label="Incluir médicos desativados"
              />
            </FormGroup>
          </Box> */}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (!dados.medicos || dados.medicos.length === 0) && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Nenhum dado encontrado para o período selecionado.
          </Alert>
        )}

        {dados && dados.medicos && dados.medicos.length > 0 && (
          <>
            {/* Cards de resumo */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Total de Médicos
                    </Typography>
                    <Typography variant="h2" color="primary.main">
                      {dados.resumo.totalMedicos}
                    </Typography>
                    <Box
                      sx={{ mt: 1, display: "flex", justifyContent: "center" }}
                    >
                      <Chip
                        label={`${dados.resumo.medicosAtivos} Ativos`}
                        color="success"
                        sx={{ m: 0.5 }}
                      />
                      {dados.resumo.medicosInativos > 0 && (
                        <Chip
                          label={`${dados.resumo.medicosInativos} Inativos`}
                          color="error"
                          sx={{ m: 0.5 }}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Tempo Médio por Sessão
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccessTimeIcon
                        color="primary"
                        sx={{ fontSize: 30, mr: 1 }}
                      />
                      <Typography variant="h2" color="primary.main">
                        {Math.round(dados.resumo.tempoMedioPorSessao)}
                      </Typography>
                      <Typography variant="h6" sx={{ ml: 1 }}>
                        min
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Taxa Média de Eficiência
                    </Typography>
                    <Typography variant="h2" color="primary.main">
                      {dados.medicos.length > 0
                        ? (
                            dados.medicos.reduce(
                              (sum, medico) => sum + medico.taxaEficiencia,
                              0
                            ) / dados.medicos.length
                          ).toFixed(1)
                        : 0}
                      %
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={
                        dados.medicos.length > 0
                          ? dados.medicos.reduce(
                              (sum, medico) => sum + medico.taxaEficiencia,
                              0
                            ) / dados.medicos.length
                          : 0
                      }
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        mx: "auto",
                        width: "70%",
                        mt: 1,
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Gráfico Comparativo */}
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Comparativo de Desempenho dos Médicos
                </Typography>
                <Box sx={{ height: 400 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={prepararDadosGrafico()}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 80,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="nome"
                        angle={-45}
                        textAnchor="end"
                        height={80}
                        interval={0}
                      />
                      <YAxis yAxisId="left" orientation="left" />
                      <YAxis yAxisId="right" orientation="right" />
                      <Tooltip />
                      <Legend />
                      <Bar
                        yAxisId="left"
                        dataKey="Sessões"
                        fill="#8884d8"
                        name="Total de Sessões"
                      />
                      <Bar
                        yAxisId="left"
                        dataKey="Completados"
                        fill="#82ca9d"
                        name="Procedimentos Completos"
                      />
                      <Bar
                        yAxisId="right"
                        dataKey="Eficiência"
                        fill="#ff8042"
                        name="Taxa de Eficiência (%)"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>

            {/* Lista de Médicos */}
            <Typography variant="h6" gutterBottom>
              Detalhe por Médico
            </Typography>

            {dados.medicos.map((medico) => (
              <Accordion
                key={medico.medico.id}
                expanded={expandedMedico === medico.medico.id}
                onChange={() => handleExpandMedico(medico.medico.id)}
                sx={{ mb: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${medico.medico.id}-content`}
                  id={`panel-${medico.medico.id}-header`}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Avatar
                        sx={{
                          bgcolor: stringToColor(medico.medico.nome),
                          width: 40,
                          height: 40,
                        }}
                      >
                        {getInitials(medico.medico.nome)}
                      </Avatar>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {medico.medico.nome}
                          {!medico.medico.ativo && (
                            <Chip
                              label="Inativo"
                              size="small"
                              color="error"
                              sx={{ ml: 1 }}
                            />
                          )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {medico.medico.especialidades || "Sem especialidade"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: "center" }}>
                      <Typography variant="body2" color="text.secondary">
                        Sessões
                      </Typography>
                      <Typography variant="h6">
                        {medico.totalSessoes}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: "center" }}>
                      <Typography variant="body2" color="text.secondary">
                        Completos
                      </Typography>
                      <Typography variant="h6">
                        {medico.totalProcedimentosCompletos}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                      <Typography variant="body2" color="text.secondary">
                        Eficiência
                      </Typography>
                      <EfficiencyChip
                        label={`${medico.taxaEficiencia.toFixed(1)}%`}
                        value={medico.taxaEficiencia}
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider sx={{ mb: 2 }} />

                  {/* Desempenho por categoria */}
                  <Typography variant="subtitle1" gutterBottom>
                    Desempenho por Categoria
                  </Typography>

                  {medico.categorias && medico.categorias.length > 0 ? (
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Categoria</TableCell>
                            <TableCell align="right">Sessões</TableCell>
                            <TableCell align="right">Completos</TableCell>
                            <TableCell align="right">Eficiência</TableCell>
                            <TableCell align="right">Progresso</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {medico.categorias.map((categoria) => {
                            const taxaEficiencia =
                              categoria.sessoes > 0
                                ? (categoria.procedimentosCompletos /
                                    categoria.sessoes) *
                                  100
                                : 0;
                            return (
                              <TableRow key={categoria.id}>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CategoryIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "primary.main" }}
                                    />
                                    {categoria.nome}
                                  </Box>
                                </TableCell>
                                <TableCell align="right">
                                  {categoria.sessoes}
                                </TableCell>
                                <TableCell align="right">
                                  {categoria.procedimentosCompletos}
                                </TableCell>
                                <TableCell align="right">
                                  {taxaEficiencia.toFixed(1)}%
                                </TableCell>
                                <TableCell align="right" sx={{ width: "30%" }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={taxaEficiencia}
                                    sx={{ height: 10, borderRadius: 5 }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Alert severity="info">
                      Este médico não possui sessões registradas no período
                      selecionado.
                    </Alert>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioEficienciaMedicos;
