import React, { useState } from "react";
import FiltrosRelatorio from "../ui/filtrosRelatorio/FiltrosRelatorio";
import RelatorioCredenciais from "./components/RelatorioCredenciais";
import RelatorioAprovacoesOrcamentos from "./components/RelatorioAprovacoesOrcamentos";
import "./RelatoriosOrcamentos.scss";

const tiposRelatorios = [
  { value: "", label: "Selecione um relatório" },
  { value: "credenciais", label: "Relatório de Credenciais" },
  { value: "aprovacoes", label: "Relatório de Aprovações de Orçamentos" },
  // Adicione mais tipos de relatórios aqui conforme necessário
];

// Definição de quais relatórios suportam quais ações
const relatorioCaps = {
  credenciais: { gerar: true, exportar: false },
  aprovacoes: { gerar: true, exportar: true },
  // Configure as capacidades para outros relatórios aqui
};

function RelatoriosOrcamentos({ auth, clinica }) {
  const [dadosRelatorio, setDadosRelatorio] = useState({
    open: "",
    dataFiltro: {},
    acao: "",
    //Contador para forçar a atualização
    atualizacoes: 0,
  });

  const gerarRelatorioHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "gerar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  const exportarFicheiroHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "exportar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  // Função auxiliar para renderizar o componente de relatório correto
  const renderizarRelatorio = () => {
    const { open, dataFiltro, acao, atualizacoes } = dadosRelatorio;

    if (!open) return null;

    const props = {
      dataFiltro: dataFiltro,
      dadosClinica: clinica.clinica,
      auth: auth,
      acao: acao, // Passa a ação para o componente
      atualizacoes: atualizacoes,
    };

    switch (open) {
      case "credenciais":
        return <RelatorioCredenciais {...props} />;
      case "aprovacoes":
        return <RelatorioAprovacoesOrcamentos {...props} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <FiltrosRelatorio
        tiposRelatorios={tiposRelatorios}
        gerarRelatorioHandler={gerarRelatorioHandler}
        exportarFicheiroHandler={exportarFicheiroHandler}
        relatorioCaps={relatorioCaps} // Passar as capacidades para potencialmente desabilitar botões
        // Manter o tipo de relatório selecionado
        relatorioSelecionado={dadosRelatorio.open}
      />

      {renderizarRelatorio()}
    </div>
  );
}

export default RelatoriosOrcamentos;
