import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";
import toast from "react-hot-toast";
import XLSX from "xlsx-js-style";

// Material UI imports
import {
  Paper,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Configurar locale do moment para português
moment.locale("pt-br");

function RelatorioCredenciais({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);
  const [selecionados, setSelecionados] = useState([]);
  const [incluirEnviados, setIncluirEnviados] = useState(false);
  const [dialogoEnvioAberto, setDialogoEnvioAberto] = useState(false);
  const [dadosEnvio, setDadosEnvio] = useState({
    numeroFatura: "",
    mesReferencia: moment().format("MMMM YYYY"),
  });

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada, execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes]);

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      const formData = new FormData();
      formData.append("startDate", dataFiltro.dataInicial.toISOString());
      formData.append("endDate", dataFiltro.dataFinal.toISOString());
      formData.append("incluirEnviados", incluirEnviados);

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/getDadosRelatorioCredenciaisInps/${dadosClinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempCredenciaisData = [];
      const tempRows = [];

      responseData.orcamentos.forEach((o, index) => {
        // Calculate the difference in months
        const startDate = moment(o.dataInicio);
        const endDate = moment(o.dataTermino || new Date());
        const monthsDiff = endDate.diff(startDate, "months", true);

        // Define the date style based on the difference
        const dateStyle = {
          alignment: { horizontal: "center" },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };

        let classe = "";
        if (monthsDiff >= 6) {
          dateStyle.font = { color: { rgb: "FF0000" } }; // Red
          classe = "expirou";
        } else if (monthsDiff >= 5) {
          dateStyle.font = { color: { rgb: "FFA500" } }; // Orange
          classe = "ajustar";
        }

        // Status especial para orçamentos já enviados
        if (o.envioInps && o.envioInps.enviado) {
          classe = "enviado";
        }

        // Create the row with the data
        const xlsRow = [
          index + 1,
          o.cliente.nome,
          ensureValue(o.numeroCredencial),
          { v: moment(o.dataInicio).format("DD/MM/YYYY"), s: dateStyle },
          {
            v: o.dataTermino
              ? moment(o.dataTermino).format("DD/MM/YYYY")
              : "Em Andamento",
            s: dateStyle,
          },
          o.totalBeneficiario,
          o.totalProtesesBeneficiario,
          o.totalInps,
          o.totalProtesesInps,
        ];

        const row = {
          id: o._id,
          idCliente: o.cliente.id,
          nome: o.cliente.nome,
          nrCredencial: o.numeroCredencial || "N/A",
          dataInicio: moment(o.dataInicio).format("DD/MM/YYYY"),
          dataTermino: o.dataTermino
            ? moment(o.dataTermino).format("DD/MM/YYYY")
            : "Em Andamento",
          dataTerminoRaw: o.dataTermino ? new Date(o.dataTermino) : new Date(),
          totalBeneficiario: o.totalBeneficiario,
          totalProtese: o.totalProtesesBeneficiario,
          totalInps: o.totalInps,
          totalProteseInps: o.totalProtesesInps,
          classe: classe,
          jaEnviado: o.envioInps && o.envioInps.enviado,
          dataEnvio:
            o.envioInps && o.envioInps.dataEnvio
              ? moment(o.envioInps.dataEnvio).format("DD/MM/YYYY")
              : "",
          numeroFatura: o.envioInps ? o.envioInps.numeroFatura : "",
          terminado: o.terminado === true,
        };

        tempCredenciaisData.push(xlsRow);
        tempRows.push(row);
      });

      // Ordenar por data de término
      tempRows.sort((a, b) => a.dataTerminoRaw - b.dataTerminoRaw);

      // Atualizar o IDs após a ordenação
      tempCredenciaisData.forEach((row, index) => {
        row[0] = index + 1;
      });

      setRows(tempRows);
      setDados({
        dadosTabela: tempCredenciaisData,
        resumo: responseData.resumo,
      });

      // Limpar seleções anteriores
      setSelecionados([]);
    } catch (err) {
      console.error("Erro ao gerar relatório de credenciais:", err);
      setError(
        err.message || "Ocorreu um erro ao gerar o relatório de credenciais."
      );
    } finally {
      stopLoading();
    }
  };

  const ensureValue = (value) =>
    value || {
      v: "",
      s: {
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      },
    };

  const exportarFicheiro = () => {
    if (!dados || !dados.dadosTabela || dados.dadosTabela.length === 0) {
      toast.error("Sem dados para exportar!");
      return;
    }

    // Filtrar apenas as linhas selecionadas, se houver seleções
    if (selecionados.length > 0) {
      const rowsIds = new Set(selecionados);
      const rowsFiltradas = rows.filter((row) => rowsIds.has(row.id));

      // Se não encontrou nenhuma linha, mostrar erro
      if (rowsFiltradas.length === 0) {
        toast.error(
          "Não foi possível encontrar os orçamentos selecionados. Verifique a seleção."
        );
        return;
      }

      // Preparar dados para exportação
      const dadosTabelaFiltrados = rowsFiltradas.map((row, index) => {
        const dateStyle = {
          alignment: { horizontal: "center" },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };

        if (row.classe === "expirou") {
          dateStyle.font = { color: { rgb: "FF0000" } };
        } else if (row.classe === "ajustar") {
          dateStyle.font = { color: { rgb: "FFA500" } };
        }

        return [
          index + 1,
          row.nome,
          ensureValue(row.nrCredencial),
          { v: row.dataInicio, s: dateStyle },
          { v: row.dataTermino, s: dateStyle },
          row.totalBeneficiario,
          row.totalProtese,
          row.totalInps,
          row.totalProteseInps,
        ];
      });

      // Calcular novos totais
      const resumoFiltrado = rowsFiltradas.reduce(
        (acc, row) => ({
          totalOrcamentos: acc.totalOrcamentos + 1,
          totalBeneficiario: acc.totalBeneficiario + row.totalBeneficiario,
          totalProtesesBeneficiario:
            acc.totalProtesesBeneficiario + row.totalProtese,
          totalInps: acc.totalInps + row.totalInps,
          totalProtesesInps: acc.totalProtesesInps + row.totalProteseInps,
        }),
        {
          totalOrcamentos: 0,
          totalBeneficiario: 0,
          totalProtesesBeneficiario: 0,
          totalInps: 0,
          totalProtesesInps: 0,
        }
      );

      // Verificar se há dados para exportar
      if (dadosTabelaFiltrados.length === 0) {
        toast.error("Sem dados para exportar após filtragem!");
        return;
      }

      // Exportar apenas os selecionados
      gerarRelatorioCredenciais({
        dadosTabela: dadosTabelaFiltrados,
        resumo: resumoFiltrado,
      });
    } else {
      // Exportar todos os dados
      gerarRelatorioCredenciais(dados);
    }
  };

  // const gerarRelatorioCredenciais = (dados) => {
  //   console.log("dados", dados);
  //   const wb = XLSX.utils.book_new();
  //   const rowsPerSheet = 15;
  //   const totalRows = dados.dadosTabela.length;
  //   const totalSheets = Math.ceil(totalRows / rowsPerSheet);

  //   for (let i = 0; i < totalSheets; i++) {
  //     // Código existente para geração de planilhas...
  //     // [mantido como no original]
  //   }

  //   // Add a summary sheet with overall totals if needed
  //   if (totalSheets > 0) {
  //     // Código existente para a folha de resumo...
  //     // [mantido como no original]
  //   }

  //   // Nome do arquivo com data
  //   const dataHoje = moment().format("YYYYMMDD");
  //   const nomeArquivo = `relatorio_credenciais_${dataHoje}.xlsx`;

  //   // Salvar o arquivo
  //   XLSX.writeFile(wb, nomeArquivo);

  //   return nomeArquivo;
  // };

  const gerarRelatorioCredenciais = (dados) => {
    if (!dados || !dados.dadosTabela || dados.dadosTabela.length === 0) {
      toast.error("Sem dados para gerar o relatório!");
      return null;
    }

    try {
      const wb = XLSX.utils.book_new();
      const rowsPerSheet = 15;
      const totalRows = dados.dadosTabela.length;
      const totalSheets = Math.ceil(totalRows / rowsPerSheet);

      // Verificamos se temos folhas a criar
      if (totalSheets <= 0) {
        console.error("Nenhuma folha para criar");
        toast.error("Sem dados suficientes para gerar o relatório!");
        return null;
      }

      for (let i = 0; i < totalSheets; i++) {
        const ws = XLSX.utils.aoa_to_sheet([]);

        // Definir range de dados para esta folha
        const startIdx = i * rowsPerSheet;
        const endIdx = Math.min(startIdx + rowsPerSheet, totalRows);
        const dadosParciais = dados.dadosTabela.slice(startIdx, endIdx);

        // Calculate the row number for totals (will be used for merging)
        const subTotalRow = 8 + dadosParciais.length + 1; // +1 for empty row before totals
        const totalRow = subTotalRow + 1;

        // Calculate page-specific subtotals
        const pageSubtotals = dadosParciais.reduce(
          (acc, row) => {
            acc.totalBeneficiario += Number(row[5] || 0);
            acc.totalProtesesBeneficiario += Number(row[6] || 0);
            acc.totalInps += Number(row[7] || 0);
            acc.totalProtesesInps += Number(row[8] || 0);
            return acc;
          },
          {
            totalBeneficiario: 0,
            totalProtesesBeneficiario: 0,
            totalInps: 0,
            totalProtesesInps: 0,
          }
        );

        XLSX.utils.sheet_add_aoa(ws, [
          ["ODONTO UP CLINICA", "", "", "", "", "", "", "NIF -287507009"],
          ["", "", "", "", "", "", "", "N° Prestador -"],
          ["Nº Fatura: ......"],
          ["Mês de Referência: ......"],
          [
            `Relação de Beneficiários atendidos: ${moment(
              dataFiltro.dataInicial
            ).format("MMMM YYYY")} (Parte ${i + 1} de ${totalSheets})`,
          ],
          [],
          [
            "Nº",
            "Nome do Beneficiário",
            "Nº Processo",
            "Tratamento",
            "",
            "Valor Pago p/ Benef",
            "",
            "Valor a Pagar INPS",
            "",
          ],
          [
            "",
            "",
            "",
            "Início",
            "Conclusão",
            "Tratamento",
            "Próteses",
            "Tratamento",
            "Próteses",
          ],
          ...dadosParciais,
          [],
          [
            {
              v: "Sub-total",
              s: {
                font: { bold: true, sz: 14 },
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            "",
            "",
            pageSubtotals.totalBeneficiario.toLocaleString("pt-BR"),
            pageSubtotals.totalProtesesBeneficiario.toLocaleString("pt-BR"),
            pageSubtotals.totalInps.toLocaleString("pt-BR"),
            pageSubtotals.totalProtesesInps.toLocaleString("pt-BR"),
          ],
          [
            {
              v: "Total (desta página)",
              s: {
                font: { bold: true, sz: 14 },
                alignment: { horizontal: "center" },
              },
            },
            "",
            "",
            "",
            "",
            {
              v: (
                Number(pageSubtotals.totalBeneficiario) +
                Number(pageSubtotals.totalProtesesBeneficiario)
              ).toLocaleString("pt-BR"),
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
            "",
            {
              v: (
                Number(pageSubtotals.totalInps) +
                Number(pageSubtotals.totalProtesesInps)
              ).toLocaleString("pt-BR"),
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
            "",
          ],
          [
            {
              v: moment().locale("pt-br").format("D [de] MMMM [de] YYYY"),
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            {
              v: "......",
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
          [
            {
              v: `${dadosClinica.endereco} - / ${dadosClinica.contacto} - / ${
                dadosClinica?.codigoPostal ? dadosClinica?.codigoPostal : "2110"
              } - / ${dadosClinica.email}`,
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ],
        ]);

        // Aplicar mesclagens
        ws["!merges"] = [
          { s: { r: 0, c: 0 }, e: { r: 1, c: 8 } }, // Título principal
          { s: { r: 2, c: 0 }, e: { r: 2, c: 8 } }, // Nº Fatura
          { s: { r: 3, c: 0 }, e: { r: 3, c: 8 } }, // Mês de Referência
          { s: { r: 4, c: 0 }, e: { r: 4, c: 8 } }, // Relação de Beneficiários
          { s: { r: 6, c: 3 }, e: { r: 6, c: 4 } }, // Tratamento
          { s: { r: 6, c: 5 }, e: { r: 6, c: 6 } }, // Valor Pago
          { s: { r: 6, c: 7 }, e: { r: 6, c: 8 } }, // Valor a Pagar
          // Add merges for total rows
          { s: { r: subTotalRow, c: 0 }, e: { r: subTotalRow, c: 4 } }, // "Sub-total" text
          { s: { r: totalRow, c: 0 }, e: { r: totalRow, c: 4 } }, // "Total" text
          { s: { r: totalRow, c: 5 }, e: { r: totalRow, c: 6 } }, // First value in Total row
          { s: { r: totalRow, c: 7 }, e: { r: totalRow, c: 8 } }, // Second value in Total row
          // Last rows
          { s: { r: totalRow + 1, c: 0 }, e: { r: totalRow + 1, c: 8 } }, // Date row
          { s: { r: totalRow + 2, c: 0 }, e: { r: totalRow + 2, c: 8 } }, // Dots row
          { s: { r: totalRow + 3, c: 0 }, e: { r: totalRow + 3, c: 8 } }, // Address row
        ];

        // Estilos principais
        const titleStyle = {
          font: { bold: true, sz: 22 },
          alignment: { horizontal: "center" },
        };

        const tableHeaderStyle = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } },
          alignment: { horizontal: "center", vertical: "center" },
        };

        ws["A1"].s = titleStyle;
        ws["A2"].s = titleStyle;
        ws["A5"].s = { alignment: { horizontal: "center" } };

        // Aplicar estilo aos cabeçalhos
        for (let c = 0; c < 9; c++) {
          ws[XLSX.utils.encode_cell({ r: 6, c })].s = tableHeaderStyle;
          ws[XLSX.utils.encode_cell({ r: 7, c })].s = tableHeaderStyle;
        }

        // Aplicar bordas em todas as células
        const borderStyle = {
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };

        // Calcular número de linhas na planilha
        const numLinhas = dadosParciais.length + 9; // 9 linhas de cabeçalho

        for (let r = 8; r < numLinhas; r++) {
          for (let c = 0; c < 9; c++) {
            const cell = XLSX.utils.encode_cell({ r, c });
            ws[cell] = ws[cell] || {};
            ws[cell].s = { ...(ws[cell].s || {}), ...borderStyle };
          }
        }

        // Definir largura das colunas
        ws["!cols"] = [
          { wch: 5 }, // Nº
          { wch: 40 }, // Nome
          { wch: 12 }, // Nº Processo
          { wch: 10 }, // Início
          { wch: 10 }, // Conclusão
          { wch: 15 }, // Tratamento Pago
          { wch: 15 }, // Próteses Pagas
          { wch: 15 }, // Tratamento INPS
          { wch: 12 }, // Próteses INPS
        ];

        // Configuração de impressão
        ws["!print"] = {
          printArea: XLSX.utils.encode_range({
            s: { r: 0, c: 0 },
            e: { r: numLinhas + 6, c: 8 },
          }),
        };

        ws["!pageSetup"] = {
          orientation: "landscape",
          paperSize: 9, // A4
        };

        // Adicionar a planilha ao workbook
        XLSX.utils.book_append_sheet(wb, ws, `Parte ${i + 1}`);
      }

      // Add a summary sheet with overall totals if needed
      if (totalSheets > 0) {
        const summaryWS = XLSX.utils.aoa_to_sheet([
          ["ODONTO UP CLINICA", "", "", "", "", "", "", "NIF -287507009"],
          ["", "", "", "", "", "", "", "N° Prestador -"],
          ["Nº Fatura: ......"],
          ["Mês de Referência: ......"],
          ["Resumo - Totais Gerais"],
          [],
          [
            "Total Geral Beneficiário (Tratamento)",
            "",
            "",
            "",
            "",
            dados.resumo.totalBeneficiario.toLocaleString("pt-BR"),
          ],
          [
            "Total Geral Beneficiário (Próteses)",
            "",
            "",
            "",
            "",
            dados.resumo.totalProtesesBeneficiario.toLocaleString("pt-BR"),
          ],
          [
            "Total Geral INPS (Tratamento)",
            "",
            "",
            "",
            "",
            dados.resumo.totalInps.toLocaleString("pt-BR"),
          ],
          [
            "Total Geral INPS (Próteses)",
            "",
            "",
            "",
            "",
            dados.resumo.totalProtesesInps.toLocaleString("pt-BR"),
          ],
          [],
          [
            "TOTAL GERAL BENEFICIÁRIO",
            "",
            "",
            "",
            "",
            (
              Number(dados.resumo.totalBeneficiario) +
              Number(dados.resumo.totalProtesesBeneficiario)
            ).toLocaleString("pt-BR"),
          ],
          [
            "TOTAL GERAL INPS",
            "",
            "",
            "",
            "",
            (
              Number(dados.resumo.totalInps) +
              Number(dados.resumo.totalProtesesInps)
            ).toLocaleString("pt-BR"),
          ],
          [
            "VALOR TOTAL",
            "",
            "",
            "",
            "",
            (
              Number(dados.resumo.totalBeneficiario) +
              Number(dados.resumo.totalProtesesBeneficiario) +
              Number(dados.resumo.totalInps) +
              Number(dados.resumo.totalProtesesInps)
            ).toLocaleString("pt-BR"),
          ],
          [],
          [
            {
              v: moment().locale("pt-br").format("D [de] MMMM [de] YYYY"),
              s: { alignment: { horizontal: "center", vertical: "center" } },
            },
            "",
            "",
            "",
            "",
            "",
          ],
        ]);

        // Aplicar estilos
        const headerStyle = {
          font: { bold: true, sz: 14 },
          alignment: { horizontal: "center" },
        };

        const totalStyle = {
          font: { bold: true, sz: 14 },
          fill: { fgColor: { rgb: "E6E6E6" } },
          alignment: { horizontal: "left" },
        };

        const grandTotalStyle = {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "CCCCCC" } },
          alignment: { horizontal: "left" },
        };

        // Apply styles to cells
        summaryWS["A1"].s = headerStyle;
        summaryWS["A5"].s = {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "center" },
        };

        // Apply row styles
        for (let r = 6; r <= 9; r++) {
          summaryWS[XLSX.utils.encode_cell({ r, c: 0 })].s = totalStyle;
        }

        // Apply grand total styles
        for (let r = 11; r <= 13; r++) {
          summaryWS[XLSX.utils.encode_cell({ r, c: 0 })].s = grandTotalStyle;
        }

        // Add merges
        summaryWS["!merges"] = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
          { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
          { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
          { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
          { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
          { s: { r: 6, c: 0 }, e: { r: 6, c: 4 } },
          { s: { r: 7, c: 0 }, e: { r: 7, c: 4 } },
          { s: { r: 8, c: 0 }, e: { r: 8, c: 4 } },
          { s: { r: 9, c: 0 }, e: { r: 9, c: 4 } },
          { s: { r: 11, c: 0 }, e: { r: 11, c: 4 } },
          { s: { r: 12, c: 0 }, e: { r: 12, c: 4 } },
          { s: { r: 13, c: 0 }, e: { r: 13, c: 4 } },
          { s: { r: 15, c: 0 }, e: { r: 15, c: 5 } },
        ];

        // Set column widths
        summaryWS["!cols"] = [
          { wch: 40 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 20 },
        ];

        // Add summary sheet to workbook
        XLSX.utils.book_append_sheet(wb, summaryWS, "Resumo");
      }

      // Nome do arquivo com data
      const dataHoje = moment().format("YYYYMMDD");
      const nomeArquivo = `relatorio_credenciais_${dataHoje}.xlsx`;

      // Verificar se o workbook tem folhas
      if (!wb.SheetNames || wb.SheetNames.length === 0) {
        toast.error("Erro ao gerar relatório: Workbook está vazio");
        return null;
      }

      // Salvar o arquivo
      XLSX.writeFile(wb, nomeArquivo);

      toast.success(`Relatório gerado com sucesso: ${nomeArquivo}`);
      return nomeArquivo;
    } catch (error) {
      toast.error(`Erro ao gerar relatório: ${error.message}`);
      return null;
    }
  };

  const abrirDialogoEnvio = () => {
    if (selecionados.length === 0) {
      toast.error("Selecione pelo menos um orçamento para enviar à INPS!");
      return;
    }

    setDadosEnvio({
      numeroFatura: "",
      mesReferencia: moment().format("MMMM YYYY"),
    });

    setDialogoEnvioAberto(true);
  };

  const fecharDialogoEnvio = () => {
    setDialogoEnvioAberto(false);
  };

  const marcarComoEnviados = async () => {
    if (selecionados.length === 0) {
      toast.error("Nenhum orçamento selecionado!");
      return;
    }

    if (!dadosEnvio.numeroFatura || !dadosEnvio.mesReferencia) {
      toast.error("Preencha todos os campos obrigatórios!");
      return;
    }

    startLoading();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/marcarOrcamentosEnviadosInps/${dadosClinica._id}`,
        "POST",
        JSON.stringify({
          orcamentoIds: selecionados,
          numeroFatura: dadosEnvio.numeroFatura,
          mesReferencia: dadosEnvio.mesReferencia,
          userId: auth.userId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData.success) {
        toast.success(
          `${responseData.resultado.nModified} orçamentos marcados como enviados à INPS!`
        );

        // Exportar automaticamente os selecionados
        exportarFicheiro();

        // Atualizar a lista
        gerarRelatorio();

        // Fechar diálogo
        fecharDialogoEnvio();
      } else {
        toast.error(
          responseData.message || "Erro ao marcar orçamentos como enviados"
        );
      }
    } catch (err) {
      console.error("Erro ao marcar orçamentos como enviados:", err);
      toast.error(
        err.message || "Ocorreu um erro ao marcar orçamentos como enviados"
      );
    } finally {
      stopLoading();
    }
  };

  const columns = [
    {
      field: "jaEnviado",
      headerName: "Enviado",
      width: 80,
      renderCell: (params) => {
        return params.row.jaEnviado ? (
          <span style={{ color: "green", fontWeight: "bold" }}>✓</span>
        ) : null;
      },
    },
    {
      field: "terminado",
      headerName: "Status",
      width: 110,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              backgroundColor: params.row.terminado ? "#4caf50" : "#ff9800",
              color: "white",
              borderRadius: "16px",
              padding: "4px 10px",
              fontSize: "0.75rem",
              fontWeight: "bold",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {params.row.terminado ? "Terminado" : "Em Andamento"}
          </Box>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 7,
      renderCell: (params) => {
        return (
          <span
            className={`nomeDataTable ${params.row.jaEnviado ? "enviado" : ""}`}
          >
            <Link to={`/clientes/${params.row.idCliente}-orcamento`}>
              {params.row.nome}
            </Link>
          </span>
        );
      },
    },
    {
      field: "nrCredencial",
      headerName: "Nr Credencial",
      flex: 3,
    },
    {
      field: "dataInicio",
      headerName: "Dt Inicio",
      flex: 3,
      renderCell: (params) => {
        return (
          <span className={params.row.classe ? params.row.classe : ""}>
            {params.row.dataInicio}
          </span>
        );
      },
    },
    {
      field: "dataTermino",
      headerName: "Dt Término",
      flex: 3,
      renderCell: (params) => {
        return (
          <span className={params.row.classe ? params.row.classe : ""}>
            {params.row.dataTermino}
          </span>
        );
      },
    },
    {
      field: "totalBeneficiario",
      headerName: "Total Benficiário",
      flex: 3,
      renderCell: (params) => {
        return (
          <span>{params.row.totalBeneficiario.toLocaleString("pt-BR")}</span>
        );
      },
    },
    {
      field: "totalInps",
      headerName: "Total Inps",
      flex: 3,
      renderCell: (params) => {
        return <span>{params.row.totalInps.toLocaleString("pt-BR")}</span>;
      },
    },
    {
      field: "totalProtese",
      headerName: "Total Prótese",
      flex: 3,
      renderCell: (params) => {
        return <span>{params.row.totalProtese.toLocaleString("pt-BR")}</span>;
      },
    },
    {
      field: "totalProteseInps",
      headerName: "Total Prótese Inps",
      flex: 3,
      renderCell: (params) => {
        return (
          <span>{params.row.totalProteseInps.toLocaleString("pt-BR")}</span>
        );
      },
    },
    {
      field: "dataEnvio",
      headerName: "Data Envio",
      flex: 3,
      renderCell: (params) => {
        return params.row.jaEnviado ? (
          <span>{params.row.dataEnvio}</span>
        ) : null;
      },
    },
  ];

  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Credenciais para INPS
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Clínica: {dadosClinica?.nome || ""}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={incluirEnviados}
                onChange={(e) => setIncluirEnviados(e.target.checked)}
              />
            }
            label="Incluir orçamentos cujas credênciais já forma enviadas à INPS"
          />

          <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={gerarRelatorio}
            >
              Aplicar Filtros
            </Button>

            {dados && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FileDownloadIcon />}
                  onClick={exportarFicheiro}
                >
                  Exportar{" "}
                  {selecionados.length > 0
                    ? `(${selecionados.length} selecionados)`
                    : "Todos"}
                </Button>

                <Button
                  variant="contained"
                  color="success"
                  startIcon={<SendIcon />}
                  onClick={abrirDialogoEnvio}
                  disabled={selecionados.length === 0}
                >
                  Exportar e marcar Como Enviado à INPS
                </Button>
              </>
            )}
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            <Box
              sx={{ mb: 3, p: 2, bgcolor: "background.paper", borderRadius: 1 }}
            >
              <Typography variant="h6" gutterBottom>
                Resumo
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Total de orçamentos
                  </Typography>
                  <Typography variant="h6">
                    {dados.resumo.totalOrcamentos}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Total beneficiário (Tratamentos)
                  </Typography>
                  <Typography variant="h6">
                    {dados.resumo.totalBeneficiario.toLocaleString("pt-BR")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Total INPS (Tratamentos)
                  </Typography>
                  <Typography variant="h6">
                    {dados.resumo.totalInps.toLocaleString("pt-BR")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Total beneficiário (Prótese)
                  </Typography>
                  <Typography variant="h6">
                    {dados.resumo.totalProtesesBeneficiario.toLocaleString(
                      "pt-BR"
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Total INPS (Prótese)
                  </Typography>
                  <Typography variant="h6">
                    {dados.resumo.totalProtesesInps.toLocaleString("pt-BR")}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  // Filtrar apenas orçamentos não enviados anteriormente
                  const selecaoFiltrada = newSelection.filter((id) => {
                    const row = rows.find((r) => r.id === id);
                    return row && !row.jaEnviado;
                  });
                  setSelecionados(selecaoFiltrada);
                }}
                selectionModel={selecionados}
                isRowSelectable={(params) => !params.row.jaEnviado}
                className="datagrid"
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Legenda:
              </Typography>
              <Box sx={{ display: "flex", gap: 3, mt: 1, flexWrap: "wrap" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: "#FF0000",
                      mr: 1,
                    }}
                  ></Box>
                  <Typography variant="body2">Expirado (≥ 6 meses)</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: "#FFA500",
                      mr: 1,
                    }}
                  ></Box>
                  <Typography variant="body2">
                    Próximo de expirar (≥ 5 meses)
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      backgroundColor: "#EEEEEE",
                      mr: 1,
                    }}
                  ></Box>
                  <Typography variant="body2">Já enviado para INPS</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: "16px",
                      backgroundColor: "#4caf50",
                      color: "white",
                      padding: "2px 8px",
                      fontSize: "0.7rem",
                      mr: 1,
                    }}
                  >
                    Terminado
                  </Box>
                  <Typography variant="body2">Orçamento concluído</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: "16px",
                      backgroundColor: "#ff9800",
                      color: "white",
                      padding: "2px 8px",
                      fontSize: "0.7rem",
                      mr: 1,
                    }}
                  >
                    Em Andamento
                  </Box>
                  <Typography variant="body2">Orçamento em execução</Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Paper>

      {/* Diálogo para confirmação de envio à INPS */}
      <Dialog
        open={dialogoEnvioAberto}
        onClose={fecharDialogoEnvio}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Confirmar Envio à INPS</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Você selecionou {selecionados.length} orçamento(s) para marcar como
            enviado(s) à INPS.
          </Typography>

          <Box sx={{ mt: 2 }}>
            <TextField
              label="Número da Fatura"
              fullWidth
              margin="normal"
              value={dadosEnvio.numeroFatura}
              onChange={(e) =>
                setDadosEnvio({ ...dadosEnvio, numeroFatura: e.target.value })
              }
              required
            />

            <TextField
              label="Mês de Referência"
              fullWidth
              margin="normal"
              value={dadosEnvio.mesReferencia}
              onChange={(e) =>
                setDadosEnvio({ ...dadosEnvio, mesReferencia: e.target.value })
              }
              required
            />
          </Box>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body2" color="text.secondary">
            Após a confirmação, estes orçamentos serão marcados como já enviados
            à INPS e não aparecerão em futuras buscas (a menos que a opção
            "Incluir orçamentos cujas credênciais já forma enviadas à INPS"
            esteja marcada).
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={fecharDialogoEnvio} color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={marcarComoEnviados}
            color="primary"
            variant="contained"
          >
            Confirmar e Exportar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RelatorioCredenciais;
