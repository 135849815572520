import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AuthContext } from "../../../../../shared/context/auth-context";
//MUI
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
/////////////////////////////////////////////////////
import ModalReceber from "./components/ModalReceber";
import ModalProcedimentos from "./components/ModalProcedimentos/ModalProcedimentos";
import ModalVerReceita from "./components/ModalVerPagamento/ModalVerReceita";
import ModalMotivoCancelamento from "./components/ModalMotivoCancelamento/ModalMotivoCancelamento";
import "./Debitos.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";
import { ClinicaContext } from "../../../../../shared/context/clinica-context";
import ModalEditarDesconto from "./components/ModalEditarDesconto/ModalEditarDesconto";
import ModalVerEntrada from "../../../../financeiro/receitas/components/modalEntrada/ModalVerEntrada";
//Mui button dropdown
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalMotivoCancelamentoPendencias from "./components/ModalMotivoCancelamentoPendencias/ModalMotivoCancelamentoPendencias";
import ModalEditarEntradaDebito from "./components/modalEntradaDebito/ModalEditarEntradaDebito";
import ModalEntradaDebito from "./components/modalEntradaDebito/ModalEntradaDebito";

import CancelIcon from "@mui/icons-material/Cancel";
import HistoryIcon from "@mui/icons-material/History";
import DiscountIcon from "@mui/icons-material/Discount";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import ModalInfo from "../../../../../shared/components/UIElements/ModalInfo";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Chip,
  Alert,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalReceberEntradaDebito from "./components/modalEntradaDebito/ModalReceberEntradaDebito";
import ModalEditarMajoracao from "./components/ModalEditarMajoracao/ModalEditarMajoracao";

function Debitos({
  clienteId,
  sendRequest,
  nomeCliente,
  dadosFatura,
  clienteData,
  dadosRecibo,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [totalRecebido, setTotalRecebido] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);
  const [rows, setRows] = useState([]);
  const [contaCorrente, setContaCorrente] = useState();
  const [entradas, setEntradas] = useState();
  const [valor, setValor] = useState(0);
  const [idParcela, setIdParcela] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [mostrarRecebidos, setMostrarRecebidos] = useState(true);
  const [dadosClinica, setDadosClinica] = useState();
  const [dadosModalMotivoCancelamento, setDadosModalMotivoCancelamento] =
    useState({
      open: false,
    });

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const handleConfirmRestaurarPendencia = async (conta) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Restaurar Pendências!`,
      message: `Tem a certeza que pretende restaurar as pendências da conta?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleRestaurarPendencias.bind(null, conta),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [contaCorrenteResponse, entradasResponse, clinicaResponse] =
          await Promise.all([
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/orcamento/cliente/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            auth.perm.includes("r-ent")
              ? sendRequest(
                  `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/cliente/${clienteId}`,
                  "GET",
                  null,
                  { Authorization: "Bearer " + auth.token }
                )
              : Promise.resolve({ entradas: [] }),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
          ]);

        setContaCorrente(contaCorrenteResponse.contaCorrente);
        setEntradas(entradasResponse.entradas);
        setDadosClinica(clinicaResponse.clinica);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [clienteId, sendRequest, clinica, auth.token]);

  useEffect(() => {
    let recebido = 0;
    let porReceber = 0;
    let dataParaDataGrid = [];

    if (contaCorrente && contaCorrente.contas) {
      const contasNaoLiquidadas = contaCorrente.contas;

      contasNaoLiquidadas.forEach((conta) => {
        if (conta.aprovado && conta.orcamento.ativo) {
          conta.parcelas.forEach((parcela) => {
            if (!mostrarRecebidos && parcela.parcelaPaga) return;
            recebido += parcela.quantiaPaga;
            if (parcela.status !== "CANCELADA") {
              porReceber += parcela.quantiaPorPagar;
            }
            dataParaDataGrid.push({
              tipo: "receita",
              orcamento: conta.orcamento,
              id: parcela.id,
              contaId: conta._id,
              dataLimitePagamento: parcela.dataLimitePagamento,
              dataPagamento: parcela.dataPagamento,
              quantiaPorPagar: parcela.quantiaPorPagar,
              quantiaPaga: parcela.quantiaPaga,
              metodoPagamento: parcela.metodoPagamento,
              nrParcela: parcela.nrParcela,
              parcelaPaga: parcela.parcelaPaga,
              documentoEletronico: parcela.documentoEletronico,
              estado: parcela.parcelaPaga
                ? "Pago"
                : moment()
                    .startOf("day")
                    .isAfter(moment(parcela.dataLimitePagamento).startOf("day"))
                ? "Em atraso"
                : "",
              nrRecibo: parcela.nrRecibo,
              cancelamentosDocumentosEletronicos:
                parcela.cancelamentosDocumentosEletronicos,
              status: parcela.status,
              motivoEncerramento: conta.motivoEncerramento,
              recebidoPor: parcela.recebidoPor?.user?.name || "",
              dataRecebimento: parcela.recebidoPor?.data
                ? moment(parcela.recebidoPor?.data).format("DD/MM/YYYY HH:mm")
                : "",
              emitidoPor: parcela.documentoEletronico?.usuarioEmissor?.name,
              dataEmissao: parcela.documentoEletronico?.created_at
                ? moment(parcela.documentoEletronico?.created_at).format(
                    "DD/MM/YYYY HH:mm"
                  )
                : "",
            });
          });
        }
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        if (!mostrarRecebidos && entrada.entradaRecebida) return;
        if (entrada.entradaRecebida && entrada.valor) {
          recebido += entrada.valor;
        } else {
          porReceber += entrada.valor;
        }
        dataParaDataGrid.push({
          tipo: "entrada",
          tipoEntrada: entrada.tipo,
          id: entrada.id,
          cliente: entrada.clienteFornecedor
            ? entrada.clienteFornecedor
            : entrada.cliente,
          dataPagamento: entrada.dataPagamento,
          dataLimitePagamento: entrada.dataLimitePagamento,
          valor: entrada.valor,
          metodoPagamento: entrada.metodoPagamento,
          descricao: entrada.descricao,
          comprovativo: entrada.comprovativo,
          nrRecibo: entrada.nrRecibo,
          documentoEletronico: entrada.documentoEletronico,
          entradaRecebida: entrada.entradaRecebida,
          cancelamentosDocumentosEletronicos:
            entrada.cancelamentosDocumentosEletronicos,
          recebidoPor: entrada.recebidoPor?.user?.name || "",
          dataRecebimento: entrada.recebidoPor?.data
            ? moment(entrada.recebidoPor?.data).format("DD/MM/YYYY HH:mm")
            : "",
          emitidoPor: entrada.documentoEletronico?.usuarioEmissor?.name,
          dataEmissao: entrada.documentoEletronico?.created_at
            ? moment(entrada.documentoEletronico?.created_at).format(
                "DD/MM/YYYY HH:mm"
              )
            : "",
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) =>
        new Date(a.dataLimitePagamento) - new Date(b.dataLimitePagamento)
    );
    setTotalRecebido(recebido.toLocaleString("pt-BR"));
    setTotalReceber(porReceber.toLocaleString("pt-BR"));
    setRows(dataGridData(dataParaDataGrid, nomeCliente));
  }, [contaCorrente, nomeCliente, mostrarRecebidos, entradas]);

  //Mui Button editarDesconto
  const [menuState, setMenuState] = React.useState({
    anchorEl: null,
    selectedConta: null,
    isClosing: false,
  });

  const handleClickMenu = (event, conta) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuState({
      anchorEl: event.currentTarget,
      selectedConta: conta,
      isClosing: false,
    });
  };

  const handleCloseMenu = (event) => {
    if (event) {
      // Add this check since onClose can be called without an event
      event.preventDefault();
      event.stopPropagation();
    }
    setMenuState((prev) => ({
      ...prev,
      anchorEl: null,
      isClosing: true,
    }));
    // Clear the selected conta after the menu closing animation
    setTimeout(() => {
      setMenuState({
        anchorEl: null,
        selectedConta: null,
        isClosing: false,
      });
    }, 200); // MUI menu transition duration
  };

  const handleOpenModalReceber = (idParcela, valorParcela) => {
    setOpenModal("receber");
    setValor(valorParcela);
    setIdParcela(idParcela);
  };

  const updateContaCorrente = (
    novaContaCorrente,
    dadosEmicaoDocumento,
    tipoDocumento
  ) => {
    if (!dadosEmicaoDocumento) {
      stopLoading();
    }

    setContaCorrente({ ...novaContaCorrente });

    if (dadosEmicaoDocumento) {
      const contaFiltrada = novaContaCorrente.contas.filter(
        (c) => c._id === dadosEmicaoDocumento.Idconta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (p) => p._id === dadosEmicaoDocumento.IdParcela
      )[0];

      if (tipoDocumento === "FRE") {
        emitirDocumentoFiscal("FRE", parcelaFiltrada);
      }
    }
  };

  const handleOpenModalProcedimentos = (orcamento) => {
    setModalData(orcamento);
    setOpenModal("procedimentos");
  };

  const handleCloseModal = () => {
    setOpenModal("");
    setDadosModalMotivoCancelamento({ open: false });
    setModalData(null);
  };

  //BEFORE RECEBIMENTO PARCELA
  const beforeCancelarRecebimento = (id, idConta) => {
    const copyContaCorrente = { ...contaCorrente };

    const filteredConta = copyContaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];

    const oldParcela = filteredConta.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    if (oldParcela.documentoEletronico?.succeeded) {
      //Codigo para obter o motivo
      setDadosModalMotivoCancelamento({
        open: true,
        id,
        tipoDocumento: "NCE",
        tipoDocumentoAnular: oldParcela.documentoEletronico.tipoDocumento,
        nif: clienteData.nif,
        nome: clienteData.nomeFiscal,
        tipoCancelamento: "parcela",
      });
    } else {
      handleCancelarRecebimento(id, idConta);
    }
  };

  const marcarCanDeleteFalse = async (contaFiltrada) => {
    const nrParcelasNaoPagas = contaFiltrada.parcelas.filter(
      (p) => p.quantiaPaga === 0
    ).length;

    //Atualizar o plano de tratamento para que este possa ser apagado
    //Caso essa for a ultima parcela recebida.
    //visto que irei passar a conta antes do cancelamento,
    //Em vez de verificar se nrParcelasNaoPagas === contaFiltrada.parcelas.length
    //verifico se nrParcelasNaoPagas === contaFiltrada.parcelas.length - 1
    if (nrParcelasNaoPagas === contaFiltrada.parcelas.length - 1) {
      let planoTratamento;
      try {
        planoTratamento = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${contaFiltrada.orcamento._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Caso tivermos varios planos de tratamento com o mesmo orcamento, porque isso pode acontecer
      //Quando apagamos um plano de tratamento, filtramos apenas o ativo
      const planoTratamentoFiltrado = planoTratamento.planosTratamento.filter(
        (plano) => plano.ativo === true
      )[0];

      const formData = new FormData();
      formData.append("canDelete", true);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamentoFiltrado._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }
    }
  };

  //Parcelas Cancelar recebimento
  const handleCancelarRecebimento = async (
    id,
    motivoCancelamento,
    tipoDocumento,
    nomeENif
  ) => {
    startLoading();
    try {
      // Encontrar a parcela e conta
      const { parcelaFiltrada, contaFiltrada } = encontrarParcelaEConta(id);

      // Se não houver documento eletrônico ou se ele tiver erro, apenas delete
      if (
        !parcelaFiltrada.documentoEletronico ||
        parcelaFiltrada.documentoEletronico?.succeeded === false
      ) {
        const formData = new FormData();
        formData.append("contaCorrenteId", contaCorrente._id);
        formData.append("contaId", contaFiltrada._id);
        formData.append("parcelaId", parcelaFiltrada._id);
        formData.append("canceladoPor", auth.userId);
        formData.append("valor", parcelaFiltrada.quantiaPaga);
        formData.append("nomeCliente", contaCorrente.cliente.nome);

        // Se houver doc com erro, enviar o ID para ser deletado
        if (parcelaFiltrada.documentoEletronico?.succeeded === false) {
          formData.append(
            "docEletronicoId",
            parcelaFiltrada.documentoEletronico._id
          );
        }

        const resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cancelarrecebimentosemdocumento`,
          "PATCH",
          formData,
          { Authorization: "Bearer " + auth.token }
        );

        await marcarCanDeleteFalse(contaFiltrada);

        setContaCorrente(resposta.contaCorrente);
        toast.success("Recebimento cancelado com sucesso!");
        return;
      }

      // Validar dados do cliente se necessário
      const validacao = validarDadosCliente(
        contaCorrente.cliente,
        tipoDocumento
      );
      if (!validacao.valido) {
        toast.error(validacao.erro);
        return;
      }

      // Preparar dados para cancelamento
      const dadosCancelamento = {
        clinicaId: clinica.clinica._id,
        tipoDocumento,
        contaCorrenteId: contaCorrente._id,
        contaId: contaFiltrada._id,
        parcelaId: parcelaFiltrada._id,
        valor: parcelaFiltrada.quantiaPaga,
        IUDCancelar: parcelaFiltrada.documentoEletronico.entryName.replace(
          /\.xml$/,
          ""
        ),
        clinica: {
          dadosEmissor: clinica.clinica.dadosEmissor,
          nif: clinica.clinica.nif,
          nomeFiscal: clinica.clinica.nomeFiscal,
          endereco: clinica.clinica.endereco,
          contacto: clinica.clinica.contacto,
          email: clinica.clinica.email,
        },
        usuarioEmissor: auth.userId,
        nomeCliente: nomeENif
          ? nomeENif.nome
          : contaCorrente.cliente.nomeFiscal,
        nifCliente: nomeENif ? nomeENif.nif : contaCorrente.cliente.nif,
        descricaoPagamento:
          parcelaFiltrada.descricao?.trim() || "Tratamento dentário",
        contactoCliente: contaCorrente.cliente.contacto,
        moradaCliente: contaCorrente.cliente.endereco,
        codigoPaisCliente: contaCorrente.cliente.pais.codigo,
        metodoPagamento: parcelaFiltrada.metodoPagamento,
        IssueReasonCode: motivoCancelamento,
        online: true,
      };

      const formData = new FormData();
      formData.append("dadosCancelamento", JSON.stringify(dadosCancelamento));

      // Enviar requisição de cancelamento
      const resposta = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cancelarrecebimento`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualizar estados locais
      setContaCorrente(resposta.contaCorrente);
      // Mostrar feedback apropriado
      if (resposta.documento?.responseData?.responses[0]?.succeeded) {
        await marcarCanDeleteFalse(contaFiltrada);
        toast.success(`Recebimento cancelado com emissão de ${tipoDocumento}!`);
      } else {
        toast.error(
          `Erro ao emitir ${tipoDocumento}: ${resposta.documento?.responseData?.responses[0]?.messages[0]?.description}`
        );
      }
    } catch (err) {
      console.error("Erro ao cancelar recebimento:", err);
      toast.error("Erro ao cancelar recebimento");
    } finally {
      stopLoading();
    }
  };

  const verReceitaHandler = (id, idConta) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    const dadosReceita = {
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      valorPago: parcelaFiltrada.quantiaPaga,
      dataRecebimento: parcelaFiltrada.dataPagamento,
      observacao: parcelaFiltrada.observacao,
      caixa: parcelaFiltrada.caixa,
      recebidoPor: parcelaFiltrada.recebidoPor?.user?.name,
    };

    if (parcelaFiltrada.cartao) dadosReceita.cartao = parcelaFiltrada.cartao;
    if (parcelaFiltrada.documentoEletronico)
      dadosReceita.documentoEletronico = parcelaFiltrada.documentoEletronico;
    if (parcelaFiltrada.metodoPagamento === "Cheque") {
      dadosReceita.nrCheque = parcelaFiltrada.nrCheque;
      dadosReceita.dataCheque = parcelaFiltrada.dataCheque;
      dadosReceita.contaCliente = parcelaFiltrada.nrContaCliente;
      dadosReceita.banco = parcelaFiltrada.banco;
    }

    setModalData(dadosReceita);
    setOpenModal("verReceita");
  };

  const gerarPdfFaturaEletronicaHandler = async (id, idConta) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    dadosFatura({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga,
      nrDocumento: parcelaFiltrada.documentoEletronico.nrDocumento,
      dataDocumento: parcelaFiltrada.documentoEletronico.authorizedDateTime,
      medico_responsavel: contaFiltrada.orcamento.medico_responsavel,
      designacao:
        parcelaFiltrada.descricao && parcelaFiltrada.descricao.trim() !== ""
          ? parcelaFiltrada.descricao
          : "Tratamento Dentário",
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      entryName: parcelaFiltrada.documentoEletronico.entryName,
      serie: parcelaFiltrada.documentoEletronico.serie,
      tipoDocumento: parcelaFiltrada.documentoEletronico.tipoDocumento,
      usuarioEmissor: parcelaFiltrada.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const gerarPdfFaturaEletronicaHandlerEntrada = async (entradaId) => {
    const entradaFiltrada = entradas.find((e) => e.id === entradaId);

    dadosFatura({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: entradaFiltrada.valor,
      nrDocumento: entradaFiltrada.documentoEletronico.nrDocumento,
      dataDocumento: entradaFiltrada.documentoEletronico.authorizedDateTime,
      designacao: entradaFiltrada.descricao,
      metodoPagamento: entradaFiltrada.metodoPagamento,
      entryName: entradaFiltrada.documentoEletronico.entryName,
      serie: entradaFiltrada.documentoEletronico.serie,
      tipoDocumento: entradaFiltrada.documentoEletronico.tipoDocumento,
      usuarioEmissor: entradaFiltrada.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const beforeGerarReciboHandler = async (id, idConta) => {
    startLoading();
    try {
      const contaFiltrada = contaCorrente.contas.filter(
        (conta) => conta._id === idConta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (parcela) => parcela._id === id
      )[0];

      let nrRecibo = 1;
      if (parcelaFiltrada.nrRecibo) {
        nrRecibo = parcelaFiltrada.nrRecibo;
        gerarPdfReciboHandler(parcelaFiltrada, nrRecibo);
      } else {
        let idDocumentoAEliminar;
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (parcelaFiltrada.documentoEletronico?.succeeded === false) {
          idDocumentoAEliminar = parcelaFiltrada.documentoEletronico._id;
        }
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o numero de recibo
        try {
          const formData = new FormData();
          formData.append("valor", nrRecibo);
          formData.append("tipoDocumento", "recibo");
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          if (idDocumentoAEliminar) {
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
                "DELETE",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }
        } catch (err) {
          console.error("err", err);
        }

        try {
          const formData = new FormData();
          formData.append("parcelaId", id);
          formData.append("contaId", idConta);
          formData.append("nrRecibo", nrRecibo);
          formData.append("utilizador", auth.userId);
          formData.append("nomeCliente", contaCorrente.cliente.nome);

          //Na backend, documento eletronico é removido da parcela
          const contaCorrenteRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarrecibo/${contaCorrente.id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          updateContaCorrente(contaCorrenteRetornada.contaCorrente);
        } catch (err) {
          console.error(
            "Erro na atualização da conta corrente. Por favor tente novamente.",
            err
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarPdfReciboHandler = async (parcelaFiltrada, nrRecibo) => {
    dadosRecibo({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga || parcelaFiltrada.valor,
      nrRecibo: nrRecibo,
      descricao: parcelaFiltrada.descricao,
    });
  };

  const handleBeforeEmitirDocEletronico = (id, idConta, tipoDocumento) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];

    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    emitirDocumentoFiscal(tipoDocumento, parcelaFiltrada);
  };

  // Função para encontrar a parcela e conta correta
  const encontrarParcelaEConta = (parcela) => {
    let parcelaFiltrada = parcela;
    let contaFiltrada;

    // Pode ser enviado o id da parcela ou a própria parcela
    if (parcela._id) {
      contaCorrente.contas.forEach((conta) => {
        conta.parcelas.forEach((p) => {
          if (p._id === parcela._id) contaFiltrada = conta;
        });
      });
    } else {
      contaCorrente.contas.forEach((conta) => {
        conta.parcelas.forEach((p) => {
          if (p._id === parcela) {
            contaFiltrada = conta;
            parcelaFiltrada = p;
          }
        });
      });
    }

    return { parcelaFiltrada, contaFiltrada };
  };

  // Função principal refatorada
  const emitirDocumentoFiscal = async (tipoDocumento, parcela) => {
    startLoading();
    try {
      setOpenModal("");

      // Validamos os dados do cliente antes de fazer qualquer chamada ao backend
      const validacao = validarDadosCliente(
        contaCorrente.cliente,
        tipoDocumento
      );
      if (!validacao.valido) {
        toast.error(validacao.erro);
        return;
      }

      // Encontramos a parcela e conta corretos no frontend pois precisamos destes dados para a interface
      const { parcelaFiltrada, contaFiltrada } =
        encontrarParcelaEConta(parcela);

      // Preparamos todos os dados necessários em um único objeto
      const dadosEmissao = {
        // Dados do documento

        tipoDocEletronico: tipoDocumento,
        valor: parcelaFiltrada.quantiaPaga,
        descricaoPagamento:
          parcelaFiltrada.descricao?.trim() || "Tratamento dentário",
        metodoPagamento: parcelaFiltrada.metodoPagamento,
        usuarioEmissor: auth.userId,
        online: true,

        // IDs para atualização da conta corrente
        contaCorrenteId: contaCorrente._id,
        contaId: contaFiltrada._id,
        parcelaId: parcelaFiltrada._id,

        // Dados do cliente (exceto para TVE)
        ...(tipoDocumento !== "TVE" && {
          nomeCliente: contaCorrente.cliente.nomeFiscal,
          nifCliente: contaCorrente.cliente.nif,
          contactoCliente: contaCorrente.cliente.contacto,
          codigoPaisCliente: contaCorrente.cliente.pais.codigo,
          moradaCliente: contaCorrente.cliente.endereco,
        }),
        //Dados da clinica
        clinicaId: clinica.clinica._id,
        clinica: {
          dadosEmissor: clinica.clinica.dadosEmissor,
          nif: clinica.clinica.nif,
          nomeFiscal: clinica.clinica.nomeFiscal,
          endereco: clinica.clinica.endereco,
          contacto: clinica.clinica.contacto,
          email: clinica.clinica.email,
        },
        // Se existe documento com erro, enviamos seu ID para ser deletado
        documentoADeletar:
          (tipoDocumento === "FRE" || tipoDocumento === "TVE") &&
          parcelaFiltrada.documentoEletronico?.succeeded === false
            ? parcelaFiltrada.documentoEletronico._id
            : null,
      };

      // Uma única chamada ao backend que fará todo o processo
      const formData = new FormData();
      formData.append("dadosEmissao", JSON.stringify(dadosEmissao));
      const resposta = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/emitirdoceletronicoeatualizar`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Se não houver resposta, interrompemos o processo
      if (!resposta) {
        toast.error(
          "Não houve resposta da Efatura. Por favor tente emitir o documento eletrónico novamente"
        );
        return;
      }

      // Atualizamos o estado local com a conta corrente atualizada que veio do backend
      setContaCorrente(resposta.contaCorrente);

      // Feedback ao usuário baseado no sucesso da operação
      if (resposta.documento?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(`${tipoDocumento} emitido com sucesso`);
      } else {
        toast.error(
          `Erro ao emitir ${tipoDocumento}. ${resposta.documento?.respostaEfatura?.responses[0]?.messages[0]?.description}`
        );
      }
    } catch (err) {
      console.error("Erro ao emitir documento:", err);
      toast.error("Ocorreu um erro ao emitir o documento");
    } finally {
      stopLoading();
    }
  };

  const handleOpenModalEditarDesconto = (conta) => {
    setModalData(conta);
    setOpenModal("editarDesconto");
  };

  const handleOpenModalEditarMajoracao = (conta) => {
    setModalData(conta);
    setOpenModal("editarMajoracao");
  };

  const handleOpenModalCancelamentoPendencias = (conta) => {
    setModalData(conta);
    setOpenModal("cancelarPendencias");
  };

  const cancelarIsencaoHandler = async (contaFiltrada) => {
    const valorBase = contaFiltrada.orcamento.comMajoracao
      ? contaFiltrada.orcamento.subtotal - contaFiltrada.orcamento.majoracao
      : contaFiltrada.orcamento.subtotal;

    try {
      handleModalCancel();
      startLoading();
      const novaConta = {
        ...contaFiltrada,
        contaLiquidada: false,
        nrParcelas: 1,
        orcamento: contaFiltrada.orcamento._id,
        total: valorBase,
        parcelas: [
          {
            cancelamentosDocumentosEletronicos:
              contaFiltrada.parcelas[0].cancelamentosDocumentosEletronicos,
            dataLimitePagamento: contaFiltrada.parcelas[0].dataLimitePagamento,
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: valorBase,
            nrParcela: "1/1",
            observacao: "",
          },
        ],
      };

      const formData = new FormData();
      formData.append("desconto", 0);
      formData.append("utilizador", auth.userId);
      formData.append("tipoDesconto", "$");
      formData.append("total", valorBase);
      formData.append("subtotal", contaFiltrada.orcamento.subtotal);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/dadosdesconto/${contaFiltrada.orcamento._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const contas = contaCorrente.contas.filter(
        (c) => c._id !== contaFiltrada._id
      );
      contas.push(novaConta);
      const formData2 = new FormData();
      formData2.append("contas", JSON.stringify(contas));

      formData2.append("operacao", "CANCELAMENTO_ISENCAO");
      formData2.append(
        "alteracoes",
        JSON.stringify({
          "Total do orçamento": {
            anterior: 0,
            novo: valorBase,
          },
          "Número de orçamento": {
            anterior: contaFiltrada.orcamento.codigo,
            novo: contaFiltrada.orcamento.codigo,
          },
        })
      );
      formData2.append("utilizador", auth.userId);
      formData2.append("nomeCliente", contaCorrente.cliente.nome);

      let contaCorrenteParaState;
      try {
        contaCorrenteParaState = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }
      toast.success("Isenção cancelada com sucesso!");

      updateContaCorrente(contaCorrenteParaState.contaCorrente);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleConfirmCancelarIsencao = (conta) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Cancelar Isenção!`,
      message: `Tem a certeza que pretende cancelar a isenção da conta?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: cancelarIsencaoHandler.bind(null, conta),
    });
  };

  const verEntradaHandler = (id) => {
    const entradaFiltrada = entradas.filter((entr) => entr.id === id)[0];
    setModalData(entradaFiltrada);
    setOpenModal("verEntrada");
  };

  const handleRestaurarPendencias = async (conta) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("contaCorrenteId", contaCorrente._id);
      formData.append("contaId", conta._id);

      const contaCorrenteRetornada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/restaurarPendencias/${clinica.clinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast.success("Conta restaurada com sucesso!");
      updateContaCorrente(contaCorrenteRetornada.contaCorrente);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      handleModalCancel();
    }
  };

  const desativarEntradaHandler = async (id) => {
    startLoading();
    const filteredEntrada = entradas.find((e) => e._id === id);
    try {
      const formDataEntradaDesativar = new FormData();
      formDataEntradaDesativar.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/toggleativo/${id}`,
        "PATCH",
        formDataEntradaDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      //Se a entrada for referente à cobrança de uma avaliação, essa avaliação deixa de ser debitada
      if (filteredEntrada.avaliacao) {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/removerdebitodeavaliacao/${filteredEntrada.avaliacao}`,
          "PATCH",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }

      const tempEntradas = [...entradas];

      const indexEntrada = tempEntradas.findIndex(
        (entrada) => entrada._id === id
      );
      tempEntradas.splice(indexEntrada, 1);
      setEntradas([...tempEntradas]);

      toast.success(
        `Entrada desativada com sucesso!${
          filteredEntrada.avaliacao
            ? " A avaliação volta ao estado não debitado."
            : ""
        }`
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const editarEntradaHandler = (id) => {
    const entradaFiltrada = entradas.find((e) => e._id === id);
    if (!entradaFiltrada) {
      toast.error("Entrada não encontrada!");
      return;
    }
    setModalData({ open: "editarEntrada", entrada: entradaFiltrada });
  };

  const updateEntradas = (entradaRetornada, action) => {
    if (action === "inserir") {
      //Inserir
      const tempEntradas = [...entradas];
      tempEntradas.push(entradaRetornada);
      setEntradas([...tempEntradas]);
      stopLoading();
    } else {
      //Atualizar
      let tempEntradas;

      tempEntradas = entradas.map((e) => {
        if (e._id === entradaRetornada._id) {
          return entradaRetornada;
        } else {
          return e;
        }
      });

      setEntradas([...tempEntradas]);
      stopLoading();
    }
  };

  //Emitir FRE ou TVE para Entradas
  const emitirDocEletronicoEntradas = async (
    entrada,
    idEntrada,
    tipoDocumento,
    operacao
  ) => {
    startLoading();
    try {
      let entradaFiltrada =
        entrada || entradas.find((e) => e._id === idEntrada);

      // Validate client data
      const validacao = validarDadosCliente(clienteData, tipoDocumento);
      if (!validacao.valido) {
        toast.error(validacao.erro);
        updateEntradas(entradaFiltrada, operacao);
        return;
      }

      // Prepare emission data
      const dadosEmissao = {
        clinicaId: clinica.clinica._id,
        tipoDocEletronico: tipoDocumento,
        valor: entradaFiltrada.valor,
        descricaoPagamento: entradaFiltrada.descricao,
        metodoPagamento: entradaFiltrada.metodoPagamento,
        usuarioEmissor: auth.userId,
        entrada: entradaFiltrada._id,
        online: true,
        clinica: {
          dadosEmissor: clinica.clinica.dadosEmissor,
          nif: clinica.clinica.nif,
          nomeFiscal: clinica.clinica.nomeFiscal,
          endereco: clinica.clinica.endereco,
          contacto: clinica.clinica.contacto,
          email: clinica.clinica.email,
        },
        ...(tipoDocumento !== "TVE" && {
          nomeCliente: clienteData.nomeFiscal,
          nifCliente: clienteData.nif,
          contactoCliente: clienteData.contacto,
          moradaCliente: clienteData.endereco,
          codigoPaisCliente: clienteData.pais.codigo,
        }),
      };

      const formData = new FormData();
      formData.append("dadosEmissao", JSON.stringify(dadosEmissao));

      const resposta = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/emitirdoceletronicoeatualizar`,
        "POST",
        formData,
        { Authorization: "Bearer " + auth.token }
      );

      if (!resposta) {
        toast.error("Não houve resposta do servidor. Tente novamente.");
        return;
      }

      updateEntradas(resposta.entrada, operacao);

      if (resposta.documento?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(`${tipoDocumento} emitido com sucesso`);
      } else {
        toast.error(
          `Erro ao emitir ${tipoDocumento}: ${resposta.documento?.respostaEfatura?.responses[0]?.messages[0]?.description}`
        );
      }
    } catch (err) {
      console.error("Erro ao emitir documento:", err);
      toast.error("Ocorreu um erro ao emitir o documento");
    } finally {
      stopLoading();
    }
  };

  const gerarReciboInternoEntradas = async (idEntrada) => {
    startLoading();
    try {
      const entradaFiltrada = entradas.find((e) => e._id === idEntrada);
      let entradaRetornada;
      let nrRecibo = 1;
      if (entradaFiltrada.nrRecibo) {
        nrRecibo = entradaFiltrada.nrRecibo;
        gerarPdfReciboHandler(entradaFiltrada, nrRecibo);
      } else {
        let idDocumentoAEliminar;
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (entradaFiltrada.documentoEletronico?.succeeded === false) {
          idDocumentoAEliminar = entradaFiltrada.documentoEletronico._id;
        }
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o numero de recibo
        const formData = new FormData();
        formData.append("valor", nrRecibo);
        formData.append("tipoDocumento", "recibo");
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        try {
          const formData = new FormData();
          formData.append("nrRecibo", nrRecibo);
          formData.append("utilizador", auth.userId);

          //Na backend, documento eletronico é removido da entrada
          const tempEntradaRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/gerarrecibo/${idEntrada}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          entradaRetornada = tempEntradaRetornada.entrada;

          if (entradaRetornada && idDocumentoAEliminar) {
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
                "DELETE",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }

          toast.success("Recibo gerado com sucesso!");
          updateEntradas(entradaRetornada, "atualizar");
        } catch (err) {
          console.error("err", err);
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleOpenModalReceberEntrada = (id) => {
    const entradaFiltada = entradas.find((e) => e.id === id);
    if (!entradaFiltada) {
      toast.error("Não foi possivel encontrar a entrada");
      return;
    }
    setModalData({ open: "receberEntrada", entrada: entradaFiltada });
  };

  //BEFORE RECEBIMENTO ENTRADA
  const handleBeforeCancelarRecebimentoEntrada = (
    id,
    enviarDocCancelamento
  ) => {
    const entradaFiltrada = entradas.find((e) => e.id === id);
    if (!entradaFiltrada) {
      toast.error("Entrada não encontrada!");
      return;
    }
    if (enviarDocCancelamento) {
      setDadosModalMotivoCancelamento({
        open: true,
        entrada: entradaFiltrada,
        tipoDocumento: "NCE",
        tipoDocumentoAnular: entradaFiltrada.documentoEletronico.tipoDocumento,
        nif: clienteData.nif,
        nome: clienteData.nomeFiscal,
        tipoCancelamento: "entrada",
      });
    } else {
      handleCancelarRecebimentoEntrada(entradaFiltrada);
    }
  };

  //CANCELAR RECEBIMENTO ENTRADAS
  const handleCancelarRecebimentoEntrada = async (
    entradaFiltrada,
    enviarDocCancelamento,
    motivoCancelamento,
    tipoDocumento,
    nomeENif
  ) => {
    startLoading();
    try {
      if (!enviarDocCancelamento) {
        // Simple cancellation without document
        const formData = new FormData();
        formData.append("canceladoPor", auth.userId);
        const result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/cancelarrecebimentosemdocumento/${entradaFiltrada._id}`,
          "PATCH",
          formData,
          { Authorization: "Bearer " + auth.token }
        );

        updateEntradas(result.entrada, "atualizar");
        toast.success("Recebimento cancelado com sucesso!");
        return;
      }

      // Prepare cancellation data
      const dadosCancelamento = {
        clinicaId: clinica.clinica._id,
        tipoDocEletronico: tipoDocumento,
        entradaId: entradaFiltrada._id,
        valor: entradaFiltrada.valor,
        usuarioEmissor: auth.userId,
        clinica: {
          dadosEmissor: clinica.clinica.dadosEmissor,
          nif: clinica.clinica.nif,
          nomeFiscal: clinica.clinica.nomeFiscal,
          endereco: clinica.clinica.endereco,
          contacto: clinica.clinica.contacto,
          email: clinica.clinica.email,
        },
        IUDCancelar: entradaFiltrada.documentoEletronico.entryName.replace(
          /\.xml$/,
          ""
        ),
        IssueReasonCode: motivoCancelamento,
        nomeCliente: nomeENif ? nomeENif.nome : clienteData.nomeFiscal,
        nifCliente: nomeENif ? nomeENif.nif : clienteData.nif,
        descricaoPagamento: entradaFiltrada.descricao,
        contactoCliente: clienteData.contacto,
        moradaCliente: clienteData.endereco,
        codigoPaisCliente: clienteData.pais.codigo,
        metodoPagamento: entradaFiltrada.metodoPagamento,
        dataPagamento: entradaFiltrada.dataPagamento,
        online: true,
      };

      const formData = new FormData();
      formData.append("dadosCancelamento", JSON.stringify(dadosCancelamento));

      const resposta = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/cancelarrecebimentocomdocumento`,
        "POST",
        formData,
        { Authorization: "Bearer " + auth.token }
      );

      updateEntradas(resposta.entrada, "atualizar");
      if (resposta.documento?.responseData?.responses[0]?.succeeded) {
        toast.success(
          `Recebimento cancelado e ${tipoDocumento} emitido com sucesso!`
        );
      } else {
        toast.error(
          `Erro ao emitir ${tipoDocumento}: ${resposta.documento?.responseData?.responses[0]?.messages[0]?.description}`
        );
      }
    } catch (err) {
      console.error("Erro ao cancelar recebimento:", err);
      toast.error("Erro ao cancelar recebimento");
    } finally {
      stopLoading();
    }
  };

  const actionColumn = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span
                className={
                  params.row.parcelaPaga && params.row.status !== "CANCELADA"
                    ? "spanCellPago"
                    : ""
                }
              >
                {params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.entradaRecebida && params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span
                  className={
                    params.row.parcelaPaga && params.row.status !== "CANCELADA"
                      ? "spanCellPago"
                      : ""
                  }
                >
                  {params.row.status === "CANCELADA"
                    ? "PARCELA " + params.row.status
                    : params.row.nome}
                </span>
                {params.row.nrParcela && params.row.status !== "CANCELADA" && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Ver orçamento">
                  <IconButton
                    onClick={handleOpenModalProcedimentos.bind(
                      null,
                      params.row.orcamento
                    )}
                  >
                    <ErrorIcon className="muiIconVerOrcamento" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  "link-cliente-receita " + params.row.entradaRecebida &&
                  params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                Entrada - {params.row.descricao}
                {params.row.cliente && (
                  <>
                    {" - "}
                    {params.row.cliente.nome}
                  </>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoEstado">
                {params.row.orcamento.desconto ===
                  params.row.orcamento.subtotal -
                    (params.row.orcamento.majoracao || 0) && (
                  <span className="debitos__isento">Isenção</span>
                )}

                {params.row.orcamento.desconto !==
                  params.row.orcamento.subtotal -
                    (params.row.orcamento.majoracao || 0) && (
                  <span
                    className={`debitos__container__estado ${
                      params.row.estado === "Em atraso" &&
                      params.row.status !== "CANCELADA"
                        ? "debitos__container__estado--atraso"
                        : params.row.estado === "Pago" &&
                          params.row.status !== "CANCELADA"
                        ? "debitos__container__estado--pago"
                        : ""
                    }`}
                  >
                    {params.row.metodoPagamento
                      ? params.row.metodoPagamento
                      : params.row.estado}
                  </span>
                )}
              </div>
            )}
            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellAction">
                {params.row.orcamento.desconto !==
                  params.row.orcamento.subtotal -
                    (params.row.orcamento.majoracao || 0) && (
                  <span
                    className={`debitos__container__valor ${
                      params.row.estado === "Em atraso" &&
                      params.row.status !== "CANCELADA"
                        ? "debitos__container__valor--atraso"
                        : params.row.estado === "Pago" &&
                          params.row.status !== "CANCELADA"
                        ? "debitos__container__valor--pago"
                        : ""
                    }`}
                  >
                    {params.row.valor}
                  </span>
                )}
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <span
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
                style={{ marginLeft: "auto" }}
              >
                {params.row.valor}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="cellDebitoStatus">
            {params.row.estado === "Pago" && (
              <span
                className={
                  params.row.orcamento?.desconto ===
                  params.row.orcamento?.subtotal -
                    (params.row.orcamento.majoracao || 0)
                    ? "debitos__container__receber debitos__container__receber--isento"
                    : "debitos__container__receber debitos__container__receber--pago"
                }
              >
                <Tooltip
                  title={
                    params.row.orcamento?.desconto ===
                    params.row.orcamento?.subtotal -
                      (params.row.orcamento.majoracao || 0)
                      ? "Parcela Isenta de Pagamento"
                      : `Parcela recebida ${
                          params.row.recebidoPor
                            ? "por " + params.row.recebidoPor
                            : ""
                        }${
                          params.row.dataRecebimento
                            ? " aos " + params.row.dataRecebimento
                            : ""
                        }`
                  }
                >
                  <CheckCircleIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === true && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {`${
                        params.row.documentoEletronico?.tipoDocumento
                      } emitido ${
                        params.row.emitidoPor
                          ? `por ${params.row.emitidoPor}`
                          : ""
                      }${
                        params.row.dataEmissao
                          ? ` aos ${params.row.dataEmissao}`
                          : ""
                      }.`}
                      {params.row?.cancelamentosDocumentosEletronicos?.length >
                        0 && (
                        <>
                          <br />
                          {`${params.row.cancelamentosDocumentosEletronicos.length} cancelamentos efetuados.`}
                          {params.row.cancelamentosDocumentosEletronicos.map(
                            (c, index) => (
                              <React.Fragment key={index}>
                                <br />
                                {c.documento.entryName.split(".")[0]}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  }
                >
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.nrRecibo && (
              <span className="debitos__container__receber debitos__container__receber--reciboInterno">
                <Tooltip title="Recibo Interno">
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === false && (
              <span className="debitos__container__receber debitos__container__receber--atraso">
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {`${params.row.documentoEletronico?.tipoDocumento} com erro.`}
                      {params.row?.documentoEletronico?.messages?.length >
                        0 && (
                        <>
                          {params.row.documentoEletronico.messages.map(
                            (m, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <br />
                                {m.description}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  }
                >
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.documentoCancelamento?.succeeded === false && (
                <span className="debitos__container__receber debitos__container__receber--atraso">
                  <Tooltip
                    title={
                      <React.Fragment>
                        {
                          params.row.cancelamentosDocumentosEletronicos[
                            params.row.cancelamentosDocumentosEletronicos
                              ?.length - 1
                          ]?.documentoCancelamento?.tipoDocumento
                        }{" "}
                        com erro:
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos[
                          params.row.cancelamentosDocumentosEletronicos.length -
                            1
                        ].documentoCancelamento.messages.map((m, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                            {m.description}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                  </Tooltip>
                </span>
              )}

            {/* Entradas */}
            {params.row.tipo === "entrada" && params.row.entradaRecebida && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip
                  title={`Entrada recebida ${
                    params.row.recebidoPor
                      ? `por ${params.row.recebidoPor}`
                      : ""
                  }${
                    params.row.dataRecebimento
                      ? " aos " + params.row.dataRecebimento
                      : ""
                  }`}
                >
                  <CheckCircleIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1.5,
      renderCell: (params) => {
        let content;
        if (params.row.tipo === "receita") {
          if (
            params.row.orcamento.desconto ===
              params.row.orcamento.subtotal -
                (params.row.orcamento.majoracao || 0) &&
            !params.row.motivoEncerramento
          ) {
            return null;
          } else if (params.row.parcelaPaga && !params.row.motivoEncerramento) {
            content = (
              <>
                <div
                  className="popOverMenu--option"
                  onClick={verReceitaHandler.bind(
                    null,
                    params.row.id,
                    params.row.contaId
                  )}
                >
                  <span>Ver</span>
                  <RemoveRedEyeIcon className="popOverMenu--option__icon" />
                </div>

                {/*PDF FRE - apenas se o documento fiscal enviado for bem sucedido  */}
                {auth.perm.includes("u-parc") &&
                  params.row.documentoEletronico?.succeeded && (
                    <div
                      className="popOverMenu--option"
                      onClick={gerarPdfFaturaEletronicaHandler.bind(
                        null,
                        params.row.id,
                        params.row.contaId
                      )}
                    >
                      <span>
                        Gerar PDF {params.row.documentoEletronico.tipoDocumento}
                      </span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  )}

                {/* EMITIR FRE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
                {auth.perm.includes("u-parc") &&
                  (!params.row.documentoEletronico ||
                    params.row.documentoEletronico?.succeeded === false) &&
                  !params.row.nrRecibo &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.documentoCancelamento?.succeeded === true)) && (
                    <Popconfirm
                      title="Fatura Recibo Eletrónico"
                      description={`Pretende emitir a FRE?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={handleBeforeEmitirDocEletronico.bind(
                        null,
                        params.row.id,
                        params.row.contaId,
                        "FRE"
                      )}
                    >
                      <div className="popOverMenu--option">
                        Emitir FRE
                        <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                      </div>
                    </Popconfirm>
                  )}

                {/* EMITIR TVE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
                {auth.perm.includes("u-parc") &&
                  (!params.row.documentoEletronico ||
                    params.row.documentoEletronico?.succeeded === false) &&
                  !params.row.nrRecibo &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.documentoCancelamento?.succeeded === true)) &&
                  (!clienteData.nif ||
                    clienteData.nif === "000000000" ||
                    clienteData.nif === "999999999") && (
                    <Popconfirm
                      title="Talão de Venda Eletrónico"
                      description={`Pretende emitir a TVE?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={handleBeforeEmitirDocEletronico.bind(
                        null,
                        params.row.id,
                        params.row.contaId,
                        "TVE"
                      )}
                    >
                      <div className="popOverMenu--option">
                        Emitir TVE
                        <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                      </div>
                    </Popconfirm>
                  )}

                {/* RECIBO INTERNO - Parcela paga (no If lá acima), com dinheiro, orcamento particular, sem documentoEletronico com ou sem erro e sem cancelamento com erro */}
                {auth.perm.includes("u-parc") &&
                  !params.row.nrRecibo &&
                  (params.row.orcamento.beneficio === "Particular" ||
                    params.row.orcamento.orcamentoMisto) &&
                  params.row.metodoPagamento === "Dinheiro" &&
                  (!params.row.documentoEletronico ||
                    params.row.documentoEletronico?.succeeded === false) &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.documentoCancelamento?.succeeded === false)) && (
                    <Popconfirm
                      title="Recibo Interno"
                      description={`Pretende emitir um recibo Interno?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={beforeGerarReciboHandler.bind(
                        null,
                        params.row.id,
                        params.row.contaId
                      )}
                    >
                      <div className="popOverMenu--option">
                        Emitir Recibo Interno
                        <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                      </div>
                    </Popconfirm>
                  )}

                {auth.perm.includes("u-parc") && params.row.nrRecibo && (
                  <div
                    className="popOverMenu--option"
                    onClick={beforeGerarReciboHandler.bind(
                      null,
                      params.row.id,
                      params.row.contaId
                    )}
                  >
                    <span>Ver Recibo Interno</span>
                    <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

                {/* Exatamento como o anterior. Caso exista documentos eletronicos ou cancelamentos, são verificados como o anterior */}
                {auth.perm.includes("u-parc") && params.row.parcelaPaga && (
                  <Popconfirm
                    title="Cancelar Recebimento"
                    description={`Pretende cancelar o recebimento da ${
                      params.row.nome
                    } ${
                      params.row.documentoEletronico?.succeeded === true
                        ? "com a emissão de uma Nota de Crédito?"
                        : ""
                    }`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeCancelarRecebimento.bind(
                      null,
                      params.row.id,
                      params.row.contaId
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Cancelar recebimento</span>
                      <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
              </>
            );
          }
        }
        if (params.row.tipo === "entrada") {
          content = (
            <div className="dots__menu__popup">
              {/* VER ENTRADA */}
              {auth.perm.includes("r-ent") && (
                <div
                  className="popOverMenu--option"
                  onClick={verEntradaHandler.bind(null, params.row.id)}
                >
                  <span>Ver</span>
                  <RemoveRedEyeIcon className="popOverMenu--option__icon" />
                </div>
              )}
              {/* EDITAR ENTRADA - apenas entradas que não possuem documento fiscal, cujo envio resultou em erro ou que nao estao pagas, não pode editar um documento com um envio de nce com erro*/}
              {auth.perm.includes("u-ent") &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                !params.row.entradaRecebida &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                auth.perm.includes("u-ent") && (
                  <div
                    className="popOverMenu--option"
                    onClick={editarEntradaHandler.bind(null, params.row.id)}
                  >
                    <span>Editar</span>
                    <EditIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {/* GERAR PDF FRE - se existe documento eletronico e foi bem sucedido */}
              {auth.perm.includes("u-ent") &&
                params.row.documentoEletronico?.succeeded && (
                  <div
                    className="popOverMenu--option"
                    onClick={gerarPdfFaturaEletronicaHandlerEntrada.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <span>
                      Gerar PDF {params.row.documentoEletronico?.tipoDocumento}
                    </span>
                    <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {auth.perm.includes("u-ent") && params.row.nrRecibo && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarReciboInternoEntradas.bind(null, params.row.id)}
                >
                  <span>Gerar PDF Recibo Interno</span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/*Emitir Fre para entrada*/}
              {auth.perm.includes("u-ent") &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                !params.row.nrRecibo &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Fatura/Recibo Eletrónico - FRE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoEntradas.bind(
                      null,
                      null,
                      params.row.id,
                      "FRE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir FRE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {auth.perm.includes("u-ent") &&
                !params.row.nrRecibo &&
                params.row.metodoPagamento === "Dinheiro" &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documento?.succeeded === false)) && (
                  <Popconfirm
                    title="Recibo Interno"
                    description={`Pretende emitir um recibo Interno?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={gerarReciboInternoEntradas.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir Recibo Interno</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*Emitir TVE para reembolsos*/}
              {auth.perm.includes("u-ent") &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                !params.row.nrRecibo &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida && (
                  <Popconfirm
                    title="Talão de Venda Eletrónico - TVE"
                    description={`Confirma a emissão do Talão de Venda?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoEntradas.bind(
                      null,
                      null,
                      params.row.id,
                      "TVE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir TVE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Comprovativo */}
              {auth.perm.includes("r-ent") &&
                params.row.comprovativo &&
                params.row.comprovativo.ficheiro && (
                  <a
                    href={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.comprovativo.ficheiro}`}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="popOverMenu--option">
                      <span>Baixar Comprovativo</span>
                      <DownloadIcon className="popOverMenu--option__icon" />
                    </div>
                  </a>
                )}

              {/*CANCELAR RECEBIMENTO 1 - apenas para FTES, caso a entrada estiver paga, o documento enviado obteve sucesso e se caso houver um envio de NCE, esse foi bem sucedido */}
              {auth.perm.includes("u-ent") &&
                params.row.entradaRecebida &&
                auth.perm.includes("u-ent") && (
                  <Popconfirm
                    title="Cancelar Recebimento"
                    description={`Pretende cancelar o recebimento da entrada - ${
                      params.row.descricao
                    } ${
                      params.row.documentoEletronico?.succeeded === true
                        ? ` e cancelar a/o ${params.row?.documentoEletronico?.tipoDocumento}`
                        : ""
                    }?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeCancelarRecebimentoEntrada.bind(
                      null,
                      params.row.id,
                      params.row.documentoEletronico?.succeeded ? true : false
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Cancelar recebimento</span>
                      <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* DESATIVAR ENTRADA -  se não houver nenum tipo de documento fiscal com ou sem erro e a entrada não estiver recebida */}
              {auth.perm.includes("d-ent") &&
                !params.row.documentoEletronico?.succeeded &&
                (!params.row.cancelamentosDocumentosEletronicos ||
                  params.row.cancelamentosDocumentosEletronicos?.length ===
                    0) &&
                !params.row.entradaRecebida &&
                auth.perm.includes("d-ent") && (
                  <Popconfirm
                    title="Desativar Entrada"
                    description={`Pretende desativar a entrada - ${params.row.descricao}?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={desativarEntradaHandler.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Desativar</span>
                      <DeleteIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
            </div>
          );
        }

        return (
          <>
            {params.row.estado !== "Pago" &&
              params.row.tipo === "receita" &&
              params.row.quantiaPorPagar !== 0 &&
              !params.row.motivoEncerramento &&
              auth.perm.includes("u-parc") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceber.bind(
                    null,
                    params.row.id,
                    params.row.valor
                  )}
                >
                  Receber
                </span>
              )}

            {params.row.tipo === "entrada" &&
              !params.row.entradaRecebida &&
              auth.perm.includes("u-ent") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceberEntrada.bind(
                    null,
                    params.row.id
                  )}
                >
                  Receber
                </span>
              )}

            {content && (
              <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                  <div className="dots__menu">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </Popover>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  //UTIL
  const validarDadosCliente = (cliente, tipoDocumento) => {
    // Se for TVE, não precisa validar dados do cliente
    if (tipoDocumento === "TVE") {
      return { valido: true };
    }

    if (!cliente.nif) {
      return { valido: false, erro: "O cliente não possui um nif" };
    }

    if (!cliente.nomeFiscal?.trim()) {
      return { valido: false, erro: "O cliente não possui um nome fiscal!" };
    }

    if (!cliente.contacto?.trim()) {
      return {
        valido: false,
        erro: "O cliente não possui um número de telefone",
      };
    }

    if (!cliente.endereco?.trim()) {
      return { valido: false, erro: "O cliente não possui um endereço" };
    }

    if (!cliente.pais) {
      return { valido: false, erro: "O cliente não possui um pais" };
    }

    if (
      (cliente.nif === "000000000" || cliente.nif === "999999999") &&
      tipoDocumento === "FRE"
    ) {
      return {
        valido: false,
        erro: "Nifs 000000000 ou 999999999 não são válidos para FREs.",
      };
    }

    return { valido: true };
  };

  console.log("contaCorrente", contaCorrente);
  return (
    <>
      <div className="debitos__container caixa">
        <ModalInfo {...modalProps} />
        <div className="debitos__container__cabecalho">
          <div
            className="checkbox-wrapper-51"
            id="debitos__container__cabecalho__mostrar-recebidos"
          >
            <input
              type="checkbox"
              id="mostrarRecebidos"
              onChange={(e) => setMostrarRecebidos(e.target.checked)}
              checked={mostrarRecebidos}
            />
            <label htmlFor="mostrarRecebidos" className="toggle">
              <span>
                <svg width="10px" height="10px" viewBox="0 0 10 10">
                  <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                </svg>
              </span>
            </label>
            <span className="checkbox-text">Mostrar Recebidos</span>
          </div>

          <div className="debitos__container__cabecalho__totais">
            {mostrarRecebidos && (
              <div className="debitos__container__cabecalho__totais--recebido">
                <span>Total Recebido</span>
                <span>{totalRecebido}$00</span>
              </div>
            )}
            <div className="debitos__container__cabecalho__totais--por-receber">
              <span>Total a receber</span>
              <span>{totalReceber}$00</span>
            </div>
          </div>

          <div style={{ justifySelf: "flex-end" }}>
            {auth.perm.includes("c-ent") && (
              <Button
                variant="contained"
                color="success"
                id="basic-button"
                onClick={(e) => setModalData({ open: "entrada" })}
              >
                + Adicionar Entrada
              </Button>
            )}
          </div>
        </div>
        {contaCorrente &&
          contaCorrente.contas
            .filter((conta) => conta.aprovado === true)
            .sort((a, b) => {
              // Helper function to determine if conta should be expanded
              const shouldBeExpanded = (conta) =>
                !conta.contaLiquidada &&
                conta.total !== 0 &&
                conta.status !== "INTERROMPIDO";

              // Sort expanded first (returns -1), collapsed last (returns 1)
              if (shouldBeExpanded(a) && !shouldBeExpanded(b)) return -1;
              if (!shouldBeExpanded(a) && shouldBeExpanded(b)) return 1;
              return 0;
            })
            .map((conta) => {
              // Skip if we're not showing received and all parcelas are paid
              if (
                !mostrarRecebidos &&
                conta.parcelas.every((p) => p.parcelaPaga)
              ) {
                return null;
              }

              // Calculate conta totals
              const totalPago = conta.parcelas.reduce(
                (sum, p) => sum + (p.quantiaPaga || 0),
                0
              );
              const totalPorPagar = conta.parcelas.reduce(
                (sum, p) => sum + (p.quantiaPorPagar || 0),
                0
              );

              return (
                <Accordion
                  key={conta._id}
                  defaultExpanded={
                    !conta.contaLiquidada &&
                    conta.total !== 0 &&
                    conta.status !== "INTERROMPIDO"
                  }
                  sx={{
                    mb: 2,
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          fontSize: "2rem",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "primary.main",
                            filter: "brightness(1.2)",
                            transition: "all 0.2s ease-in-out",
                          },
                        }}
                      />
                    }
                    sx={{
                      height: "55px",
                      backgroundColor:
                        conta.status === "INTERROMPIDO"
                          ? "#fff8f8"
                          : conta.total === 0
                          ? "#f9feff"
                          : "#fdfdfd",
                      borderLeft:
                        conta.status === "INTERROMPIDO"
                          ? "4px solid #eb3c5f"
                          : conta.total === 0
                          ? "4px solid #1976d2"
                          : "4px solid #4caf50",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <span className="debitos__accordion__title">
                          Orçamento #{conta.orcamento?.codigo || "N/A"} -{" "}
                          {conta.orcamento?.orcamentoMisto
                            ? "Misto"
                            : conta.orcamento?.beneficio}
                        </span>
                        {conta.status === "INTERROMPIDO" && (
                          <Chip
                            label="Tratamento Interrompido"
                            size="small"
                            sx={{
                              color: "#eb3c5f",
                              backgroundColor:
                                "rgba(241, 167, 182, 0.4745098039)",
                            }}
                          />
                        )}

                        {conta.total === 0 && (
                          <Chip
                            label="Isento de Pagamento"
                            size="small"
                            sx={{
                              color: "#1976D2",
                              backgroundColor: "#bbdefb4f",
                            }}
                          />
                        )}

                        {conta.contaLiquidada && conta.total !== 0 && (
                          <Chip
                            label="Liquidada"
                            size="small"
                            sx={{
                              color: "#12d453",
                              backgroundColor: "#e5faec",
                            }}
                          />
                        )}

                        {/* Botao de ação */}
                        {/* -------------------------------------------------------- */}
                        {(((!conta.contaLiquidada || conta.total === 0) &&
                          conta.orcamento.ativo &&
                          conta.aprovado &&
                          !conta.motivoEncerramento) ||
                          conta.motivoEncerramento) && (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Button
                              size="small"
                              onClick={(event) => handleClickMenu(event, conta)}
                              variant="contained"
                              disableElevation
                              color="primary"
                              sx={{ textTransform: "none" }}
                              endIcon={<ExpandMoreIcon />}
                            >
                              Ações
                            </Button>
                            <Menu
                              anchorEl={menuState.anchorEl}
                              open={Boolean(menuState.anchorEl)}
                              onClose={handleCloseMenu}
                              onClick={(e) => e.stopPropagation()}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              TransitionProps={{
                                onExited: () => {
                                  setMenuState((prev) => ({
                                    ...prev,
                                    selectedConta: null,
                                    isClosing: false,
                                  }));
                                },
                              }}
                            >
                              {menuState.selectedConta &&
                                !menuState.isClosing &&
                                (menuState.selectedConta.motivoEncerramento &&
                                auth.perm.includes("u-pend") ? (
                                  (auth.role === "Médico/Administrador" ||
                                    auth.role === "Gerente") && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleConfirmRestaurarPendencia(
                                          menuState.selectedConta
                                        );
                                        handleCloseMenu();
                                      }}
                                      sx={{
                                        color: "#273445",
                                        "&:hover": {
                                          backgroundColor: "#f5f5f5",
                                        },
                                      }}
                                    >
                                      <HistoryIcon
                                        sx={{ mr: 1, color: "#88959e" }}
                                      />
                                      Restaurar Pendências
                                    </MenuItem>
                                  )
                                ) : (
                                  // Replace the array with conditional rendering
                                  <>
                                    {menuState.selectedConta.total === 0 ? (
                                      auth.perm.includes("u-desc") && (
                                        <MenuItem
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleConfirmCancelarIsencao(
                                              menuState.selectedConta
                                            );
                                            handleCloseMenu();
                                          }}
                                          sx={{
                                            color: "#273445",
                                            "&:hover": {
                                              backgroundColor: "#f5f5f5",
                                            },
                                          }}
                                        >
                                          <CancelIcon
                                            sx={{ mr: 1, color: "#88959e" }}
                                          />
                                          Cancelar isenção
                                        </MenuItem>
                                      )
                                    ) : (
                                      <>
                                        {auth.perm.includes("u-desc") && (
                                          <MenuItem
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleOpenModalEditarDesconto(
                                                menuState.selectedConta
                                              );
                                              handleCloseMenu();
                                            }}
                                            sx={{
                                              color: "#273445",
                                              "&:hover": {
                                                backgroundColor: "#f5f5f5",
                                              },
                                            }}
                                          >
                                            <DiscountIcon
                                              sx={{ mr: 1, color: "#88959e" }}
                                            />
                                            Editar Desconto
                                          </MenuItem>
                                        )}

                                        {auth.perm.includes("u-desc") &&
                                          menuState.selectedConta.orcamento
                                            .beneficio === "INPS" && (
                                            <MenuItem
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleOpenModalEditarMajoracao(
                                                  menuState.selectedConta
                                                );
                                                handleCloseMenu();
                                              }}
                                              sx={{
                                                color: "#273445",
                                                "&:hover": {
                                                  backgroundColor: "#f5f5f5",
                                                },
                                              }}
                                            >
                                              <DiscountIcon
                                                sx={{ mr: 1, color: "#88959e" }}
                                              />
                                              Editar Majoração
                                            </MenuItem>
                                          )}

                                        {auth.perm.includes("u-pend") &&
                                          menuState.selectedConta.parcelas.some(
                                            (parcela) =>
                                              parcela.quantiaPorPagar === 0
                                          ) && (
                                            <MenuItem
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleOpenModalCancelamentoPendencias(
                                                  menuState.selectedConta
                                                );
                                                handleCloseMenu();
                                              }}
                                              sx={{
                                                color: "#273445",
                                                "&:hover": {
                                                  backgroundColor: "#f5f5f5",
                                                },
                                              }}
                                            >
                                              <CancelIcon
                                                sx={{
                                                  mr: 1,
                                                  color: "#88959e",
                                                }}
                                              />
                                              Terminar Pendências
                                            </MenuItem>
                                          )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </Menu>
                          </Box>
                        )}
                        {/* -------------------------------------------------------- */}
                      </Box>
                      <Box sx={{ display: "flex", gap: 3 }}>
                        {mostrarRecebidos && conta.total !== 0 && (
                          <Box>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              Total Recebido
                            </Typography>
                            <Typography color="success.main">
                              {totalPago.toLocaleString("pt-BR")}$00
                            </Typography>
                          </Box>
                        )}
                        {conta.total !== 0 && (
                          <Box sx={{ marginRight: "25px" }}>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {conta.motivoEncerramento
                                ? "Total Pendente"
                                : "Total a Receber"}
                            </Typography>
                            <Typography color="error.main">
                              {totalPorPagar.toLocaleString("pt-BR")}$00
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {conta.status === "INTERROMPIDO" && (
                      <Alert
                        severity="info"
                        sx={{ mb: 2 }}
                        icon={
                          conta.motivoEncerramento?.descricao ? (
                            <Tooltip
                              title={conta.motivoEncerramento?.descricao}
                            >
                              <InfoIcon sx={{ cursor: "help" }} />
                            </Tooltip>
                          ) : (
                            <InfoIcon />
                          )
                        }
                      >
                        <Typography variant="body2">
                          <strong>Motivo da Interrupção:</strong>{" "}
                          {conta.motivoEncerramento?.tipo}
                          <br />
                          <div style={{ marginTop: "7px" }}>
                            <strong>Data:</strong>{" "}
                            {moment(conta.motivoEncerramento?.data).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            <strong style={{ marginLeft: "15px" }}>
                              Responsável:{" "}
                            </strong>
                            {conta.motivoEncerramento?.responsavel?.name}
                          </div>
                        </Typography>
                      </Alert>
                    )}

                    {/* Your existing DataGrid for this conta's parcelas */}
                    <div
                      style={{
                        height: Math.min(165 + conta.nrParcelas * 45, 380),
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={rows.filter((row) => row.contaId === conta._id)}
                        columns={actionColumn}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        {/* Entradas */}
        {entradas && entradas.length > 0 && auth.perm.includes("r-ent") && (
          <Accordion
            key={"entradas1"}
            defaultExpanded={true}
            sx={{
              mb: 2,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    fontSize: "2rem",
                    "&:hover": {
                      transform: "scale(1.2)",
                      color: "primary.main",
                      filter: "brightness(1.2)",
                      transition: "all 0.2s ease-in-out",
                    },
                  }}
                />
              }
              sx={{
                height: "55px",
                backgroundColor: "#eafdff",
                borderLeft: "4px solid #00BCD4",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <span className="debitos__accordion__title">Entradas</span>
                  {/* -------------------------------------------------------- */}
                </Box>
                <Box sx={{ display: "flex", gap: 3 }}>
                  {mostrarRecebidos && (
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Total Recebido
                      </Typography>
                      <Typography color="success.main">
                        {entradas
                          .reduce(
                            (sum, e) => sum + (e.entradaRecebida ? e.valor : 0),
                            0
                          )
                          .toLocaleString("pt-BR")}
                        $00
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ marginRight: "25px" }}>
                    <Typography variant="caption" color="text.secondary">
                      Total a Receber
                    </Typography>
                    <Typography color="error.main">
                      {entradas
                        .reduce(
                          (sum, e) => sum + (e.entradaRecebida ? 0 : e.valor),
                          0
                        )
                        .toLocaleString("pt-BR")}
                      $00
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {/* Your existing DataGrid for this conta's parcelas */}
              <div
                style={{
                  height: Math.min(165 + entradas.length * 45, 380),
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={rows.filter((row) => row.tipo === "entrada")}
                  columns={actionColumn}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {openModal === "receber" && (
          <ModalReceber
            open={openModal === "receber"}
            handleCloseModalReceber={handleCloseModal}
            nomeCliente={nomeCliente}
            valorAPagar={parseFloat(valor.slice(0, -3).replace(".", ""))}
            contaCorr={contaCorrente}
            idParcela={idParcela}
            sendRequest={sendRequest}
            updateContaCorrente={updateContaCorrente}
          />
        )}

        {openModal === "procedimentos" && (
          <ModalProcedimentos
            open={openModal === "procedimentos"}
            handleCloseModalProcedimentos={handleCloseModal}
            modalProcedimentosData={modalData}
          />
        )}

        {openModal === "verReceita" && (
          <ModalVerReceita
            open={openModal === "verReceita"}
            handleCloseModalVerReceita={handleCloseModal}
            dadosDaReceita={modalData}
            nomeCliente={nomeCliente}
          />
        )}

        {openModal === "editarDesconto" && (
          <ModalEditarDesconto
            open={openModal === "editarDesconto"}
            handleCloseModalEditarDesconto={handleCloseModal}
            modalData={modalData}
            updateContaCorrente={updateContaCorrente}
            contaCorrente={contaCorrente}
            auth={auth}
          />
        )}

        {openModal === "editarMajoracao" && (
          <ModalEditarMajoracao
            open={openModal === "editarMajoracao"}
            handleCloseModalEditarMajoracao={handleCloseModal}
            modalData={modalData}
            updateContaCorrente={updateContaCorrente}
            contaCorrente={contaCorrente}
            auth={auth}
          />
        )}

        {openModal === "cancelarPendencias" && (
          <ModalMotivoCancelamentoPendencias
            open={openModal === "cancelarPendencias"}
            handleCloseModalCancelarPendencias={handleCloseModal}
            modalData={modalData}
            updateContaCorrente={updateContaCorrente}
            contaCorrente={contaCorrente}
            auth={auth}
            sendRequest={sendRequest}
            clinicaId={clinica.clinica._id}
          />
        )}

        {dadosModalMotivoCancelamento.open && (
          <ModalMotivoCancelamento
            open={dadosModalMotivoCancelamento.open}
            handleCloseModal={handleCloseModal}
            modalData={dadosModalMotivoCancelamento}
            handleCancelarRecebimento={handleCancelarRecebimento}
            handleCancelarRecebimentoEntrada={handleCancelarRecebimentoEntrada}
            clinica={clinica}
            auth={auth}
          />
        )}

        {openModal === "verEntrada" && (
          <ModalVerEntrada
            openModalVerEntrada={openModal === "verEntrada"}
            handleCloseModalVerEntrada={handleCloseModal}
            dadosModalVerEntrada={modalData}
          />
        )}
        {modalData?.open === "editarEntrada" && (
          <ModalEditarEntradaDebito
            openModalEditarEntrada={modalData?.open === "editarEntrada"}
            sendRequest={sendRequest}
            handleCloseModalEditarEntrada={handleCloseModal}
            clinicaId={clinica.clinica._id}
            auth={auth}
            updateEntradas={updateEntradas}
            entrada={modalData.entrada}
            emitirDocEletronicoEntradas={emitirDocEletronicoEntradas}
            cliente={clienteData}
          />
        )}
        {modalData?.open === "receberEntrada" && (
          <ModalReceberEntradaDebito
            openModalReceberEntrada={modalData?.open === "receberEntrada"}
            sendRequest={sendRequest}
            handleCloseModalReceberEntrada={handleCloseModal}
            auth={auth}
            updateEntradas={updateEntradas}
            entrada={modalData.entrada}
            cliente={clienteData}
            clinicaId={clinica.clinica._id}
            emitirDocEletronicoEntradas={emitirDocEletronicoEntradas}
          />
        )}

        {modalData?.open === "entrada" && (
          <ModalEntradaDebito
            openModalEntrada={modalData.open === "entrada"}
            sendRequest={sendRequest}
            handleCloseModalEntrada={handleCloseModal}
            clinicaId={clinica.clinica._id}
            auth={auth}
            updateEntradas={updateEntradas}
            emitirDocEletronicoEntradas={emitirDocEletronicoEntradas}
            cliente={clienteData}
          />
        )}
      </div>
    </>
  );
}

export default Debitos;
