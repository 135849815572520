import { React, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";

import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { localeText } from "../../../../shared/util/datatableTranslations";

//Mui icons
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon //Edit icon
//ver
//Delete icon
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "../../../../shared/css/Datatable.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function Datatable() {
  const { startLoading, stopLoading } = useLoading();
  const [data, setData] = useState();

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [open, setOpen] = useState(false);
  const [fotoFuncionario, setFotoFuncionario] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFuncionarios = async () => {
      startLoading();
      try {
        let responseData;

        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        responseData.funcionarios.forEach((funcionario) => {
          let temp = funcionario.data_nasc.split("T")[0].split("-");
          funcionario.data_nasc = temp[2] + "-" + temp[1] + "-" + temp[0];
        });
        setData(responseData.funcionarios);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchFuncionarios();
  }, [sendRequest, clinica, auth.token]);

  const handleDesativar = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/ativardesativar/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setData(
        data.filter((el) => {
          return el.id !== id;
        })
      );

      toast.success("Funcionario desativado com sucesso!");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleOpen = (foto) => {
    setOpen(true);
    setFotoFuncionario(foto);
  };

  const handleClose = () => setOpen(false);

  const funcionarioColumns = [
    {
      field: "funcionario",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.foto ? (
              <img
                className="cellImg"
                src={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.foto}`}
                alt="avatar"
                onClick={handleOpen.bind(
                  null,
                  `${process.env.REACT_APP_BACKEND_LINK}/${params.row.foto}`
                )}
              />
            ) : (
              <svg version="1.1" viewBox="0 0 256 256" className="cellImg">
                <g
                  className="gUserIconStyle"
                  transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                >
                  <path
                    className="pathUserIconStyle"
                    d="M 45 0 C 20.147 0 0 20.147 0 45 c 0 24.853 20.147 45 45 45 s 45 -20.147 45 -45 C 90 20.147 69.853 0 45 0 z M 45 22.007 c 8.899 0 16.14 7.241 16.14 16.14 c 0 8.9 -7.241 16.14 -16.14 16.14 c -8.9 0 -16.14 -7.24 -16.14 -16.14 C 28.86 29.248 36.1 22.007 45 22.007 z M 45 83.843 c -11.135 0 -21.123 -4.885 -27.957 -12.623 c 3.177 -5.75 8.144 -10.476 14.05 -13.341 c 2.009 -0.974 4.354 -0.958 6.435 0.041 c 2.343 1.126 4.857 1.696 7.473 1.696 c 2.615 0 5.13 -0.571 7.473 -1.696 c 2.083 -1 4.428 -1.015 6.435 -0.041 c 5.906 2.864 10.872 7.591 14.049 13.341 C 66.123 78.957 56.135 83.843 45 83.843 z"
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                  />
                </g>
              </svg>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 7,
      renderCell: (params) => {
        return (
          <span className="nomeDataTable">
            {auth.perm.includes("r-func") && (
              <Link to={`/funcionarios/${params.row.id}/edit`}>
                {params.row.nome}
              </Link>
            )}
            {!auth.perm.includes("r-func") && params.row.nome}
          </span>
        );
      },
    },
    {
      field: "contacto",
      headerName: "Contacto",
      flex: 4,
    },
    {
      field: "contacto2",
      headerName: "Contacto 2",
      flex: 4,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 7,
    },
    {
      field: "data_nasc",
      headerName: "Dt. Nasc.",
      flex: 3,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 3,
      renderCell: (params) => {
        const content = (
          <div className="dots__menu__popup">
            {auth.perm.includes("u-func") && (
              <Link
                to={`/funcionarios/${params.row.id}/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className="popOverMenu--option">
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              </Link>
            )}

            {auth.perm.includes("d-func") && (
              <Popconfirm
                title={`Excluir Cliente`}
                description={`Pretende desativar o funcionario ${params.row.nome}?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={handleDesativar.bind(null, params.row.id)}
                // onCancel={}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );
        return (
          <div className="cellAction">
            <Space wrap>
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {data && (
        <>
          <div className="datatable caixa">
            {auth.perm.includes("c-func") && (
              <div className="datatable__add-button-div">
                <span
                  className="blue-button"
                  onClick={() => navigate("/funcionarios/new")}
                >
                  Adicionar Funcionário
                </span>
              </div>
            )}
            <DataGrid
              autoWidth
              className="datagrid"
              rows={data}
              columns={funcionarioColumns.concat(actionColumn)}
              autoPageSize
              localeText={localeText}
            />
          </div>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={fotoFuncionario} alt="" className="fotoModal" />
        </Box>
      </Modal>
    </>
  );
}

export default Datatable;
