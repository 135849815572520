import React, { useState, useContext, useEffect } from "react";
import "./ModalStockUpdate.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, IconButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClearIcon from "@mui/icons-material/Clear";

function ModalStockUpdate({
  handleSubmitUpdateStock,
  handleCloseModalAdicionarStock,
  stock,
  categoriasStock,
  clinicaId,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newStockInfo, setNewStockInfo] = useState(
    stock.stock.map((item) => ({
      ...item,
      lotes: item.lotes.map((lote) => ({
        ...lote,
        novaQuantidade: lote.quantidade,
        quantidadeAdicionada: 0,
      })),
    }))
  );
  const [erro, setErro] = useState("");
  const [filteredStock, setFilteredStock] = useState([]);
  const [textQuery, setTextQuery] = useState("");
  const [categoria, setCategoria] = useState("todas");
  const auth = useContext(AuthContext);

  const handleQtddChange = (value, produtoId, loteIndex) => {
    const numValue = Number(value);
    const updatedStock = newStockInfo.map((item) => {
      if (item.produto._id === produtoId) {
        const updatedLotes = item.lotes.map((lote, index) => {
          if (index === loteIndex) {
            const quantidadeAdicionada = numValue - lote.quantidade;
            return quantidadeAdicionada >= 0
              ? {
                  ...lote,
                  novaQuantidade: lote.quantidade,
                  quantidadeAdicionada,
                }
              : lote;
          }
          return lote;
        });
        return {
          ...item,
          lotes: updatedLotes,
        };
      }
      return item;
    });

    setNewStockInfo(updatedStock);
    setFilteredStock(applyFilters(updatedStock, textQuery, categoria));
  };

  const handleDataValidadeChange = (date, produtoId, loteIndex) => {
    const updatedStock = newStockInfo.map((item) => {
      if (item.produto._id === produtoId) {
        const updatedLotes = item.lotes.map((lote, index) =>
          index === loteIndex
            ? { ...lote, dataValidade: date ? date.toDate() : null }
            : lote
        );
        return { ...item, lotes: updatedLotes };
      }
      return item;
    });
    setNewStockInfo(updatedStock);
    setFilteredStock(applyFilters(updatedStock, textQuery, categoria));
  };

  const clearDataValidade = (produtoId, loteIndex) => {
    handleDataValidadeChange(null, produtoId, loteIndex);
  };

  const preSubmit = () => {
    let haErro = false;

    const movimentos = newStockInfo.flatMap((newItem) =>
      newItem.lotes
        .filter((lote) => lote.quantidadeAdicionada > 0)
        .map((lote) => ({
          clinica: clinicaId,
          produto: newItem.produto._id,
          lote: lote.lote,
          dataValidade: lote.dataValidade,
          responsavelMovimento: { tipo: "User", responsavel: auth.userId },
          quantidade: lote.quantidadeAdicionada,
          data: new Date(),
          saida: false,
        }))
    );

    if (movimentos.length === 0) {
      setErro("Nenhuma alteração foi feita no stock.");
      haErro = true;
    }

    if (!haErro && !isSubmitting) {
      setIsSubmitting(true);
      handleSubmitUpdateStock(newStockInfo, movimentos);
    }
  };

  const applyFilters = (stockData, text, cat) => {
    let result = stockData;

    if (cat !== "todas") {
      result = result.filter((st) => st.produto.categoriaStock === cat);
    }

    if (text) {
      result = result.filter((item) =>
        item.produto.nome.toLowerCase().includes(text.toLowerCase())
      );
    }

    return result.sort((a, b) => a.produto.nome.localeCompare(b.produto.nome));
  };

  useEffect(() => {
    setFilteredStock(applyFilters(newStockInfo, textQuery, categoria));
  }, [newStockInfo, textQuery, categoria]);

  const categoriaChangeHandler = (e) => {
    setCategoria(e.target.value);
  };

  const textChangeHandler = (e) => {
    setTextQuery(e.target.value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="stockCadastrar__container">
        <div className="stockUpdate__table">
          <div className="stockUpdate__select-container">
            <select
              onChange={categoriaChangeHandler}
              className="select__filter-categoria"
              value={categoria}
            >
              <option value="todas">Todas Categorias</option>
              {categoriasStock.map((cat, index) => (
                <option value={cat.id} key={index}>
                  {cat.nome}
                </option>
              ))}
            </select>

            <div className="stock__header__container2__container2 textBoxSearch__container">
              <input
                type="text"
                className="textBoxSearch__input"
                placeholder="O que está procurando?"
                value={textQuery}
                onChange={textChangeHandler}
              />
              <SearchIcon className="financeiro__filtros__search-icon" />
            </div>
          </div>
          <div className="stockUpdate__table__row stockUpdate__table__row--header">
            <span>Produto</span>
            <span>Lote</span>
            <span>Qtd Atual</span>
            <span>Adicionar</span>
          </div>

          {filteredStock &&
            filteredStock.map((st) =>
              st.lotes.map((lote, loteIndex) => (
                <div
                  className="stockUpdate__table__row"
                  key={`${st.produto._id}-${lote.lote}`}
                >
                  <span>{st.produto.nome}</span>
                  <span>{lote.lote}</span>
                  <span>
                    {lote.quantidade + " " + st.produto.unidadeMedida}
                  </span>
                  <TextField
                    className="modalStockUpdateMuiTextField"
                    type="number"
                    value={lote.quantidadeAdicionada || 0}
                    onChange={(e) =>
                      handleQtddChange(
                        Number(e.target.value) + lote.quantidade,
                        st.produto._id,
                        loteIndex
                      )
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                  />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flexEnd",
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        clearDataValidade(st.produto._id, loteIndex)
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </div>
              ))
            )}
        </div>
        {erro && <div className="stockUpdate__erro">{erro}</div>}
        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModalAdicionarStock}
            style={{ display: "block" }}
          >
            Fechar
          </span>

          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={preSubmit}
          >
            {isSubmitting ? "Guardando..." : "Alterar Stock"}
          </span>
        </div>
      </div>
    </LocalizationProvider>
  );
}

export default ModalStockUpdate;
