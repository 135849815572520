import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../shared/context/auth-context";
import moment from "moment";
import ModalAdicionarEvolucao from "./ModalAdicionarEvolucao/ModalAdicionarEvolucao";
import ModalEditarEvolucao from "./ModalEditarEvolucao/ModalEditarEvolucao";
import ModalEvolucao from "./ModalEvolucao";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModalInfo from "../../../../../shared/components/UIElements/ModalInfo";
import toast from "react-hot-toast";
import "./Historico.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
  Box,
  Chip,
} from "@mui/material";
import ModalEditarConsulta from "./ModalEditarConsulta/ModalEditarConsulta";

function Historico({ clienteId, sendRequest, clienteData }) {
  const { startLoading, stopLoading } = useLoading();

  const [planosTratamento, setPlanosTratamento] = useState();
  const [modalData, setModalData] = useState();
  const [evolucoes, setEvolucoes] = useState();
  const [consultas, setConsultas] = useState();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const sortedEvolucoes = [...clienteData.evolucoes].sort((a, b) => {
      return new Date(b.data_fim_execucao) - new Date(a.data_fim_execucao);
    });

    setEvolucoes(sortedEvolucoes);
  }, [clienteData]);

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  useEffect(() => {
    const fetchPlanosTratamentoByCliente = async () => {
      startLoading();
      try {
        let responseData;
        let responseDataConsulta;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/historico/${clienteId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        try {
          responseDataConsulta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/cliente/${clienteId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        const tempConsultas = responseDataConsulta.consultas.sort((a, b) => {
          return new Date(b.data_inicio) - new Date(a.data_inicio);
        });

        const temp = responseData.planosTratamento.sort((a, b) => {
          return new Date(b.data_inicio) - new Date(a.data_inicio);
        });

        temp.forEach((plano) => {
          if (plano.data_inicio) {
            plano.data_inicio = moment(plano.data_inicio).format("DD-MM-YYYY");
          }
          if (plano.data_fim) {
            plano.data_fim = moment(plano.data_fim).format("DD-MM-YYYY");
          }
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        // let tempPlanosTratamento = [];
        // let tempConsultasFinal = [];

        // if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
        //   tempPlanosTratamento = temp.filter(
        //     (pl) => pl.medico_responsavel.id === auth.userId
        //   );
        // } else
        // if (auth.role === "Secretária(o)" || auth.role === "Assistente") {
        //   temp.forEach((pl) => {
        //     auth.usuariosAssociados.forEach((medico) => {
        //       if (medico._id === pl.medico_responsavel.id)
        //         tempPlanosTratamento.push(pl);
        //     });
        //   });
        // } else if (auth.role === "Gerente") {
        //   tempPlanosTratamento = [...temp];
        // }

        // if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
        //   tempConsultasFinal = tempConsultas.filter(
        //     (ct) => ct.medico_responsavel._id === auth.userId
        //   );
        // } else
        // if (auth.role === "Secretária(o)" || auth.role === "Assistente") {
        //   tempConsultas.forEach((ct) => {
        //     auth.usuariosAssociados.forEach((medico) => {
        //       if (medico.id === ct.medico_responsavel.id)
        //         tempConsultasFinal.push(ct);
        //     });
        //   });
        // } else if (auth.role === "Gerente") {
        //   tempConsultasFinal = [...tempConsultas];
        // }

        setConsultas(tempConsultas);
        setPlanosTratamento(temp);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchPlanosTratamentoByCliente();
  }, [sendRequest, clienteId, auth]);

  const handleShowEvolucao = (proc, idPlano) => {
    setModalData({ open: "evolucao", data: { proc: proc, idPlano: idPlano } });
  };

  const handleCloseModal = () => {
    setModalData();
  };

  const handleOpenModalAdicionarEvolucao = () => {
    setModalData({ open: "adicionarEvolucao" });
  };

  const handleModalSubmitSuccess = (evolu) => {
    handleCloseModal();
    setEvolucoes((current) => [...current, evolu]);
  };

  const handleModalEditarSuccess = (evolu) => {
    handleCloseModal();

    setEvolucoes((prevItems) =>
      prevItems.map((item) => (item.id === evolu.id ? { ...evolu } : item))
    );
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const handleApagarEvolucao = async (idEvolucao) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Eliminar Evolução!`,
      message: `Tem a certeza que pretende eliminar a evolução?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: apagarEvolucao.bind(null, idEvolucao),
    });
  };

  const apagarEvolucao = async (idEvolucao) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("usuario", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/apagarevolucao/${idEvolucao}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast.success("Evolução eliminada com sucesso");
      setEvolucoes((current) => current.filter((c) => c.id !== idEvolucao));
    } catch (err) {
      console.error("err", err);
      console.error(err);
    } finally {
      stopLoading();
      handleModalCancel();
    }
  };

  const handleOpenModalEditar = async (idEvolucao) => {
    setModalData({
      open: "editarEvolucao",
      data: evolucoes.find((ev) => ev.id === idEvolucao),
    });
  };

  const updatePlanosTratamento = (newPlano) => {
    const tempNovosPlanos = [...planosTratamento].map((p) =>
      p._id === newPlano._id ? newPlano : p
    );

    const temp = tempNovosPlanos.sort((a, b) => {
      return new Date(b.data_inicio) - new Date(a.data_inicio);
    });

    temp.forEach((plano) => {
      if (plano.data_inicio) {
        plano.data_inicio = moment(plano.data_inicio).format("DD-MM-YYYY");
      }
      if (plano.data_fim) {
        plano.data_fim = moment(plano.data_fim).format("DD-MM-YYYY");
      }
    });

    setPlanosTratamento(temp);
  };

  const handleShowConsulta = (id) => {
    const consultaFiltrada = consultas.find((c) => c._id === id);
    if (!consultaFiltrada) {
      toast.error("Dados da consulta não encontradas!");
      return;
    }
    setModalData({ open: "editarConsulta", data: consultaFiltrada });
  };

  const handleEditarConsultaSuccess = (consultaRetornada) => {
    handleCloseModal();
    setConsultas((current) =>
      current.map((c) =>
        c._id === consultaRetornada._id ? consultaRetornada : c
      )
    );
  };

  const hasSessions = (planoTratamento) => {
    // Check if any procedimento has sessions
    const hasSessions = planoTratamento.procedimentos.some(
      (procedimento) => procedimento.sessoes && procedimento.sessoes.length > 0
    );

    // Return true only if there are sessions
    return hasSessions;
  };

  return (
    <>
      <ModalInfo {...modalProps} />

      {planosTratamento && planosTratamento.length > 0 && (
        <div
          className="historicoContainer caixa"
          style={{ marginBottom: "25px" }}
        >
          {planosTratamento.map((plano) => (
            <Accordion
              key={plano._id}
              defaultExpanded={
                !plano.contaLiquidada &&
                plano.total !== 0 &&
                plano.status !== "INTERROMPIDO"
              }
              sx={{
                mb: 2,
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: "2rem",
                      "&:hover": {
                        transform: "scale(1.2)",
                        color: "primary.main",
                        filter: "brightness(1.2)",
                        transition: "all 0.2s ease-in-out",
                      },
                    }}
                  />
                }
                sx={{
                  height: "55px",
                  backgroundColor:
                    plano.status === "INTERROMPIDO" ? "#fff8f8" : "#fdfdfd",
                  borderLeft:
                    plano.status === "INTERROMPIDO"
                      ? "4px solid #eb3c5f"
                      : "4px solid #4caf50",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {(plano.data_inicio || plano.data_fim) && (
                      <span className="debitos__accordion__title">
                        Plano de Tratamento {plano.numero} -{" "}
                        {plano.data_inicio ? plano.data_inicio : "?"} a{" "}
                        {plano.data_fim ? plano.data_fim : "?"}
                      </span>
                    )}
                    {plano.status === "INTERROMPIDO" && (
                      <>
                        <span className="debitos__accordion__title">
                          Plano de Tratamento {plano.numero} -
                        </span>
                        <Chip
                          label="Tratamento Interrompido"
                          size="small"
                          sx={{
                            color: "#eb3c5f",
                            backgroundColor: "rgba(241, 167, 182, 0.47)",
                          }}
                        />
                      </>
                    )}

                    {hasSessions(plano) === false && (
                      <>
                        <span className="debitos__accordion__title">
                          Plano de Tratamento {plano.numero} -
                        </span>
                        <Chip
                          label="Por iniciar"
                          size="small"
                          sx={{
                            color: "#fdcb7f",
                            backgroundColor: "#fff7eb",
                          }}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                {plano.status === "INTERROMPIDO" &&
                  plano.motivoEncerramento && (
                    <Alert
                      severity="info"
                      sx={{ mb: 2 }}
                      icon={
                        plano.motivoEncerramento?.descricao ? (
                          <Tooltip title={plano.motivoEncerramento?.descricao}>
                            <InfoIcon sx={{ cursor: "help" }} />
                          </Tooltip>
                        ) : (
                          <InfoIcon />
                        )
                      }
                    >
                      <Typography variant="body2">
                        <strong>Motivo da Interrupção:</strong>{" "}
                        {plano.motivoEncerramento?.tipo}
                        <br />
                        <div style={{ marginTop: "7px" }}>
                          <strong>Data:</strong>{" "}
                          {moment(plano.motivoEncerramento?.data).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          <strong style={{ marginLeft: "15px" }}>
                            Responsável:{" "}
                          </strong>
                          {plano.motivoEncerramento?.responsavel?.name}
                        </div>
                      </Typography>
                    </Alert>
                  )}

                <div className="historico__colapse-container">
                  <div className="historico__colapse-container__row--header">
                    <span>Data Inicio</span>
                    <span>Procedimento</span>
                    <span>Sessões</span>
                    <span>Dente</span>
                    <span>Faces</span>
                    <span>Status</span>
                  </div>

                  {plano.procedimentos.map(
                    (proc, idx) =>
                      proc.sessoes.length > 0 && (
                        <div
                          className="historico__colapse-container__row caixa"
                          key={idx}
                        >
                          <div className="historico__colapse-container__row__item">
                            <span>
                              {proc.data_inicio_execucao
                                ? moment(proc.data_inicio_execucao).format(
                                    "DD-MM-YYYY"
                                  )
                                : moment(
                                    proc.sessoes[0].data_inicio_execucao
                                  ).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className="historico__colapse-container__row__item">
                            <span
                              className="historico__colapse-container__row__item--tratamento"
                              onClick={() =>
                                handleShowEvolucao(proc, plano._id)
                              }
                            >
                              {proc.tratamento.designacao}
                            </span>
                            {/* <span>
                              {proc.medico.genero === "feminino"
                                ? "Dra. "
                                : "Dr. "}
                              {proc.medico.name}
                            </span> */}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {proc.sessoes ? proc.sessoes.length : 1}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {proc.dente}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {proc.faces.join(", ")}
                          </div>
                          <div
                            className="historico__colapse-container__row__item"
                            style={{}}
                          >
                            <span
                              className={`historicoStatus ${
                                proc.procedimento_completo
                                  ? "historicoStatusConcluido"
                                  : proc.sessoes.length > 0
                                  ? "historicoStatusTransformado"
                                  : ""
                              }`}
                            >
                              {proc.procedimento_completo
                                ? "Finalizado"
                                : proc.sessoes.length > 0
                                ? "Em Andamento"
                                : ""}
                            </span>
                          </div>
                        </div>
                      )
                  )}

                  {!plano.procedimentos.filter((p) => p.sessoes.length > 0)
                    .length > 0 && (
                    <div className="historico__noDataMessage">
                      Nenhum procedimento iniciado nesse plano. Sem dados por
                      mostrar!
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}

      {consultas && consultas.length > 0 && (
        <div className="historicoContainer caixa">
          <Accordion
            defaultExpanded
            sx={{
              mb: 2,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    fontSize: "2rem",
                    "&:hover": {
                      transform: "scale(1.2)",
                      color: "primary.main",
                      filter: "brightness(1.2)",
                      transition: "all 0.2s ease-in-out",
                    },
                  }}
                />
              }
              sx={{
                height: "55px",
                backgroundColor: "#eafdff",
                borderLeft: "4px solid #00BCD4",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <span className="debitos__accordion__title">Consultas</span>
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <div className="historico__colapse-container">
                <div className="historico__colapse-container__consulta__row--header">
                  <span>Número</span>
                  <span>Data Inicio</span>
                  <span>Data Fim</span>
                  <span>Status</span>
                </div>

                {consultas.map((consulta, index) => (
                  <div
                    className="historico__colapse-container__consulta__row caixa"
                    key={index}
                  >
                    <div className="historico__colapse-container__consulta__row__item">
                      {!consulta.executada ? (
                        <span>Consulta nr {consulta.numero}</span>
                      ) : (
                        <span
                          className="historico__colapse-container__consulta__row__item--tratamento"
                          onClick={handleShowConsulta.bind(null, consulta._id)}
                        >
                          Consulta nr {consulta.numero}
                        </span>
                      )}
                    </div>

                    <div className="historico__colapse-container__consulta__row__item">
                      <span>
                        {moment(consulta.data_inicio).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </span>
                    </div>

                    <div className="historico__colapse-container__consulta__row__item">
                      <span>
                        {moment(consulta.data_fim).format("DD-MM-YYYY HH:mm")}
                      </span>
                    </div>

                    <div className="historico__colapse-container__consulta__row__item">
                      <span
                        className={`historicoStatus ${
                          consulta.executada
                            ? "historicoStatusConcluido"
                            : consulta.faltou
                            ? "historicoStatusINTERROMPIDO"
                            : "historicoStatusTransformado"
                        }`}
                      >
                        {consulta.executada
                          ? "Executada"
                          : consulta.faltou
                          ? "Faltou"
                          : "Pendente"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div className="adicionarEvolucaoButtonContainer">
        <span
          className="blue-button"
          onClick={handleOpenModalAdicionarEvolucao}
        >
          Adicionar Evolução
        </span>
      </div>

      {evolucoes && (
        <div className="evolucaoClienteSolo__container">
          {evolucoes.map((ev, index) => (
            <div className="caixa evolucaoItemContainer" key={index}>
              <div className="evolucaoClienteSolo">
                <span className="evolucao-descricao">{ev.evolucao}</span>
                <div>
                  <span className="evolucao-datas">
                    {moment(ev.data_inicio_execucao).format("DD/MM/YYYY HH:mm")}
                  </span>{" "}
                  {"  -  "}
                  <span className="evolucao-datas">
                    {moment(ev.data_fim_execucao).format("HH:mm")} -{" "}
                    {ev.medico ? ev.medico.name : ""}
                  </span>
                </div>
              </div>
              <div className="evolucao-btns">
                {ev.medico?._id === auth.userId && (
                  <>
                    <Tooltip title="Apagar">
                      <IconButton
                        onClick={handleApagarEvolucao.bind(null, ev._id)}
                        className="modalEvolucao__container__btns__icon-btn"
                      >
                        <DeleteIcon className="evolucao-btns__btn" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        onClick={handleOpenModalEditar.bind(null, ev._id)}
                        className="modalEvolucao__container__btns__icon-btn"
                      >
                        <EditIcon className="evolucao-btns__btn" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {modalData?.open === "evolucao" && (
        <ModalEvolucao
          data={modalData.data}
          openModalEvolucao={modalData.open === "evolucao"}
          handleCloseModal={handleCloseModal}
          auth={auth}
          sendRequest={sendRequest}
          updatePlanosTratamento={updatePlanosTratamento}
        />
      )}

      {modalData?.open === "editarConsulta" && (
        <ModalEditarConsulta
          dados={modalData.data}
          open={modalData.open === "editarConsulta"}
          handleCloseModal={handleCloseModal}
          auth={auth}
          sendRequest={sendRequest}
          handleModalSubmitSuccess={handleEditarConsultaSuccess}
        />
      )}

      {modalData?.open === "adicionarEvolucao" && (
        <ModalAdicionarEvolucao
          handleCloseModalEvolucao={handleCloseModal}
          handleModalSubmitSuccess={handleModalSubmitSuccess}
          open={modalData.open === "adicionarEvolucao"}
          cliente={{ nome: clienteData.nome, id: clienteData.id }}
          sendRequest={sendRequest}
          auth={auth}
        />
      )}

      {modalData?.open === "editarEvolucao" && (
        <ModalEditarEvolucao
          handleCloseModalEvolucao={handleCloseModal}
          handleModalEditarSuccess={handleModalEditarSuccess}
          open={modalData.open === "editarEvolucao"}
          cliente={{ nome: clienteData.nome, id: clienteData.id }}
          sendRequest={sendRequest}
          auth={auth}
          dadosEvolucao={modalData.data}
        />
      )}
    </>
  );
}

export default Historico;
