import React, { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../categoriasFinanceiro/CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import "./CategoriaStock.scss";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

function CategoriasStock({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();
  const auth = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nomeCategoria, setNomeCategoria] = useState("");
  const [descricaoCategoria, setDescricaoCategoria] = useState("");
  const [categoriaPaiId, setCategoriaPaiId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [idCategoria, setIdCategoria] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [mensagemDeErro, setMensagemDeErro] = useState("");

  useEffect(() => {
    fetchCategorias();
  }, [auth.token, clinicaId, sendRequest]);

  const fetchCategorias = async () => {
    startLoading();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinicaId}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      const transformedCategorias = transformCategorias(
        responseData.categoriasStock
      );
      setCategorias(transformedCategorias);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const transformCategorias = (rawCategorias) => {
    // Create a map for quick lookups
    const categoriaMap = new Map();
    rawCategorias.forEach((cat) => {
      categoriaMap.set(cat.id, {
        ...cat,
        subcategorias: [], // Reset subcategorias to build them properly
      });
    });

    // Build proper tree structure
    rawCategorias.forEach((cat) => {
      if (cat.categoriaPai) {
        const parentCategoria = categoriaMap.get(cat.categoriaPai);
        if (parentCategoria) {
          parentCategoria.subcategorias.push(cat.id);
        }
      }
    });

    return Array.from(categoriaMap.values());
  };

  const guardarCategoria = async () => {
    startLoading();
    try {
      const formData = {
        nome: nomeCategoria,
        descricao: descricaoCategoria,
        categoriaPaiId: categoriaPaiId || null,
        criadoPor: auth.userId,
      };

      const categoriaCriada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/${clinicaId}`,
        "POST",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setCategorias((current) => [...current, categoriaCriada.categoriaStock]);
      resetForm();
      toast.success("Categoria criada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  const editarCategoria = async (id) => {
    startLoading();
    try {
      const formData = {
        nome: nomeCategoria,
        descricao: descricaoCategoria,
        categoriaPaiId: categoriaPaiId === "" ? null : categoriaPaiId,
        atualizadoPor: auth.userId,
      };

      const categoriaAtualizada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/${id}`,
        "PATCH",
        JSON.stringify(formData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setCategorias((current) =>
        updateCategoriaTree(current, categoriaAtualizada.categoriaStock)
      );

      resetForm();
      toast.success("Categoria modificada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  // Helper function to update the category tree
  const updateCategoriaTree = (categories, updatedCategory) => {
    return categories.map((cat) => {
      if (cat.id === updatedCategory.id) {
        return updatedCategory;
      }
      if (cat.subcategorias) {
        return {
          ...cat,
          subcategorias: updateCategoriaTree(
            cat.subcategorias,
            updatedCategory
          ),
        };
      }
      return cat;
    });
  };

  // Function to get valid parent categories (excluding self and descendants)
  const getValidParentCategories = (categories, currentCategoryId) => {
    return categories.filter((cat) => {
      if (cat.id === currentCategoryId) {
        return false;
      }
      return !isDescendant(cat, currentCategoryId);
    });
  };

  // Helper function to check if a category is a descendant
  const isDescendant = (category, targetId) => {
    if (category.subcategorias) {
      return category.subcategorias.some(
        (subCat) => subCat.id === targetId || isDescendant(subCat, targetId)
      );
    }
    return false;
  };

  const preEditarCategoria = async (id) => {
    const categoria = categorias.find((cat) => cat.id === id);
    setIdCategoria(id);
    setNomeCategoria(categoria.nome);
    setDescricaoCategoria(categoria.descricao);
    setCategoriaPaiId(categoria.categoriaPai);
    setOpenModal(true);
  };

  const resetForm = () => {
    setNomeCategoria("");
    setDescricaoCategoria("");
    setCategoriaPaiId("");
    setIdCategoria(null);
    setOpenModal(false);
    setMensagemDeErro("");
  };

  const beforeSubmit = (idCategoria) => {
    let duplicidadeCategoria = false;

    if (idCategoria) {
      duplicidadeCategoria = categorias.filter(
        (c) => c.nome.toLowerCase() === nomeCategoria.trim().toLowerCase()
      );
    }

    let haErro = false;
    if (duplicidadeCategoria?.length > 0 && !idCategoria) {
      setMensagemDeErro("Já existe uma categoria com esse nome.");
      haErro = true;
    } else if (!nomeCategoria || nomeCategoria.trim() === "") {
      setMensagemDeErro("Introduza o nome para a categoria.");
      haErro = true;
    } else {
      setMensagemDeErro("");
    }
    if (!isSubmitting && !haErro) {
      setIsSubmitting(true);
      if (idCategoria) {
        editarCategoria(idCategoria);
      } else {
        guardarCategoria();
      }
    }
  };

  const desativarCategoria = async (id) => {
    startLoading();
    try {
      const formDataDesativar = new FormData();
      formDataDesativar.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/toggleativo/${id}`,
        "PATCH",
        formDataDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setCategorias((current) => current.filter((cat) => cat.id !== id));
      toast.success("Categoria desativada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  // const renderCategorias = (categorias, level = 0) => {
  //   return categorias.map((categoria) => (
  //     <React.Fragment key={categoria.id}>
  //       <div
  //         className="categoriasFinanceiro__container__categoria"
  //         style={{ marginLeft: `${level * 20}px` }}
  //       >
  //         <div className="categoriasFinanceiro__container__categoria--1">
  //           {categoria.nome}
  //         </div>
  //         <div className="categoriasFinanceiro__container__categoria--2">
  //           {(auth.role === "Médico/Administrador" ||
  //             auth.role === "Gerente") && (
  //             <Popconfirm
  //               title={`Desativar Categoria`}
  //               description={`Pretende desativar essa categoria?`}
  //               icon={<ErrorIcon style={{ color: "red" }} />}
  //               okText="Sim"
  //               cancelText="Não"
  //               onConfirm={() => desativarCategoria(categoria.id)}
  //               className="popConfirm--biggerPadding"
  //             >
  //               <span>Desativar</span>
  //             </Popconfirm>
  //           )}
  //           {(auth.role === "Médico/Administrador" ||
  //             auth.role === "Gerente") && (
  //             <span onClick={() => preEditarCategoria(categoria.id)}>
  //               Editar
  //             </span>
  //           )}
  //         </div>
  //       </div>
  //       {categoria.subcategorias &&
  //         renderCategorias(categoria.subcategorias, level + 1)}
  //     </React.Fragment>
  //   ));
  // };

  const renderCategorias = (categoriasToRender, level = 0) => {
    // Only render root categories at first level
    const categoriasToShow =
      level === 0
        ? categoriasToRender.filter((cat) => !cat.categoriaPai)
        : categoriasToRender;

    return categoriasToShow.map((categoria) => {
      // Find actual subcategory objects instead of just IDs
      const subcategorias = categoria.subcategorias
        .map((subId) => categorias.find((c) => c.id === subId))
        .filter(Boolean); // Remove any undefined entries

      return (
        <React.Fragment key={categoria.id}>
          <div
            className="categoriasFinanceiro__container__categoria"
            style={{ marginLeft: `${level * 20}px` }}
          >
            <div className="categoriasFinanceiro__container__categoria--1">
              {categoria.nome || "Sem nome"}
            </div>
            <div className="categoriasFinanceiro__container__categoria--2">
              {(auth.role === "Médico/Administrador" ||
                auth.role === "Gerente") && (
                <Popconfirm
                  title={`Desativar Categoria`}
                  description={`Pretende desativar essa categoria?`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={() => desativarCategoria(categoria.id)}
                  className="popConfirm--biggerPadding"
                >
                  <span>Desativar</span>
                </Popconfirm>
              )}
              {(auth.role === "Médico/Administrador" ||
                auth.role === "Gerente") && (
                <span onClick={() => preEditarCategoria(categoria.id)}>
                  Editar
                </span>
              )}
            </div>
          </div>
          {subcategorias.length > 0 &&
            renderCategorias(subcategorias, level + 1)}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <div className="categoriasGeral__container">
        {(auth.role === "Médico/Administrador" || auth.role === "Gerente") && (
          <div className="categoriasGeral__container__btn-adicionar">
            <Button
              variant="contained"
              color="success"
              onClick={(e) => setOpenModal(true)}
            >
              Nova Categoria
            </Button>
          </div>
        )}

        {renderCategorias(categorias)}

        <Modal
          open={openModal}
          onClose={resetForm}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="fluxo-atendimento__modal__box adicionar-categoria__modal"
            id="modalStockCategoria"
          >
            <h1 className="fluxo-atendimento__modal__titulo">
              Categoria de Stock
            </h1>
            <div className="adicionar-categoriaStock__modal__container">
              <TextField
                fullWidth
                label="Nome da Categoria *"
                variant="outlined"
                value={nomeCategoria}
                onChange={(e) => setNomeCategoria(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Descrição"
                variant="outlined"
                value={descricaoCategoria}
                onChange={(e) => setDescricaoCategoria(e.target.value)}
                margin="normal"
              />

              <FormControl fullWidth margin="normal">
                <InputLabel id="categoria-pai-label">Categoria Pai</InputLabel>
                <Select
                  labelId="categoria-pai-label"
                  value={categoriaPaiId}
                  onChange={(e) => setCategoriaPaiId(e.target.value)}
                  label="Categoria Pai"
                >
                  <MenuItem value="">
                    <em>Nenhuma (Categoria principal)</em>
                  </MenuItem>
                  {getValidParentCategories(categorias, idCategoria).map(
                    (cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.nome}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            {mensagemDeErro && (
              <div className="erroContainer__categoria-caixa">
                {mensagemDeErro}
              </div>
            )}
            <div className="fluxo-atendimento__modal__bottom_btns">
              <span className="cancel-btn" onClick={resetForm}>
                Fechar
              </span>
              <span
                className="blue-button"
                onClick={() => beforeSubmit(idCategoria)}
              >
                {idCategoria
                  ? isSubmitting
                    ? "Editando"
                    : "Editar"
                  : isSubmitting
                  ? "Guardando"
                  : "Guardar"}
              </span>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default CategoriasStock;
