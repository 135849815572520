// utils/immutableHelpers.js
export const cloneProfundo = (obj) => JSON.parse(JSON.stringify(obj));

export const atualizarParcela = (parcelas, id, updates) =>
  parcelas.map((p) => (p._id === id ? { ...p, ...updates } : p));

export const filtrarParcelasNaoPagas = (parcelas) =>
  parcelas.filter((p) => !p.parcelaPaga);

export const atualizarContaComParcelas = (conta, novasParcelas) => ({
  ...conta,
  parcelas: novasParcelas,
  nrParcelas: novasParcelas.length,
});
