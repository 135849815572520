import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  Card,
  CardContent,
  Tabs,
  Tab,
  Alert,
  CircularProgress,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// Configurar locale do moment
moment.locale("pt-br");

// Componentes estilizados
const SummaryCard = styled(Card)(({ theme, cardtype }) => ({
  height: "100%",
  backgroundColor:
    cardtype === "entrada"
      ? "rgba(46, 125, 50, 0.08)"
      : cardtype === "saida"
      ? "rgba(211, 47, 47, 0.08)"
      : cardtype === "saldo"
      ? "rgba(25, 118, 210, 0.08)"
      : theme.palette.background.paper,
  borderLeft: `4px solid ${
    cardtype === "entrada"
      ? theme.palette.success.main
      : cardtype === "saida"
      ? theme.palette.error.main
      : cardtype === "saldo"
      ? theme.palette.primary.main
      : theme.palette.divider
  }`,
}));

const ValueTypography = styled(Typography)(({ theme, valuetype }) => ({
  color:
    valuetype === "positivo"
      ? theme.palette.success.main
      : valuetype === "negativo"
      ? theme.palette.error.main
      : theme.palette.text.primary,
  fontWeight: "bold",
}));

function RelatorioFluxoCaixa({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // Paginação para entradas
  const [entradasPage, setEntradasPage] = useState(0);
  const [entradasRowsPerPage, setEntradasRowsPerPage] = useState(10);

  // Paginação para saídas
  const [saidasPage, setSaidasPage] = useState(0);
  const [saidasRowsPerPage, setSaidasRowsPerPage] = useState(10);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes]); // Importante: só reexecuta quando a ação muda

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      const formData = new FormData();
      formData.append("dataInicio", dataInicialFormatada);
      formData.append("dataFim", dataFinalFormatada);
      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarRelatorioFluxoCaixa/${dadosClinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData);
    } catch (err) {
      console.error("Erro ao gerar relatório de fluxo de caixa:", err);
      setError(
        err.message || "Ocorreu um erro ao gerar o relatório de fluxo de caixa."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Chamada para a API de exportação
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_LINK}/api/relatorios/clinica/${dadosClinica._id}/fluxo-caixa/exportar?dataInicio=${dataInicialFormatada}&dataFim=${dataFinalFormatada}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + auth.token,
          },
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `fluxo-caixa-${dataInicialFormatada}-a-${dataFinalFormatada}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handlers para paginação de entradas
  const handleEntradasChangePage = (event, newPage) => {
    setEntradasPage(newPage);
  };

  const handleEntradasChangeRowsPerPage = (event) => {
    setEntradasRowsPerPage(parseInt(event.target.value, 10));
    setEntradasPage(0);
  };

  // Handlers para paginação de saídas
  const handleSaidasChangePage = (event, newPage) => {
    setSaidasPage(newPage);
  };

  const handleSaidasChangeRowsPerPage = (event) => {
    setSaidasRowsPerPage(parseInt(event.target.value, 10));
    setSaidasPage(0);
  };

  // Handler para mudança de aba
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Fluxo de Caixa
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1">
            Clínica: {dadosClinica?.nome || ""}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            {/* Cards de resumo */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={4}>
                <SummaryCard cardtype="entrada">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <TrendingUpIcon color="success" sx={{ mr: 1 }} />
                      <Typography variant="h6">Total de Entradas</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="success.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.resumo.totalEntradas.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <SummaryCard cardtype="saida">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <TrendingDownIcon color="error" sx={{ mr: 1 }} />
                      <Typography variant="h6">Total de Saídas</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="error.main"
                      sx={{ fontWeight: "bold" }}
                    >
                      {dados.resumo.totalSaidas.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <SummaryCard cardtype="saldo">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <AccountBalanceWalletIcon
                        color="primary"
                        sx={{ mr: 1 }}
                      />
                      <Typography variant="h6">Saldo</Typography>
                    </Box>
                    <ValueTypography
                      variant="h4"
                      component="div"
                      valuetype={
                        dados.resumo.saldo >= 0 ? "positivo" : "negativo"
                      }
                    >
                      {dados.resumo.saldo.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </ValueTypography>
                  </CardContent>
                </SummaryCard>
              </Grid>
            </Grid>

            {/* Fluxo diário */}
            <Paper sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Fluxo Diário
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell align="right">Entradas</TableCell>
                      <TableCell align="right">Saídas</TableCell>
                      <TableCell align="right">Saldo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dados.fluxoDiario.map((item, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{item.data}</TableCell>
                        <TableCell align="right" sx={{ color: "success.main" }}>
                          {item.entradas.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "CVE",
                          })}
                        </TableCell>
                        <TableCell align="right" sx={{ color: "error.main" }}>
                          {item.saidas.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "CVE",
                          })}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: "bold",
                            color:
                              item.saldo >= 0 ? "success.main" : "error.main",
                          }}
                        >
                          {item.saldo.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "CVE",
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {/* Detalhes (com abas) */}
            <Paper sx={{ p: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                sx={{ mb: 2 }}
              >
                <Tab label="Entradas" />
                <Tab label="Saídas" />
              </Tabs>

              <Divider sx={{ mb: 2 }} />

              {/* Tab de Entradas */}
              {tabValue === 0 && (
                <>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Cliente</TableCell>
                          <TableCell>Método Pagamento</TableCell>
                          <TableCell align="right">Valor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dados.entradas
                          .slice(
                            entradasPage * entradasRowsPerPage,
                            entradasPage * entradasRowsPerPage +
                              entradasRowsPerPage
                          )
                          .map((entrada, index) => (
                            <TableRow key={index} hover>
                              <TableCell>{entrada.data}</TableCell>
                              <TableCell>{entrada.descricao}</TableCell>
                              <TableCell>{entrada.cliente}</TableCell>
                              <TableCell>{entrada.metodoPagamento}</TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  fontWeight: "bold",
                                  color: "success.main",
                                }}
                              >
                                {entrada.valor.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "CVE",
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dados.entradas.length}
                    rowsPerPage={entradasRowsPerPage}
                    page={entradasPage}
                    onPageChange={handleEntradasChangePage}
                    onRowsPerPageChange={handleEntradasChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página:"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${count}`
                    }
                  />
                </>
              )}

              {/* Tab de Saídas */}
              {tabValue === 1 && (
                <>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Categoria</TableCell>
                          <TableCell>Método Pagamento</TableCell>
                          <TableCell align="right">Valor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dados.saidas
                          .slice(
                            saidasPage * saidasRowsPerPage,
                            saidasPage * saidasRowsPerPage + saidasRowsPerPage
                          )
                          .map((saida, index) => (
                            <TableRow key={index} hover>
                              <TableCell>{saida.data}</TableCell>
                              <TableCell>{saida.descricao}</TableCell>
                              <TableCell>{saida.categoria}</TableCell>
                              <TableCell>{saida.metodoPagamento}</TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontWeight: "bold", color: "error.main" }}
                              >
                                {saida.valor.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "CVE",
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dados.saidas.length}
                    rowsPerPage={saidasRowsPerPage}
                    page={saidasPage}
                    onPageChange={handleSaidasChangePage}
                    onRowsPerPageChange={handleSaidasChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página:"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${count}`
                    }
                  />
                </>
              )}
            </Paper>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioFluxoCaixa;
