import { useState, useEffect, useContext, memo } from "react";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import moment from "moment";
import OrcamentosPorAprovar from "./orcamentosPorAprovar/OrcamentosPorAprovar";
import AvaliacoesPorOrcamentar from "./avaliacoesPorOrcamentar/AvaliacoesPorOrcamentar";
import ModalOrcamentosHoje from "./modalOrcamentosHoje/ModalOrcamentosHoje";
import "./SecretariaBottomContainers.scss";
// import Debitos from "../../components/debitos/Debitos";
import CredenciaisExpirando from "../../components/credenciaisExpirando/CredenciaisExpirando";
function SecretariaBottomContainers() {
  const { sendRequest } = useHttpClient();
  const [orcamentosPorAprovar, setOrcamentosPorAprovar] = useState([]);
  const [avaliacoesPorOrcamentar, setAvaliacoesPorOrcamentar] = useState([]);
  const [openModalOrcamentos, setOpenModalOrcamentos] = useState(false);
  const [openModalAvaliacoes, setOpenModalAvaliacoes] = useState(false);
  const [orcamentosHoje, setOrcamentosHoje] = useState();
  const [openModalOrcamentosHoje, setOpenModalOrcamentosHoje] = useState(false);

  const handleCloseModal = (tipo) => {
    if (tipo === "orcamentos") setOpenModalOrcamentos(false);
    if (tipo === "avaliacoes") setOpenModalAvaliacoes(false);
  };

  const handleCloseModalOrcamentosHoje = () => {
    setOpenModalOrcamentosHoje(false);
  };

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  useEffect(() => {
    const idsUsuariosAssociados = auth.usuariosAssociados.map((u) => u.id);
    const fetchOrcamentos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/poraprovar/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        let tempOrcamentos = [];

        responseData.orcamentos.forEach((or) => {
          if (idsUsuariosAssociados.includes(or.medico_responsavel.id))
            tempOrcamentos.push(or);
        });

        setOrcamentosPorAprovar(
          tempOrcamentos.map((dt) => ({
            id: dt.id,
            nome: dt.cliente.nome,
            idCliente: dt.cliente.id,
            data: moment(dt.created_at).format("DD-MM-YYYY HH:mm"),
            total: dt.total.toLocaleString("pt-Br") + "$00",
            medico: dt.medico_responsavel.name,
          }))
        );
      } catch (err) {}
    };
    fetchOrcamentos();
    const fetchOrcamentosCriadosHoje = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/clinica/hojedashboardsecretaria/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setOrcamentosHoje(responseData.orcamentos);
      } catch (err) {}
    };

    fetchOrcamentosCriadosHoje();

    const fetchAvaliacoes = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/clinica/pororcamentar/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        let tempAvaliacoes = [];

        responseData.avaliacoes.forEach((av) => {
          if (idsUsuariosAssociados.includes(av.medico_responsavel.id))
            tempAvaliacoes.push(av);
        });

        setAvaliacoesPorOrcamentar(
          tempAvaliacoes.map((dt) => ({
            id: dt.id,
            nome: dt.cliente.nome,
            idCliente: dt.cliente.id,
            data: moment(dt.created_at).format("DD-MM-YYYY HH:mm"),
            medico: dt.medico_responsavel.name,
          }))
        );
      } catch (err) {}
    };

    fetchAvaliacoes();
  }, [sendRequest, clinica, auth]);

  return (
    <>
      <div className="administrativoBottomContainers">
        {/* Orcamentos e avaliacoes */}
        <div className="administrativoBottomContainers__container administrativoBottomContainers__container--avalOrc caixa">
          <div>
            <span className="box-title">Orçamentos</span>
            <div
              className="administrativoBottomContainers__container--avalOrc--container"
              style={{ marginTop: 12 }}
            >
              <div>
                <span className="total-patients">
                  {orcamentosPorAprovar.length}
                </span>
                <span className="lucro-description">{"  "}Por Aprovar</span>
              </div>
              <div>
                <span
                  className="administrativoBottomContainers__container--details"
                  onClick={(e) => setOpenModalOrcamentos(true)}
                >
                  Detalhes {">"}
                </span>
              </div>
            </div>
          </div>

          <div>
            <span className="box-title">Avaliações</span>
            <div className="administrativoBottomContainers__container--avalOrc--container">
              <div>
                <span className="total-patients">
                  {avaliacoesPorOrcamentar.length}
                </span>
                <span className="lucro-description"> {"  "}Por Orçamentar</span>
              </div>
              <div>
                <span
                  className="administrativoBottomContainers__container--details"
                  onClick={(e) => setOpenModalAvaliacoes(true)}
                >
                  Detalhes {">"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="administrativoBottomContainers__container administrativoBottomContainers__container--procedimentosPorAgendar dashboard__middle__topTreatments caixa">
          <span className="box-title">Orcamentos de Hoje</span>

          <div className="topTreatments">
            {orcamentosHoje && (
              <ol>
                {orcamentosHoje &&
                  orcamentosHoje.slice(0, 5).map((or, index) => (
                    <li
                      key={index}
                      className={`procedimentosPorAgendar--li ${
                        index % 2 !== 0 && "elemento-lista-bg-escuro"
                      }`}
                    >
                      <span>{or.cliente.nome}</span>
                      <span style={{ paddingRight: "5px", fontWeight: "700" }}>
                        {or.total.toLocaleString("pt-Br") + "$00"}
                      </span>
                    </li>
                  ))}
              </ol>
            )}
          </div>

          <div
            className="button-more-div"
            onClick={(e) => setOpenModalOrcamentosHoje(true)}
          >
            <span className="button-more">Mais</span>
            <span className="more-arrow"> {">"} </span>
          </div>
        </div>

        {/* <Debitos sendRequest={sendRequest} auth={auth} clinica={clinica} /> */}
        <div className="administrativoBottomContainers__container caixa">
          <CredenciaisExpirando
            sendRequest={sendRequest}
            auth={auth}
            clinica={clinica}
          />
        </div>
      </div>

      <OrcamentosPorAprovar
        open={openModalOrcamentos}
        data={orcamentosPorAprovar}
        handleCloseModal={handleCloseModal}
      />
      <AvaliacoesPorOrcamentar
        open={openModalAvaliacoes}
        data={avaliacoesPorOrcamentar}
        handleCloseModal={handleCloseModal}
      />
      {openModalOrcamentosHoje && (
        <ModalOrcamentosHoje
          openModal={openModalOrcamentosHoje}
          orcamentosHoje={orcamentosHoje}
          handleClose={handleCloseModalOrcamentosHoje}
          auth={auth}
          clinicaId={clinica.clinica._id}
        />
      )}
    </>
  );
}

export default memo(SecretariaBottomContainers);
