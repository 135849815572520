import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PaymentIcon from "@mui/icons-material/Payment";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ShowChartIcon from "@mui/icons-material/ShowChart";

// Para gráficos
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
} from "recharts";

// Configurar locale do moment para português
moment.locale("pt-br");

// Cores para o gráfico
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884D8",
  "#82CA9D",
  "#A4DE6C",
  "#D0ED57",
  "#F5A623",
  "#FF6B6B",
];

// Componentes estilizados
const SummaryCard = styled(Card)(({ theme, trend }) => ({
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  borderLeft: `4px solid ${
    trend === "positivo"
      ? theme.palette.success.main
      : trend === "negativo"
      ? theme.palette.error.main
      : theme.palette.warning.main
  }`,
}));

const TrendIndicator = styled(Box)(({ theme, trend }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    trend === "positivo"
      ? "rgba(76, 175, 80, 0.1)"
      : trend === "negativo"
      ? "rgba(244, 67, 54, 0.1)"
      : "rgba(255, 152, 0, 0.1)",
  color:
    trend === "positivo"
      ? theme.palette.success.main
      : trend === "negativo"
      ? theme.palette.error.main
      : theme.palette.warning.main,
}));

function RelatorioFaturamento({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada, execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes]);

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      const formData = new FormData();
      formData.append("dataInicio", dataInicialFormatada);
      formData.append("dataFim", dataFinalFormatada);
      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarRelatorioFaturamento/${dadosClinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData);
    } catch (err) {
      console.error("Erro ao gerar relatório de faturamento:", err);
      setError(err.message || "Ocorreu um erro ao gerar o relatório.");
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      const formData = new FormData();
      formData.append("dataInicio", dataInicialFormatada);
      formData.append("dataFim", dataFinalFormatada);
      // Chamada para a API de exportação
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarRelatorioFaturamento/${dadosClinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `faturamento-${dataInicialFormatada}-a-${dataFinalFormatada}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handler para mudança de aba
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Prepara dados para o gráfico de métodos de pagamento
  const preparePaymentMethodsData = () => {
    if (!dados?.porMetodoPagamento) return [];

    return dados.porMetodoPagamento.map((method, index) => ({
      name: method.metodo,
      value: method.valor,
      percentual: parseFloat(method.percentual),
      color: COLORS[index % COLORS.length],
    }));
  };

  // Prepara dados para o gráfico diário
  const prepareDailyData = () => {
    if (!dados?.faturamentoDiario) return [];

    // Ordenar por data
    return [...dados.faturamentoDiario].sort((a, b) => {
      return moment(a.data, "DD/MM/YYYY").diff(moment(b.data, "DD/MM/YYYY"));
    });
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Faturamento
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD [de] MMMM [de] YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD [de] MMMM [de] YYYY")}
          </Typography>
          <Typography variant="subtitle1">
            Clínica: {dadosClinica?.nome || ""}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            {/* Resumo Geral */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} md={6}>
                <SummaryCard
                  trend={
                    dados.comparativo.variacao >= 0 ? "positivo" : "negativo"
                  }
                >
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <TrendingUpIcon
                        color={
                          dados.comparativo.variacao >= 0 ? "success" : "error"
                        }
                        sx={{ mr: 1 }}
                      />
                      <Typography variant="h6">Faturamento Total</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{ fontWeight: "bold", mb: 2 }}
                    >
                      {dados.faturamentoTotal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "CVE",
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <TrendIndicator
                        trend={
                          dados.comparativo.variacao >= 0
                            ? "positivo"
                            : "negativo"
                        }
                      >
                        {dados.comparativo.variacao >= 0 ? "+" : ""}
                        {dados.comparativo.variacao}% comparado a{" "}
                        {dados.comparativo.periodoAnterior}
                      </TrendIndicator>
                    </Box>

                    <Typography variant="body2" color="text.secondary">
                      Valor no período anterior:{" "}
                      {dados.comparativo.valorPeriodoAnterior.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "CVE",
                        }
                      )}
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card sx={{ height: "100%" }}>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PaymentIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Métodos de Pagamento</Typography>
                    </Box>

                    <Box sx={{ height: 200 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={preparePaymentMethodsData()}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            nameKey="name"
                          >
                            {preparePaymentMethodsData().map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Legend
                            layout="vertical"
                            verticalAlign="middle"
                            align="right"
                          />
                          <RechartsTooltip
                            formatter={(value) =>
                              value.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "CVE",
                              })
                            }
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Abas para alternar entre visualizações */}
            <Box sx={{ width: "100%", mb: 3 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab icon={<DateRangeIcon />} label="Faturamento Diário" />
                <Tab icon={<PaymentIcon />} label="Métodos de Pagamento" />
              </Tabs>

              <Divider sx={{ mb: 3 }} />

              {/* Conteúdo da aba Faturamento Diário */}
              {tabValue === 0 && (
                <>
                  <Typography variant="h6" gutterBottom>
                    <ShowChartIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                    Evolução do Faturamento Diário
                  </Typography>

                  <Box sx={{ height: 400, mb: 3 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={prepareDailyData()}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="data" />
                        <YAxis
                          tickFormatter={(value) =>
                            value.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "CVE",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })
                          }
                        />
                        <RechartsTooltip
                          formatter={(value) =>
                            value.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "CVE",
                            })
                          }
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="valor"
                          name="Faturamento"
                          stroke="#0088FE"
                          strokeWidth={2}
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>

                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell align="right">Valor</TableCell>
                          <TableCell align="right">
                            Percentual do Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prepareDailyData().map((dia, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{dia.data}</TableCell>
                            <TableCell align="right">
                              {dia.valor.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "CVE",
                              })}
                            </TableCell>
                            <TableCell align="right">
                              {dia.percentual}%
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {/* Conteúdo da aba Métodos de Pagamento */}
              {tabValue === 1 && (
                <>
                  <Typography variant="h6" gutterBottom>
                    <PaymentIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                    Faturamento por Método de Pagamento
                  </Typography>

                  <Box sx={{ height: 400, mb: 3 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={preparePaymentMethodsData()}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis
                          tickFormatter={(value) =>
                            value.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "CVE",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })
                          }
                        />
                        <RechartsTooltip
                          formatter={(value) =>
                            value.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "CVE",
                            })
                          }
                        />
                        <Legend />
                        <Bar dataKey="value" name="Valor" radius={[5, 5, 0, 0]}>
                          {preparePaymentMethodsData().map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>

                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Método de Pagamento</TableCell>
                          <TableCell align="right">Valor</TableCell>
                          <TableCell align="right">
                            Percentual do Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {preparePaymentMethodsData().map((metodo, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{metodo.name}</TableCell>
                            <TableCell align="right">
                              {metodo.value.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "CVE",
                              })}
                            </TableCell>
                            <TableCell align="right">
                              {metodo.percentual}%
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioFaturamento;
