import React, { useState } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import FiltrosRelatorio from "../ui/filtrosRelatorio/FiltrosRelatorio";
import RelatorioFaltas from "./components/RelatorioFaltas";
import "./RelatoriosAgendamentos.scss";

const tiposRelatorios = [
  { value: "", label: "Selecione um relatório" },
  { value: "faltas", label: "Relatório de Faltas" },
  // Adicione mais tipos de relatórios de agendamento conforme necessário
];

// Definição de quais relatórios suportam quais ações
const relatorioCaps = {
  faltas: { gerar: true, exportar: true },
  // Configure as capacidades para outros relatórios aqui
};

function RelatoriosAgendamentos({ auth, clinica }) {
  const { sendRequest } = useHttpClient();
  const [dadosRelatorio, setDadosRelatorio] = useState({
    open: "",
    dataFiltro: {},
    acao: "",
    //Contador para forçar a atualização
    atualizacoes: 0,
  });

  const gerarRelatorioHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "gerar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  const exportarFicheiroHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "exportar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  // Função auxiliar para renderizar o componente de relatório correto
  const renderizarRelatorio = () => {
    const { open, dataFiltro, acao, atualizacoes } = dadosRelatorio;

    if (!open) return null;

    const props = {
      dataFiltro: dataFiltro,
      clinicaId: clinica.clinica._id,
      auth: auth,
      acao: acao,
      sendRequest: sendRequest,
      atualizacoes: atualizacoes,
    };

    switch (open) {
      case "faltas":
        return <RelatorioFaltas {...props} />;
      // Adicione outros casos conforme necessário
      default:
        return null;
    }
  };

  return (
    <div>
      <FiltrosRelatorio
        tiposRelatorios={tiposRelatorios}
        gerarRelatorioHandler={gerarRelatorioHandler}
        exportarFicheiroHandler={exportarFicheiroHandler}
        relatorioCaps={relatorioCaps}
        // Manter o tipo de relatório selecionado
        relatorioSelecionado={dadosRelatorio.open}
      />

      {renderizarRelatorio()}
    </div>
  );
}

export default RelatoriosAgendamentos;
