import { useState, useEffect } from "react";
import ListUpcomingAppointments from "./Lists/ListUpcomingAppointments/ListUpcomingAppointments";
import moment from "moment/moment";
import { useContext } from "react";
import { AuthContext } from "../../../../shared/context/auth-context";

function UpcomingAppointmentsComponent({ clienteId, sendRequest }) {
  const [upcommingAppointments, setUpcommingAppointments] = useState([]);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchProximosAgendamentos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/proximosagendamentos/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/proximosagendamentos/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseDataConsultas = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/proximosagendamentos/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        let tempAppointments = [];

        responseData.marcacoes.forEach((mr) => {
          tempAppointments.push({
            data: moment(mr.data_inicio_execucao).format("DD-MM-YY"),
            horaInicio: moment(mr.data_inicio_execucao).format("HH:mm"),
            horaFim: moment(mr.data_fim_execucao).format("HH:mm"),
            data_completa: mr.data_inicio_execucao,
            tratamento: mr.avaliacao
              ? "Avaliação"
              : mr.categoriaTratamento.categoria,
            medico: { id: mr.medico._id, nome: mr.medico.name },
            agendadoPor: { id: mr.agendadoPor._id, nome: mr.agendadoPor.name },
          });
        });

        responseData2.marcacoes.forEach((mr) => {
          tempAppointments.push({
            data: moment(mr.data_inicio_execucao).format("DD-MM-YY"),
            horaInicio: moment(mr.data_inicio_execucao).format("HH:mm"),
            horaFim: moment(mr.data_fim_execucao).format("HH:mm"),
            data_completa: mr.data_inicio_execucao,
            tratamento: mr.categoriaTratamento.categoria,
            medico: { id: mr.medico._id, nome: mr.medico.name },
            agendadoPor: { id: mr.agendadoPor._id, nome: mr.agendadoPor.name },
          });
        });

        responseDataConsultas.marcacoes.forEach((consulta) => {
          tempAppointments.push({
            data: moment(consulta.data_inicio).format("DD-MM-YY"),
            horaInicio: moment(consulta.data_inicio).format("HH:mm"),
            horaFim: moment(consulta.data_fim).format("HH:mm"),
            data_completa: consulta.data_inicio,
            tratamento: "Consulta",
            medico: {
              id: consulta.medico_responsavel._id,
              nome: consulta.medico_responsavel.name,
            },
            agendadoPor: {
              id: consulta.agendadoPor._id,
              nome: consulta.agendadoPor.name,
            },
          });
        });

        tempAppointments = tempAppointments.sort((a, b) => {
          return new Date(a.data_completa) - new Date(b.data_completa);
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        let temp = [];

        if (auth.role === "Dentista" || auth.role === "Médico/Administrador") {
          temp = tempAppointments.filter((ap) => ap.medico.id === auth.userId);
        } else if (
          auth.role === "Secretária(o)" ||
          auth.role === "Assistente"
        ) {
          tempAppointments.forEach((ap) => {
            auth.usuariosAssociados.forEach((medico) => {
              if (medico.id === ap.medico.id) temp.push(ap);
            });
          });
        } else if (auth.role === "Gerente") {
          temp = [...tempAppointments];
        }

        setUpcommingAppointments(temp);
      } catch (err) {}
    };
    fetchProximosAgendamentos();
  }, [sendRequest, clienteId, auth]);

  return (
    <>
      {upcommingAppointments.length !== 0 && (
        <>
          <ListUpcomingAppointments agendamentos={upcommingAppointments} />
        </>
      )}
      {upcommingAppointments.length === 0 && (
        <div className="no_data_div">Nenhum agendamento futuro.</div>
      )}
    </>
  );
}

export default UpcomingAppointmentsComponent;
