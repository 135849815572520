import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";
import toast from "react-hot-toast";
import XLSX from "xlsx-js-style";

// Material UI imports
import {
  Paper,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import PercentIcon from "@mui/icons-material/Percent";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PersonIcon from "@mui/icons-material/Person";
import MoneyIcon from "@mui/icons-material/Money";
import { Link } from "react-router-dom";

// Para gráficos
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
} from "recharts";

// Configurar locale do moment para português
moment.locale("pt-br");

// Cores para gráfico
const COLORS = ["#4caf50", "#ff9800", "#2196f3", "#9c27b0", "#f44336"];
const STATUS_COLORS = {
  aprovado: "#4caf50",
  pendente: "#ff9800",
};

// Componentes estilizados
const SummaryCard = styled(Card)(({ theme, cardtype }) => ({
  height: "100%",
  backgroundColor:
    cardtype === "aprovado"
      ? "rgba(76, 175, 80, 0.08)"
      : cardtype === "pendente"
      ? "rgba(255, 152, 0, 0.08)"
      : cardtype === "inps"
      ? "rgba(33, 150, 243, 0.08)"
      : cardtype === "particular"
      ? "rgba(156, 39, 176, 0.08)"
      : "rgba(33, 150, 243, 0.08)",
  borderLeft: `4px solid ${
    cardtype === "aprovado"
      ? theme.palette.success.main
      : cardtype === "pendente"
      ? theme.palette.warning.main
      : cardtype === "inps"
      ? theme.palette.info.main
      : cardtype === "particular"
      ? theme.palette.secondary.main
      : theme.palette.primary.main
  }`,
}));

const TrendIndicator = styled(Box)(({ theme, trend }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor:
    trend === "positivo"
      ? "rgba(76, 175, 80, 0.1)"
      : trend === "negativo"
      ? "rgba(244, 67, 54, 0.1)"
      : "rgba(255, 152, 0, 0.1)",
  color:
    trend === "positivo"
      ? theme.palette.success.main
      : trend === "negativo"
      ? theme.palette.error.main
      : theme.palette.warning.main,
}));

function RelatorioAprovacoesOrcamentos({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [atrasadosPage, setAtrasadosPage] = useState(0);
  const [atrasadosRowsPerPage, setAtrasadosRowsPerPage] = useState(10);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada, execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes]);

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      const formData = new FormData();
      formData.append("dataInicio", dataInicialFormatada);
      formData.append("dataFim", dataFinalFormatada);

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/gerarRelatorioAprovacoes/${dadosClinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData);
    } catch (err) {
      console.error(
        "Erro ao gerar relatório de aprovações de orçamentos:",
        err
      );
      setError(
        err.message || "Ocorreu um erro ao gerar o relatório de aprovações."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = () => {
    if (!dados) {
      toast.error("É necessário gerar o relatório primeiro!");
      return;
    }

    try {
      // Criar um novo workbook
      const wb = XLSX.utils.book_new();

      // Criar planilha de resumo
      const resumoData = [
        ["RELATÓRIO DE APROVAÇÕES DE ORÇAMENTOS"],
        [`Período: ${dados.periodo.inicio} a ${dados.periodo.fim}`],
        [`Clínica: ${dadosClinica?.nome || ""}`],
        [],
        ["RESUMO GERAL"],
        ["Total de Orçamentos", dados.resumo.totalOrcamentos],
        [
          "Orçamentos Aprovados",
          dados.resumo.aprovados,
          `${dados.resumo.percentualAprovados}%`,
        ],
        [
          "Orçamentos Pendentes",
          dados.resumo.pendentes,
          `${dados.resumo.percentualPendentes}%`,
        ],
        [
          "Tempo Médio de Aprovação",
          `${dados.resumo.tempoMedioAprovacao} dias`,
        ],
        ["Orçamentos Atrasados (>30 dias)", dados.resumo.orcamentosAtrasados],
        [],
        ["VALORES"],
        [
          "Valor Total Aprovados",
          dados.resumo.valorTotalAprovados.toLocaleString("pt-BR"),
        ],
        [
          "Valor Total Pendentes",
          dados.resumo.valorTotalPendentes.toLocaleString("pt-BR"),
        ],
        [
          "Valor Médio Aprovados",
          dados.resumo.valorMedioAprovados.toLocaleString("pt-BR"),
        ],
        [
          "Valor Médio Pendentes",
          dados.resumo.valorMedioPendentes.toLocaleString("pt-BR"),
        ],
        [],
        ["COMPARATIVO POR TIPO DE BENEFÍCIO"],
        ["INPS - Total", dados.beneficios.inps.total],
        [
          "INPS - Aprovados",
          dados.beneficios.inps.aprovados,
          `${dados.beneficios.inps.percentualAprovados}%`,
        ],
        [
          "INPS - Valor Médio",
          dados.beneficios.inps.valorMedioAprovados.toLocaleString("pt-BR"),
        ],
        ["Particular - Total", dados.beneficios.particular.total],
        [
          "Particular - Aprovados",
          dados.beneficios.particular.aprovados,
          `${dados.beneficios.particular.percentualAprovados}%`,
        ],
        [
          "Particular - Valor Médio",
          dados.beneficios.particular.valorMedioAprovados.toLocaleString(
            "pt-BR"
          ),
        ],
        [],
        ["COMPARATIVO COM PERÍODO ANTERIOR"],
        ["Período Anterior", dados.comparativo.periodoAnterior],
        [
          "Taxa de Aprovação Anterior",
          `${dados.comparativo.taxaAprovacaoAnterior.toFixed(2)}%`,
        ],
        [
          "Taxa de Aprovação Atual",
          `${dados.comparativo.taxaAprovacaoAtual.toFixed(2)}%`,
        ],
        [
          "Variação",
          `${dados.comparativo.variacao > 0 ? "+" : ""}${
            dados.comparativo.variacao
          }%`,
        ],
        ["Tendência", dados.comparativo.tendencia],
      ];

      const wsResumo = XLSX.utils.aoa_to_sheet(resumoData);

      // Criar planilha para análise por faixas de preço
      const faixasHeader = [
        [
          "Faixa de Preço (CVE)",
          "Total de Orçamentos",
          "Aprovados",
          "Pendentes",
          "Taxa de Aprovação (%)",
        ],
      ];

      const faixasData = dados.analiseFaixasPreco.map((faixa) => [
        faixa.faixa,
        faixa.total,
        faixa.aprovados,
        faixa.pendentes,
        faixa.taxaAprovacao,
      ]);

      const wsFaixas = XLSX.utils.aoa_to_sheet([
        ...faixasHeader,
        ...faixasData,
      ]);

      // Criar planilha com detalhes dos orçamentos
      const orcamentosHeader = [
        [
          "#",
          "Código",
          "Cliente",
          "Data",
          "Status",
          "Dias Pendente",
          "Valor",
          "Benefício",
          "Médico",
          "Observações",
        ],
      ];

      const orcamentosData = dados.orcamentos.map((orc, index) => [
        index + 1,
        orc.codigo,
        orc.cliente,
        moment(orc.data).format("DD/MM/YYYY"),
        orc.status === "aprovado" ? "Aprovado" : "Pendente",
        orc.status === "aprovado" ? 0 : orc.diasPendente,
        orc.valor,
        orc.beneficio,
        orc.medico,
        orc.observacoes,
      ]);

      const wsOrcamentos = XLSX.utils.aoa_to_sheet([
        ...orcamentosHeader,
        ...orcamentosData,
      ]);

      // Criar planilha para orçamentos atrasados
      const atrasadosHeader = [
        [
          "#",
          "Código",
          "Cliente",
          "Data de Criação",
          "Dias Pendente",
          "Valor",
          "Benefício",
          "Médico",
        ],
      ];

      const atrasadosData = dados.orcamentosAtrasados.map((orc, index) => [
        index + 1,
        orc.codigo,
        orc.cliente,
        moment(orc.data).format("DD/MM/YYYY"),
        orc.diasPendente,
        orc.valor,
        orc.beneficio,
        orc.medico,
      ]);

      const wsAtrasados = XLSX.utils.aoa_to_sheet([
        ...atrasadosHeader,
        ...atrasadosData,
      ]);

      // Aplicar estilos
      // Título
      wsResumo["A1"].s = {
        font: { bold: true, sz: 16 },
        alignment: { horizontal: "center" },
      };

      // Cabeçalhos de seção
      wsResumo["A5"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A12"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A17"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };
      wsResumo["A24"].s = {
        font: { bold: true, sz: 14 },
        fill: { fgColor: { rgb: "E6E6E6" } },
      };

      // Cabeçalhos de tabelas
      for (let i = 0; i < faixasHeader[0].length; i++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: i });
        wsFaixas[cell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } },
          alignment: { horizontal: "center" },
        };
      }

      for (let i = 0; i < orcamentosHeader[0].length; i++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: i });
        wsOrcamentos[cell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } },
          alignment: { horizontal: "center" },
        };
      }

      for (let i = 0; i < atrasadosHeader[0].length; i++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: i });
        wsAtrasados[cell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } },
          alignment: { horizontal: "center" },
        };
      }

      // Adicionar planilhas ao workbook
      XLSX.utils.book_append_sheet(wb, wsResumo, "Resumo");
      XLSX.utils.book_append_sheet(wb, wsFaixas, "Análise por Faixas de Preço");
      XLSX.utils.book_append_sheet(wb, wsAtrasados, "Orçamentos Atrasados");
      XLSX.utils.book_append_sheet(wb, wsOrcamentos, "Todos os Orçamentos");

      // Configurar tamanhos de coluna
      wsResumo["!cols"] = [{ wch: 30 }, { wch: 15 }, { wch: 15 }];
      wsFaixas["!cols"] = [
        { wch: 20 }, // Faixa
        { wch: 15 }, // Total
        { wch: 15 }, // Aprovados
        { wch: 15 }, // Pendentes
        { wch: 20 }, // Taxa
      ];
      wsOrcamentos["!cols"] = [
        { wch: 5 }, // #
        { wch: 15 }, // Código
        { wch: 30 }, // Cliente
        { wch: 12 }, // Data
        { wch: 12 }, // Status
        { wch: 12 }, // Dias Pendente
        { wch: 15 }, // Valor
        { wch: 15 }, // Benefício
        { wch: 20 }, // Médico
        { wch: 40 }, // Observações
      ];
      wsAtrasados["!cols"] = [
        { wch: 5 }, // #
        { wch: 15 }, // Código
        { wch: 30 }, // Cliente
        { wch: 12 }, // Data
        { wch: 12 }, // Dias Pendente
        { wch: 15 }, // Valor
        { wch: 15 }, // Benefício
        { wch: 20 }, // Médico
      ];

      // Configurar merges
      wsResumo["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // Título
        { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } }, // Período
        { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } }, // Clínica
      ];

      // Salvar o arquivo
      XLSX.writeFile(
        wb,
        `aprovacoes_orcamentos_${moment().format("YYYY-MM-DD")}.xlsx`
      );

      toast.success("Arquivo exportado com sucesso!");
    } catch (err) {
      console.error("Erro ao exportar arquivo:", err);
      toast.error("Erro ao exportar arquivo");
    }
  };

  // Handlers para tabs
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handlers para paginação
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handlers para paginação de orçamentos atrasados
  const handleAtrasadosChangePage = (event, newPage) => {
    setAtrasadosPage(newPage);
  };

  const handleAtrasadosChangeRowsPerPage = (event) => {
    setAtrasadosRowsPerPage(parseInt(event.target.value, 10));
    setAtrasadosPage(0);
  };

  // Prepara dados para o gráfico de status de orçamentos
  const prepareStatusData = () => {
    if (!dados?.resumo) return [];

    return [
      {
        name: "Aprovados",
        value: dados.resumo.aprovados,
        color: STATUS_COLORS.aprovado,
      },
      {
        name: "Pendentes",
        value: dados.resumo.pendentes,
        color: STATUS_COLORS.pendente,
      },
    ];
  };

  // Prepara dados para o gráfico de valor médio por status
  const prepareBeneficioData = () => {
    if (!dados?.beneficios) return [];

    return [
      {
        name: "INPS",
        aprovados: dados.beneficios.inps.aprovados,
        pendentes: dados.beneficios.inps.pendentes,
        total: dados.beneficios.inps.total,
        taxaAprovacao: dados.beneficios.inps.percentualAprovados,
      },
      {
        name: "Particular",
        aprovados: dados.beneficios.particular.aprovados,
        pendentes: dados.beneficios.particular.pendentes,
        total: dados.beneficios.particular.total,
        taxaAprovacao: dados.beneficios.particular.percentualAprovados,
      },
    ];
  };

  // Prepara dados para o gráfico de análise por faixa de preço
  const prepareFaixasPrecoData = () => {
    if (!dados?.analiseFaixasPreco) return [];
    return dados.analiseFaixasPreco;
  };

  // Formatação de moeda
  const formatCurrency = (value) => {
    return (
      value?.toLocaleString("pt-BR", {
        style: "currency",
        currency: "CVE",
      }) || "0 CVE"
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Aprovações de Orçamentos
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1">
            Clínica: {dadosClinica?.nome || ""}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && (
          <>
            {/* Cards de resumo */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="aprovado">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                      <Typography variant="h6">Aprovados</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="success.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {dados.resumo.aprovados}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {dados.resumo.percentualAprovados}% do total
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Valor médio:{" "}
                      {formatCurrency(dados.resumo.valorMedioAprovados)}
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="pendente">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PendingIcon color="warning" sx={{ mr: 1 }} />
                      <Typography variant="h6">Pendentes</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="warning.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {dados.resumo.pendentes}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {dados.resumo.percentualPendentes}% do total
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {dados.resumo.orcamentosAtrasados} atrasados{" "}
                      {"(>30 dias)"}
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="inps">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <MedicalServicesIcon color="info" sx={{ mr: 1 }} />
                      <Typography variant="h6">INPS</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="info.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {dados.beneficios.inps.percentualAprovados}%
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Taxa de aprovação
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Tempo médio: {dados.beneficios.inps.tempoMedioAprovacao}{" "}
                      dias
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SummaryCard cardtype="particular">
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <PersonIcon color="secondary" sx={{ mr: 1 }} />
                      <Typography variant="h6">Particular</Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="secondary.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {dados.beneficios.particular.percentualAprovados}%
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Taxa de aprovação
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Tempo médio:{" "}
                      {dados.beneficios.particular.tempoMedioAprovacao} dias
                    </Typography>
                  </CardContent>
                </SummaryCard>
              </Grid>
            </Grid>

            {/* Segunda linha de cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <ScheduleIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">
                        Tempo Médio de Aprovação
                      </Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="primary.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {dados.resumo.tempoMedioAprovacao} dias
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TrendIndicator
                        trend={
                          dados.comparativo.variacao >= 0
                            ? "positivo"
                            : "negativo"
                        }
                      >
                        {dados.comparativo.variacao >= 0 ? "+" : ""}
                        {dados.comparativo.variacao}%
                      </TrendIndicator>
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      Taxa de aprovação vs. período anterior
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <MoneyIcon color="primary" sx={{ mr: 1 }} />
                      <Typography variant="h6">
                        Valor Total de Orçamentos
                      </Typography>
                    </Box>
                    <Typography
                      variant="h4"
                      component="div"
                      color="primary.main"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      {formatCurrency(
                        dados.resumo.valorTotalAprovados +
                          dados.resumo.valorTotalPendentes
                      )}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Aprovados:{" "}
                      {formatCurrency(dados.resumo.valorTotalAprovados)} (
                      {Math.round(
                        (dados.resumo.valorTotalAprovados /
                          (dados.resumo.valorTotalAprovados +
                            dados.resumo.valorTotalPendentes)) *
                          100
                      )}
                      %)
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Pendentes:{" "}
                      {formatCurrency(dados.resumo.valorTotalPendentes)} (
                      {Math.round(
                        (dados.resumo.valorTotalPendentes /
                          (dados.resumo.valorTotalAprovados +
                            dados.resumo.valorTotalPendentes)) *
                          100
                      )}
                      %)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Abas para as diferentes análises */}
            <Box sx={{ width: "100%", mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="Visão Geral" />
                <Tab label="Análise por Valor" />
                <Tab label="Orçamentos Atrasados" />
                <Tab label="Todos os Orçamentos" />
              </Tabs>
            </Box>

            {/* Conteúdo da aba Visão Geral */}
            {tabValue === 0 && (
              <>
                <Grid container spacing={3} sx={{ mb: 4 }}>
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                      <Typography variant="h6" gutterBottom>
                        Status dos Orçamentos
                      </Typography>
                      <Box sx={{ height: 300 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={prepareStatusData()}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                              label={({
                                cx,
                                cy,
                                midAngle,
                                innerRadius,
                                outerRadius,
                                percent,
                                name,
                              }) => {
                                const radius =
                                  innerRadius +
                                  (outerRadius - innerRadius) * 0.5;
                                const x =
                                  cx +
                                  radius *
                                    Math.cos(-midAngle * (Math.PI / 180));
                                const y =
                                  cy +
                                  radius *
                                    Math.sin(-midAngle * (Math.PI / 180));
                                return (
                                  <text
                                    x={x}
                                    y={y}
                                    fill="#fff"
                                    textAnchor={x > cx ? "start" : "end"}
                                    dominantBaseline="central"
                                  >
                                    {`${name} ${(percent * 100).toFixed(0)}%`}
                                  </text>
                                );
                              }}
                            >
                              {prepareStatusData().map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={entry.color}
                                />
                              ))}
                            </Pie>
                            <Legend
                              layout="vertical"
                              verticalAlign="middle"
                              align="right"
                            />
                            <RechartsTooltip />
                          </PieChart>
                        </ResponsiveContainer>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                      <Typography variant="h6" gutterBottom>
                        Análise por Benefício
                      </Typography>
                      <Box sx={{ height: 300 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={prepareBeneficioData()}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <RechartsTooltip />
                            <Legend />
                            <Bar dataKey="total" name="Total" fill="#8884d8" />
                            <Bar
                              dataKey="aprovados"
                              name="Aprovados"
                              fill="#4caf50"
                            />
                            <Bar
                              dataKey="pendentes"
                              name="Pendentes"
                              fill="#ff9800"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>

                <Paper sx={{ p: 2, mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Comparativo com Período Anterior
                  </Typography>

                  <Box
                    sx={{
                      p: 2,
                      bgcolor: "background.paper",
                      borderRadius: 1,
                      mb: 2,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2" color="text.secondary">
                          Período Anterior
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                          {dados.comparativo.periodoAnterior}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="text.secondary">
                          Taxa de Aprovação Anterior
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                          {dados.comparativo.taxaAprovacaoAnterior.toFixed(2)}%
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="body2" color="text.secondary">
                          Taxa de Aprovação Atual
                        </Typography>
                        <Typography variant="body1" fontWeight="bold">
                          {dados.comparativo.taxaAprovacaoAtual.toFixed(2)}%
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="body2" color="text.secondary">
                          Variação
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {dados.comparativo.variacao > 0 ? (
                            <TrendingUpIcon
                              color="success"
                              fontSize="small"
                              sx={{ mr: 0.5 }}
                            />
                          ) : dados.comparativo.variacao < 0 ? (
                            <TrendingDownIcon
                              color="error"
                              fontSize="small"
                              sx={{ mr: 0.5 }}
                            />
                          ) : null}
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color={
                              dados.comparativo.variacao > 0
                                ? "success.main"
                                : dados.comparativo.variacao < 0
                                ? "error.main"
                                : "text.primary"
                            }
                          >
                            {dados.comparativo.variacao > 0 ? "+" : ""}
                            {dados.comparativo.variacao}%
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </>
            )}

            {/* Conteúdo da aba Análise por Valor */}
            {tabValue === 1 && (
              <Paper sx={{ p: 2, mb: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Taxa de Aprovação por Faixa de Preço
                </Typography>
                <Box sx={{ height: 400 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={prepareFaixasPrecoData()}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="faixa" />
                      <YAxis
                        yAxisId="left"
                        orientation="left"
                        stroke="#8884d8"
                      />
                      <YAxis
                        yAxisId="right"
                        orientation="right"
                        stroke="#82ca9d"
                      />
                      <RechartsTooltip />
                      <Legend />
                      <Bar
                        yAxisId="left"
                        dataKey="total"
                        name="Total de Orçamentos"
                        fill="#8884d8"
                      />
                      <Bar
                        yAxisId="left"
                        dataKey="aprovados"
                        name="Orçamentos Aprovados"
                        fill="#4caf50"
                      />
                      <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="taxaAprovacao"
                        name="Taxa de Aprovação (%)"
                        stroke="#ff7300"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" gutterBottom>
                  Detalhes por Faixa de Preço
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Faixa de Preço (CVE)</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Aprovados</TableCell>
                        <TableCell align="right">Pendentes</TableCell>
                        <TableCell align="right">Taxa de Aprovação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dados.analiseFaixasPreco.map((faixa, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{faixa.faixa}</TableCell>
                          <TableCell align="right">{faixa.total}</TableCell>
                          <TableCell align="right">{faixa.aprovados}</TableCell>
                          <TableCell align="right">{faixa.pendentes}</TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color:
                                parseFloat(faixa.taxaAprovacao) > 50
                                  ? "success.main"
                                  : parseFloat(faixa.taxaAprovacao) < 30
                                  ? "error.main"
                                  : "warning.main",
                              fontWeight: "bold",
                            }}
                          >
                            {faixa.taxaAprovacao}%
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {/* Conteúdo da aba Orçamentos Atrasados */}
            {tabValue === 2 && (
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Orçamentos Pendentes há Mais de 30 Dias (
                  {dados.orcamentosAtrasados.length})
                </Typography>

                {dados.orcamentosAtrasados.length === 0 ? (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    Não há orçamentos pendentes há mais de 30 dias. Parabéns!
                  </Alert>
                ) : (
                  <>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Código</TableCell>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Data Criação</TableCell>
                            <TableCell align="right">Dias Pendente</TableCell>
                            <TableCell align="right">Valor</TableCell>
                            <TableCell align="right">Benefício</TableCell>
                            <TableCell align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dados.orcamentosAtrasados
                            .slice(
                              atrasadosPage * atrasadosRowsPerPage,
                              atrasadosPage * atrasadosRowsPerPage +
                                atrasadosRowsPerPage
                            )
                            .map((orcamento, index) => (
                              <TableRow
                                key={orcamento.id}
                                hover
                                sx={{
                                  backgroundColor:
                                    orcamento.diasPendente > 60
                                      ? "rgba(244, 67, 54, 0.04)"
                                      : orcamento.diasPendente > 45
                                      ? "rgba(255, 152, 0, 0.04)"
                                      : "rgba(255, 235, 59, 0.04)",
                                }}
                              >
                                <TableCell>{orcamento.codigo}</TableCell>
                                <TableCell>{orcamento.cliente}</TableCell>
                                <TableCell>
                                  {moment(orcamento.data).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    fontWeight: "bold",
                                    color:
                                      orcamento.diasPendente > 60
                                        ? "error.main"
                                        : orcamento.diasPendente > 45
                                        ? "warning.main"
                                        : "text.secondary",
                                  }}
                                >
                                  {orcamento.diasPendente}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(orcamento.valor)}
                                </TableCell>
                                <TableCell align="right">
                                  {orcamento.beneficio}
                                </TableCell>
                                <TableCell align="center">
                                  <Link to={`/orcamentos/${orcamento.id}`}>
                                    <ArrowForwardIcon fontSize="small" />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={dados.orcamentosAtrasados.length}
                      rowsPerPage={atrasadosRowsPerPage}
                      page={atrasadosPage}
                      onPageChange={handleAtrasadosChangePage}
                      onRowsPerPageChange={handleAtrasadosChangeRowsPerPage}
                      labelRowsPerPage="Linhas por página:"
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${count}`
                      }
                    />
                  </>
                )}
              </Paper>
            )}

            {/* Conteúdo da aba Todos os Orçamentos */}
            {tabValue === 3 && (
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Todos os Orçamentos do Período
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Dias Pendente</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell align="right">Benefício</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dados.orcamentos
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((orcamento) => (
                          <TableRow
                            key={orcamento.id}
                            hover
                            sx={{
                              backgroundColor:
                                orcamento.status === "aprovado"
                                  ? "rgba(76, 175, 80, 0.04)"
                                  : orcamento.diasPendente > 30
                                  ? "rgba(255, 152, 0, 0.04)"
                                  : "inherit",
                            }}
                          >
                            <TableCell>{orcamento.codigo}</TableCell>
                            <TableCell>{orcamento.cliente}</TableCell>
                            <TableCell>
                              {moment(orcamento.data).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    orcamento.status === "aprovado"
                                      ? "success.main"
                                      : "warning.main",
                                }}
                              >
                                {orcamento.status === "aprovado" ? (
                                  <CheckCircleIcon
                                    sx={{ mr: 0.5, fontSize: 16 }}
                                  />
                                ) : (
                                  <PendingIcon sx={{ mr: 0.5, fontSize: 16 }} />
                                )}
                                {orcamento.status === "aprovado"
                                  ? "Aprovado"
                                  : "Pendente"}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                fontWeight:
                                  orcamento.diasPendente > 30
                                    ? "bold"
                                    : "normal",
                                color:
                                  orcamento.diasPendente > 30
                                    ? "warning.main"
                                    : "text.secondary",
                              }}
                            >
                              {orcamento.status === "aprovado"
                                ? "-"
                                : orcamento.diasPendente}
                            </TableCell>
                            <TableCell align="right">
                              {formatCurrency(orcamento.valor)}
                            </TableCell>
                            <TableCell align="right">
                              {orcamento.beneficio}
                            </TableCell>
                            <TableCell align="center">
                              <Link to={`/orcamentos/${orcamento.id}`}>
                                <ArrowForwardIcon fontSize="small" />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dados.orcamentos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Linhas por página:"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                />
              </Paper>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioAprovacoesOrcamentos;
