import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";

import { useLoading } from "../../../../shared/context/LoadingContext";

import FiltrosRelatorio from "../ui/filtrosRelatorio/FiltrosRelatorio";
import RelatorioMapaVenda from "./components/RelatorioMapaVenda";
import RelatorioFluxoCaixa from "./components/RelatorioFluxoCaixa";

import toast from "react-hot-toast";
import RelatorioDashboardFinanceiro from "./components/RelatorioDashboardFinanceiro";
import RelatorioContasPagarReceber from "./components/RelatorioContasPagarReceber";
import RelatorioFaturamento from "./components/RelatorioFaturamento";

import "./RelatoriosFinanceiro.scss";

const tiposRelatorios = [
  { value: "", label: "Selecione um relatório" },
  { value: "mapaVenda", label: "Mapa de Venda" },
  { value: "fluxo-caixa", label: "Relatório de Fluxo de Caixa" },
  {
    value: "dashboard-financeiro",
    label: "Relatório de Dashboard Financeiro",
  },
  {
    value: "contas-pagar-receber",
    label: "Relatório de Contas a Pagar e Receber",
  },
  {
    value: "faturamento",
    label: "Relatório de Faturamento",
  },
  // {
  //   value: "categorias-despesas",
  //   label: "Relatório por Categorias de Despesas",
  // },
  // { value: "inadimplencia", label: "Relatório de Inadimplência" },
  // { value: "anual-financeiro", label: "Relatório Anual Financeiro" },
];

// Definição de quais relatórios suportam quais ações
const relatorioCaps = {
  mapaVenda: { gerar: true, exportar: false },
  "fluxo-caixa": { gerar: true, exportar: true },
  "contas-pagar-receber": { gerar: true, exportar: false },
  "dashboard-financeiro": { gerar: true, exportar: false },
  faturamento: { gerar: true, exportar: false },
  // "categorias-despesas": { gerar: true, exportar: true },
  // inadimplencia: { gerar: false, exportar: true },
  // "anual-financeiro": { gerar: true, exportar: true },
};

function RelatoriosFinanceiro({ clinicaId, dadosMapa, auth }) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dadosClinica, setDadosClinica] = useState();
  const [dadosRelatorio, setDadosRelatorio] = useState({
    open: "",
    dataFiltro: {},
    acao: "",
    //Contador para forçar a atualização
    atualizacoes: 0,
  });

  useEffect(() => {
    const fetchDadosClinica = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosClinica(responseData.clinica);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchDadosClinica();
  }, [auth.perm, auth.token, clinicaId, sendRequest]);

  const gerarRelatorioHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      toast.error("Por favor, selecione um tipo de relatório.");
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "gerar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  const exportarFicheiroHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      toast.error("Por favor, selecione um tipo de relatório.");
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "exportar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  // Função auxiliar para renderizar o componente de relatório correto
  const renderizarRelatorio = () => {
    const { open, dataFiltro, acao, atualizacoes } = dadosRelatorio;

    if (!open) return null;

    const props = {
      dataFiltro: dataFiltro,
      dadosClinica: dadosClinica,
      auth: auth,
      acao: acao, // Passa a ação para o componente
      atualizacoes: atualizacoes,
    };

    switch (open) {
      case "mapaVenda":
        return <RelatorioMapaVenda {...props} dadosMapa={dadosMapa} />;
      case "fluxo-caixa":
        return <RelatorioFluxoCaixa {...props} />;
      case "dashboard-financeiro":
        return <RelatorioDashboardFinanceiro {...props} />;
      case "contas-pagar-receber":
        return <RelatorioContasPagarReceber {...props} />;
      case "faturamento":
        return <RelatorioFaturamento {...props} />;
      // case "categorias-despesas":
      //   return <RelatorioCategoriasDespesas {...props} />;
      // case "inadimplencia":
      //   return <RelatorioInadimplencia {...props} />;
      // case "anual-financeiro":
      //   return <RelatorioAnualFinanceiro {...props} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <FiltrosRelatorio
        tiposRelatorios={tiposRelatorios}
        gerarRelatorioHandler={gerarRelatorioHandler}
        exportarFicheiroHandler={exportarFicheiroHandler}
        relatorioCaps={relatorioCaps} // Passar as capacidades para potencialmente desabilitar botões
        // Manter o tipo de relatório selecionado
        relatorioSelecionado={dadosRelatorio.open}
      />

      {renderizarRelatorio()}
    </div>
  );
}

export default RelatoriosFinanceiro;
