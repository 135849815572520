import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import ElementoOrcamento from "../components/Elemento/ElementoOrcamento";
import ElementoOrcamentoOrcamentados from "../components/Elemento/ElementoOrcamentoOrcamentados";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"; //gerar Orcamento
import validarOrcamento from "../helperFunctions/validation";
import toast from "react-hot-toast";

import { useLoading } from "../../../shared/context/LoadingContext";

import "../../../shared/css/PlanoAvaliacaoOrcamento.scss";
import "../../../style/formError.scss";
import { ClinicaContext } from "../../../shared/context/clinica-context";
const moment = require("moment");
const OPCOES_BENEFICIO = ["Particular", "INPS"];

function NewOrcamento() {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avaliacao, setAvaliacao] = useState();
  const [serviceList, setServiceList] = useState();
  const [procedimentosOrcamentados, setProcedimentosOrcamentados] = useState();
  const [beneficio, setBeneficio] = useState("Particular");
  const [subtotal, setSubtotal] = useState();
  const [total, setTotal] = useState();
  const [desconto, setDesconto] = useState(0);
  const [tipoDesconto, setTipoDesconto] = useState("$");
  const [contaCorrente, setContaCorrente] = useState();
  const [observacoes, setObservacoes] = useState();
  const [nrTratamentosInps, setNrTratamentosInps] = useState();
  const [nrTratamentosNaoInps, setNrTratamentosNaoInps] = useState();
  const [nrTratamentosInpsFixed, setNrTratamentosInpsFixed] = useState();
  const [nrTratamentosNaoInpsFixed, setNrTratamentosNaoInpsFixed] = useState();
  const [orcamentoIsentoPagamento, setOrcamentoIsentoPagamento] =
    useState(false);
  const [caixas, setCaixas] = useState([]);
  const { sendRequest } = useHttpClient();
  const avaliacaoId = useParams().avaliacaoId;
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAvaliacaoContaCorrenteCaixas = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${avaliacaoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        try {
          const responseCaixas = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          );
          setCaixas(responseCaixas.caixas);
        } catch (err) {
          console.error("err", err);
        }

        const newProcedimentosList =
          responseData.avaliacao.procedimentos.filter(
            (pr) => pr.orcamentado === false
          );

        setProcedimentosOrcamentados(
          responseData.avaliacao.procedimentos.filter(
            (pr) => pr.orcamentado === true
          )
        );

        let nrCobertosInps = 0;
        let nrNaoCobertosInps = 0;
        const newServiceList = newProcedimentosList.map((prc) => {
          if (prc.tratamento.coberturaInps) nrCobertosInps += 1;
          else nrNaoCobertosInps += 1;
          return { ...prc, show: true };
        });

        setNrTratamentosInps(nrCobertosInps);
        setNrTratamentosNaoInps(nrNaoCobertosInps);
        setNrTratamentosInpsFixed(nrCobertosInps);
        setNrTratamentosNaoInpsFixed(nrNaoCobertosInps);

        setSubtotal(
          newServiceList.reduce((acc, el) => {
            return acc + el.tratamento[`preco_particular`];
          }, 0)
        );

        setTotal(
          newServiceList.reduce((acc, el) => {
            return acc + el.tratamento.preco_particular;
          }, 0)
        );

        setAvaliacao(responseData.avaliacao);
        setServiceList(newServiceList);

        let response;

        try {
          response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cliente/${responseData.avaliacao.cliente.id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        setContaCorrente(response.contaCorrente);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchAvaliacaoContaCorrenteCaixas();
  }, [sendRequest, avaliacaoId, auth.token]);

  const submitHandler = async (contaAprovada) => {
    startLoading();
    try {
      const filteredServiceList = [];
      //////////////Orcamento///////////////
      ////////////////////////////////////
      const formErrorMessage = validarOrcamento(serviceList);

      if (!formErrorMessage) {
        serviceList.forEach((service) => {
          if (service.show) {
            filteredServiceList.push({
              id: service.id,
              tratamento: service.tratamento.id, //
              dente: service.dente, //
              quantidade: 1,
              faces: service.faces, //
              pr_total:
                beneficio === "Particular"
                  ? service.tratamento.preco_particular
                  : service.tratamento.coberturaInps
                  ? service.tratamento.preco_beneficiario
                  : service.tratamento.preco_particular,
              iva: 0,
            });
          }
        });
        let avaliacaoCopia = avaliacao;

        serviceList.forEach((ser) => {
          avaliacaoCopia.procedimentos.forEach((avPr) => {
            if (ser.show && avPr.id === ser.id) {
              avPr.orcamentado = true;
            }
          });
        });

        try {
          const formData = new FormData();
          formData.append("cliente", avaliacao.cliente.id);
          formData.append(
            "medico_responsavel",
            avaliacao.medico_responsavel.id
          );
          formData.append("clinica", avaliacao.clinica.id);
          formData.append("avaliacao", avaliacao.id);
          //Para garantir que o beneficio vá como INPS para um orçamento Particular e visse versa
          formData.append(
            "beneficio",
            beneficio === "INPS" && nrTratamentosInps > 0
              ? "INPS"
              : "Particular"
          );
          formData.append("procedimentos", JSON.stringify(filteredServiceList));
          formData.append("total", total);
          formData.append("subtotal", subtotal);
          if (!desconto) {
            formData.append("desconto", 0);
          } else {
            formData.append(
              "desconto",
              tipoDesconto === "$"
                ? desconto
                : Math.round((subtotal * desconto) / 100)
            );
          }
          formData.append("tipoDesconto", tipoDesconto);
          if (tipoDesconto === "%") {
            formData.append("percentagemDescontoOriginal", desconto);
          }
          formData.append("transformado", contaAprovada);
          formData.append("observacoes", observacoes ? observacoes : "");
          formData.append("criadoPor", auth.userId);
          formData.append("ativo", true);
          if (nrTratamentosNaoInps > 0 && beneficio === "INPS") {
            formData.append("orcamentoMisto", true);
          }

          let respostaOrcamento;
          try {
            respostaOrcamento = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          //Atualizando os itens orcamentos na avaliacao
          //Avaliacao fica terminada se todos os procedimentos forem orcamentados de primeira
          const procedimentosOrcamentados = avaliacaoCopia.procedimentos.filter(
            (pr) => pr.orcamentado === true
          );
          const nrDeProcedimentosNaAvaliacao = avaliacao.procedimentos.length;

          const formData2 = new FormData();

          if (
            procedimentosOrcamentados.length === nrDeProcedimentosNaAvaliacao
          ) {
            formData2.append("terminado", true);
            formData2.append("transformado", false);
          }

          if (
            procedimentosOrcamentados.length > 0 &&
            procedimentosOrcamentados.length < nrDeProcedimentosNaAvaliacao
          ) {
            formData2.append("transformado", true);
            formData2.append("terminado", false);
          }

          formData2.append(
            "procedimentos",
            JSON.stringify(avaliacaoCopia.procedimentos)
          );

          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${avaliacao.id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          //Conta Corrente
          ////////////////////
          const parcelas = [];

          if (!orcamentoIsentoPagamento) {
            parcelas.push({
              quantiaPaga: 0,
              dataLimitePagamento: moment().add(
                clinica.clinica.configuracoes.configuracoes.validadeParcela
                  .validade,
                "days"
              ),
              parcelaPaga: false,
              quantiaPorPagar: total,
            });
          }

          if (orcamentoIsentoPagamento) {
            //isento de pagamento, fica com uma parcela apenas
            parcelas.push({
              dataLimitePagamento: moment().add(
                clinica.clinica.configuracoes.configuracoes.validadeParcela
                  .validade,
                "days"
              ),
              parcelaPaga: true,
              quantiaPaga: 0,
              quantiaPorPagar: 0,
              dataPagamento: moment(),
              nrParcela: "1/1",
              caixa: caixas[0]._id,
              observacao: "Parcela de isenção",
              cancelamentosDocumentosEletronicos: [],
            });
          }

          //Criando o objeto conta
          const conta = {
            orcamento: respostaOrcamento.orcamento.id,
            parcelas: parcelas,
            nrParcelas: 1,
            contaLiquidada: orcamentoIsentoPagamento ? true : false,
            total: total,
            data_criacao: new Date(),
            aprovado: contaAprovada,
          };

          const formData3 = new FormData();
          //Caso o cliente nao tem conta corrente, ela é criada na base de dados
          if (!contaCorrente) {
            formData3.append("cliente", avaliacao.cliente.id);
            formData3.append("clinica", avaliacao.clinica.id);
            formData3.append("contas", JSON.stringify(conta));
            formData3.append("orcamento", respostaOrcamento.orcamento._id);
            formData3.append("utilizador", auth.userId);
            formData3.append("contaAprovada", contaAprovada);
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes`,
                "POST",
                formData3,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          } else {
            //Caso o cliente ja tem conta corrente, ela é atualizada
            formData3.append(
              "contas",
              JSON.stringify([...contaCorrente.contas, conta])
            );
            formData3.append("orcamento", respostaOrcamento.orcamento._id);
            formData3.append("utilizador", auth.userId);
            formData3.append("contaAprovada", contaAprovada);
            formData3.append("operacao", "CRIACAO_ORCAMENTO");
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
                "PATCH",
                formData3,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }

          //Guardando o plano de tratamento
          if (contaAprovada) {
            const procedimentosFiltrados = [];

            filteredServiceList.forEach((service) => {
              procedimentosFiltrados.push({
                tratamento: service.tratamento,
                dente: service.dente,
                faces: service.faces,
                medico: avaliacao.medico_responsavel.id,
                procedimento_completo: false,
              });
            });

            const formData = new FormData();
            formData.append(
              "procedimentos",
              JSON.stringify(procedimentosFiltrados)
            );
            formData.append("cliente", avaliacao.cliente.id);
            formData.append("clinica", avaliacao.clinica.id);
            formData.append("orcamento", respostaOrcamento.orcamento.id);
            formData.append(
              "medico_responsavel",
              avaliacao.medico_responsavel.id
            );
            formData.append("criadoPor", auth.userId);

            //Gravando plano de tratamento
            let planoTratamentoCriado;
            try {
              planoTratamentoCriado = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento`,
                "POST",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }

            //Gravando plano de tratamento, transformando orcamento
            const formData2 = new FormData();
            formData2.append("utilizador", auth.userId);
            formData2.append("transformado", true);
            formData2.append(
              "planoTratamento",
              planoTratamentoCriado.planoTratamento._id
            );
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/transformar/${respostaOrcamento.orcamento._id}`,
                "PATCH",
                formData2,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }

          toast.success(
            `Orçamento ${
              desconto === subtotal ? " isento de pagamento " : ""
            } adicionado com sucesso!`
          );
          navigate(`../../../clientes/${avaliacao.cliente.id}-orcamento`);
        } catch (err) {
          console.error("err", err);
        }
      } else {
        toast.error(formErrorMessage);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };

  //funcoes executadas pelas childs
  const removeService = (id) => {
    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });

    const indice = list.indexOf(servico[0]);

    list[indice].show = false;
    setServiceList([...list]);

    //Visto que a funcao é chamada para atribuir um evento onde se necessita do valor do evento
    //Crio um objeto similar ao evento e reaproveito a funcao passando o valor que pretendo usar
    updateValores({ target: { value: beneficio } });

    //Atualizar o numero de tratamentos cobertos e nao cobertos pelo inps na lista
    if (servico[0].tratamento.coberturaInps)
      setNrTratamentosInps((current) => current - 1);
    else setNrTratamentosNaoInps((current) => current - 1);

    //Se não existir mais tratamentos cobertos pelo INPS, o orçamento se transforma num orçamento particular
    if (beneficio === "INPS") {
      let nrCobertos = 0;

      list.forEach((l) => {
        if (l.show) {
          if (l.tratamento.coberturaInps) nrCobertos += 1;
        }
      });

      if (nrCobertos <= 0) {
        updateValores({ target: { value: "Particular" } });
      }
    }
  };

  const addService = (id) => {
    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });

    const indice = list.indexOf(servico[0]);

    list[indice].show = true;
    setServiceList([...list]);

    //Visto que a funcao é chamada para atribuir um evento onde se necessita do valor do evento
    //Crio um objeto similar ao evento e reaproveito a funcao passando o valor que pretendo usar
    updateValores({ target: { value: beneficio } });

    //Atualizar o numero de tratamentos cobertos e nao cobertos pelo inps na lista
    if (servico[0].tratamento.coberturaInps)
      setNrTratamentosInps((current) => current + 1);
    else setNrTratamentosNaoInps((current) => current + 1);

    //Se não existir mais tratamentos cobertos pelo INPS, o orçamento se transforma num orçamento particular
    // if (beneficio === "INPS") {
    //   let nrCobertos = 0;

    //   list.forEach((l) => {
    //     if (l.show) {
    //       if (l.tratamento.coberturaInps) nrCobertos += 1;
    //     }
    //   });

    //   if (nrCobertos <= 0) {
    //     setOpcoesBeneficio(["Particular"]);
    //     //Com updateValores com particular, o beneficio e set a particular portanto nao há necessidade
    //     //de atualizar a state beneficio para particular aqui
    //     updateValores({ target: { value: "Particular" } });
    //   }
    // }
  };

  //Quando se altera o beneficio
  const updateValores = (e) => {
    setBeneficio(e.target.value);
    const isParticular = e.target.value === "Particular";

    const tempServiceList = serviceList.map((el) => {
      if (el.show === true) {
        return {
          ...el,
          valUnit: el.tratamento.preco_particular,
          valorTotal: isParticular
            ? el.tratamento.preco_particular
            : el.tratamento.coberturaInps
            ? el.tratamento.preco_beneficiario
            : el.tratamento.preco_particular,
        };
      } else {
        return el;
      }
    });

    const filteredServiceList = tempServiceList.filter((el) => {
      return el.show === true;
    });

    setServiceList(tempServiceList);

    let tempSubTotal = 0;

    if (isParticular) {
      tempSubTotal = filteredServiceList.reduce((acc, el) => {
        return acc + el.tratamento.preco_particular;
      }, 0);
    } else {
      filteredServiceList.forEach((s) => {
        if (s.tratamento.coberturaInps)
          tempSubTotal += s.tratamento.preco_beneficiario;
        else tempSubTotal += s.tratamento.preco_particular;
      });
    }

    setSubtotal(tempSubTotal);
    handleIsencaoPagamento(desconto, tipoDesconto, tempSubTotal);
    calculateTotalWithDesconto(desconto, tipoDesconto, tempSubTotal);
  };

  const handleIsencaoPagamento = (desc, tipoDesc, subTo) => {
    if (
      (tipoDesc === "%" && desc >= 100) ||
      (tipoDesc === "$" && desc >= subTo)
    ) {
      setOrcamentoIsentoPagamento(true);
    } else {
      setOrcamentoIsentoPagamento(false);
    }
  };

  const changeDescontoHandler = (e) => {
    handleIsencaoPagamento(e.target.value, tipoDesconto, subtotal);

    if (e.target.value < 0) {
      setDesconto(0);
      calculateTotalWithDesconto(0, tipoDesconto, subtotal);
    } else if (tipoDesconto === "%" && e.target.value > 100) {
      setDesconto(100);
      calculateTotalWithDesconto(100, tipoDesconto, subtotal);
    } else if (tipoDesconto === "$" && e.target.value > subtotal) {
      setDesconto(subtotal);
      calculateTotalWithDesconto(subtotal, tipoDesconto, subtotal);
    } else {
      setDesconto(e.target.value);
      calculateTotalWithDesconto(e.target.value, tipoDesconto, subtotal);
    }
  };

  const changeTipoDescontoHandler = (e) => {
    handleIsencaoPagamento(desconto, e.target.value, subtotal);

    setTipoDesconto(e.target.value);
    if (e.target.value === "%" && desconto > 100) {
      setDesconto(100);
      calculateTotalWithDesconto(100, e.target.value, subtotal);
    } else {
      calculateTotalWithDesconto(desconto, e.target.value, subtotal);
    }
  };

  const calculateTotalWithDesconto = (desconto, tipoDesconto, subtotal) => {
    if (tipoDesconto === "$") {
      setTotal(subtotal - desconto);
    }

    if (tipoDesconto === "%") {
      setTotal(Math.round(subtotal - (desconto / 100) * subtotal));
    }
  };

  const preSubmitHandler = (aprovar) => {
    if (!isSubmitting) {
      if (serviceList.filter((el) => el.show === true).length <= 0) {
        toast.error("Nenhum tratamento selecionado");
      } else {
        setIsSubmitting(true);
        submitHandler(aprovar);
      }
    }
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {avaliacao && (
            <Navbar
              title="Orçamento"
              icon={ArticleOutlinedIcon}
              paths={[
                {
                  nome: avaliacao.cliente.nome,
                  link: `../../../clientes/${avaliacao.cliente.id}-orcamento`,
                },
                { nome: "Novo" },
              ]}
            />
          )}

          {avaliacao?.ativo === false || avaliacao?.ativo === "false" ? (
            <div className="avaliacaoDesativadaErrorMsg">
              Este orçamento não pode ser criado pois a avaliação correspondente
              se encontra desativada!
            </div>
          ) : (
            <>
              {serviceList && beneficio && avaliacao && (
                <div className="bottom-orcamento caixa">
                  <div className="pOrcamento-header">
                    <div className="pOrcamento-header__item">
                      <label className="label">Cliente:</label>
                      <span className="input">{avaliacao.cliente.nome}</span>
                    </div>

                    <div className="pOrcamento-header__item form-input">
                      <select
                        id="select_beneficio"
                        name="beneficio"
                        value={beneficio}
                        onChange={updateValores}
                        className="input"
                      >
                        {OPCOES_BENEFICIO.map((op, index) => (
                          <option value={op} key={index}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="pOrcamento-header__item">
                      <label className="label">Médico Avaliador:</label>
                      <span className="input">
                        {avaliacao.medico_responsavel.name}
                      </span>
                    </div>
                  </div>

                  {nrTratamentosInpsFixed &&
                  nrTratamentosInpsFixed > 0 &&
                  nrTratamentosNaoInpsFixed > 0 &&
                  beneficio === "INPS" ? (
                    <span className="tituloListaProcedimentos">
                      {`Lista de tratamentos - INPS (${nrTratamentosInps})`}
                    </span>
                  ) : (
                    ""
                  )}

                  <div
                    className={`orcamento__item orcamento__item--headerDiv ${
                      beneficio === "Particular"
                        ? "orcamento__item--particular"
                        : ""
                    }`}
                  >
                    <span className="orcamento__item--header">Tratamento</span>
                    <span className="orcamento__item--header">Val. Unit.</span>
                    {beneficio !== "Particular" && (
                      <span className="orcamento__item--header">INPS</span>
                    )}
                    {beneficio !== "Particular" && (
                      <span className="orcamento__item--header">
                        Beneficiario
                      </span>
                    )}
                  </div>

                  {beneficio === "Particular" &&
                    serviceList.map((singleService, i) => (
                      <div
                        key={i}
                        className="orcamento__item orcamento__item--particular"
                      >
                        <ElementoOrcamento
                          //single service data
                          dente={singleService.dente}
                          faces={singleService.faces}
                          tratamento={singleService.tratamento}
                          beneficio={beneficio}
                          //outros
                          removeService={removeService}
                          addService={addService}
                          id={singleService.id}
                          show={singleService.show}
                        />
                      </div>
                    ))}

                  {/* Lista para os de cobertura INPS */}
                  {beneficio === "INPS" &&
                    serviceList.map(
                      (singleService, i) =>
                        singleService.tratamento.coberturaInps &&
                        nrTratamentosInpsFixed > 0 && (
                          <div key={i} className="orcamento__item">
                            <ElementoOrcamento
                              //single service data
                              dente={singleService.dente}
                              faces={singleService.faces}
                              tratamento={singleService.tratamento}
                              beneficio={beneficio}
                              //outros
                              removeService={removeService}
                              addService={addService}
                              id={singleService.id}
                              show={singleService.show}
                            />
                          </div>
                        )
                    )}

                  {beneficio === "INPS" &&
                  nrTratamentosNaoInpsFixed &&
                  nrTratamentosNaoInpsFixed > 0 ? (
                    <>
                      <span className="tituloListaProcedimentos tituloListaProcedimentos--2">
                        Lista de tratamentos - PARTICULAR (
                        {nrTratamentosNaoInps})
                      </span>
                      {serviceList.map(
                        (singleService, i) =>
                          !singleService.tratamento.coberturaInps && (
                            <div key={i} className="orcamento__item">
                              <ElementoOrcamento
                                //single service data
                                dente={singleService.dente}
                                faces={singleService.faces}
                                tratamento={singleService.tratamento}
                                beneficio={beneficio}
                                //outros
                                removeService={removeService}
                                addService={addService}
                                id={singleService.id}
                                show={singleService.show}
                                tratamentoNaoCoberto={true}
                              />
                            </div>
                          )
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {/* Orcamentados */}
                  {procedimentosOrcamentados.map((pr, i) => (
                    <div key={i} className="orcamento__item">
                      <ElementoOrcamentoOrcamentados procedimento={pr} />
                    </div>
                  ))}

                  <span className="orcamento__areaPagamento__titulo">
                    Pagamento
                  </span>
                  <div className="orcamento__areaPagamento__container">
                    <div className="orcamento__areaPagamento__container--1">
                      <span className="orcamento__areaPagamento__tituloDesconto">
                        Desconto
                      </span>
                      <div className="orcamento__areaPagamento__desconto">
                        <select
                          onChange={changeTipoDescontoHandler}
                          value={tipoDesconto}
                        >
                          <option value="$">$00</option>
                          <option value="%">%</option>
                        </select>
                        <input
                          type="number"
                          min={0}
                          value={desconto}
                          onChange={(e) => {
                            const value = Math.floor(Number(e.target.value));
                            if (!isNaN(value)) {
                              changeDescontoHandler({
                                target: { value: value },
                              });
                            }
                          }}
                          onKeyDown={(e) => {
                            // Prevent decimal point
                            if (e.key === ".") {
                              e.preventDefault();
                            }
                          }}
                          step={1}
                        />
                      </div>

                      <span className="orcamento__areaPagamento__tituloDesconto">
                        Observação
                      </span>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="7"
                        value={observacoes}
                        onChange={(e) => setObservacoes(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="orcamento__areaPagamento__container--3"></div>

                    <div className="orcamento__areaPagamento__container--2">
                      <span className="orcamento__areaPagamento__container--2__titulo">
                        Detalhes sobre o pagamento
                      </span>
                      <div className="orcamento__areaPagamento__container--2__line">
                        <span>Subtotal:</span>
                        <span>
                          {Intl.NumberFormat("pt-BR").format(subtotal)}$00
                        </span>
                      </div>

                      <div className="orcamento__areaPagamento__container--2__line">
                        <span>Desconto</span>
                        <span>
                          {Intl.NumberFormat("pt-BR").format(
                            tipoDesconto === "$"
                              ? desconto
                              : Math.round((subtotal * desconto) / 100)
                          )}
                          $00
                        </span>
                      </div>
                      <div className="orcamento__areaPagamento__container--2__line">
                        <span className="orcamento__areaPagamento__container--2__line__title-big">
                          Total
                        </span>
                        <span>
                          {Intl.NumberFormat("pt-BR").format(total)}$00
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="botoes">
                    <button
                      className="cancel-btn"
                      onClick={() =>
                        navigate(
                          `../../../clientes/${avaliacao.cliente.id}-orcamento`
                        )
                      }
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={"blue-button"}
                      onClick={preSubmitHandler.bind(null, false)}
                    >
                      {isSubmitting ? "Criando..." : "Criar"}
                    </button>

                    {beneficio === "Particular" && (
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="blue-button"
                        onClick={preSubmitHandler.bind(null, true)}
                      >
                        {isSubmitting ? "Criando..." : "Criar e Aprovar"}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default NewOrcamento;
