//MUI
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalProcedimentos.scss";
function ModalProcedimentos({
  open,
  handleCloseModalProcedimentos,
  modalProcedimentosData,
}) {
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalProcedimentos}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box verProcedimentos__modal"
          id="modal__verProcedimentos"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Orçamento nr {modalProcedimentosData.codigo} -
            {modalProcedimentosData.orcamentoMisto
              ? " Misto"
              : modalProcedimentosData.beneficio}
          </h1>

          <span className="fluxo-atendimento__modal__designacao">
            Tratamento
          </span>
          <span className="fluxo-atendimento__modal__designacao">
            Dentes/Região
          </span>

          <span className="fluxo-atendimento__modal__designacao">Plano</span>

          <span className="fluxo-atendimento__modal__designacao">Valor</span>

          {modalProcedimentosData.procedimentos &&
            modalProcedimentosData.procedimentos.map((proc, index) => (
              <React.Fragment key={index}>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.tratamento.designacao}
                  {proc.faces && proc.faces > 0
                    ? `- ${proc.faces.length} ${
                        proc.faces.length > 1 ? "faces" : "face"
                      } ${`- ${proc.faces.join(",")}`}`
                    : ""}
                </span>

                <span className="fluxo-atendimento__modal__cell">
                  {proc.dente}
                </span>

                <span className="fluxo-atendimento__modal__cell">
                  {modalProcedimentosData.orcamentoMisto
                    ? proc.tratamento.coberturaInps
                      ? "INPS"
                      : "Particular"
                    : modalProcedimentosData.beneficio}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {modalProcedimentosData.beneficio === "Particular"
                    ? proc.tratamento.preco_particular
                    : modalProcedimentosData.orcamentoMisto
                    ? proc.tratamento.coberturaInps
                      ? proc.tratamento.preco_beneficiario
                      : proc.tratamento.preco_particular
                    : proc.tratamento.preco_beneficiario}
                </span>
              </React.Fragment>
            ))}

          <div className="verOrcamentoModal__valorCredencialContainer">
            {modalProcedimentosData.orcamentoMisto && (
              <div className="verOrcamentoModal__valorCredencialContainer__col">
                <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                  <span className="orcamento__modal__valores__descricao">
                    Total Particular
                  </span>
                  <span className="orcamento__modal__valores__valor">
                    {modalProcedimentosData.procedimentos
                      .reduce(
                        (acc, p) =>
                          acc +
                          (p.tratamento.coberturaInps
                            ? 0
                            : p.tratamento.preco_particular),
                        0
                      )
                      .toLocaleString("pt-BR")}
                    $00
                  </span>
                </div>

                <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                  <span className="orcamento__modal__valores__descricao">
                    Total Beneficiário
                  </span>
                  <span className="orcamento__modal__valores__valor">
                    {modalProcedimentosData.procedimentos
                      .reduce(
                        (acc, p) =>
                          acc +
                          (p.tratamento.coberturaInps
                            ? p.tratamento.preco_beneficiario
                            : 0),
                        0
                      )
                      .toLocaleString("pt-BR")}
                    $00
                  </span>
                </div>
              </div>
            )}

            <div className="verOrcamentoModal__valorCredencialContainer__col">
              <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                <span className="orcamento__modal__valores__descricao">
                  Subtotal
                </span>
                <span className="orcamento__modal__valores__valor">
                  {modalProcedimentosData.subtotal.toLocaleString("pt-BR")}$00
                </span>
              </div>
              {modalProcedimentosData.comMajoracao && (
                <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                  <span className="orcamento__modal__valores__descricao">
                    Majoração
                  </span>
                  <span className="orcamento__modal__valores__valor">
                    {modalProcedimentosData.majoracao.toLocaleString("pt-BR")}
                    $00
                  </span>
                </div>
              )}
              <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                <span className="orcamento__modal__valores__descricao">
                  Desconto
                </span>
                <span className="orcamento__modal__valores__valor">
                  {modalProcedimentosData.desconto.toLocaleString("pt-BR")}$00
                </span>
              </div>

              <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                <span className="orcamento__modal__valores__descricao">
                  Total
                </span>
                <span className="orcamento__modal__valores__valor orcamento__modal__valores__valor--total">
                  {modalProcedimentosData.total.toLocaleString("pt-BR")}$00
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <span
              className="cancel-btn"
              onClick={handleCloseModalProcedimentos}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalProcedimentos;
