import React, { useState, useEffect } from "react";
import "./ModalEditarProduto.scss";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../shared/context/LoadingContext";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const UNIDADES_MEDIDA = [
  { value: "EA", label: "Unidade" },
  { value: "KGM", label: "Kilograma (kg)" },
  { value: "MTR", label: "Metro (m)" },
  { value: "LTR", label: "Litro (l)" },
  { value: "MTQ", label: "Metro Cúbico (m³)" },
  { value: "MTK", label: "Metro Quadrado (m²)" },
  { value: "BX", label: "Caixa" },
];

function ModalEditarProduto({
  handleCloseModalEditar,
  sendRequest,
  id,
  open,
  clinicaId,
  auth,
  categoriasStock,
  fetchData,
  qtdIdeal,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [nomeProduto, setNomeProduto] = useState("");
  const [categoria, setCategoria] = useState("");
  const [unidadeMedida, setUnidadeMedida] = useState("EA");
  const [quantidadeIdeal, setQuantidadeIdeal] = useState(0); // Estado para a quantidade ideal

  useEffect(() => {
    const fetchProduto = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/${id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setNomeProduto(responseData.produto.nome);
        setCategoria(responseData.produto.categoriaStock);
        setUnidadeMedida(responseData.produto.unidadeMedida || "EA");

        // Definir a quantidade ideal inicial
        setQuantidadeIdeal(qtdIdeal || 0);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchProduto();
  }, [sendRequest, clinicaId, auth.token, qtdIdeal]);

  const handleSubmit = async () => {
    startLoading();
    //Atualizando produtos
    try {
      const formData = new FormData();
      formData.append("categoriaStock", categoria);
      formData.append("nome", nomeProduto);
      formData.append("unidadeMedida", unidadeMedida);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verificar se a quantidade ideal foi alterada
      if (quantidadeIdeal !== qtdIdeal) {
        // Fazer requisição para atualizar a quantidade ideal
        // Nota: A implementação exata dependerá da sua API
        const formData2 = new FormData();
        formData2.append("produtoId", id);
        formData2.append("novaQuantidadeIdeal", quantidadeIdeal);
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/atualizarQuantidadeIdeal/${clinicaId}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }

      fetchData();
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      handleCloseModalEditar();
    }
  };

  const preHandleSubmit = () => {
    let mensagemDeErro = "";
    if (!nomeProduto) {
      mensagemDeErro = "Por favor introduza o nome do Produto!";
      return;
    }

    if (!categoria) {
      mensagemDeErro = "Por favor introduza a categoria de Stock do produto!";
      return;
    }

    if (quantidadeIdeal < 0) {
      mensagemDeErro = "A quantidade ideal não pode ser negativa!";
      return;
    }

    if (mensagemDeErro) {
      setValidationError(mensagemDeErro);
    } else if (!mensagemDeErro && validationError) {
      setValidationError("");
    }

    if (!isSubmitting && !mensagemDeErro) {
      setIsSubmitting(true);
      handleSubmit();
    }
  };

  const renderCategoryOptions = (categories, level = 0) => {
    return categories.flatMap((category) => [
      <MenuItem
        key={category.id}
        value={category.id}
        style={{ paddingLeft: `${level * 20}px` }}
      >
        {category.nome}
      </MenuItem>,
      ...(category.subcategorias
        ? renderCategoryOptions(category.subcategorias, level + 1)
        : []),
    ]);
  };

  const handleQuantidadeIdealChange = (e) => {
    const value = e.target.value;
    // Converter para número e garantir que não seja negativo
    setQuantidadeIdeal(Math.max(0, Number(value)));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalEditar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box " id="modal__editarStock">
          <h1 className="fluxo-atendimento__modal__titulo">Editar Produto</h1>
          <div className="stockEditar__container">
            <div className="stockEditar__container__data">
              <TextField
                label="Nome do Produto"
                value={nomeProduto}
                onChange={(e) => setNomeProduto(e.target.value)}
                fullWidth
                margin="normal"
                required
              />

              <TextField
                select
                label="Medida"
                value={unidadeMedida}
                onChange={(e) => setUnidadeMedida(e.target.value)}
                fullWidth
                margin="normal"
              >
                {UNIDADES_MEDIDA.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label="Categoria Stock"
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
                fullWidth
                margin="normal"
                required
              >
                {renderCategoryOptions(categoriasStock)}
              </TextField>

              <TextField
                label="Quantidade Ideal"
                type="number"
                value={quantidadeIdeal}
                onChange={handleQuantidadeIdealChange}
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
              />
            </div>

            {validationError && (
              <div className="modal__validationErrors__container">
                {validationError}
              </div>
            )}
            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleCloseModalEditar}
                style={{ display: "block" }}
              >
                Fechar
              </span>

              <span
                disabled={isSubmitting}
                className="blue-button"
                onClick={preHandleSubmit}
              >
                {isSubmitting ? "Guardando..." : "Guardar"}
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarProduto;
