import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-hot-toast";

import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./ModalExecutar.scss";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import DoneIcon from "@mui/icons-material/Done";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLoading } from "../../../../../../shared/context/LoadingContext";
function ModalExecutar({
  plano,
  openModal,
  handleCloseModal,
  sendRequest,
  auth,
  updatePlanosTratamento,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [evolucao, setEvolucao] = useState();
  const [planoTratamento, setPlanoTratamento] = useState();
  const [procedimento, setProcedimento] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [step, setStep] = useState("1");
  const [dataInicioExecucao, setDataInicioExecucao] = useState(moment());
  const [dataFimExecucao, setDataFimExecucao] = useState(moment());

  useEffect(() => {
    const sortedProcedimentos = [...plano.procedimentos].sort((a, b) => {
      if (a.procedimento_completo && !b.procedimento_completo) {
        return 1;
      }
      if (!a.procedimento_completo && b.procedimento_completo) {
        return -1;
      }
      return 0;
    });
    const planoFinal = { ...plano, procedimentos: sortedProcedimentos };
    setPlanoTratamento(planoFinal);
  }, [plano]);

  const submitHandler = async () => {
    try {
      startLoading();
      let data_fim;
      let data_inicio;
      //Calcular o nr de procedimentos efetuados no plano
      const nrProcedimentosEfetuados = planoTratamento.procedimentos.filter(
        (pr) => pr.procedimento_completo
      );

      //Se o nr de procedimentos efetuados for igual ao nr de procedimento do plano
      //O plano fica completo com uma data de fim definida pela data de fim da ultima marcacao executada
      if (
        nrProcedimentosEfetuados.length ===
        planoTratamento.procedimentos.length - 1
      ) {
        data_fim = dataFimExecucao;
      }
      //Se esse procedimento é o primeiro procedimento efetuado
      //O plano fica com uma data de inicio definida pela data de inicio dess marcacao
      if (nrProcedimentosEfetuados.length === 0) {
        data_inicio = dataInicioExecucao;
      }

      const procedimentosTrabalhados = [];
      let procedimentoEfetuado;
      planoTratamento.procedimentos.forEach((proc) => {
        if (proc.id === procedimento.id) {
          procedimentoEfetuado = proc.tratamento.designacao;
          procedimentosTrabalhados.push({
            ...proc,
            tratamento: proc.tratamento.id,
            procedimento_completo: true,
            data_inicio_execucao: proc.sessoes[0]
              ? proc.sessoes[0].data_inicio_execucao
              : dataInicioExecucao,
            data_fim_execucao: dataFimExecucao,
            sessoes: [
              ...proc.sessoes.map((s) => ({ ...s, medico: s.medico._id })),
              {
                data_inicio_execucao: dataInicioExecucao,
                data_fim_execucao: dataFimExecucao,
                evolucao: evolucao ? evolucao : "Procedimento Executado",
                medico: auth.userId,
              },
            ],
          });
        } else {
          procedimentosTrabalhados.push({
            ...proc,
            tratamento: proc.tratamento.id,
          });
        }
      });

      const formData = new FormData();
      //Se o procedimento tem uma data de fim, o plano fica completo
      formData.append("plano_completo", data_fim ? true : false);
      formData.append("utilizador", auth.userId);
      formData.append("data_inicio", data_inicio ? data_inicio : null);
      formData.append("data_fim", data_fim ? data_fim : null);
      formData.append(
        "procedimentos",
        JSON.stringify(procedimentosTrabalhados)
      );
      formData.append("operacao", "EXECUCAO");
      formData.append("procedimentoId", procedimento.id);
      formData.append("procedimentoNome", procedimentoEfetuado);
      let planoTratamentoRetornado;
      try {
        planoTratamentoRetornado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/${planoTratamento.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Se o plano terminar, o orcamento fica a finalizado
      if (data_fim) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/finalizar/${planoTratamento.orcamento._id}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      setPlanoTratamento((current) => {
        return {
          ...current,
          data_inicio: moment(data_inicio).format("DD-MM-YYYY"),
          data_fim: data_fim ? moment(data_fim).format("DD-MM-YYYY") : null,
          plano_completo: data_fim ? true : false,
          procedimentos: planoTratamentoRetornado.planoTratamento.procedimentos,
        };
      });

      toast.success(
        `Procedimento finalizado!${
          data_fim ? "Plano de tratamento terminado!" : ""
        }`
      );

      updatePlanosTratamento({
        ...planoTratamento,
        data_inicio: moment(data_inicio).format("DD-MM-YYYY"),
        data_fim: data_fim ? moment(data_fim).format("DD-MM-YYYY") : null,
        plano_completo: data_fim ? true : false,
        procedimentos: planoTratamentoRetornado.planoTratamento.procedimentos,
      });

      setEvolucao("");
      setIsSubmiting(false);
      setStep("1");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleGuardar = async () => {
    if (!dataInicioExecucao) {
      toast.error("Introduza a data de inicio de execução");
    } else if (
      !moment.isMoment(dataInicioExecucao) ||
      !dataInicioExecucao.isValid()
    ) {
      toast.error("Introduza a data de inicio de execução corretamente");
    } else if (!dataFimExecucao) {
      toast.error("Introduza a data de fim de execução");
    } else if (
      !moment.isMoment(dataFimExecucao) ||
      !dataFimExecucao.isValid()
    ) {
      toast.error("Introduza a data de fim de execução corretamente");
    } else if (moment(dataFimExecucao).isBefore(moment(dataInicioExecucao))) {
      toast.error(
        "A data de fim de execução não pode ser antes da data de inicio."
      );
    } else {
      setIsSubmiting(true);
      startLoading();
      try {
        //Ao clicar em guardar, é adicionada uma sessão
        const formData = new FormData();
        formData.append("planoTratamentoId", planoTratamento.id);
        formData.append("data_inicio_execucao", dataInicioExecucao);
        formData.append("data_fim_execucao", dataFimExecucao);
        formData.append(
          "evolucao",
          evolucao ? evolucao : "Procedimento executado."
        );
        formData.append("medico", auth.userId);
        formData.append("nomeProcedimento", procedimento.tratamento.designacao);
        let planoTratamentoRetornado;
        try {
          planoTratamentoRetornado = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/adicionarsessao/${procedimento.id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        const tempPlano = {
          ...planoTratamento,
          data_inicio: planoTratamento.data_inicio
            ? planoTratamento.data_inicio
            : moment(dataInicioExecucao).format("DD-MM-YYYY"),
          procedimentos: planoTratamentoRetornado.planoTratamento.procedimentos,
        };
        setPlanoTratamento({ ...tempPlano });

        updatePlanosTratamento({
          ...tempPlano,
        });

        setIsSubmiting(false);
        toast.success(`Sessão do procedimento efetuada!`);
        setEvolucao("");
        setStep("1");
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    }
  };

  const preSubmitHandler = () => {
    if (!dataInicioExecucao) {
      toast.error("Introduza a data de inicio de execução");
    } else if (
      !moment.isMoment(dataInicioExecucao) ||
      !dataInicioExecucao.isValid()
    ) {
      toast.error("Introduza a data de inicio de execução corretamente");
    } else if (!dataFimExecucao) {
      toast.error("Introduza a data de fim de execução");
    } else if (
      !moment.isMoment(dataFimExecucao) ||
      !dataFimExecucao.isValid()
    ) {
      toast.error("Introduza a data de fim de execução corretamente");
    } else if (moment(dataFimExecucao).isBefore(moment(dataInicioExecucao))) {
      toast.error(
        "A data de fim de execução não pode ser antes da data de inicio."
      );
    } else {
      setIsSubmiting(true);
      submitHandler();
    }
  };

  const handleChooseProcedimento = (idPro) => {
    setStep("2");

    const procedimentoFiltrado = planoTratamento.procedimentos.filter(
      (p) => p.id === idPro
    )[0];
    setProcedimento(procedimentoFiltrado);
  };

  const handleVoltar = () => {
    setProcedimento(null);
    setEvolucao("");
    setStep("1");
  };

  const handleCancelarProcedimento = async (idProc) => {
    startLoading();
    try {
      //Calcular o nr de procedimentos efetuados no plano
      const nrProcedimentosEfetuados = planoTratamento.procedimentos.filter(
        (pr) => pr.procedimento_completo
      );

      const procedimentosParaAtualizarState = [];
      const procedimentosTrabalhados = [];
      let procedimentoEfetuado;
      planoTratamento.procedimentos.forEach((proc) => {
        procedimentoEfetuado = proc.tratamento.designacao;
        if (proc.id === idProc) {
          procedimentosTrabalhados.push({
            ...proc,
            sessoes: proc.sessoes.slice(0, -1),
            tratamento: proc.tratamento.id,
            procedimento_completo: false,
            data_fim_execucao: null,
            data_inicio_execucao: null,
          });
          //////
          procedimentosParaAtualizarState.push({
            ...proc,
            sessoes: proc.sessoes.slice(0, -1),
            procedimento_completo: false,
            data_fim_execucao: null,
            data_inicio_execucao: null,
          });
        } else {
          procedimentosTrabalhados.push({
            ...proc,
            tratamento: proc.tratamento.id,
          });
          procedimentosParaAtualizarState.push(proc);
        }
      });

      const formData = new FormData();
      //Verificar backend. Indeterminado faz com que a data de fim seja null
      formData.append("data_fim", "indeterminado");
      formData.append("plano_completo", false);
      formData.append("operacao", "CANCELAMENTO");
      formData.append("procedimentoId", idProc);
      formData.append("procedimentoNome", procedimentoEfetuado);
      if (nrProcedimentosEfetuados === 0) {
        formData.append("data_inicio", "indeterminado");
      }
      formData.append(
        "procedimentos",
        JSON.stringify(procedimentosTrabalhados)
      );
      formData.append("utilizador", auth.userId);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/${planoTratamento.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //O orçamento já não pode ficar como concluido
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/reverterfinalizacao/${planoTratamento.orcamento._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      let planoIniciado = false;
      procedimentosParaAtualizarState.forEach((p) => {
        if (p.sessoes.length > 0) {
          planoIniciado = true;
          return;
        }
      });

      const newPlanoParaState = {
        ...planoTratamento,
        data_inicio: planoIniciado ? planoTratamento.data_inicio : null,
        data_fim: null,
        plano_completo: false,
        procedimentos: procedimentosParaAtualizarState,
      };

      setPlanoTratamento({ ...newPlanoParaState });

      toast.success(`Execução do procedimento cancelado.`);

      updatePlanosTratamento({ ...newPlanoParaState });
      setIsSubmiting(false);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* Verificar se nos dados da modal conter avaliacao. Se sim, estamos lidando com dados do orcamento */}
      <Box
        className="fluxo-atendimento__modal__box"
        id="modal-executar-procedimentos"
      >
        {step === "1" && (
          <div className="executarProcedimento__step1 caixa">
            <span className="executarProcedimento__step1__tabela-heading executarProcedimento__step1__tabela-heading--1st">
              Tratamento
            </span>
            <span className="executarProcedimento__step1__tabela-heading">
              Dentes
            </span>
            <span className="executarProcedimento__step1__tabela-heading">
              Faces
            </span>
            <span className="executarProcedimento__step1__tabela-heading">
              Sessões
            </span>
            <span className="executarProcedimento__step1__tabela-heading">
              Status
            </span>
            <span className="executarProcedimento__step1__tabela-heading executarProcedimento__step1__tabela-heading--last">
              Ação
            </span>
            {planoTratamento &&
              planoTratamento.procedimentos.map(
                (p, index) =>
                  !p.procedimento_completo && (
                    <React.Fragment key={index}>
                      <span className="executarProcedimento__step1__lineItem executarProcedimento__step1__lineItem--1st">
                        {p.tratamento.designacao}
                      </span>
                      <span className="executarProcedimento__step1__lineItem">
                        {p.dente}
                      </span>
                      <span className="executarProcedimento__step1__lineItem">
                        {p.faces.join(" ,")}
                      </span>

                      <span className="executarProcedimento__step1__lineItem">
                        {p.sessoes.length}
                      </span>
                      <div className="executarProcedimento__step1__procedimento__container executarProcedimento__step1__lineItem">
                        <span
                          className={`
                        executarProcedimento__step1__procedimento
                        ${
                          p.sessoes.length > 0
                            ? "executarProcedimento__step1__procedimento-emProgresso"
                            : "executarProcedimento__step1__procedimento-novo"
                        }
                      `}
                        >
                          {p.sessoes.length > 0 ? (
                            <>
                              <SettingsIcon className="executarProcedimento__step1__procedimento__icon" />{" "}
                              Em Progresso
                            </>
                          ) : (
                            <>
                              <StarBorderIcon className="executarProcedimento__step1__procedimento__icon" />{" "}
                              Novo
                            </>
                          )}
                        </span>
                      </div>

                      <div className="executarProcedimento__step1__lineItem executarProcedimento__step1__btnContainer">
                        <span
                          className="executarProcedimento__step1__btn-executar executarButton"
                          onClick={handleChooseProcedimento.bind(null, p.id)}
                        >
                          Executar
                        </span>
                      </div>
                    </React.Fragment>
                  )
              )}
            <div className="linhaCinzenta">Finalizados</div>
            {planoTratamento &&
              planoTratamento.procedimentos.map(
                (p, index) =>
                  p.procedimento_completo && (
                    <React.Fragment key={index}>
                      <span className="executarProcedimento__step1__lineItem executarProcedimento__step1__lineItem--1st">
                        {p.tratamento.designacao}
                      </span>
                      <span className="executarProcedimento__step1__lineItem">
                        {p.dente}
                      </span>
                      <span className="executarProcedimento__step1__lineItem">
                        {p.faces.join(" ,")}
                      </span>

                      <span className="executarProcedimento__step1__lineItem">
                        {p.sessoes.length}
                      </span>
                      <div className="executarProcedimento__step1__procedimento__container executarProcedimento__step1__lineItem">
                        <span
                          className="
                        executarProcedimento__step1__procedimento executarProcedimento__step1__procedimento-completo"
                        >
                          <DoneIcon className="executarProcedimento__step1__procedimento__icon" />{" "}
                          Completo
                        </span>
                      </div>

                      <div className="executarProcedimento__step1__lineItem executarProcedimento__step1__btnContainer">
                        {p.sessoes[p.sessoes.length - 1].medico._id ===
                        auth.userId ? (
                          <span
                            className="executarProcedimento__step1__btn-executar cancelarButton"
                            onClick={handleCancelarProcedimento.bind(
                              null,
                              p.id
                            )}
                          >
                            Cancelar
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </React.Fragment>
                  )
              )}
          </div>
        )}

        {step === "2" && (
          <div className="executarProcedimento__step2">
            <div className="modal-executar-procedimentos__datetimeContainer">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  className="planos-tratamento__modal__datePicker planos-tratamento__modal__datePicker--1"
                  label="Data Inicio"
                  inputFormat="DD-MM-YYYY HH:mm"
                  value={dataInicioExecucao}
                  onChange={(value) => setDataInicioExecucao(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  className="planos-tratamento__modal__datePicker planos-tratamento__modal__datePicker--2"
                  label="Data Fim"
                  inputFormat="DD-MM-YYYY HH:mm"
                  value={dataFimExecucao}
                  onChange={(value) => setDataFimExecucao(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            {procedimento && (
              <div className="bottom-orcamento caixa">
                <div className="executarProcedimento__container">
                  <span className="executarProcedimento__container__item--header">
                    Tratamento
                  </span>
                  <span className="executarProcedimento__container__item--header">
                    Dentes/Região
                  </span>

                  <span className="executarProcedimento__container__item--header">
                    Faces
                  </span>

                  <span className="executarProcedimento__container__item">
                    {procedimento.tratamento.designacao}
                  </span>
                  <span className="executarProcedimento__container__item">
                    {procedimento.dente}
                  </span>

                  <span className="executarProcedimento__container__item">
                    {procedimento.faces.join(", ")}
                  </span>

                  <textarea
                    className="executarProcedimento__container__textarea"
                    cols="30"
                    rows="5"
                    value={evolucao}
                    placeholder="Escreva aqui a evolução do procedimento."
                    onChange={(e) => {
                      e.target.value
                        ? setEvolucao(e.target.value)
                        : setEvolucao("");
                    }}
                  />

                  <div className="listaEvolucoesContainer">
                    {procedimento.sessoes.map((sessao, index) => (
                      <div className="evolucoesPassadasContainer" key={index}>
                        <span>
                          Evolução da sessão nr {index + 1} - das{" "}
                          <span>
                            {moment(sessao.data_inicio_execucao).format(
                              "DD-MM-YYYY/HH:mm"
                            )}{" "}
                            às{" "}
                            {moment(sessao.data_fim_execucao).format("HH:mm")}
                          </span>
                        </span>
                        <textarea
                          defaultValue={sessao.evolucao}
                          readOnly
                          cols="30"
                          rows="5"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {step === "2" && (
                  <div className="botoes">
                    <button className="cancel-btn" onClick={handleVoltar}>
                      Voltar
                    </button>

                    <button
                      className="blue-button"
                      onClick={handleGuardar}
                      disabled={isSubmiting}
                    >
                      {isSubmiting ? "Guardando" : "Guardar"}
                    </button>

                    <button
                      disabled={isSubmiting}
                      className="blue-button"
                      onClick={preSubmitHandler}
                    >
                      {isSubmiting ? "Finalizando" : "Finalizar"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default ModalExecutar;
