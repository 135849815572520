import React from "react";
import "./ImportErrorList.scss";

function ImportErrorList({ columnErrors, rowErrors }) {
  return (
    <div className="modalImportarXlsStock__errorContainer">
      {columnErrors.length > 0 && (
        <div className="modalImportarXlsStock__errorHeader">
          <h4>Problemas com o formato do arquivo:</h4>
          <ul>
            {columnErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {rowErrors.length > 0 && (
        <div className="modalImportarXlsStock__errorDetails">
          <h4>
            Problemas encontrados nos dados ({rowErrors.length} linhas com
            erros):
          </h4>
          <div className="modalImportarXlsStock__errorTable">
            <table>
              <thead>
                <tr>
                  <th>Linha</th>
                  <th>Produto</th>
                  <th>Problemas</th>
                </tr>
              </thead>
              <tbody>
                {rowErrors.map((row) => (
                  <tr key={row.linha}>
                    <td>{row.linha}</td>
                    <td>{row.produto}</td>
                    <td>
                      <ul>
                        {row.erros.map((err, i) => (
                          <li key={i}>{err}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImportErrorList;
