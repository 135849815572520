import { useState, useEffect } from "react";
import uuid from "react-uuid";
import "./InputNumber.scss";
import "../customInputs.scss";
const randomId = uuid();

function InputNumber({
  initialValue,
  label,
  handleChange,
  min,
  max,
  className,
}) {
  const [value, setValue] = useState("");
  const [maximum, setMaximum] = useState();
  const [minimum, setMinimum] = useState();

  useEffect(() => {
    setValue(initialValue?.toString() || "");
    setMinimum(min);
    setMaximum(max);
  }, [initialValue, min, max]);

  const validateNumber = (value) => {
    // Handle empty or non-numeric input
    if (!value || !/\d/.test(value)) {
      const defaultValue = minimum !== undefined ? minimum : 0;
      return defaultValue.toString();
    }

    // Remove any non-digit characters except decimal point and minus
    const cleanValue = value.replace(/[^\d.-]/g, "");

    // Ensure only one decimal point
    const parts = cleanValue.split(".");
    if (parts.length > 2) {
      return parts[0] + "." + parts.slice(1).join("");
    }

    // Handle negative numbers
    if (cleanValue.startsWith("-")) {
      if (cleanValue.split("-").length > 2) {
        return "-" + cleanValue.split("-").slice(1).join("");
      }
    }

    return cleanValue;
  };

  const preChange = (e) => {
    const inputValue = e.target.value;
    const currentSelectionLength =
      e.target.selectionEnd - e.target.selectionStart;
    const currentValueLength = e.target.value.length;
    const isFullySelected = currentSelectionLength === currentValueLength;

    // If the entire field is selected and user types a non-number
    if (isFullySelected && !/^-?\d*\.?\d*$/.test(inputValue)) {
      const defaultValue = minimum !== undefined ? minimum : 0;
      setValue(defaultValue.toString());
      handleChange(defaultValue);
      return;
    }

    // If there are numbers and user adds invalid character, keep only the valid part
    const validNumbers = inputValue.match(/\d*\.?\d*/g)?.join("") || "";
    if (validNumbers) {
      let nextValue = validateNumber(validNumbers);
      const numericValue = parseFloat(nextValue);

      if (!isNaN(numericValue)) {
        if (minimum !== undefined && numericValue < minimum) {
          nextValue = minimum.toString();
        } else if (maximum !== undefined && numericValue > maximum) {
          nextValue = maximum.toString();
        }
        setValue(nextValue);
        handleChange(parseFloat(nextValue));
      }
      return;
    }

    // Default to minimum or zero for completely invalid input
    const defaultValue = minimum !== undefined ? minimum : 0;
    setValue(defaultValue.toString());
    handleChange(defaultValue);
  };

  return (
    <div className="customInputContainer">
      <input
        type="text"
        value={value}
        className={`customInput inputNumber ${className || ""}
        }`}
        placeholder={label}
        onChange={preChange}
        id={randomId}
        inputMode="decimal"
      />
      <label className="customInputLabel" htmlFor={randomId}>
        {label}
      </label>
    </div>
  );
}

export default InputNumber;
