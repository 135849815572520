import { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
//Tabs components
import TransferenciaTab from "./ModalReceberTabs/TransferenciaTab";
import DinheiroTab from "./ModalReceberTabs/DinheiroTab";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//icons
import PaidIcon from "@mui/icons-material/Paid"; //cabecalho
import CreditCardIcon from "@mui/icons-material/CreditCard"; //Credit card
import LocalAtmIcon from "@mui/icons-material/LocalAtm"; //Nota
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"; //Calendar
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"; //Banco
import RequestPageIcon from "@mui/icons-material/RequestPage";

import { AuthContext } from "../../../../../../shared/context/auth-context";
import { useHttpClient } from "../../../../../../shared/hooks/http-hook";

import "./ModalReceber.scss";
import toast from "react-hot-toast";
import CreditoTab from "./ModalReceberTabs/CreditoTab";
import ChequeTab from "./ModalReceberTabs/ChequeTab";
import { ClinicaContext } from "../../../../../../shared/context/clinica-context";
import { useLoading } from "../../../../../../shared/context/LoadingContext";

import InputText from "../../../../../../shared/components/inputs/InputText/InputText";

import { cloneProfundo } from "../../../../../../shared/util/immutableHelper";

import { produce } from "immer";
import { fixSpaces } from "../../../../../../shared/util/helperFunctions";

function ModalReceber({
  open,
  handleCloseModalReceber,
  nomeCliente,
  valorAPagar,
  idParcela,
  contaCorr,
  updateContaCorrente,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [openModal, setOpenModal] = useState(open);
  const [showTab, setShowTab] = useState("Dinheiro");
  const [idDaParcela, setIdDaParcela] = useState(idParcela);
  const [contaCorrente, setContaCorrente] = useState(contaCorr);
  const [conta, setConta] = useState();
  const [totalPorReceber, setTotalPorReceber] = useState();
  const { sendRequest } = useHttpClient();
  const [caixas, setCaixas] = useState();
  const [parcela, setParcela] = useState();
  const [descricao, setDescricao] = useState("Tratamento dentário");

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const checkboxRef = useRef(null);
  useEffect(() => {
    setOpenModal(open);
    setContaCorrente(contaCorrente);
    setIdDaParcela(idParcela);
    let contaFiltrada;
    let parcelaFiltrada;

    contaCorr.contas.forEach((c) => {
      c.parcelas.forEach((p) => {
        if (p._id === idParcela) {
          parcelaFiltrada = p;
          contaFiltrada = c;
        }
      });
    });

    setParcela(parcelaFiltrada);
    setConta(contaFiltrada);

    setTotalPorReceber(
      contaFiltrada.parcelas.reduce(
        (total, item) =>
          !item.parcelaPaga ? total + item.quantiaPorPagar : total,
        0
      )
    );
  }, [open]);

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  const receberHandler = async (dados, emitirDocumento) => {
    startLoading();
    let responseCheckPagamento = false;

    try {
      const formData = new FormData();
      formData.append("idParcela", parcela._id);
      formData.append("clinicaId", clinica.clinica._id);

      responseCheckPagamento = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/verificarrecebimento/${contaCorrente._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {
      console.error("err", err);
    }

    if (!responseCheckPagamento.parcelaPaga) {
      try {
        // Create a deep clone of the account to ensure immutability
        let contaFiltrada = cloneProfundo(conta);

        const parcelaIndex = contaFiltrada.parcelas.findIndex(
          (parcela) => parcela._id === idDaParcela
        );

        if (parcelaIndex !== -1) {
          // Store original values before any modifications
          const parcela = contaFiltrada.parcelas[parcelaIndex];
          contaFiltrada = handleRegularParcelPayment(
            contaFiltrada,
            parcela,
            dados,
            parcelaIndex
          );
        }

        // Update description using immutable approach
        contaFiltrada = produce(contaFiltrada, (draft) => {
          draft.parcelas = draft.parcelas.map((p) =>
            p._id === idDaParcela
              ? {
                  ...p,
                  descricao: fixSpaces(descricao) || "Tratamento dentário",
                }
              : p
          );
        });

        let nrRecibo = 1;
        if (emitirDocumento === "emitirRecibo") {
          try {
            const nrDocumentos = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${clinica.clinica._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;

            // Update receipt number immutably
            contaFiltrada = produce(contaFiltrada, (draft) => {
              draft.parcelas = draft.parcelas.map((p) =>
                p._id === idDaParcela ? { ...p, nrRecibo } : p
              );
            });

            //Atualizar o numero de recibo
            const formData8 = new FormData();
            formData8.append("valor", nrRecibo);
            formData8.append("tipoDocumento", "recibo");
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${clinica.clinica._id}`,
              "PATCH",
              formData8,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        //Verificar se todas as parcelas estão pagas
        const nrParcelasPagas = contaFiltrada.parcelas.filter(
          (p) => p.quantiaPaga > 0
        ).length;

        contaFiltrada = {
          ...contaFiltrada,
          contaLiquidada: nrParcelasPagas === contaFiltrada.parcelas.length,
        };

        // const parcelaFiltrada = contaFiltrada.parcelas.find(
        //   (p) => p._id === idDaParcela
        // );

        const parcelaFiltrada = contaFiltrada.parcelas.find(
          (p) => p._id === idDaParcela || p._id === `temp_${idDaParcela}`
        );

        if (!parcelaFiltrada) {
          toast.error("Parcela não encontrada após processamento!");
          return;
        }

        const alteracoes = {
          "Número da parcela": {
            anterior: "",
            novo: parcelaFiltrada.nrParcela || "N/A",
          },
          Valor: {
            anterior: "",
            novo: parcelaFiltrada.quantiaPaga,
          },
          "Número de orçamento": {
            anterior: contaFiltrada.orcamento.codigo,
            novo: contaFiltrada.orcamento.codigo,
          },
        };

        if (parcelaFiltrada.nrRecibo) {
          alteracoes["Recibo interno emitido"] = {
            anterior: "",
            novo: `Recibo nr ${parcelaFiltrada.nrRecibo}`,
          };
        }

        const formData3 = new FormData();
        formData3.append(
          "contas",
          JSON.stringify(
            contaCorrente.contas.map((c) =>
              c._id === contaFiltrada._id ? contaFiltrada : c
            )
          )
        );
        formData3.append("operacao", "RECEBIMENTO_PARCELA");
        formData3.append("utilizador", auth.userId);
        formData3.append("alteracoes", JSON.stringify(alteracoes));
        formData3.append("nomeCliente", nomeCliente);

        let contaCorrenteParaState;
        try {
          contaCorrenteParaState = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente._id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o plano de tratamento para que este não possa ser apagado
        //Caso essa for a primeria parcela a ser paga. Se não, isso quer dizer que
        //O campo canDelete ja esta a true e nao necessita ser atualizado
        if (nrParcelasPagas === 1) {
          let planoTratamento;
          try {
            planoTratamento = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${contaFiltrada.orcamento._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          //Caso tivermos varios planos de tratamento com o mesmo orcamento, porque isso pode acontecer
          //Quando apagamos um plano de tratamento, filtramos apenas o ativo

          const planoTratamentoFiltrado =
            planoTratamento.planosTratamento.filter(
              (plano) => plano.ativo === true
            )[0];

          const formData = new FormData();
          formData.append("canDelete", false);
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamentoFiltrado._id}`,
              "PATCH",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        /////////////////////////////////////////////////////
        toast.success(
          `Pagamento efetuado com sucesso!${
            contaFiltrada.contaLiquidada ? " Conta Liquidada!" : ""
          }`
        );

        if (emitirDocumento === "emitirFRE") {
          updateContaCorrente(
            contaCorrenteParaState.contaCorrente,
            {
              IdcontaCorrente: contaCorrente._id,
              Idconta: contaFiltrada._id,
              IdParcela: idDaParcela,
            },
            "FRE"
          );
        } else {
          updateContaCorrente(contaCorrenteParaState.contaCorrente);
          //Atualizar a state para fechar a modal receber apos execucao de updateContaCorrente que tenta abrir a outra modal
          //Causa problemas pois handleCloseModalReceber no final fecha a modal q tentamos abrir em updateContaCorrente
          handleCloseModalReceber();
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      stopLoading();
      toast.error(
        "A parcela que pretende receber já se encontra recebida. Por favor faça refresh da página."
      );
      handleCloseModalReceber();
    }
  };

  const handleOverpayment = (draft, parcelaOriginal, dados, parcelaIndex) => {
    const outrasParcelas = draft.parcelas.filter(
      (p) => !p.parcelaPaga && p._id !== parcelaOriginal._id
    );

    let combinacoes = encontrarCombinacoesExatas(
      outrasParcelas,
      dados.quantiaPaga,
      parcelaOriginal
    );

    if (combinacoes.length === 0) {
      combinacoes = encontrarCombinacaoMaisProxima(
        outrasParcelas,
        dados.quantiaPaga,
        parcelaOriginal
      );
    }

    const combinacaoEscolhida = combinacoes[0];
    const totalCombinado = combinacaoEscolhida.reduce(
      (sum, p) => sum + p.quantiaPorPagar,
      0
    );

    // Criar nova parcela mantendo ID e dados originais
    const novaParcela = {
      _id: parcelaOriginal._id,
      dataLimitePagamento: parcelaOriginal.dataLimitePagamento,
      parcelaPaga: true,
      quantiaPaga: dados.quantiaPaga,
      quantiaPorPagar: 0,
      metodoPagamento: dados.metodoPagamento,
      caixa: dados.caixa.id ? dados.caixa.id : dados.caixa,
      dataPagamento: dados.dataPagamento,
      observacao: dados.observacao,
      recebidoPor: { data: new Date(), user: auth.userId },
      oculto: checkboxRef.current.checked,
      cancelamentosDocumentosEletronicos:
        parcelaOriginal.cancelamentosDocumentosEletronicos,
    };

    // Remover parcelas fundidas (exceto a original)
    const idsParaRemover = combinacaoEscolhida
      .filter((p) => p._id !== parcelaOriginal._id)
      .map((p) => p._id);

    draft.parcelas = draft.parcelas.filter(
      (p) => !idsParaRemover.includes(p._id) || p._id === parcelaOriginal._id
    );

    // Atualizar parcela original
    const index = draft.parcelas.findIndex(
      (p) => p._id === parcelaOriginal._id
    );
    draft.parcelas[index] = novaParcela;

    // Lidar com excesso
    const excesso = dados.quantiaPaga - totalCombinado;

    const proximaParcelaNaoPaga = draft.parcelas.find(
      (p) => !p.parcelaPaga && p._id !== parcelaOriginal._id
    );

    if (excesso !== 0 && proximaParcelaNaoPaga) {
      // Ajustar a parcela, independente se o excesso é positivo ou negativo
      proximaParcelaNaoPaga.quantiaPorPagar = Math.max(
        0,
        proximaParcelaNaoPaga.quantiaPorPagar - excesso
      );
    } else if (excesso < 0 && !proximaParcelaNaoPaga) {
      // Se excesso negativo e não há parcela para aplicar, criar nova parcela
      draft.parcelas.push({
        dataLimitePagamento: parcelaOriginal.dataLimitePagamento,
        quantiaPaga: 0,
        quantiaPorPagar: Math.abs(excesso),
        parcelaPaga: false,
      });
    }

    draft.nrParcelas = draft.parcelas.length;
    renumberParcelas(draft);
    return draft;
  };

  const handleRegularParcelPayment = (
    contaFiltrada,
    parcela,
    dados,
    parcelaIndex
  ) => {
    return produce(contaFiltrada, (draft) => {
      // Garantir que parcelas existe
      if (!draft.parcelas) draft.parcelas = [];

      // Caso 1: Pagamento exato
      if (dados.quantiaPaga === parcela.quantiaPorPagar) {
        draft.parcelas[parcelaIndex] = {
          ...parcela,
          quantiaPaga: dados.quantiaPaga,
          quantiaPorPagar: 0,
          parcelaPaga: true,
          metodoPagamento: dados.metodoPagamento,
          caixa: dados.caixa.id ? dados.caixa.id : dados.caixa,
          dataPagamento: dados.dataPagamento,
          observacao: dados.observacao,
          recebidoPor: { data: new Date(), user: auth.userId },
          oculto: checkboxRef.current.checked,
        };
      }
      // Caso 2: Pagamento parcial
      else if (dados.quantiaPaga < parcela.quantiaPorPagar) {
        const valorRestante = parcela.quantiaPorPagar - dados.quantiaPaga;

        draft.parcelas[parcelaIndex] = {
          ...parcela,
          quantiaPaga: dados.quantiaPaga,
          quantiaPorPagar: 0,
          parcelaPaga: true,
          metodoPagamento: dados.metodoPagamento,
          caixa: dados.caixa.id ? dados.caixa.id : dados.caixa,
          dataPagamento: dados.dataPagamento,
          observacao: dados.observacao,
          recebidoPor: { data: new Date(), user: auth.userId },
          oculto: checkboxRef.current.checked,
        };

        draft.parcelas.splice(parcelaIndex + 1, 0, {
          dataLimitePagamento: parcela.dataLimitePagamento,
          quantiaPaga: 0,
          quantiaPorPagar: valorRestante,
          parcelaPaga: false,
        });
      }
      // Caso 3: Pagamento maior que a parcela
      else if (dados.quantiaPaga > parcela.quantiaPorPagar) {
        handleOverpayment(draft, parcela, dados, parcelaIndex);
      }
      // Renumerar parcelas
      renumberParcelas(draft);
      draft.nrParcelas = draft.parcelas.length;
      return draft;
    });
  };

  // const updateParcela = (parcela, dados) => ({
  //   // ...parcela,
  //   quantiaPaga: dados.quantiaPaga,
  //   quantiaPorPagar: dados.quantiaPorPagar,
  //   metodoPagamento: dados.metodoPagamento,
  //   caixa: dados.caixa.id ? dados.caixa.id : dados.caixa,
  //   dataPagamento: dados.dataPagamento,
  //   parcelaPaga: dados.quantiaPorPagar === 0,
  //   observacao: dados.observacao,
  //   recebidoPor: {
  //     data: new Date(),
  //     user: auth.userId,
  //   },
  //   oculto: checkboxRef.current.checked,
  //   recebimentoCanceladoPor: null,
  // });

  function encontrarCombinacoesExatas(parcelas, quantiaPaga, parcelaOriginal) {
    const combinacoes = [];
    encontrarCombinacoesRecursivo(
      parcelas,
      quantiaPaga,
      [parcelaOriginal], // Começa com a parcela original
      quantiaPaga - parcelaOriginal.quantiaPorPagar, // Ajusta o valor restante
      0,
      combinacoes
    );
    return combinacoes;
  }

  function encontrarCombinacoesRecursivo(
    parcelas,
    valorAlvo,
    combinacaoAtual,
    valorRestante,
    index,
    combinacoes
  ) {
    if (valorRestante === 0) {
      combinacoes.push([...combinacaoAtual]);
      return;
    }

    if (index === parcelas.length || valorRestante < 0) {
      return;
    }

    const parcela = parcelas[index];

    // Evita incluir a parcela original novamente
    if (parcela._id !== combinacaoAtual[0]._id) {
      // Tenta incluir a parcela atual
      encontrarCombinacoesRecursivo(
        parcelas,
        valorAlvo,
        [...combinacaoAtual, parcela],
        valorRestante - parcela.quantiaPorPagar,
        index + 1,
        combinacoes
      );
    }

    // Exclui a parcela atual
    encontrarCombinacoesRecursivo(
      parcelas,
      valorAlvo,
      combinacaoAtual,
      valorRestante,
      index + 1,
      combinacoes
    );
  }

  function encontrarCombinacaoMaisProxima(
    parcelas,
    valorAlvo,
    parcelaOriginal
  ) {
    const valorRestante = valorAlvo - parcelaOriginal.quantiaPorPagar;
    const outrasParcelas = parcelas.filter(
      (p) => p._id !== parcelaOriginal._id
    );

    // Implementação simplificada usando abordagem "greedy"
    let melhorCombinacao = [parcelaOriginal];
    let melhorDiferenca = Math.abs(valorRestante);

    for (const parcela of outrasParcelas) {
      const novaDiferenca = Math.abs(valorRestante - parcela.quantiaPorPagar);
      if (novaDiferenca < melhorDiferenca) {
        melhorCombinacao = [parcelaOriginal, parcela];
        melhorDiferenca = novaDiferenca;
      }
    }

    return [melhorCombinacao];
  }

  //Funcao para rearanjar a numeracao das parcelas
  const renumberParcelas = (draft) => {
    const total = draft.parcelas.length;
    draft.parcelas.forEach((p, index) => {
      p.nrParcela = `${index + 1}/${total}`;
    });
    draft.nrParcelas = total;
  };

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalReceber}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
          id="modal__cinquentaPorCento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Receber receita de {nomeCliente}
          </h1>
          <div className="modal-receber__cabecalho">
            <div className="modal-receber__cabecalho__data-divida">
              <div className="modal-receber__cabecalho__data">
                <CalendarMonthIcon className="modal-receber__cabecalho__icon" />{" "}
                {moment().format("DD-MM-YYYY")}
              </div>
              <div className="modal-receber__cabecalho__divida">
                <PaidIcon className="modal-receber__cabecalho__icon" />
                {valorAPagar}$00
              </div>
            </div>
            <span className="modal-receber__cabecalho__meio-pagamento">
              Meio de pagamento
            </span>
          </div>
          <div className="modal-receber__tabs">
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Dinheiro" && "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Dinheiro")}
            >
              <LocalAtmIcon />
              Dinheiro
            </div>
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Credito" && "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Credito")}
            >
              <CreditCardIcon />
              Crédito
            </div>
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Internet banking" &&
                "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Internet banking")}
            >
              <AccountBalanceIcon />
              Transferência
            </div>
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Cheque" && "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Cheque")}
            >
              <RequestPageIcon />
              Cheque
            </div>
          </div>

          <div className="checkboxOcultoContainer">
            <span>Ocultar</span> <input type="checkbox" ref={checkboxRef} />
          </div>

          <div style={{ marginTop: "20px" }}>
            <InputText
              initialValue={descricao}
              label="Descrição"
              handleChange={descricaoChangeHandler}
            />
          </div>

          {showTab === "Dinheiro" && caixas && parcela && (
            <DinheiroTab
              handleCloseModalReceber={handleCloseModalReceber}
              valor={valorAPagar}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              orcamento={conta.orcamento}
            />
          )}

          {showTab === "Internet banking" && caixas && parcela && (
            <TransferenciaTab
              handleCloseModalReceber={handleCloseModalReceber}
              valor={valorAPagar}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              parcela={parcela}
            />
          )}

          {showTab === "Credito" && caixas && parcela && (
            <CreditoTab
              valor={valorAPagar}
              handleCloseModalReceber={handleCloseModalReceber}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              parcela={parcela}
            />
          )}

          {showTab === "Cheque" && caixas && parcela && (
            <ChequeTab
              valor={valorAPagar}
              handleCloseModalReceber={handleCloseModalReceber}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              parcela={parcela}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ModalReceber;
