import { useState, useEffect } from "react";
import ElementoParcela from "./ElementoParcela";
import moment from "moment/moment";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { v4 as uuidv4 } from "uuid";
import "./ModalAprovarOrcamento.scss";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputText from "../../../../../../shared/components/inputs/InputText/InputText";
import toast from "react-hot-toast";
import { useLoading } from "../../../../../../shared/context/LoadingContext";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import { updateCalculations } from "./discountCalculations";
// import InputNumber from "../../../../../../shared/components/inputs/InputNumber/InputNumber";

function ModalAprovarOrcamento({
  open,
  modalData,
  handleCloseModalAprovarOrcamento,
  sendRequest,
  auth,
  contaCorrente,
  updateOrcamentos,
  clinica,
}) {
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [caixaClinicaId, setCaixaClinicaId] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nrParcelas, setNrParcelas] = useState(modalData.conta.nrParcelas);
  const [totalParcelas, setTotalParcelas] = useState(() => {
    return modalData.conta.parcelas.reduce(
      (sum, parcela) => sum + parcela.quantiaPorPagar,
      0
    );
  });

  const [mensagemErro, setMensagemErro] = useState("");
  const [parcelas, setParcelas] = useState(modalData.conta.parcelas);
  const [showParcelarOrcamento, setShowParcelarOrcamento] = useState(
    modalData.conta.nrParcelas > 1
  );
  const [majoracao, setMajoracao] = useState(
    modalData.orcamento.majoracao || 0
  );
  const [serviceList, setServiceList] = useState(() =>
    modalData.orcamento.procedimentos.map((p) => ({
      ...p,
      show: true,
      comparticipacao_inps: getComparticipacaoINPS(
        p.tratamento,
        modalData.orcamento.created_at
      ),
    }))
  );

  const [dataCredencial, setDataCredencial] = useState(
    modalData.orcamento.dataCredencial
      ? moment(modalData.orcamento.dataCredencial)
      : null
  );
  const [nrCredencial, setNrCredencial] = useState(
    modalData.orcamento.nrCredencial || ""
  );
  const [beneficio, setBeneficio] = useState(modalData.orcamento.beneficio);

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${contaCorrente.clinica}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCaixaClinicaId(
          responseData.caixas.find((cai) => cai.caixa === "Clínica")._id
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, []);

  useEffect(() => {
    const { newSubtotal, newTotal } = updateCalculations(
      serviceList,
      modalData.orcamento.desconto,
      modalData.orcamento.tipoDesconto,
      modalData.orcamento.percentagemDescontoOriginal,
      majoracao
    );
    setSubtotal(newSubtotal);
    setTotal(newTotal);

    // Add this part to update totalParcelas
    const updatedTotalParcelas = parcelas.reduce(
      (sum, parcela) => sum + parcela.quantiaPorPagar,
      0
    );
    setTotalParcelas(updatedTotalParcelas);
  }, [modalData, serviceList]);

  useEffect(() => {
    resetParcelas();
  }, [total, showParcelarOrcamento]);

  useEffect(() => {
    // Quando o benefício muda, recalcule os preços
    if (beneficio !== modalData.orcamento.beneficio) {
      const recalculatedServices = recalculateServicePrices(
        serviceList,
        beneficio
      );
      setServiceList(recalculatedServices);
      updateParcelasAfterServiceChange(recalculatedServices);
    }
  }, [beneficio]);

  const resetParcelas = () => {
    let newParcelas = [];
    const installmentValue = Math.floor(total / parcelas.length);

    for (let i = 0; i < parcelas.length; i++) {
      if (i === parcelas.length - 1) {
        // Last installment gets any remaining cents
        newParcelas.push({
          ...parcelas[i],
          quantiaPorPagar: total - (parcelas.length - 1) * installmentValue,
        });
      } else {
        newParcelas.push({
          ...parcelas[i],
          quantiaPorPagar: installmentValue,
        });
      }
    }
    setParcelas(newParcelas);
  };

  const createParcela = (valor, diasAdicionar) => ({
    id: uuidv4(),
    dataLimitePagamento: moment().add(diasAdicionar, "days"),
    parcelaPaga: false,
    quantiaPaga: 0,
    quantiaPorPagar: valor,
  });

  const nrParcelasChange = (e) => {
    setMensagemErro("");
    const novoNrParcelas = Math.max(1, Number(e.target.value) || 1);
    let newParcelas = [];

    const valorPorParcela = Math.floor(total / novoNrParcelas);
    for (let i = 0; i < novoNrParcelas; i++) {
      newParcelas.push(
        createParcela(
          i === novoNrParcelas - 1
            ? total - (novoNrParcelas - 1) * valorPorParcela
            : valorPorParcela,
          (i + 1) *
            clinica.clinica.configuracoes.configuracoes.validadeParcela.validade
        )
      );
    }

    setParcelas(newParcelas);
    setNrParcelas(novoNrParcelas);
    setTotalParcelas(total);
  };

  const dataLimitePagamentoChange = (indice, valor) => {
    const parcelasCopy = [...parcelas];
    parcelasCopy[indice].dataLimitePagamento = valor;
    setParcelas(parcelasCopy);
  };

  const parcelasValueChange = (indice, valor) => {
    const parcelasCopy = [...parcelas];
    parcelasCopy[indice].quantiaPorPagar = Number(valor);

    let totalAteEssaParcela = parcelasCopy
      .slice(0, indice + 1)
      .reduce((sum, p) => sum + p.quantiaPorPagar, 0);

    if (totalAteEssaParcela < total) {
      const valorRestante = total - totalAteEssaParcela;
      const nrParcelasRestantes = parcelasCopy.length - (indice + 1);
      const valorPorParcela = Math.floor(valorRestante / nrParcelasRestantes);

      for (let i = indice + 1; i < parcelasCopy.length; i++) {
        parcelasCopy[i].quantiaPorPagar =
          i === parcelasCopy.length - 1
            ? valorRestante - (nrParcelasRestantes - 1) * valorPorParcela
            : valorPorParcela;
      }
    } else {
      for (let i = indice + 1; i < parcelasCopy.length; i++) {
        parcelasCopy[i].quantiaPorPagar = 0;
      }
    }

    setParcelas(parcelasCopy);
    setTotalParcelas(
      parcelasCopy.reduce((sum, p) => sum + p.quantiaPorPagar, 0)
    );
  };

  const showParcelarOrcamentoHandler = (e) => {
    const isChecked = e.target.checked;
    setShowParcelarOrcamento(isChecked);

    let newParcelas = [];
    if (isChecked) {
      // If checked, set to 2 parcelas
      const installmentValue = Math.floor(total / 2);
      newParcelas.push(
        createParcela(
          installmentValue,
          clinica.clinica.configuracoes.configuracoes.validadeParcela.validade,
          false
        )
      );
      newParcelas.push(createParcela(total - installmentValue, 30, false));
      setNrParcelas(2);
    } else {
      newParcelas.push(createParcela(total, 0, false));

      setNrParcelas(1);
    }

    setParcelas(newParcelas);
    setTotalParcelas(total);
  };

  const handleRemoveTratamento = (index) => {
    const updatedServiceList = serviceList.map((service, i) =>
      i === index ? { ...service, show: false } : service
    );

    // Verifica se ainda há serviços visíveis após a remoção
    const visibleServicesAfterRemoval = updatedServiceList.filter(
      (service) => service.show
    );

    // Se não houver mais serviços visíveis, apenas atualize a lista sem mudar o benefício
    if (visibleServicesAfterRemoval.length === 0) {
      setServiceList(updatedServiceList);
      updateParcelasAfterServiceChange(updatedServiceList);
      return;
    }

    // Determinar o benefício apropriado
    const appropriateBeneficio = determineAppropriateBeneficio(
      updatedServiceList,
      modalData.orcamento.beneficio
    );

    // Se houver uma mudança no benefício
    if (beneficio !== appropriateBeneficio) {
      if (appropriateBeneficio === "Particular") {
        toast.error(
          "Não restam mais tratamentos cobertos pelo INPS. O orçamento será transformado em Particular."
        );

        // Se mudar para Particular, a majoração deve ficar a null
        if (majoracao) {
          setMajoracao(0);
        }
      } else {
        toast.success("O orçamento voltou a ter cobertura INPS.");
      }

      // Atualizar o benefício
      setBeneficio(appropriateBeneficio);

      // Recalcular os preços
      const recalculatedServices = recalculateServicePrices(
        updatedServiceList,
        appropriateBeneficio
      );

      setServiceList(recalculatedServices);
      // O updateParcelasAfterServiceChange precisa considerar a majoração zerada se for o caso
      updateParcelasAfterServiceChange(
        recalculatedServices,
        appropriateBeneficio === "Particular" ? 0 : majoracao
      );
      return;
    }

    setServiceList(updatedServiceList);
    updateParcelasAfterServiceChange(updatedServiceList);
  };

  // Modificar a função handleAddTratamento para lidar com a majoração da mesma forma
  const handleAddTratamento = (index) => {
    const updatedServiceList = serviceList.map((service, i) =>
      i === index ? { ...service, show: true } : service
    );

    // Determinar o benefício apropriado
    const appropriateBeneficio = determineAppropriateBeneficio(
      updatedServiceList,
      modalData.orcamento.beneficio
    );

    // Se houver uma mudança no benefício
    if (beneficio !== appropriateBeneficio) {
      if (appropriateBeneficio === "INPS") {
        toast.success(
          "Você adicionou um tratamento com cobertura INPS. O orçamento voltará a ser INPS."
        );
        // Ao voltar para INPS, a majoração continua como estava (não precisa fazer nada)
      } else {
        // Se mudar para Particular, a majoração fica a null
        if (majoracao) {
          setMajoracao(0);
        }
      }

      // Atualizar o benefício
      setBeneficio(appropriateBeneficio);

      // Recalcular os preços
      const recalculatedServices = recalculateServicePrices(
        updatedServiceList,
        appropriateBeneficio
      );

      setServiceList(recalculatedServices);
      // O updateParcelasAfterServiceChange precisa considerar a majoração zerada se for o caso
      updateParcelasAfterServiceChange(
        recalculatedServices,
        appropriateBeneficio === "Particular" ? 0 : majoracao
      );
      return;
    }

    setServiceList(updatedServiceList);
    updateParcelasAfterServiceChange(updatedServiceList);
  };

  const preSubmit = () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const parcelasFinal = [...parcelas];

    if (total < 0) {
      setMensagemErro("O total não pode ser um valor negativo!");
      setIsSubmitting(false);
      return;
    }

    if (totalParcelas !== total) {
      setMensagemErro(
        `A soma dos valores (${totalParcelas}$00) é ${
          totalParcelas < total ? "inferior" : "superior"
        } ao valor total do orçamento (${total}$00).`
      );
      setIsSubmitting(false);
      return;
    }

    const negativeParcela = parcelasFinal.find((p) => p.quantiaPorPagar < 0);
    if (negativeParcela) {
      setMensagemErro("Não é permitido ter parcelas com valores negativos.");
      setIsSubmitting(false);
      return;
    }

    //Isenção só é permitido quando existe um desconto
    if (parcelasFinal.some((p) => p.quantiaPorPagar === 0)) {
      if (total === 0 && modalData.orcamento.desconto <= 0) {
        // Se total=0 mas não há desconto, não permitir (caso da majoração)
        setMensagemErro(
          "Não é permitida isenção sem desconto. Ajuste a majoração para manter um valor a pagar."
        );
        setIsSubmitting(false);
        return;
      } else if (total > 0) {
        // Se total>0, não permitir parcelas zeradas
        setMensagemErro("Preencha todas as parcelas com valor superior a 0.");
        setIsSubmitting(false);
        return;
      }
    }

    const parcelasSemData = parcelasFinal.filter((p) => !p.dataLimitePagamento);
    const parcelasComErroEmData = parcelasFinal.filter(
      (p) =>
        !moment.isMoment(p.dataLimitePagamento) ||
        !p.dataLimitePagamento?.isValid()
    );

    //Validação pa ra credenciais
    if (beneficio === "INPS") {
      if (!dataCredencial) {
        setMensagemErro("A data de credencial é obrigatória");
        setIsSubmitting(false);
        return;
      } else if (
        !moment.isMoment(dataCredencial) ||
        !dataCredencial?.isValid()
      ) {
        setMensagemErro("Preencha a data de credencial corretamente.");
        setIsSubmitting(false);
        return;
      }

      if (!nrCredencial || nrCredencial.trim() === "") {
        setMensagemErro(
          "O número de credencial é obrigatório para orçamentos INPS"
        );
        setIsSubmitting(false);
        return;
      }
    }

    if (parcelasSemData.length > 0) {
      setMensagemErro("Preencha as datas de vencimento em todas as parcelas");
      setIsSubmitting(false);
      return;
    }

    if (parcelasComErroEmData.length > 0) {
      setMensagemErro(
        "As datas de vencimento não estão preenchidas corretamente"
      );
      setIsSubmitting(false);
      return;
    }

    const parcelasLength = parcelasFinal.length;
    parcelasFinal.forEach((parcela, index) => {
      parcela.nrParcela = `${index + 1}/${parcelasLength}`;
      delete parcela.id;
    });

    submit(parcelasFinal);
  };

  const submit = async (parcelasFinal) => {
    startLoading();

    try {
      let planoTratamentoCriado;
      const shownServices = serviceList.filter((s) => s.show === true);
      const hiddenServices = serviceList.filter((s) => s.show === false);
      if (shownServices.length === 0) {
        toast.error("Nenhum procedimento selecionado!");
      } else {
        const procedimentosFiltrados = [];
        let orcamentoRetornado;

        shownServices.forEach((pr) => {
          procedimentosFiltrados.push({
            tratamento: pr.tratamento._id,
            dente: pr.dente,
            faces: pr.faces,
            procedimento_completo: false,
          });
        });

        const formData = new FormData();
        formData.append(
          "procedimentos",
          JSON.stringify(procedimentosFiltrados)
        );
        formData.append("cliente", modalData.orcamento.cliente._id);
        formData.append("clinica", modalData.orcamento.clinica._id);
        formData.append("orcamento", modalData.orcamento._id);
        formData.append(
          "medico_responsavel",
          modalData.orcamento.medico_responsavel._id
        );
        formData.append("criadoPor", auth.userId);

        //Gravando plano de tratamento
        try {
          planoTratamentoCriado = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
        //Gravando plano de tratamento, transformando orcamento
        //Se orçamentarmos tudo, atualizamos o orçamento para ficar como transformado
        //Atualizamos também os dados da majoração e do novo total se aplicável
        if (modalData.orcamento.procedimentos.length === shownServices.length) {
          const formData2 = new FormData();
          formData2.append("transformado", true);
          formData2.append("utilizador", auth.userId);
          formData2.append(
            "planoTratamento",
            planoTratamentoCriado.planoTratamento._id
          );

          if (beneficio === "INPS" && majoracao > 0) {
            formData2.append("comMajoracao", true);
            formData2.append("majoracao", majoracao);
            formData2.append("total", total);
          } else {
            formData2.append("comMajoracao", false);
            formData2.append("total", total);
          }

          try {
            orcamentoRetornado = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/transformar/${modalData.orcamento._id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        } else {
          //Caso não orçamentarmos tudo, atualizamos o orçamento, e a avaliação
          //Tambem atualizamos o desconto caso for percentagem
          const formDataOrcamento = new FormData();

          const newProcedimentos = shownServices.map((s) => {
            const { comparticipacao_inps, ...rest } = s;
            return {
              ...rest,
              tratamento: s.tratamento._id,
            };
          });

          if (beneficio === "INPS" && majoracao > 0) {
            formDataOrcamento.append("comMajoracao", true);
            formDataOrcamento.append("majoracao", majoracao);
          } else {
            formDataOrcamento.append("comMajoracao", false);
          }

          formDataOrcamento.append("total", total);
          formDataOrcamento.append(
            "procedimentos",
            JSON.stringify(newProcedimentos)
          );
          formDataOrcamento.append("transformado", true);
          formDataOrcamento.append("beneficio", beneficio);
          formDataOrcamento.append("subtotal", subtotal);
          formDataOrcamento.append(
            "desconto",
            modalData.orcamento.tipoDesconto === "%"
              ? Math.round(
                  (subtotal * modalData.orcamento.percentagemDescontoOriginal) /
                    100
                )
              : modalData.orcamento.desconto
          );
          formDataOrcamento.append(
            "tipoDesconto",
            modalData.orcamento.tipoDesconto
          );
          formDataOrcamento.append("terminado", false);
          formDataOrcamento.append(
            "observacoes",
            modalData.orcamento.observacoes
          );
          formDataOrcamento.append(
            "planoTratamento",
            planoTratamentoCriado.planoTratamento._id
          );
          formDataOrcamento.append("utilizador", auth.userId);

          formDataOrcamento.append("acao", "ORCAMENTACAO_PARCIAL");

          try {
            orcamentoRetornado = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/${modalData.orcamento._id}`,
              "PATCH",
              formDataOrcamento,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          //Atualizando a avaliacao
          const newProcedimentosAvaliacao =
            modalData.orcamento.avaliacao.procedimentos.map((procedimento) => {
              if (
                hiddenServices.some(
                  (service) => service.id === procedimento._id
                )
              ) {
                return {
                  ...procedimento,
                  orcamentado: false,
                  tratamento: procedimento.tratamento._id,
                };
              }
              return {
                ...procedimento,
                tratamento: procedimento.tratamento._id,
              };
            });

          const formDataAvaliacao = new FormData();
          formDataAvaliacao.append(
            "procedimentos",
            JSON.stringify(newProcedimentosAvaliacao)
          );
          formDataAvaliacao.append("transformado", true);
          formDataAvaliacao.append(
            "cliente",
            modalData.orcamento.avaliacao.cliente
          );
          formDataAvaliacao.append("atualizadoPor", auth.userId);
          formDataAvaliacao.append(
            "medico_responsavel",
            modalData.orcamento.avaliacao.medico_responsavel
          );
          formDataAvaliacao.append("terminado", false);
          formDataAvaliacao.append(
            "observacoes",
            modalData.orcamento.avaliacao.observacoes
          );

          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${modalData.orcamento.avaliacao._id}`,
              "PATCH",
              formDataAvaliacao,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        //Atualizando a conta corrente
        const formData3 = new FormData();

        const filteredDadosConta = {
          ...modalData.conta,
          aprovado: true,
          contaLiquidada: false,
          nrParcelas: parcelasFinal.length,
          parcelas: parcelasFinal,
          total: total,
        };

        //Se for uma conta de isenção
        if (total === 0 && modalData.orcamento.desconto > 0) {
          filteredDadosConta.parcelas = [
            {
              dataLimitePagamento: moment().add(
                clinica.clinica.configuracoes.configuracoes.validadeParcela
                  .validade,
                "days"
              ),
              dataPagamento: new Date(),
              parcelaPaga: true,
              metodoPagamento: "Dinheiro",
              quantiaPaga: 0,
              quantiaPorPagar: 0,
              nrParcela: "1/1",
              observacao: "Parcela de isenção",
              caixa: caixaClinicaId,
            },
          ];
          filteredDadosConta.nrParcelas = 1;
        }
        const contasDaContaCorrente = contaCorrente.contas.filter(
          (conta) => conta.id !== modalData.conta.id
        );
        contasDaContaCorrente.push(filteredDadosConta);

        formData3.append("contas", JSON.stringify(contasDaContaCorrente));
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        if (nrCredencial) {
          try {
            if (!nrCredencial || nrCredencial.trim().length === 0) {
              toast.error("Por Favor introduza o número do credencial");
            } else {
              const formData = new FormData();
              formData.append("dataCredencial", dataCredencial);
              formData.append("nrCredencial", nrCredencial);
              formData.append("utilizador", auth.userId);

              orcamentoRetornado = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/credencial/${modalData.orcamento._id}`,
                "PATCH",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );

              orcamentoRetornado.orcamento.nrCredencial = nrCredencial;
              orcamentoRetornado.orcamento.dataCredencial = dataCredencial;
            }
          } catch (err) {
            console.error("err", err);
          }
        }
        toast.success(
          `Orçamento ${
            total === 0 ? "Isento de Pagamento, " : ""
          }aprovado com sucesso!`
        );
        updateOrcamentos(orcamentoRetornado.orcamento);
        handleCloseModalAprovarOrcamento();
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const nrCredencialChangeHandler = (value) => {
    setNrCredencial(value);
  };

  const majoracaoChangeHandler = (event) => {
    const value = parseFloat(event.target.value) || 0;
    let valorMajoracao = value;

    if (
      value > 0 &&
      updateCalculations(
        serviceList,
        modalData.orcamento.desconto,
        modalData.orcamento.tipoDesconto,
        modalData.orcamento.percentagemDescontoOriginal,
        valorMajoracao
      ).newTotal < 0
    ) {
      valorMajoracao =
        value -
        Math.abs(
          updateCalculations(
            serviceList,
            modalData.orcamento.desconto,
            modalData.orcamento.tipoDesconto,
            modalData.orcamento.percentagemDescontoOriginal,
            valorMajoracao
          ).newTotal
        );
    }

    let { newTotal } = updateCalculations(
      serviceList,
      modalData.orcamento.desconto,
      modalData.orcamento.tipoDesconto,
      modalData.orcamento.percentagemDescontoOriginal,
      valorMajoracao
    );

    setMajoracao(valorMajoracao);
    setTotal(newTotal);
    setTotalParcelas(newTotal);
    resetParcelas();
  };

  const updateParcelasAfterServiceChange = (
    updatedServiceList,
    majoracaoValue = majoracao
  ) => {
    const { newTotal } = updateCalculations(
      updatedServiceList,
      modalData.orcamento.desconto,
      modalData.orcamento.tipoDesconto,
      modalData.orcamento.percentagemDescontoOriginal,
      majoracaoValue // Use o valor passado como parâmetro
    );

    const updatedParcelas = parcelas.map((parcela, index) => {
      // Distribute the remaining total evenly
      const parcelasCount = parcelas.length;
      const newQuantiaPorPagar = Math.floor(newTotal / parcelasCount);

      if (index === parcelas.length - 1) {
        // Last parcela gets any remaining cents
        return {
          ...parcela,
          quantiaPorPagar: newTotal - (parcelasCount - 1) * newQuantiaPorPagar,
        };
      } else {
        return {
          ...parcela,
          quantiaPorPagar: newQuantiaPorPagar,
        };
      }
    });

    setParcelas(updatedParcelas);
    setTotal(newTotal);
    setTotalParcelas(newTotal);
  };

  function getComparticipacaoINPS(tratamento, orcamentoCreationDate) {
    const creationDate = new Date(orcamentoCreationDate);

    // Sort historicoPrecos by date in descending order
    const sortedHistorico = tratamento.historicoPrecos.sort(
      (a, b) => new Date(b.data) - new Date(a.data)
    );

    // Find the most recent price change before or on the orçamento creation date
    const relevantPriceChange = sortedHistorico.find(
      (priceChange) => new Date(priceChange.data) <= creationDate
    );

    // If a relevant price change is found, use its comparticipacao_inps value
    // Otherwise, fall back to the current value in the tratamento object
    return relevantPriceChange
      ? relevantPriceChange.comparticipacao_inps
      : tratamento.comparticipacao_inps;
  }

  //Para verificar se todos os procedimentos são INPS senão o orçamento fica particular
  //Quando se remove todos os procedimentos cobertos por INPS
  const recalculateServicePrices = (services, newBeneficio) => {
    return services.map((service) => {
      if (service.show) {
        let updatedPrTotal;

        if (newBeneficio === "Particular") {
          // Se for Particular, sempre usa o preço particular
          updatedPrTotal = service.tratamento.preco_particular;
        } else {
          // Se for INPS:
          if (service.tratamento.coberturaInps) {
            // Para procedimentos com cobertura INPS, usa preço beneficiário
            updatedPrTotal = service.tratamento.preco_beneficiario;
          } else {
            // Para procedimentos sem cobertura INPS, usa preço particular mesmo que o orçamento seja INPS
            updatedPrTotal = service.tratamento.preco_particular;
          }
        }

        return {
          ...service,
          pr_total: updatedPrTotal,
        };
      }
      return service;
    });
  };

  const determineAppropriateBeneficio = (services, originalBeneficioValue) => {
    // Se o benefício original não era INPS, mantenha o original
    if (originalBeneficioValue !== "INPS") {
      return originalBeneficioValue;
    }

    // Filtra apenas os serviços visíveis
    const visibleServices = services.filter((service) => service.show);

    // Se não houver serviços visíveis, mantenha o benefício atual
    // Isso evita conversões desnecessárias quando a lista estiver vazia
    if (visibleServices.length === 0) {
      return originalBeneficioValue;
    }

    // Se algum serviço visível tem cobertura INPS, use INPS
    const hasINPSCoverage = visibleServices.some(
      (service) => service.tratamento.coberturaInps
    );

    return hasINPSCoverage ? "INPS" : "Particular";
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalAprovarOrcamento}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
        id="modal__aprovar-orcamento"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Aprovar Orçamento {modalData.orcamento.codigo} - {beneficio}
        </h1>
        <span className="aprovar-orcamento__modal__regras-aprovacao">
          Ao aprovar esse orçamento, o plano de tratamento é criado
          automaticamente na aba Planos Tratamento e os valores são lançados em
          Débito.
        </span>

        <div className="aprovar-orcamento__modal__procedimentos__container">
          <div
            className={`aprovar-orcamento__modal__procedimentos__container__item ${
              beneficio === "Particular"
                ? " aprovar-orcamento__modal__procedimentos__container__item--particular"
                : ""
            }`}
          >
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Tratamento
            </span>
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Dentes/Região
            </span>
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Valor
            </span>
            {beneficio === "INPS" && (
              <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
                INPS
              </span>
            )}
            <span className="aprovar-orcamento__modal__procedimentos__container__item__title">
              Ação
            </span>
          </div>
          {serviceList &&
            serviceList.length > 0 &&
            serviceList.map((s, index) => (
              <div
                className={`aprovar-orcamento__modal__procedimentos__container__item ${
                  beneficio === "Particular"
                    ? " aprovar-orcamento__modal__procedimentos__container__item--particular"
                    : ""
                }`}
                key={index}
              >
                <span
                  className={
                    s.show === false
                      ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                      : ""
                  }
                >
                  {s.tratamento.designacao}
                </span>
                <span
                  className={
                    s.show === false
                      ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                      : ""
                  }
                >
                  {s.dente}
                </span>
                <span
                  className={
                    s.show === false
                      ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                      : ""
                  }
                >
                  {s.pr_total}
                </span>

                {beneficio === "INPS" && (
                  <span
                    className={
                      s.show === false
                        ? "aprovar-orcamento__modal__procedimentos__container__item__out"
                        : ""
                    }
                  >
                    {s.comparticipacao_inps}
                  </span>
                )}
                {s.show && (
                  <Tooltip title="Remover">
                    <IconButton
                      onClick={() => handleRemoveTratamento(index)}
                      className="modalAprovar__icon"
                    >
                      <CloseIcon className="modalAprovar__icon--remove" />
                    </IconButton>
                  </Tooltip>
                )}

                {!s.show && (
                  <Tooltip title="Adicionar">
                    <IconButton
                      onClick={() => handleAddTratamento(index)}
                      className="modalAprovar__icon"
                    >
                      <AddIcon className="modalAprovar__icon--add" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ))}
        </div>

        <div className="aprovar-orcamento__modal__valor-orcamento-container">
          Valor Total do Orçamento:{" "}
          <span className="aprovar-orcamento__modal__valor-orcamento-span">
            {total}$00
          </span>{" "}
          <>
            &rArr; Soma dos valores:{" "}
            <span className="aprovar-orcamento__modal__valor-orcamento-span">
              {totalParcelas}$00
            </span>
          </>
        </div>
        <div className="aprovar-orcamento__modal__valor-orcamento-container">
          Desconto:{" "}
          <span className="aprovar-orcamento__modal__valor-orcamento-span">
            {modalData.orcamento.tipoDesconto === "%"
              ? `${modalData.orcamento.percentagemDescontoOriginal}% => ${(
                  subtotal - total
                ).toLocaleString("pt-BR")}$00`
              : modalData.orcamento.desconto + "$00"}
          </span>{" "}
        </div>

        <div className="aprovarOrcamento__parcelasCredencial__container">
          <div className="aprovarOrcamento__parcelasCredencial__container__item">
            <div className="aprovar-orcamento__modal__descricao-container">
              <div className="parcelarOrcamentoDiv">
                <label htmlFor="" className="parcelarOrcamento">
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={showParcelarOrcamentoHandler}
                    checked={showParcelarOrcamento}
                  />
                  Parcelar Orcamento
                </label>
                {showParcelarOrcamento && (
                  <div className="parcelarOrcamentoDivHorizontal">
                    Parcelar em
                    <input
                      type="number"
                      className="aprovar-orcamento__modal__nrParcelas"
                      value={nrParcelas}
                      onChange={nrParcelasChange}
                      min={1}
                    />
                    {nrParcelas === 1 ? "vez" : "vezes"}
                  </div>
                )}
              </div>
            </div>
            {parcelas &&
              parcelas.map((parcela, index) => (
                <ElementoParcela
                  key={index}
                  dataLimitePagamento={parcela.dataLimitePagamento}
                  quantiaPorPagar={parcela.quantiaPorPagar}
                  indiceParcela={index}
                  totalParcelas={nrParcelas}
                  parcelasValueChange={parcelasValueChange}
                  dataLimitePagamentoChange={dataLimitePagamentoChange}
                />
              ))}
          </div>

          {beneficio === "INPS" && (
            <div className="modalCredencialContainer aprovarOrcamento__parcelasCredencial__container__item">
              <h2>Inserir dados de crendencial</h2>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data de Inicio*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataCredencial(value)}
                  value={dataCredencial}
                  clearable
                />
              </LocalizationProvider>

              <InputText
                id="nrCredencialInput"
                className="adicionar-entrada__modal__container--span2"
                label="Nº do Credencial*"
                initialValue={nrCredencial}
                handleChange={nrCredencialChangeHandler}
              />

              <h2>Majoração se aplicável</h2>
              {/* <InputNumber
                id="majoracaoInput"
                className="adicionar-entrada__modal__container--span2"
                label="Majoração"
                initialValue={majoracao}
                handleChange={majoracaoChangeHandler}
                min={0}
              /> */}

              <TextField
                label="Majoração"
                type="number"
                value={majoracao}
                onChange={majoracaoChangeHandler}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: "any", // Permite valores decimais
                  },
                }}
                fullWidth
                variant="outlined"
                className="adicionar-entrada__modal__container--span2"
              />
            </div>
          )}
        </div>
        {mensagemErro && (
          <div className="fluxo-atendimento__modal__erros">{mensagemErro}</div>
        )}
        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModalAprovarOrcamento}
            style={{ display: "block" }}
          >
            Fechar
          </span>
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={preSubmit}
            style={{ display: "block" }}
          >
            {isSubmitting ? "Aprovando..." : "Aprovar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalAprovarOrcamento;
