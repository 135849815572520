import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import moment from "moment";

import "./FiltrosRelatorio.scss";
import InfoRelatorio from "../InfoRelatorio/InfoRelatorio";

function FiltrosRelatorio({
  tiposRelatorios,
  gerarRelatorioHandler,
  exportarFicheiroHandler,
  relatorioCaps, // Adicionada para verificar quais botões mostrar
}) {
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(1, "months").startOf("day")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const [tipoRelatorio, setTipoRelatorio] = useState("");
  const [esconderDatas, setEsconderDatas] = useState(false);

  // Verifica se o botão de gerar deve estar desabilitado
  const isGerarDesabilitado = () => {
    return (
      !tipoRelatorio ||
      (relatorioCaps[tipoRelatorio] && !relatorioCaps[tipoRelatorio].gerar)
    );
  };

  // Verifica se o botão de exportar deve estar desabilitado
  const isExportarDesabilitado = () => {
    return (
      !tipoRelatorio ||
      (relatorioCaps[tipoRelatorio] && !relatorioCaps[tipoRelatorio].exportar)
    );
  };

  const changeTipoRelatorioHandler = (e) => {
    setTipoRelatorio(e.target.value);
    const tipoRelatorioFiltrado = tiposRelatorios.find(
      (t) => t.value === e.target.value
    );
    if (tipoRelatorioFiltrado?.esconderData) {
      setEsconderDatas(true);
    } else {
      setEsconderDatas(false);
    }
  };

  return (
    <>
      <div className="relatorio__filtros__container">
        {!esconderDatas && (
          <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => setDataInicial(value)}
                value={dataInicial}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => setDataFinal(value)}
                value={dataFinal}
              />
            </LocalizationProvider>
          </>
        )}

        <FormControl
          className="relatorio__filtros__container__selectContainer"
          style={{ minWidth: "350px" }}
        >
          <InputLabel id="demo-simple-select-label">Tipo Relatório</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tipoRelatorio}
            label="Periodo"
            onChange={changeTipoRelatorioHandler}
          >
            {tiposRelatorios.map((tipo, index) => (
              <MenuItem key={index} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {!isGerarDesabilitado() && (
          <span
            onClick={gerarRelatorioHandler.bind(
              null,
              dataInicial,
              dataFinal,
              tipoRelatorio
            )}
            className="relatorio__filtros__container__btn relatorio__filtros__container__btn--green"
          >
            Gerar Relatório
          </span>
        )}

        {!isExportarDesabilitado() && (
          <span
            onClick={exportarFicheiroHandler.bind(
              null,
              dataInicial,
              dataFinal,
              tipoRelatorio
            )}
            className="relatorio__filtros__container__btn relatorio__filtros__container__btn--blue"
          >
            Exportar Ficheiro
          </span>
        )}
      </div>
      <InfoRelatorio tipoRelatorio={tipoRelatorio} />
    </>
  );
}

export default FiltrosRelatorio;
