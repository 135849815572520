import React from "react";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// Objeto com informações específicas para cada tipo de relatório
const informacoesRelatorioPorTipo = {
  // Padrão para quando nenhum relatório é selecionado
  default: {
    title: "Selecione um relatório",
    description:
      "Escolha um tipo de relatório para visualizar informações específicas.",
    icon: <InfoIcon />,
    severity: "info",
  },

  // Relatório de Credenciais
  credenciais: {
    title: "Relatório de Credenciais INPS",
    description:
      "Este relatório exibe os orçamentos aprovados do tipo INPS dentro do período selecionado. É possível marcar orçamentos como enviados à INPS e exportar um relatório formatado seguindo os padrões exigidos pela instituição.",
    detailedInfo: [
      {
        label: "Vermelho",
        description: "Credenciais com mais de 6 meses (expiradas)",
        color: "#f44336",
      },
      {
        label: "Laranja",
        description: "Credenciais com mais de 5 meses (quase expirando)",
        color: "#ff9800",
      },
      {
        label: "Visto verde",
        description: "Orçamentos já enviados à INPS",
        color: "#4caf50",
      },
    ],
    icon: <ReceiptLongIcon />,
    severity: "info",
  },

  // Relatório de Aprovações de Orçamentos
  aprovacoes: {
    title: "Relatório de Aprovações de Orçamentos",
    description:
      "Este relatório mostra todos os orçamentos aprovados no período selecionado, com detalhes de data, valor, benefício e médico responsável.",
    detailedInfo: [
      {
        label: "Exportar",
        description:
          "Permite gerar um arquivo Excel com todos os dados de aprovações",
      },
      {
        label: "Filtrar",
        description:
          "Os resultados podem ser filtrados por tipo de benefício e médico",
      },
    ],
    icon: <AssignmentIcon />,
    severity: "info",
  },

  // Adicione mais tipos de relatórios conforme necessário
};

const InfoRelatorio = ({ tipoRelatorio = "" }) => {
  // Obter informações específicas para o tipo de relatório selecionado ou usar o padrão
  const infoRelatório =
    informacoesRelatorioPorTipo[tipoRelatorio] ||
    informacoesRelatorioPorTipo.default;

  return (
    <Alert
      severity={infoRelatório.severity}
      variant="outlined"
      icon={infoRelatório.icon}
      sx={{ mb: 3, mt: 2 }}
    >
      <AlertTitle>{infoRelatório.title}</AlertTitle>
      <Typography variant="body2">{infoRelatório.description}</Typography>

      {infoRelatório.detailedInfo && (
        <Box sx={{ mt: 1 }}>
          {infoRelatório.detailedInfo.map((info, index) => (
            <Typography variant="body2" key={index}>
              <strong style={info.color ? { color: info.color } : {}}>
                • {info.label}:
              </strong>{" "}
              {info.description}
            </Typography>
          ))}
        </Box>
      )}
    </Alert>
  );
};

export default InfoRelatorio;
