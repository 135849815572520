import { useState, useEffect } from "react";
import InputNumber from "../../../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../../../shared/components/inputs/InputText/InputText";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import InputSelect from "../../../../../../../shared/components/inputs/InputSelect/InputSelect";

import { fixSpaces } from "../../../../../../../shared/util/helperFunctions";
import "./ModalEntradaDebito.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  // "Cheque Visado",
  // "Transferência de crédito",
  "Internet banking",
  // "Cartão do banco",
  // "Débito direto da conta bancária",
  "Cartão de crédito",
  // "Cartão de débito",
];

function ModalEditarEntradaDebito({
  openModalEditarEntrada,
  handleCloseModalEditarEntrada,
  sendRequest,
  clinicaId,
  entrada,
  updateEntradas,
  auth,
  emitirDocEletronicoEntradas,
  cliente,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalEditarEntrada);
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [categoriasResponse, caixasResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/entrada/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );

        //Verificar se a categoria ja foi desativada ou não
        //Se sim, adicionamo-la à lista de categorias pois se trata de uma edição
        const isCategoriaActive = categoriasFormatted.find(
          (catF) => catF.id === entrada.categoria._id
        );
        if (!isCategoriaActive) {
          categoriasFormatted.push({
            id: entrada.categoria._id,
            value: entrada.categoria.categoria,
          });
        }

        setCategorias(categoriasFormatted);
        setCategoria(categoriasFormatted[0]?.id);

        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));

        //Verificar se a caixa ja foi desativada ou não
        //Se sim, adicionamo-la à lista de caixas pois se trata de uma edição
        const isCaixaActive = caixasFormatted.find(
          (caiF) => caiF.id === entrada.caixa._id
        );
        if (!isCaixaActive) {
          caixasFormatted.push({
            id: entrada.caixa._id,
            value: entrada.caixa.caixa,
          });
        }

        setCaixas(caixasFormatted);
        setCaixa(caixasFormatted[0]?.id);

        setCategoria(entrada.categoria.id);
        setCaixa(entrada.caixa.id);
        setValor(entrada.valor);
        setDescricao(entrada.descricao);
        setObservacao(entrada.observacao || "");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [clinicaId, sendRequest, auth.token]);

  useEffect(() => {
    setOpenModal(openModalEditarEntrada);
  }, [openModalEditarEntrada]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const editarEntradaHandler = async (emit) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("tipoEntrada", "reembolso");

      formData.append("observacoes", observacao);
      formData.append("descricao", fixSpaces(descricao));
      formData.append("valor", valor);
      formData.append("categoria", categoria);
      formData.append("atualizadoPor", auth.userId);

      formData.append("cliente", cliente.id);
      formData.append("entradaRecebida", true);
      formData.append("recebidoPor", auth.userId);
      formData.append("caixa", caixa);
      formData.append("metodoPagamento", metodoPagamento);

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      const entradaAtualizada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/${entrada._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if (emit) {
        emitirDocEletronicoEntradas(
          entradaAtualizada.entrada,
          null,
          "FRE",
          "atualizar"
        );
      } else {
        toast.success("Entrada atualizada e recebida com sucesso!");
        updateEntradas(entradaAtualizada.entrada, "atualizar");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      handleCloseModalEditarEntrada();
      setIsSubmitting(false);
    }
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const preGuardarEntradaHandler = (e, emit) => {
    if (valor === 0 || valor < 0) {
      toast.error("O valor da entrada não pode ser 0 ou menor que 0.");
    } else if (!descricao || descricao.trim() === "") {
      toast.error("Introduza a descrição");
    } else if (!cliente) {
      toast.error("Escolha o Cliente");
    } else if (!categoria) {
      toast.error("Introduza uma categoria");
    } else if (!caixa) {
      toast.error("Introduza uma categoria");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      editarEntradaHandler(emit);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalEditarEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Editar entrada - {entrada.descricao}
          </h1>
          {/* {entrada && ( */}
          <div className="adicionar-entrada__modal__container">
            <InputText
              className="adicionar-entrada__modal__container--span2"
              label="Descrição *"
              initialValue={descricao}
              handleChange={descricaoChangeHandler}
            />

            <InputNumber
              className="adicionar-entrada__modal__container--span1"
              label="Valor *"
              initialValue={valor}
              handleChange={valorChangeHandler}
            />

            <InputSelect
              className="adicionar-entrada__modal__container--span1"
              label="Forma de pagamento"
              initialValue={metodoPagamento}
              handleChange={metodoPagamentoChangeHandler}
              items={formasPagamento}
            />

            {categorias && categoria && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCategoria(e.target.value)}
                  className="customInputSelect"
                  value={categoria}
                >
                  {categorias &&
                    categoria &&
                    categorias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Categoria</label>
              </div>
            )}

            {caixas && caixa && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
              </div>
            )}

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              className="adicionar-entrada__modal__container__textarea"
            ></textarea>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEditarEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={preGuardarEntradaHandler}
            >
              {isSubmitting ? "Recebendo..." : "Receber"}
            </span>
            {/* Não dá para colocar guardar e emitir fre pois não posso colocar o campo metodoPagamento e dataPagamento numa modal que o usuário pode simplismente estar a guardar para não receber*/}
            <span
              disabled={isSubmitting}
              className={"blue-button"}
              onClick={preGuardarEntradaHandler.bind(null, null, "emitirFRE")}
            >
              {isSubmitting ? "Recebendo..." : "Receber e emitir fre"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarEntradaDebito;
