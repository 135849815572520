import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import moment from "moment";

function RelatorioMapaVenda({
  dataFiltro,
  dadosClinica,
  auth,
  dadosMapa,
  acao,
  atualizacoes,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      }
    }
  }, [acao, atualizacoes]); // Importante: só reexecuta quando a ação muda

  const gerarRelatorio = async () => {
    try {
      startLoading();
      const formData = new FormData();
      formData.append(
        "dataInicial",
        dataFiltro.dataInicial.format("YYYY-MM-DD")
      );
      formData.append("dataFinal", dataFiltro.dataFinal.format("YYYY-MM-DD"));
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/mapavenda/${dadosClinica._id}`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData.data.length === 0) {
        toast.error("Sem dados para gerar o mapa!");
      } else {
        //Enviar dados de entrada
        dadosMapa(
          responseData.data,
          dadosClinica,
          moment(dataFiltro.dataInicial).format("DD-MM-YYYY"),
          moment(dataFiltro.dataFinal).format("DD-MM-YYYY")
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return <div></div>;
}

export default RelatorioMapaVenda;
