import React, { useState, useEffect } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import FiltrosRelatorio from "../ui/filtrosRelatorio/FiltrosRelatorio";
import RelatorioDocumentosEletronicosComErro from "./components/RelatorioDocumentosEletronicosComErro";
import "./RelatoriosDocumentosEletronicos.scss";

import { useLoading } from "../../../../shared/context/LoadingContext";

const tiposRelatorios = [
  { value: "", label: "Selecione um relatório" },
  { value: "erros", label: "Relatório de Docs com erro" },
  // Adicione mais tipos de relatórios de agendamento conforme necessário
];

// Definição de quais relatórios suportam quais ações
const relatorioCaps = {
  faltas: { gerar: true, exportar: true },
  // Configure as capacidades para outros relatórios aqui
};

function RelatoriosDocumentosEletronicos({ auth, clinica }) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dadosClinica, setDadosClinica] = useState();
  const [dadosRelatorio, setDadosRelatorio] = useState({
    open: "",
    dataFiltro: {},
    acao: "",
    //Contador para forçar a atualização
    atualizacoes: 0,
  });

  useEffect(() => {
    const fetchDadosClinica = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosClinica(responseData.clinica);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchDadosClinica();
  }, [auth.perm, auth.token, sendRequest]);

  const gerarRelatorioHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "gerar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  const exportarFicheiroHandler = (dataInicial, dataFinal, tipoRelatorio) => {
    if (!tipoRelatorio) {
      // Alerta se nenhum tipo de relatório for selecionado
      return;
    }

    setDadosRelatorio((prevState) => ({
      open: tipoRelatorio,
      dataFiltro: { dataInicial: dataInicial, dataFinal: dataFinal },
      acao: "exportar",
      atualizacoes: prevState.atualizacoes + 1,
    }));
  };

  // Função auxiliar para renderizar o componente de relatório correto
  const renderizarRelatorio = () => {
    const { open, dataFiltro, acao, atualizacoes } = dadosRelatorio;

    if (!open) return null;

    const props = {
      dataFiltro: dataFiltro,
      dadosClinica: dadosClinica,
      auth: auth,
      acao: acao,
      atualizacoes: atualizacoes,
    };

    switch (open) {
      case "erros":
        return <RelatorioDocumentosEletronicosComErro {...props} />;
      // Adicione outros casos conforme necessário
      default:
        return null;
    }
  };

  return (
    <div>
      <FiltrosRelatorio
        tiposRelatorios={tiposRelatorios}
        gerarRelatorioHandler={gerarRelatorioHandler}
        exportarFicheiroHandler={exportarFicheiroHandler}
        relatorioCaps={relatorioCaps}
        // Manter o tipo de relatório selecionado
        relatorioSelecionado={dadosRelatorio.open}
      />

      {renderizarRelatorio()}
    </div>
  );
}

export default RelatoriosDocumentosEletronicos;
