import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import moment from "moment";
import "moment/locale/pt-br";

// Material UI imports
import { styled } from "@mui/material/styles";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  LinearProgress,
  Card,
  CardContent,
  Divider,
  Alert,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PieChartIcon from "@mui/icons-material/PieChart";

// Recharts para gráficos
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

// Configurar locale do moment
moment.locale("pt-br");

// Cores para o gráfico
const COLORS = ["#4caf50", "#ff9800", "#2196f3", "#f44336"];

// Componentes estilizados
const StatusChip = styled(Chip)(({ theme, statustype }) => ({
  backgroundColor:
    statustype === "completo"
      ? theme.palette.success.light
      : statustype === "andamento"
      ? theme.palette.primary.light
      : statustype === "naoIniciado"
      ? theme.palette.warning.light
      : theme.palette.grey[300],
}));

function RelatorioProcedimentosCategoria({
  dataFiltro,
  dadosClinica,
  auth,
  acao,
  atualizacoes,
  filtrosAdicionais,
  atualizarFiltrosAdicionais,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [dados, setDados] = useState(null);
  const [error, setError] = useState(null);
  const [expandedCategoria, setExpandedCategoria] = useState(null);

  // Este useEffect executa quando o componente é montado ou quando a ação muda
  useEffect(() => {
    // Se uma ação foi especificada (gerar ou exportar), execute-a
    if (acao) {
      if (acao === "gerar") {
        gerarRelatorio();
      } else if (acao === "exportar") {
        exportarFicheiro();
      }
    }
  }, [acao, atualizacoes, filtrosAdicionais]); // Reexecuta quando a ação ou filtros mudam

  const gerarRelatorio = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar o corpo da requisição com filtros adicionais
      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // ocultarVazios: filtrosAdicionais.ocultarVazios,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      };

      // Chamada para a API
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getRelatorioProcedimentosPorCategoria/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // Atualiza o estado com os dados recebidos
      setDados(responseData.data);
    } catch (err) {
      console.error(
        "Erro ao gerar relatório de procedimentos por categoria:",
        err
      );
      setError(
        err.message || "Ocorreu um erro ao gerar o relatório de procedimentos."
      );
    } finally {
      stopLoading();
    }
  };

  const exportarFicheiro = async () => {
    if (!dadosClinica?._id) return;

    setError(null);
    startLoading();
    try {
      // Formatação das datas para a API
      const dataInicialFormatada = dataFiltro.dataInicial.format("YYYY-MM-DD");
      const dataFinalFormatada = dataFiltro.dataFinal.format("YYYY-MM-DD");

      // Preparar os parâmetros de query com filtros adicionais
      const queryParams = new URLSearchParams({
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // ocultarVazios: filtrosAdicionais.ocultarVazios,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      }).toString();

      const requestBody = {
        dataInicio: dataInicialFormatada,
        dataFim: dataFinalFormatada,
        // ocultarVazios: filtrosAdicionais.ocultarVazios,
        // incluirDesativados: filtrosAdicionais.incluirDesativados,
      };

      // Chamada para a API de exportação
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/getRelatorioProcedimentosPorCategoria/${dadosClinica._id}`,
        "PATCH",
        JSON.stringify(requestBody),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // Verifica se a resposta foi bem-sucedida
      if (response.ok) {
        // Obtém o blob da resposta
        const blob = await response.blob();

        // Cria um URL para o blob
        const url = window.URL.createObjectURL(blob);

        // Cria um elemento <a> para download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Define o nome do arquivo
        const filename = `procedimentos-categoria-${dataInicialFormatada}-a-${dataFinalFormatada}.xlsx`;
        a.download = filename;

        // Adiciona o elemento ao DOM, clica nele e remove-o
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error("Falha ao exportar o ficheiro.");
      }
    } catch (err) {
      console.error("Erro ao exportar ficheiro:", err);
      setError(err.message || "Ocorreu um erro ao exportar o ficheiro.");
    } finally {
      stopLoading();
    }
  };

  // Handler para expandir/recolher categorias
  const handleExpandCategoria = (categoriaId) => {
    setExpandedCategoria(
      expandedCategoria === categoriaId ? null : categoriaId
    );
  };

  // Handler para atualizar os filtros adicionais
  const handleFiltroChange = (event) => {
    atualizarFiltrosAdicionais({
      [event.target.name]: event.target.checked,
    });
  };

  // Calcular totais para o gráfico (se dados existirem)
  const calcularDadosGrafico = () => {
    if (!dados) return [];

    const totalCompletos = dados.reduce((sum, cat) => sum + cat.completos, 0);
    const totalEmAndamento = dados.reduce(
      (sum, cat) => sum + cat.emAndamento,
      0
    );
    const totalNaoIniciados = dados.reduce(
      (sum, cat) => sum + cat.naoIniciados,
      0
    );
    const totalFaltamExecutar = dados.reduce(
      (sum, cat) => sum + cat.faltamExecutar,
      0
    );
    const totalProcedimentos = dados.reduce((sum, cat) => sum + cat.total, 0);

    return [
      { name: "Completos", value: totalCompletos },
      { name: "Em Andamento", value: totalEmAndamento },
      { name: "Não Iniciados", value: totalNaoIniciados },
      {
        name: "Restantes",
        value: totalFaltamExecutar - totalEmAndamento - totalNaoIniciados,
      },
    ];
  };

  // Renderização do componente
  return (
    <Box sx={{ mt: 3 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          Relatório de Procedimentos por Categoria
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Período: {dataFiltro.dataInicial.format("DD/MM/YYYY")} a{" "}
            {dataFiltro.dataFinal.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Clínica: {dadosClinica?.nome || ""}
          </Typography>

          {/* Filtros adicionais */}
          {/* <Box sx={{ mt: 2, mb: 2 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtrosAdicionais.ocultarVazios}
                    onChange={handleFiltroChange}
                    name="ocultarVazios"
                  />
                }
                label="Ocultar categorias vazias"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtrosAdicionais.incluirDesativados}
                    onChange={handleFiltroChange}
                    name="incluirDesativados"
                  />
                }
                label="Incluir itens desativados"
              />
            </FormGroup>
          </Box> */}
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {!dados && !error && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {dados && dados.length === 0 && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Nenhum dado encontrado para o período selecionado.
          </Alert>
        )}

        {dados && dados.length > 0 && (
          <>
            {/* Resumo Geral */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {/* Gráfico de distribuição */}
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Distribuição de Procedimentos
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={calcularDadosGrafico()}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            label={({ name, percent }) =>
                              `${name}: ${(percent * 100).toFixed(0)}%`
                            }
                          >
                            {calcularDadosGrafico().map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            formatter={(value) => [
                              `${value} procedimentos`,
                              "Quantidade",
                            ]}
                          />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Cards de totais */}
              <Grid item xs={12} md={6}>
                <Card sx={{ height: "100%" }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Resumo de Procedimentos
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            bgcolor: "success.light",
                          }}
                        >
                          <Typography variant="subtitle2">Completos</Typography>
                          <Typography
                            variant="h4"
                            sx={{ color: "success.dark" }}
                          >
                            {dados.reduce((sum, cat) => sum + cat.completos, 0)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            bgcolor: "primary.light",
                          }}
                        >
                          <Typography variant="subtitle2">
                            Em Andamento
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{ color: "primary.dark" }}
                          >
                            {dados.reduce(
                              (sum, cat) => sum + cat.emAndamento,
                              0
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            p: 1,
                            borderRadius: 1,
                            bgcolor: "warning.light",
                          }}
                        >
                          <Typography variant="subtitle2">
                            Não Iniciados
                          </Typography>
                          <Typography
                            variant="h4"
                            sx={{ color: "warning.dark" }}
                          >
                            {dados.reduce(
                              (sum, cat) => sum + cat.naoIniciados,
                              0
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{ p: 1, borderRadius: 1, bgcolor: "error.light" }}
                        >
                          <Typography variant="subtitle2">
                            Faltam Executar
                          </Typography>
                          <Typography variant="h4" sx={{ color: "error.dark" }}>
                            {dados.reduce(
                              (sum, cat) => sum + cat.faltamExecutar,
                              0
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Taxa de Conclusão Geral:
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={
                          (dados.reduce((sum, cat) => sum + cat.completos, 0) /
                            dados.reduce((sum, cat) => sum + cat.total, 0)) *
                          100
                        }
                        sx={{ height: 10, borderRadius: 5 }}
                      />
                      <Typography variant="body2" align="right" sx={{ mt: 1 }}>
                        {(
                          (dados.reduce((sum, cat) => sum + cat.completos, 0) /
                            dados.reduce((sum, cat) => sum + cat.total, 0)) *
                          100
                        ).toFixed(1)}
                        %
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Detalhes por Categoria */}
            <Typography variant="h6" gutterBottom>
              Detalhes por Categoria de Tratamento
            </Typography>

            {dados.map((categoria) => (
              <Accordion
                key={categoria.categoriaId}
                expanded={expandedCategoria === categoria.categoriaId}
                onChange={() => handleExpandCategoria(categoria.categoriaId)}
                sx={{ mb: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${categoria.categoriaId}-content`}
                  id={`panel-${categoria.categoriaId}-header`}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {categoria.categoria}
                        {!categoria.ativo && (
                          <Chip
                            label="Inativo"
                            size="small"
                            color="error"
                            sx={{ ml: 1 }}
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <StatusChip
                            icon={<CheckCircleIcon />}
                            label={categoria.completos}
                            size="small"
                            statustype="completo"
                            sx={{ mr: 1 }}
                          />
                          <StatusChip
                            icon={<HourglassEmptyIcon />}
                            label={categoria.emAndamento}
                            size="small"
                            statustype="andamento"
                            sx={{ mr: 1 }}
                          />
                          <StatusChip
                            icon={<WarningIcon />}
                            label={categoria.naoIniciados}
                            size="small"
                            statustype="naoIniciado"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "40%",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ ml: 2, minWidth: 40 }}
                          >
                            {(
                              (categoria.completos / categoria.total) *
                              100
                            ).toFixed(1)}
                            %
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={
                              (categoria.completos / categoria.total) * 100
                            }
                            sx={{
                              flexGrow: 1,
                              ml: 1,
                              height: 10,
                              borderRadius: 5,
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider sx={{ mb: 2 }} />

                  <Grid container spacing={3}>
                    {/* Detalhes do Status */}
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle1" gutterBottom>
                            Status dos Procedimentos
                          </Typography>
                          <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Status</TableCell>
                                  <TableCell align="right">
                                    Quantidade
                                  </TableCell>
                                  <TableCell align="right">
                                    Percentual
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Completos</TableCell>
                                  <TableCell align="right">
                                    {categoria.completos}
                                  </TableCell>
                                  <TableCell align="right">
                                    {(
                                      (categoria.completos / categoria.total) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Em Andamento</TableCell>
                                  <TableCell align="right">
                                    {categoria.emAndamento}
                                  </TableCell>
                                  <TableCell align="right">
                                    {(
                                      (categoria.emAndamento /
                                        categoria.total) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Não Iniciados</TableCell>
                                  <TableCell align="right">
                                    {categoria.naoIniciados}
                                  </TableCell>
                                  <TableCell align="right">
                                    {(
                                      (categoria.naoIniciados /
                                        categoria.total) *
                                      100
                                    ).toFixed(1)}
                                    %
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Total</TableCell>
                                  <TableCell align="right">
                                    {categoria.total}
                                  </TableCell>
                                  <TableCell align="right">100%</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Executados por Médico */}
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <Typography variant="subtitle1" gutterBottom>
                            Médicos Responsáveis (
                            {categoria.porMedico?.length || 0})
                          </Typography>
                          <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Médico</TableCell>
                                  <TableCell align="right">
                                    Quantidade
                                  </TableCell>
                                  <TableCell align="right">
                                    % da Categoria
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {categoria.porMedico &&
                                categoria.porMedico.length > 0 ? (
                                  categoria.porMedico.map((medico) => (
                                    <TableRow key={medico.medicoId}>
                                      <TableCell>
                                        {medico.medico}
                                        {!medico.ativo && (
                                          <Chip
                                            label="Inativo"
                                            size="small"
                                            color="error"
                                            sx={{ ml: 1 }}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell align="right">
                                        {medico.quantidade}
                                      </TableCell>
                                      <TableCell align="right">
                                        {(
                                          (medico.quantidade /
                                            categoria.total) *
                                          100
                                        ).toFixed(1)}
                                        %
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={3} align="center">
                                      Nenhum médico registrado para esta
                                      categoria
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Paper>
    </Box>
  );
}

export default RelatorioProcedimentosCategoria;
