export function fixSpaces(str) {
  if (str) {
    // Trim leading and trailing spaces
    str = str.trim();
    // Replace multiple spaces with a single space
    str = str.replace(/\s+/g, " ");
  } else {
    str = "";
  }
  return str;
}
