import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import toast from "react-hot-toast";
import { useHttpClient } from "../../../../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import { produce } from "immer";
import "./ModalEditarMajoracao.scss";

function ModalEditarMajoracao({
  open,
  handleCloseModalEditarMajoracao,
  modalData,
  updateContaCorrente,
  contaCorrente,
  auth,
}) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();

  const [caixaClinicaId, setCaixaClinicaId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [majoracao, setMajoracao] = useState(
    modalData.orcamento.majoracao || 0
  );

  // Valores constantes
  const subtotal = modalData.orcamento.subtotal;
  const desconto = modalData.orcamento.desconto || 0;

  // Função para calcular o total após majoração e desconto
  const calcularTotal = (majoracaoValue) => {
    return subtotal - majoracaoValue - desconto;
  };

  const porReceber = modalData.parcelas.reduce(
    (accumulator, currentValue) =>
      currentValue.parcelaPaga
        ? accumulator
        : accumulator + currentValue.quantiaPorPagar,
    0
  );

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${contaCorrente.clinica}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCaixaClinicaId(
          responseData.caixas.find((cai) => cai.caixa === "Clínica")._id
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, []);

  const submit = async () => {
    startLoading();
    try {
      // Verifica se a majoração foi alterada
      if (majoracao === (modalData.orcamento.majoracao || 0)) {
        toast.error("A majoração permanece a mesma. Altere-a para continuar");
        setIsSubmitting(false);
        return;
      }

      if (majoracao === modalData.orcamento.subtotal) {
        toast.error(
          "A majoração não pode ter o mesmo valor do que o subtotal do orçamento!"
        );
        setIsSubmitting(false);
        return;
      }

      // Verificar se o total será zero com a nova majoração
      if (calcularTotal(majoracao) === 0) {
        await handleFullExemption();
        return;
      }

      // Cria uma cópia profunda da contaCorrente para manipulação
      const contasAtualizadas = produce(contaCorrente.contas, (draft) => {
        const contaIndex = draft.findIndex(
          (conta) => conta._id === modalData._id
        );

        if (contaIndex !== -1) {
          const contaAtual = draft[contaIndex];

          // Determina o tipo de alteração de majoração
          const majoracaoAnterior = modalData.orcamento.majoracao || 0;
          const diferencaMajoracao = majoracao - majoracaoAnterior;

          if (diferencaMajoracao > 0) {
            // Aumentando a majoração (reduzindo o valor a pagar)
            handleMajoracaoIncrease(contaAtual, diferencaMajoracao);
          } else if (diferencaMajoracao < 0) {
            // Reduzindo a majoração (aumentando o valor a pagar)
            handleMajoracaoDecrease(contaAtual, Math.abs(diferencaMajoracao));
          }

          // Calcular o novo total considerando majoração
          contaAtual.total = calcularTotal(majoracao);

          // Verifica se a conta foi liquidada
          const temParcelasNaoPagas = contaAtual.parcelas.some(
            (p) => !p.parcelaPaga
          );
          contaAtual.contaLiquidada = !temParcelasNaoPagas;
        }
      });

      // Atualiza os dados da majoração no orçamento
      await updateOrcamentoMajoracao();

      //Atualizando os campos orcamento para que contenha apenas o id, antes de enviar para a backend
      const processedContasAtualizadas = contasAtualizadas.map((conta) => {
        if (
          conta.orcamento &&
          typeof conta.orcamento === "object" &&
          conta.orcamento._id
        ) {
          return {
            ...conta,
            orcamento: conta.orcamento._id,
          };
        }
        return conta;
      });

      // Atualiza a contaCorrente com as parcelas modificadas
      const formData2 = new FormData();
      formData2.append("contas", JSON.stringify(processedContasAtualizadas));

      const contaCorrenteParaState = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
        "PATCH",
        formData2,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success(
        `Majoração atualizada com sucesso!${
          contasAtualizadas.find((c) => c._id === modalData._id).contaLiquidada
            ? " Conta Liquidada!"
            : ""
        }`
      );

      updateContaCorrente(contaCorrenteParaState.contaCorrente);
    } catch (err) {
      console.error("err", err);
    } finally {
      handleCloseModalEditarMajoracao();
      stopLoading();
    }
  };

  // Função para atualizar o orçamento com a nova majoração
  const updateOrcamentoMajoracao = async (totalOverride = null) => {
    const formData = new FormData();
    formData.append("majoracao", Number(majoracao));

    // Usar totalOverride se fornecido, caso contrário calcular normalmente
    const novoTotal =
      totalOverride !== null ? totalOverride : calcularTotal(Number(majoracao));
    formData.append("total", novoTotal);

    formData.append("utilizador", auth.userId);

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/dadosmajoracao/${modalData.orcamento._id}`,
        "PATCH",
        formData,
        { Authorization: "Bearer " + auth.token }
      );
    } catch (err) {
      console.error("err", err);
    }
  };

  // Função para gerenciar aumento de majoração (reduz o valor a pagar)
  const handleMajoracaoIncrease = (draft, diferencaMajoracao) => {
    const parcelasNaoPagas = draft.parcelas.filter((p) => !p.parcelaPaga);

    // Utiliza a lógica existente para encontrar combinações
    let combinacoesPossiveis = encontrarCombinacoesExatas(
      parcelasNaoPagas,
      diferencaMajoracao
    );

    // Se não houver combinação exata, busca a mais próxima
    if (combinacoesPossiveis.length === 0) {
      combinacoesPossiveis = encontrarMelhorCombinacao(
        parcelasNaoPagas,
        diferencaMajoracao
      );
    }

    if (combinacoesPossiveis.length > 0) {
      const parcelasParaReduzir = combinacoesPossiveis[0];
      let majoracaoRestante = diferencaMajoracao;

      // Aplicando a redução às parcelas selecionadas
      for (const parcela of parcelasParaReduzir) {
        const index = draft.parcelas.findIndex((p) => p._id === parcela._id);

        if (majoracaoRestante >= parcela.quantiaPorPagar) {
          // Remove a parcela completamente
          draft.parcelas.splice(index, 1);
          majoracaoRestante -= parcela.quantiaPorPagar;
        } else {
          // Reduz o valor da parcela
          draft.parcelas[index].quantiaPorPagar -= majoracaoRestante;
          majoracaoRestante = 0;
        }
      }

      // Aplica majoração restante à próxima parcela, se houver
      if (majoracaoRestante > 0) {
        const proximaParcelaNaoPaga = draft.parcelas.find(
          (p) => !p.parcelaPaga
        );
        if (proximaParcelaNaoPaga) {
          const index = draft.parcelas.indexOf(proximaParcelaNaoPaga);
          if (majoracaoRestante >= proximaParcelaNaoPaga.quantiaPorPagar) {
            draft.parcelas.splice(index, 1);
          } else {
            draft.parcelas[index].quantiaPorPagar -= majoracaoRestante;
          }
        }
      }

      // Renumera as parcelas
      renumberParcelas(draft);
    }
  };

  // Função para gerenciar redução de majoração (aumenta o valor a pagar)
  const handleMajoracaoDecrease = (draft, diferencaMajoracao) => {
    // Cria uma nova parcela com o valor da diferença
    const novaParcela = {
      quantiaPaga: 0,
      quantiaPorPagar: diferencaMajoracao,
      dataLimitePagamento: new Date(),
      parcelaPaga: false,
      cancelamentosDocumentosEletronicos: [],
    };

    // Adiciona a nova parcela
    draft.parcelas.push(novaParcela);
    draft.nrParcelas = draft.parcelas.length;
    draft.contaLiquidada = false;

    // Renumera as parcelas
    renumberParcelas(draft);
  };

  const preSubmitHandler = () => {
    setIsSubmitting(true);
    submit();
  };

  const majoracaoChangeHandler = (e) => {
    const newValue = e.target.value;

    // Expressão regular para validar números inteiros
    const validNumberRegex = /^(\d*)$/;
    if (!validNumberRegex.test(newValue)) {
      return; // Ignora entradas inválidas
    }

    // Se o valor não for um número válido, mantenha o valor anterior
    if (isNaN(parseInt(newValue)) && newValue !== "") {
      return;
    }

    const numericValue = parseInt(newValue) || 0;

    // Modificar esta parte: verificar o limite máximo
    // A majoração não pode ser maior que a quantia por receber
    const limiteMajoracao = Math.min(
      subtotal - desconto,
      porReceber + modalData.orcamento.majoracao
    );

    if (numericValue > limiteMajoracao) {
      setMajoracao(limiteMajoracao);
    } else {
      setMajoracao(numericValue);
    }
  };

  // Modificada para usar a nova abordagem
  function encontrarCombinacoesExatas(parcelas, valorAlvo) {
    const combinacoes = [];
    encontrarCombinacoesRecursivo(parcelas, valorAlvo, [], 0, 0, combinacoes);
    return combinacoes;
  }

  function encontrarCombinacoesRecursivo(
    parcelas,
    valorAlvo,
    combinacaoAtual,
    somaAtual,
    index,
    combinacoes
  ) {
    if (Math.abs(somaAtual - valorAlvo) < 0.01) {
      combinacoes.push([...combinacaoAtual]);
      return;
    }

    if (index === parcelas.length || somaAtual > valorAlvo) {
      return;
    }

    // Incluir a parcela atual
    encontrarCombinacoesRecursivo(
      parcelas,
      valorAlvo,
      [...combinacaoAtual, parcelas[index]],
      somaAtual + parcelas[index].quantiaPorPagar,
      index + 1,
      combinacoes
    );

    // Não incluir a parcela atual
    encontrarCombinacoesRecursivo(
      parcelas,
      valorAlvo,
      combinacaoAtual,
      somaAtual,
      index + 1,
      combinacoes
    );
  }

  // Versão simplificada para encontrar a melhor combinação
  function encontrarMelhorCombinacao(parcelas, valorAlvo) {
    // Ordenar parcelas do menor para o maior valor
    const parcelasOrdenadas = [...parcelas].sort(
      (a, b) => a.quantiaPorPagar - b.quantiaPorPagar
    );

    let melhorCombinacao = [];
    let melhorDiferenca = valorAlvo;

    // Tenta combinações simples primeiro (1 ou 2 parcelas)
    for (let i = 0; i < parcelasOrdenadas.length; i++) {
      const parcelaA = parcelasOrdenadas[i];
      const diferencaA = Math.abs(valorAlvo - parcelaA.quantiaPorPagar);

      if (diferencaA < melhorDiferenca) {
        melhorCombinacao = [parcelaA];
        melhorDiferenca = diferencaA;
      }

      // Tenta com duas parcelas
      for (let j = i + 1; j < parcelasOrdenadas.length; j++) {
        const parcelaB = parcelasOrdenadas[j];
        const soma = parcelaA.quantiaPorPagar + parcelaB.quantiaPorPagar;
        const diferenca = Math.abs(valorAlvo - soma);

        if (diferenca < melhorDiferenca) {
          melhorCombinacao = [parcelaA, parcelaB];
          melhorDiferenca = diferenca;
        }
      }
    }

    // Se a melhor combinação não for próxima o suficiente, tenta a abordagem gulosa
    if (melhorDiferenca > valorAlvo * 0.1 && parcelasOrdenadas.length > 2) {
      const combinacaoGulosa = [];
      let somaGulosa = 0;

      for (const parcela of parcelasOrdenadas) {
        if (somaGulosa + parcela.quantiaPorPagar <= valorAlvo) {
          combinacaoGulosa.push(parcela);
          somaGulosa += parcela.quantiaPorPagar;
        }
      }

      const diferencaGulosa = Math.abs(valorAlvo - somaGulosa);
      if (diferencaGulosa < melhorDiferenca) {
        melhorCombinacao = combinacaoGulosa;
      }
    }

    return [melhorCombinacao];
  }

  // Função para rearanjar a numeração das parcelas ignorando a parcela de entrada caso ela exista
  const renumberParcelas = (contaFiltrada) => {
    let regularParcelaCount = 0;
    const regularParcelas = contaFiltrada.parcelas.filter((p) => !p.entrada);

    contaFiltrada.parcelas.forEach((p) => {
      if (!p.entrada) {
        regularParcelaCount++;
        p.nrParcela = `${regularParcelaCount}/${regularParcelas.length}`;
      } else {
        delete p.nrParcela; // Ensure entrada parcels don't have nrParcela
      }
    });

    contaFiltrada.nrParcelas = regularParcelas.length;
  };

  // Função para tratar isenção total
  const handleFullExemption = async () => {
    try {
      const novaConta = produce(modalData, (draft) => {
        draft.contaLiquidada = true;
        draft.nrParcelas = 1;
        draft.orcamento = modalData.orcamento._id;
        draft.total = 0;
        draft.parcelas = [
          {
            cancelamentosDocumentosEletronicos:
              modalData.parcelas[0].cancelamentosDocumentosEletronicos,
            dataLimitePagamento: new Date(),
            dataPagamento: new Date(),
            parcelaPaga: true,
            metodoPagamento: "Dinheiro",
            quantiaPaga: 0,
            quantiaPorPagar: 0,
            nrParcela: "1/1",
            observacao: "Parcela de isenção",
            caixa: caixaClinicaId,
          },
        ];
      });

      await updateOrcamentoMajoracao(0);

      const contasAtualizadas = produce(contaCorrente.contas, (draft) => {
        const index = draft.findIndex((c) => c._id === modalData._id);
        if (index !== -1) {
          draft[index] = novaConta;
        }
      });

      const formData2 = new FormData();
      formData2.append("contas", JSON.stringify(contasAtualizadas));

      const contaCorrenteParaState = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
        "PATCH",
        formData2,
        { Authorization: "Bearer " + auth.token }
      );

      toast.success(
        "Majoração atualizada com sucesso! Conta Fechada e isenta de pagamento."
      );
      updateContaCorrente(contaCorrenteParaState.contaCorrente);
    } catch (err) {
      console.error("err", err);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalEditarMajoracao}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
          id="modal__cinquentaPorCento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Editar Majoração do Orçamento {modalData.orcamento.codigo}
          </h1>

          <div className="modalEditarDesconto__container">
            <TextField
              className="modalEditarDesconto__container__inputText"
              label="Majoração"
              value={majoracao}
              onChange={majoracaoChangeHandler}
            />

            <span className="modalEditarDesconto__container__valor">
              Valor: {majoracao.toLocaleString("pt-br")}$00
            </span>
          </div>

          <div className="modalEditarDesconto__dadosOrcamento">
            <span className="modalEditarDesconto__dadosOrcamento__titulo">
              Dados do Orçamento
            </span>
            <span className="modalEditarDesconto__dadosOrcamento__subtitulo">
              Antes
            </span>
            <span className="modalEditarDesconto__dadosOrcamento__subtitulo">
              Depois
            </span>

            {/* Subtotal antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Subtotal
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {subtotal.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Subtotal depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Subtotal
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {subtotal.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Majoração antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Majoração
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {(modalData.orcamento.majoracao || 0).toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Majoração depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Majoração
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {majoracao.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Desconto antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Desconto
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {desconto.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Desconto depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Desconto
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {desconto.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Total antes */}
            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Total
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {modalData.orcamento.total.toLocaleString("pt-Br")}
              </span>
            </div>

            {/* Total depois */}
            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Total
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {calcularTotal(majoracao).toLocaleString("pt-Br")}
              </span>
            </div>

            <div className="modalEditarDesconto__dadosOrcamento__linha">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Por Receber
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {porReceber.toLocaleString("pt-Br")}
              </span>
            </div>

            <div className="modalEditarDesconto__dadosOrcamento__linha modalEditarDesconto__dadosOrcamento__linha--right">
              <span className="modalEditarDesconto__dadosOrcamento__legenda">
                Por Receber
              </span>
              <span className="modalEditarDesconto__dadosOrcamento__valor">
                {(
                  porReceber +
                  (modalData.orcamento.majoracao || 0) -
                  majoracao
                ).toLocaleString("pt-Br")}
              </span>
            </div>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEditarMajoracao}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={preSubmitHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarMajoracao;
